export const validText = (val: any, minLength = 1) => {
	if (val){
		val = val.toString()
		if (val.length > minLength){
			console.log("return true", val)
			return true;
		}
	}
	console.log("return false", val);
	return false
}
