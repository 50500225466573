import { InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  getActiveCompanies,
  getUsersActivityByCompany,
} from "../../store/actions/accountActions";
import { ICompany } from "../../interfaces/model/MSHAPCT/company.interface";
import MaterialTable from "material-table";
import { tableIcons } from "../shared/MaterialTableShared";
import { result } from "lodash";
import UsersUsageTable from "./UsersUsageTable";

interface IAdministrationProps {}

const UsageStatistics: React.FC<IAdministrationProps> = (props) => {
  const dispatch = useDispatch();

  const [selectedCompany, setSelectedCompany] = useState<number | undefined>(0);
  const [allCompanies, setAllCompanies] = useState<ICompany[]>([]);
  const [usersData, setUsersData] = useState([] as any[]);
  const handleChangeCompany = (val: any) => {
    setSelectedCompany(val);
  };

  useEffect(() => {
    dispatch(
      getActiveCompanies((result: ICompany[]) => {
        setAllCompanies(result);
        setSelectedCompany(result[0].pk);
      })
    );
    getCompanyData(selectedCompany);
  }, []);

  useEffect(() => {
    getCompanyData(selectedCompany);
  }, [selectedCompany]);

  function getCompanyData(companypk: number | undefined) {
    dispatch(
      getUsersActivityByCompany({ companypk: companypk }, (result: any) => {
        setUsersData(result);
      })
    );
  }
  console.log("usersData", usersData);
  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div className="flex-column-oriented mr-16" style={{ width: "25%" }}>
          {/* <label htmlFor="Mine Selector">Mine Selector</label> */}
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Company
          </InputLabel>
          <Select
            style={{ height: 40 }}
            id="inlineFormCustomSelect"
            variant="outlined"
            value={selectedCompany}
            onChange={(e) => {
              handleChangeCompany(e.target.value);
            }}
            displayEmpty
          >
            {Object.keys(allCompanies).map((key: any) => {
              return (
                <MenuItem key={key} value={allCompanies[key].pk}>
                  {allCompanies[key].companyName}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
      <br></br>
      <UsersUsageTable title={"Users"} data={usersData} />
    </>
  );
};
export default UsageStatistics;
