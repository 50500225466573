import React, { useState } from "react";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Loading from "../shared/Loading";
import { useDispatch } from "react-redux";
import { createCompany } from "../../store/actions/companyActions";
import { useHistory } from "react-router";

interface IAddCompanyModalProps {
  open: boolean;
  onClose: Function;
}

const AddCompanyModal: React.FC<IAddCompanyModalProps> = (props) => {
  const [companyName, setCompanyName] = useState("");

  const [contractorName, setContractorName] = useState("");
  const [contractorId, setContractorId] = useState("");

  const [isPubliclyTraded, setIsPubliclyTraded] = useState(false);
  const [isMineStateAllowed, setIsMineStateAllowed] = useState(false);
  const [contractorCompany, setContractorCompany] = useState(false);
  const [alwaysOverrideMSHAImport, setAlwaysOverrideMSHAImport] =
    useState(false);
  const [enableEmailNotification, setEnableEmailNotification] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [processsing, setProcesssing] = useState(false);

  const resetForm = () => {
    setCompanyName("");
    setIsPubliclyTraded(false);
    setIsMineStateAllowed(false);
    setContractorCompany(false);
    setAlwaysOverrideMSHAImport(false);
    setEnableEmailNotification(false);
  };

  const submitForm = () => {
    //dispatch add company, show loader wait, and then close
    setProcesssing(true);
    dispatch(
      createCompany(
        {
          companyName,
          contractorCompany,
          contractorName,
          contractorId,
          isPubliclyTraded,
          isMineStateAllowed,
          alwaysOverrideMSHAImport,
          enableEmailNotification,
        },
        (primaryKey: number) => {
          setProcesssing(false);
          history.push(`/Configuration/Company/${primaryKey}/Edit`);
        },
        () => {
          setProcesssing(false);
        }
      )
    );
  };

  return (
    <>
      <Loading loading={processsing} />
      <Dialog
        onClose={() => props.onClose()}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <MuiDialogTitle>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 3, marginTop: 10 }}>
              <Typography variant="h6">Add Company</Typography>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <IconButton aria-label="close" onClick={() => props.onClose()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Company Name"
                id="standard-size-normal"
                variant="standard"
                value={companyName}
                onChange={(event) => setCompanyName(event?.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label="Is Publicaly Traded"
                control={
                  <Checkbox
                    checked={isPubliclyTraded}
                    onChange={(event) => {
                      setIsPubliclyTraded(event.target.checked);
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label="Is Mine State Allowed"
                control={
                  <Checkbox
                    checked={isMineStateAllowed}
                    onChange={(event) => {
                      setIsMineStateAllowed(event.target.checked);
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControlLabel
                    label="Contractor Company"
                    control={
                      <Checkbox
                        checked={contractorCompany}
                        onChange={(event) => {
                          setContractorCompany(event.target.checked);
                        }}
                        color="primary"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <TextField
                    label="Contractor Name"
                    id="standard-size-normal"
                    variant="standard"
                    value={contractorName}
                    onChange={(event) => setContractorName(event?.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Contractor ID"
                    id="standard-size-normal"
                    variant="standard"
                    value={contractorId}
                    onChange={(event) => setContractorId(event?.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label="Always Override MSHA Import"
                control={
                  <Checkbox
                    checked={alwaysOverrideMSHAImport}
                    onChange={(event) => {
                      setAlwaysOverrideMSHAImport(event.target.checked);
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label="Enable Email Notifications"
                control={
                  <Checkbox
                    checked={enableEmailNotification}
                    onChange={(event) => {
                      setEnableEmailNotification(event.target.checked);
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
          </Grid>
        </MuiDialogTitle>
        <DialogActions>
          <Button
            variant={"outlined"}
            autoFocus
            onClick={() => submitForm()}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddCompanyModal;
