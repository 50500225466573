import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { get } from "lodash";
import { HttpResponse, HttpStatus } from "../interfaces/api/HttpResponse";
import { PublicResource } from "../interfaces/api/PublicResource";

const API_URL: string =
  process.env.REACT_APP_API_HOST || "https://localhost:44381";

interface IAxiosHttpResponseWrapper<T> {
  data: T;
  errors: unknown[];
}

export const objectToQuery = (obj: any) => {
  return (
    "?" +
    Object.keys(obj)
      .map((key) => key + "=" + obj[key])
      .join("&")
  );
};

export default class HttpClient {
  private url: string;

  private publicResources: PublicResource[] = [];

  private currentResource: string;

  private axios = axios.create();

  constructor() {
    this.url = `${API_URL}`;
    this.currentResource = "";
    this.axios.interceptors.request.use(this.requestInterceptor.bind(this));
  }

  private async requestInterceptor(config: AxiosRequestConfig) {
    if (this.publicResources.length > 0) {
      const isResourcePublic = !!this.publicResources.find(
        (r) => r.resource === this.currentResource && r.method === config.method
      );
      if (isResourcePublic) {
        return config;
      }
    }

    // if (!config.headers) {
    //   config.headers = { Authorization: this.token };
    // } else {
    //   (config.headers as Record<string, unknown>).Authorization = this.token;
    // }
    // config.withCredentials = false;
    const token = localStorage.getItem("jwt");
    config.headers = { Authorization: `bearer ${token}` };
    return config;
  }

  public setPublicResources(resources: PublicResource[]): void {
    this.publicResources = resources;
  }

  async get<Data>(
    resource?: string,
    options?: AxiosRequestConfig
  ): Promise<HttpResponse<any>> {
    this.currentResource = resource ?? "";
    const endpoint = resource ? `${this.url}/${resource}` : this.url;

    try {
      const response = await this.axios.get<{ data: Data; errors: unknown[] }>(
        endpoint,
        options
      );
      return {
        status: response.status as HttpStatus,
        data: response.data,
        errors: [],
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      if (get(error, "response.status", 500) === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
      throw new Error(error.response?.data.error);
    }
  }

  async getFile<Data>(
    resource?: string,
    options?: AxiosRequestConfig
  ): Promise<HttpResponse<any>> {
    this.currentResource = resource ?? "";
    const endpoint = resource ? `${this.url}/${resource}` : this.url;

    try {
      const options: AxiosRequestConfig = { responseType: "blob" };
      const response = await this.axios.get<{ data: Data; errors: unknown[] }>(
        endpoint,
        options
      );
      return {
        status: response.status as HttpStatus,
        data: response.data,
        errors: [],
      };
    } catch (e) {
      const error = e as any;
      if (error.response?.data.error) {
        throw new Error(error.response?.data.error);
      } else if (error.response.status === 401) {
        throw new Error("Unauthorized resource access.");
      } else {
        throw new Error("An unexpected error ocurried.");
      }
    }
  }

  async getFileByParameter<Data>(
    resource?: string,
    data?: unknown,
    options?: AxiosRequestConfig
  ): Promise<HttpResponse<any>> {
    this.currentResource = resource ?? "";
    const endpoint = resource ? `${this.url}/${resource}` : this.url;

    try {
      const options: AxiosRequestConfig = { responseType: "blob" };
      const response = await this.axios.post<{ data: Data; errors: unknown[] }>(
        endpoint,
        data,
        options
      );
      return {
        status: response.status as HttpStatus,
        data: response.data,
        errors: [],
      };
    } catch (e) {
      const error = e as any;
      if (error.response?.data.error) {
        throw new Error(error.response?.data.error);
      } else if (error.response.status === 401) {
        throw new Error("Unauthorized resource access.");
      } else {
        throw new Error("An unexpected error ocurried.");
      }
    }
  }

  async uploadFiles<Data>(
    resource?: string,
    data?: unknown,
    options?: AxiosRequestConfig
  ): Promise<HttpResponse<any>> {
    this.currentResource = resource ?? "";
    const endpoint = resource ? `${this.url}/${resource}` : this.url;

    try {
      const options: AxiosRequestConfig = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      const response = await this.axios.post<IAxiosHttpResponseWrapper<Data>>(
        endpoint,
        data,
        options
      );

      return {
        status: response.status as HttpStatus,
        data: response.data,
        errors: [],
      };
    } catch (e) {
      const error = e as any;
      if (error.response?.data.error) {
        throw new Error(error.response?.data?.error);
      } else if (error?.response?.status === 401) {
        throw new Error("Unauthorized resource access.");
      } else {
        throw new Error("An unexpected error ocurried.");
      }
    }
  }

  async postFormData<Data>(
    resource?: string,
    data?: unknown,
    options?: AxiosRequestConfig
  ): Promise<HttpResponse<any>> {
    this.currentResource = resource ?? "";
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      // const token = localStorage.getItem("jwt");

      // const result = await axios({
      //   method: "post",
      //   url: endpoint,
      //   data: data,
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     Authorization: `bearer ${token}`,
      //   },
      // });

      const response = await this.axios.post<IAxiosHttpResponseWrapper<Data>>(
        endpoint,
        data,
        options
      );
      return {
        status: response.status,
        data: response.data,
        errors: response.data.errors,
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      throw new Error(error.response?.data.error);
    }
  }

  async post<Data>(
    resource?: string,
    data?: unknown,
    options?: AxiosRequestConfig
  ): Promise<HttpResponse<any>> {
    this.currentResource = resource ?? "";
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      const response = await this.axios.post<IAxiosHttpResponseWrapper<Data>>(
        endpoint,
        data,
        options
      );
      return {
        status: response.status,
        data: response.data,
        errors: response.data.errors,
      };
    } catch (e) {
      const error = e as any;
      if (error.response?.data.error) {
        throw new Error(error.response?.data.error);
      } else if (error.response?.data.errors) {
        throw new Error(error.response?.data.errors[0]);
      } else {
        throw new Error("An unexpected error ocurried.");
      }
    }
  }

  async patch<Data>(
    resource?: string,
    data?: unknown,
    options?: AxiosRequestConfig
  ): Promise<HttpResponse<Data>> {
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      const response = await this.axios.patch<IAxiosHttpResponseWrapper<Data>>(
        endpoint,
        data,
        options
      );
      return {
        status: response.status,
        data: response.data.data,
        errors: response.data.errors,
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      throw new Error(error.response?.data.error);
    }
  }

  async put<Data>(
    resource?: string,
    data?: unknown,
    options?: AxiosRequestConfig
  ): Promise<HttpResponse<any>> {
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      const response = await this.axios.put<IAxiosHttpResponseWrapper<Data>>(
        endpoint,
        data,
        options
      );
      return {
        status: response.status,
        data: response.data,
        errors: response.data.errors,
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      throw new Error(error.response?.data.error);
    }
  }

  async delete<Data>(
    resource?: string,
    options?: AxiosRequestConfig
  ): Promise<HttpResponse<any>> {
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      const response = await this.axios.delete<IAxiosHttpResponseWrapper<Data>>(
        endpoint,
        options
      );
      return {
        status: response.status,
        data: response.data,
        errors: response.data.errors,
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      throw new Error(error.response?.data.error);
    }
  }
}
