import { ACTIONS } from "../../enums/actions";

export const getInspection = (
  payload: { pk: number },
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_INSPECTION,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getInspectionsByMine = (
  payload: { minepk: number },
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_INSPECTIONS_BY_MINE,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const addInspection = (
  payload: {
    mineid: any;
    startDate: string;
    endDate: string;
    vpidHourse: string;
    eventNumber: string;
    dataSource: string;
    inspectionStatus: null;
    notes: string;
  },
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.ADD_INSPECTION,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
  stateKey: "main",
});

export const updateInspection = (
  payload: {
    mineid: any;
    startDate: string;
    endDate: string;
    vpidHourse: string;
    eventNumber: string;
    dataSource: string;
    inspectionStatus: null;
    notes: string;
  },
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.UPDATE_INSPECTION,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
  stateKey: "main",
});

export const deleteInspection = (
  payload: {
    pk: any;
  },
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.DELETE_INSPECTION,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
  stateKey: "main",
});


export const getInspectionByEventNumber = (
  payload: { mineid: any; eventNumber: string },
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_INSPECTION_BY_EVENT_NUMBER,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
  stateKey: "flatfile",
});

export const getInspectorARList = (
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_INSPECTOR_AR_LIST,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
  stateKey: "main",
});

export const getInspectorTrends = (
  payload: { inspectorar: any, minepk: any; contractorpk: any },
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_INSPECTOR_TRENDS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
  stateKey: "main",
});

