import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 4000,
    color: "#fff",
  },
}));

interface ILoadingProps {
  loading: boolean;
}

const Loading: React.FC<ILoadingProps> = (props) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={props.loading}>
      <CircularProgress color="inherit" />
      {/* <ScheduleOutlined fontSize={"large"} /> */}
    </Backdrop>
  );
};

export default Loading;
