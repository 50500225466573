import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Container, Grid } from "@material-ui/core";
import BuildRoundedIcon from "@material-ui/icons/BuildRounded";
import { COLORS } from "../../enums/layout-enums";
import { IRootState } from "../../interfaces/store";
import Login from "../auth/Login";
interface ILandingProps {}

const Landing: React.FC<ILandingProps> = (props) => {

  const jwt_token = useSelector((state: IRootState) => state.main.jwt_token);
  let jwt = localStorage.getItem('jwt')

  useEffect(()=>{
    
    fetch(process.env.REACT_APP_API_HOST + "/api/test/verify-jwt",{
      headers:new Headers({
        'Authorization': 'Bearer ' + jwt,
      })
    }).then(response => 
    {
          let statusCode = response.status;
          if(statusCode == 401){   
            if(jwt)
             window.location.href = "/";
            localStorage.clear();
          }

    }
  ).catch(error => console.error(error));
  },[])


  return (
    <Container
      style={{ minHeight: "100vh", backgroundColor: COLORS.WHITE_PAPER }}
    >
      <div
        style={{ display: "flex", justifyContent: "flex-end", paddingTop: 32 }}
      >
        <div
          style={{
            flex: 2,
            color: COLORS.BLUE_MEDIUM_DARK_BACKGROUND,
            paddingRight: 32,
          }}
        >
          <h1>Automated Citation and Penalty Management</h1>
          <hr />
          <div style={{ lineHeight: 2, color: COLORS.GREY_DARK }}>
            <ol>
              <li>Setup an account with us.</li>
              <li>
                Within 48 hours, we will register your mines and retrieve your
                historical citation data from MSHA.
              </li>
              <li>Upload your new citations when you get them.</li>
              <li>
                Our proprietary software will calculate and predict:
                <ul>
                  <li>your likely penalty costs</li>
                  <li>
                    the possible effect of your citations on Potential Pattern
                    of Violations, along with future RPID and VPID points; and
                  </li>
                  <li>identify your most vulnerable compliance areas.</li>
                </ul>
              </li>
            </ol>
          </div>
        </div>

        {!jwt_token ? (
          <div style={{ flex: 1 }}>
            <Login />
          </div>
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          paddingTop: 16,
        }}
      >
        {!jwt_token ? <Button onClick={() => {window.location.href = '/Information/ContactUs'}} variant="outlined">Get Started</Button> : null}
      </div>
      <Grid container wrap="nowrap">
        {/* <div
          style={{
            color: COLORS.GREY_DARK,
            lineHeight: 1.3,
            flex: 2,
            marginTop: "1rem",
          }}
        >
          <h1 style={{ color: COLORS.BLUE_MEDIUM_DARK_BACKGROUND }}>
            Featured Customer
          </h1>
          <div style={{ float: "left", marginRight: "1rem" }}>
            <img
              alt="Newmont Mining Company logo"
              src={process.env.PUBLIC_URL + '/NewmontLogo.png'}
            />
          </div>
          <strong>LETTER FROM NEWMONT MINING COMPANY:</strong>
          <br></br>
          <p>
            "We are looking forward not only to bringing value to their
            production, but increasing safety and compliance as well. This is a
            new product and its very exciting to see it in action. Its also
            gratifying to see the reaction of those who are working with it. We
            are very happy to work interactively with them to make our product
            even better."
          </p>
          <br></br>- Mr. Jon Upsahw, Newmont Mining Company, SLP Data Analyst,
          September 21, 2009
        </div> */}
        <div style={{ color: COLORS.GREY_DARK, lineHeight: 2, flex: 1 }}>
          <hr />
          <Grid>
            <Grid container alignItems="center" style={{marginTop: 32}}>
              <BuildRoundedIcon />{" "}
              <h3 style={{ paddingLeft: "15px", margin: 0 }}>
                Instant Penalty Calculation
              </h3>
            </Grid>
            <ul>
              <li>
                Upload a new citation using your own scanner and we instantly
                calculate what the <i>likely penalty will be.</i>
              </li>
              <li>
                Identify how the new citation may affect your penalties for
                future citations (VPID and RPID) as well as possible Pattern of
                Violations consideration.
              </li>
            </ul>
          </Grid>
          <Grid>
            <Grid container alignItems="center">
              <BuildRoundedIcon />
              <h3 style={{ paddingLeft: "15px", margin: 0, lineHeight: 1.2 }}>
                Dispute Impact Analysis/Conference Planning
              </h3>
            </Grid>
            <ul>
              <li>
                Instantly determine the possible impact of contesting criteria
                on the citation.
              </li>
              <li>
                Identify how much money a successful contest can save you (or
                what an upward modification will cost you!).
              </li>
            </ul>
          </Grid>
          <Grid>
            <Grid container alignItems="center">
              <BuildRoundedIcon />{" "}
              <h3 style={{ paddingLeft: "15px", margin: 0 }}>
                Citation Management
              </h3>
            </Grid>
            <ul>
              <li>Create your own reports.</li>
              <li>Manage your citation data from one central location.</li>
            </ul>
          </Grid>
          <p>
            <i>
              * Remember, we are not MSHA and cannot guarantee that our penalty
              calculations will always exactly reflect the assessment arrived at
              by them. Although the system is set up to alert you to any
              differences between the predicted penalty and the actual penalty,
              you are ultimately responsible for checking and verifying all data
              that you enter and making sure that you are using the most
              accurate data regarding the penalty. Predictive Compliance is just
              a planning tool and cannot be responsible for the results of any
              course of action resulting from the use of its data.
            </i>
          </p>
        </div>
      </Grid>
    </Container>
  );
};

export default Landing;
