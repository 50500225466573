
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Disclaimer from "./Disclaimer";
import FAQ from "./FAQ";
import LegalNotice from "./LegalNotice";
import OurServices from "./OurServices";
import PredictiveCompliance from "./PredictiveCompliance";
import PressReleases from "./PressReleases";
import PricingAndLicensing from "./PricingAndLicensing";
import PrivacyPolicy from "./PrivacyPolicy";
import SuccessStories from "./SuccessStories";
import Support from "./Support";
import About from "./About";

interface IInformationRoutesProps {}

const InformationRoutes: React.FC<IInformationRoutesProps> = (props) => {
  let { path, url } = useRouteMatch();

  return (
    <div>
      <Switch>
	  <Route path={`${path}/About`}>
          <About />
        </Route>
        <Route path={`${path}/CompanyOverview`}>
          <AboutUs />
        </Route>
        <Route path={`${path}/OurServices`}>
          <OurServices />
        </Route>
        <Route path={`${path}/PressReleases`}>
          <PressReleases />
        </Route>
        <Route path={`${path}/PredictiveCompliance`}>
          <PredictiveCompliance />
        </Route>
        <Route path={`${path}/SuccessStories`}>
          <SuccessStories />
        </Route>
        <Route path={`${path}/PricingAndLicensing`}>
          <PricingAndLicensing />
        </Route>
        <Route path={`${path}/ContactUs`}>
          <div style={{minHeight: '100vh'}}>
            <ContactUs />
          </div>
        </Route>
        <Route path={`${path}/Support`}>
          <Support />
        </Route>
        <Route path={`${path}/PrivacyPolicy`}>
          <PrivacyPolicy />
        </Route>
        <Route path={`${path}/LegalNotice`}>
          <LegalNotice />
        </Route>
        <Route path={`${path}/Disclaimer`}>
          <Disclaimer />
        </Route>
        <Route path={`${path}/FAQ`}>
          <FAQ />
        </Route>
      </Switch>
    </div>
  );
};

export default InformationRoutes;
