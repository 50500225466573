import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { IRootState } from '../../interfaces/store';
import { getCitationMgmtCustomFields } from '../../store/actions/citationActions';
import { updateCitationMgmtCustomField } from '../../store/actions/citationActions';
import { tableIcons } from '../shared/MaterialTableShared';
import { Checkbox, FormControlLabel, Grid, TextField, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import MaterialTable from 'material-table';
import Loading from '../shared/Loading';
import StandardModal from '../shared/StandardModal';
import { CitationMgmtCustomFieldIds } from '../../constants/constants';

interface ICustomFields {

}

interface ICustomFieldRow {
    in_use: boolean, field_id: string, display_name: string
}

const CustomFields: React.FC<ICustomFields> = (props) => {

    const { companypk } = useParams() as any;
    const dispatch = useDispatch();
    const customFieldsRaw = useSelector(
        (state: IRootState) => state.main.citation_mgmt_custom_fields
    );
    const [customFieldData, setCustomFieldData] = useState([] as ICustomFieldRow[])
    const [processing, setProcessing] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [selectedCustomField, setSelectedCustomField] = useState({} as ICustomFieldRow)
    const [inputDisplayName, setInputDisplayName] = useState('');
    const [inputInUse, setInputInUse] = useState(false);

    useEffect(() => {
        dispatch(getCitationMgmtCustomFields({ companypk: companypk }))
    }, [])

    const columns = [
        { title: 'Field Id', field: 'field_id', sorting: true },
        { title: 'In Use', field: 'in_use', sorting: true },
        { title: 'Display Name', field: 'display_name', sorting: true },
        {
            title: 'Actions', sorting: false, field: 'mineCount', render: (rowData: any) => {
                return <div>
                    <Tooltip title="Edit" placement="top">
                        <EditIcon
                            style={{ fontSize: 16 }}
                            className="transparent75"
                            onClick={() => {
                                if (rowData && rowData.pk) {

                                }
                                setInputDisplayName(rowData.display_name)
                                setInputInUse(rowData.in_use)
                                setSelectedCustomField(rowData)
                                setEditModalOpen(true)
                            }}
                        />
                    </Tooltip>
                    {/* {rowData.in_use === true && <Tooltip title="Delete" placement="top">
                        <DeleteIcon
                            style={{ marginLeft: 12, fontSize: 16 }}
                            className="transparent75"
                            onClick={() => {
                                if (rowData && rowData.pk) {

                                }
                            }}
                        />
                    </Tooltip>} */}



                </div>

            }
        }
    ]

    useEffect(() => {

        const customFieldArr = Object.values(customFieldsRaw).filter((x) => x.companyFk === Number(companypk))
        if (customFieldArr.length === 0) {
            console.log("returning 0")
            return;
        }
        const fieldIdArr = CitationMgmtCustomFieldIds

        const tableData: ICustomFieldRow[] = []

        fieldIdArr.forEach((fieldId) => {
            const rowData = { in_use: false, field_id: fieldId, display_name: '' }
            const existing = customFieldArr.find((x) => x.fieldId === fieldId);
            if (existing) {
                rowData.in_use = true
                rowData.display_name = existing.fieldDisplayName
            }
            tableData.push(rowData);
        })

        setCustomFieldData(tableData);

    }, [customFieldsRaw, companypk])

    return (
        <div>
            <Loading loading={processing} />
            <StandardModal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                actions={[
                    {
                        title: "Cancel", callback: () => {
                            setEditModalOpen(false);
                        }
                    },
                    {
                        title: "Update", callback: () => {
                            setProcessing(true);
                            dispatch(updateCitationMgmtCustomField({
                                field_id: selectedCustomField.field_id,
                                company_pk: companypk,
                                in_use: inputInUse,
                                display_name: inputDisplayName
                            }, () => {
                                setEditModalOpen(false);
                                setProcessing(false)
                            }))
                        }
                    },
                ]}
            customActions={[]}
            paragraphs={[`Edit ${selectedCustomField.field_id} Attributes`]}
            title={'Edit Custom Field'}
            children={
                <div>
                    <br /><br />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="In Use"
                            control={
                                <Checkbox
                                    checked={inputInUse}
                                    onChange={(event) => {
                                        setInputInUse(event.target.checked);
                                    }}
                                    color="primary"
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Display Name"
                            fullWidth={true}
                            id="standard-size-normal"
                            variant="standard"
                            value={inputDisplayName}
                            onChange={(event) => setInputDisplayName(event?.target.value)}
                        />
                    </Grid>  
                </Grid>
                <br /><br />
                </div>
                }
            />
            {customFieldData.length > 0 &&
                <MaterialTable
                    icons={tableIcons}
                    title="Citation Management Custom Field Configuration"
                    columns={columns}
                    data={customFieldData}
                    options={{
                        sorting: true,
                        headerStyle: {
                            zIndex: 8
                        },
                        paging: true,
                        pageSize: Math.min(customFieldData.length, 10),
                        draggable: false,
                        rowStyle: {
                            height: 20
                        }
                    }}
                    onRowClick={(event, rowData, togglePanel) => {

                    }}
                />}
        </div>
    )
}

export default CustomFields