import React from "react";
import { Container } from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";
interface IAboutUsProps {}

const AboutUs: React.FC<IAboutUsProps> = () => {
  return (
    <Container
      style={{ minHeight: "100vh", backgroundColor: COLORS.WHITE_PAPER }}
    >
      <h1>Company Overview</h1>
      <section>
        <article>
          Created by renowned MSHA compliance attorney Mark Savit, based on over
          30 years of dealing with compliance issues in the mining industry,
          Predictive Compliance represents a{" "}
          <b>
            <i>completely new approach to MSHA compliance management.</i>
          </b>
        </article>
        <br></br>
        <br></br>
        <article>
          Predictive Compliance provides services that allow mine owners and
          operators to proactively evaluate and manage potential compliance
          issues before they are assessed by MSHA. With our proprietary
          analytics and penalty calculation engine, Predictive Compliance
          provides mining owners and operators with insight into the financial
          impact of receiving additional citations as well as disputing current
          citations.
        </article>
        <br></br>
        <br></br>
        <article>
          Our software is designed to be as user friendly as possible. All you
          need to do is upload citations as you get them and our proprietary
          software calculates the predicted penalty, shows you the impact that
          this citation (and future citations) would have on compliance costs,
          allows you to plan how best to contest the citation, and provides a
          platform for citation management. It also will allow you to predict
          the impact of your future citations on costs, resources and
          operations.&nbsp;
          <b>
            <i>
              Predictive Compliance will allow you to dramatically reduce your
              costs of compliance and allocate your resources for safer, more
              efficient operations.
            </i>
          </b>
        </article>
        <br></br>
        <br></br>
        <article>
          Predictive Compliance Services are web-based and are compatible with
          any type of computer with an internet browser and any type of scanner
          equipment.{" "}
          <b>
            <i>
              There is no need to purchase specialized equipment to use
              Predictive Compliance Services.
            </i>
          </b>
        </article>
        <br></br>
        <br></br>
        <article>
          Please download our latest Key Note presentation <a href="#">here</a>
        </article>
      </section>
    </Container>
  );
};

export default AboutUs;
