import { ACTIONS } from "../../enums/actions";

export const getCitations = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_CITATIONS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getCitation = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_CITATION,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getCitationWithMetada = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_CITATION_WITH_METADATA,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const updateCitations = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.UPDATE_CITATIONS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const deleteCitation = (
	payload: any,
	onSuccess: Function | null = null,
	onFail: Function | null = null
) => ({
	type: ACTIONS.DELETE_CITATION,
	payload,
	onSuccess: onSuccess || null,
	onFail: onFail || null,
});

export const updateCitationNote = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.UPDATE_CITATION_NOTE,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const downloadCitationFile = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.DOWNLOAD_CITATION_DOC,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const downloadBulkCitations = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.DOWNLOAD_BULK_CITATIONS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const uploadCitations = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.UPLOAD_CITATIONS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const saveCitationScans = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.SAVE_CITATION_SCANS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getPaternOfViolations = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_PATERN_OF_VIOLATIONS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getCitationPenaltyCalculationAnalysis = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_CITATION_PENALTY_CALCULATION_ANALYSIS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const deleteCitationNotesFile = (
	payload: any,
	onSuccess: Function | null = null,
	onFail: Function | null = null
  ) => ({
	type: ACTIONS.DELETE_CITATION_NOTES_FILE,
	payload,
	onSuccess: onSuccess || null,
	onFail: onFail || null,
  });

export const getCitationWarnings = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_CITATION_WARNINGS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getConferenceTracking = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_CONFERENCE_TRACKING,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getConferenceTrackingByCitationPk = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_CONFERENCE_TRACKING_BY_CITATION_PK,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const upsertConferenceTracking = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.UPSERT_CONFERENCE_TRACKING,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getCitationMgmtCustomFields = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_CITATION_MGMT_CUSTOM_FIELDS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const updateCitationMgmtCustomField = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.UPDATE_CITATION_MGMT_CUSTOM_FIELD,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});