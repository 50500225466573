import React from "react";
import initializeReduxStorage from "./store";
import { Provider } from "react-redux";
import App from "./components/App";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { SnackbarProvider } from "notistack";
import { COLORS } from "./enums/layout-enums";
import CloseIcon from "@material-ui/icons/Close";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
const store = initializeReduxStorage();

const Main: React.FC = () => {
  document.body.style.backgroundColor = COLORS.BLUE_MEDIUM_DARK_BACKGROUND;
  const notistackRef = React.createRef<any>();

  const onClickDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#78a9d9'
      }
    }
  });

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={3}
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          action={(key) => (
            <CloseIcon
              onClick={onClickDismiss(key)}
              fontSize="small"
              className="transparent75"
            />
          )}
        >
          <Router>
            <Route path="/">
              <App />
            </Route>
          </Router>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Provider>
  );
};

export default Main;
