import { all, call, put } from "redux-saga/effects";
import { ACTIONS } from "../../enums/actions";
import { HttpResponse } from "../../interfaces/api/HttpResponse";
import { IDispatchAction } from "../../interfaces/store";
import HttpClient from "../../lib/HttpClient";
import GetFriendlyError from "../../utils/general";
import { normalize, schema } from "normalizr";
import {
  accountLogin_schema,
  adminEmailSettings_schema,
  companyAccount_schema,
} from "./schemas";
const client = new HttpClient();

async function updateSelectedColumnIndexesAPI(data: {
  userId: number;
  companyId: Number;
}): Promise<HttpResponse<{ x: string }>> {
  return client.post(`api/account/update-selected-column-indexes`, data);
}

async function getAccountsByFlagAPI(data: any): Promise<HttpResponse<any>> {
  return client.post("api/sysadmin/get-accounts-by-flag", data);
}

async function getAdminEmailSettingsAPI(): Promise<HttpResponse<any>> {
  return client.get("api/sysadmin/get-admin-email-settings");
}

async function updateAdminEmailSettingAPI(
  data: any
): Promise<HttpResponse<any>> {
  return client.post("api/sysadmin/update-admin-email-settings", data);
}

async function getByCompanyAPI(data: {
  companypk: number;
}): Promise<HttpResponse<any>> {
  return client.get(
    "api/Account/account-logins-by-company?companypk=" + data.companypk
  );
}
async function createAdminEmailSettingAPI(
  data: any
): Promise<HttpResponse<any>> {
  return client.post("api/sysadmin/create-admin-email-settings", data);
}

async function deleteAdminEmailSettingAPI(
  settingId: Number
): Promise<HttpResponse<any>> {
  return client.delete(`api/sysadmin/delete-admin-email-settings/${settingId}`);
}

async function getActiveCompaniesAPI(): Promise<HttpResponse<any>> {
  return client.get("api/Account/get-companies");
}

async function getUsersActivityByCompanyAPI(data: {
  companypk: number;
}): Promise<HttpResponse<any>> {
  return client.get(
    "api/Account/get-user-activity?companypk=" + data.companypk
  );
}

const accountSaga = {
  *createAdminEmailSettings(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        createAdminEmailSettingAPI,
        payload.data
      )) as HttpResponse<any>;

      if (response.status === 200 || response.status === 201) {
        yield put({
          type: "UPDATE_ADMIN_EMAIL_SETTINGS_SUCCESS",
          normalized: normalize(response.data, [adminEmailSettings_schema]),
        });

        if (action.onSuccess) {
          action.onSuccess();
        }
      }
    } catch (e) {
      const error = e as Error;
      if (action.onFail) action.onFail(error.message);

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getAccountsByFlag(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        getAccountsByFlagAPI,
        action.payload
      )) as HttpResponse<any>;
      console.log("raw response?", response);
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess();
        }
        yield put({
          type: "GET_ACCOUNTS_BY_FLAG_SUCCESS",
          normalized: normalize(response.data, [accountLogin_schema]),
        });
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getByCompany(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getByCompanyAPI,
        payload
      )) as HttpResponse<any>;
      if ([200, 201].includes(response.status)) {
        yield all([
          put({
            type: "GET_ACCOUNT_LOGIN_BY_COMPANY_SUCCESS",
            normalized: normalize(response.data.accountLogins, [
              accountLogin_schema,
            ]),
          }),
          put({
            type: "GET_ACCOUNT_LOGIN_BY_COMPANY_SUCCESS",
            normalized: normalize(response.data.companyAccounts, [
              companyAccount_schema,
            ]),
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else if (action.onFail) {
        action.onFail();
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  *getAdminEmailSettings(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        getAdminEmailSettingsAPI
      )) as HttpResponse<any>;
      if ([200, 201].includes(response.status)) {
        yield put({
          type: "GET_ADMIN_EMAIL_SETTINGS_SUCCESS",
          normalized: normalize(response.data, [adminEmailSettings_schema]),
        });

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else if (action.onFail) {
        action.onFail();
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  *updateAdminEmailSetting(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        updateAdminEmailSettingAPI,
        payload
      )) as HttpResponse<any>;
      if ([200, 201].includes(response.status)) {
        yield put({
          type: "UPDATE_ADMIN_EMAIL_SETTINGS_SUCCESS",
          normalized: normalize(response.data, [adminEmailSettings_schema]),
        });

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else if (action.onFail) {
        action.onFail();
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *deleteAdminEmailSettings(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        deleteAdminEmailSettingAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        const isAccountDeleted: boolean = response.data;
        if (isAccountDeleted) {
          let removeKeys = [];
          removeKeys.push({
            entities: "admin_email_settings",
            ids: Array.of(payload),
          });
          yield put({
            type: "DELETE_ADMIN_EMAIL_SETTINGS_SUCCESS",
            removeKeys: removeKeys,
          });

          if (action.onSuccess) {
            action.onSuccess(response.data);
          }
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *updatedSelectedColumnIndexes(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        updateSelectedColumnIndexesAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        yield put({
          type: "UPDATE_ACCOUNT_LOGIN_SUCCESS",
          data: response.data,
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
  *getActiveCompanies(action: IDispatchAction): Generator {
    try {
      const response = (yield call(getActiveCompaniesAPI)) as HttpResponse<any>;
      if ([200, 201].includes(response.status)) {
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      } else if (action.onFail) {
        action.onFail();
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  *getUsersActivityByCompany(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getUsersActivityByCompanyAPI,
        payload
      )) as HttpResponse<any>;
      if ([200, 201].includes(response.status)) {
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      } else if (action.onFail) {
        action.onFail();
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },
};

export default accountSaga;
