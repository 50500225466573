import React, { useEffect, useState } from "react";
import { AppBar, Container, Grid, MenuItem } from "@material-ui/core";
import {
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { APP_BREAKPOINT, COLORS } from "../enums/layout-enums";
import CompanyOverview from "./companies/CompanyOverview";
import Menu from "material-ui-popup-state/HoverMenu";
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import Landing from "./landing/Landing";
import { IRootState } from "../interfaces/store";
import { useDispatch, useSelector } from "react-redux";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { logout } from "../store/actions/authActions";
import { MAIN_TAB } from "../interfaces/store/main";
import { setClientOptions } from "../store/actions/clientActions";
import MineRoute from "./mines/MineRoute";
import AdminRoute from "./admin/AdminRoute";
import { useKeyPress } from "./shared/KeypressHook";
import { useParams } from "react-router";
import AnalyticsRoute from "./analytics/AnalyticsRoute";
import InformationRoutes from "./informative/InformationRoutes";
import Footer from "./shared/Footer";
import OperatorAnalytics from "./analytics/OperatorAnalytics";
import ForgotPassword from "./auth/ForgotPassword";
import MineSummary from "./mines/MineSummary";
import { hasFlag } from "../utils/bitwise-utils";
import { ACCOUNT_FLAGS } from "../enums/account-enums";
import ResetPassword from "./auth/ResetPassword";
import About from "./informative/InformationRoutes";
import { BorderHorizontal, BorderTop } from "@material-ui/icons";
interface IApp {}

const App: React.FC<IApp> = () => {
  const dispatch = useDispatch();
  const { minepk } = useParams() as any;

  const popupStateMineContractor = usePopupState({
    variant: "popover",
    popupId: "minecontractormenu",
  });
  const popupStateOurServices = usePopupState({
    variant: "popover",
    popupId: "aboutusmenu",
  });
  const popupStateAboutUs = usePopupState({
    variant: "popover",
    popupId: "aboutusmenu",
  });
  const popupStateContactUs = usePopupState({
    variant: "popover",
    popupId: "aboutusmenu",
  });

  const popupStateSettings = usePopupState({
    variant: "popover",
    popupId: "settingsmenu",
  });
  /*
  const popupStateOurServices = usePopupState({ variant: 'popover', popupId: 'ourservicesmenu' })
  const popupStateAboutUs = usePopupState({ variant: 'popover', popupId: 'aboutusmenu' })
  const popupStateContactUs = usePopupState({ variant: 'popover', popupId: 'contactusmenu' })
  */
  const history = useHistory();
  const location = useLocation();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const accountLogin = useSelector(
    (state: IRootState) => state.main.account_login
  );

  const jwt_token = useSelector((state: IRootState) => state.main.jwt_token);

  const selected_tab = useSelector(
    (state: IRootState) => state.main.client_options.selected_tab
  );

  const selected_mine = useSelector(
    (state: IRootState) => state.main.client_options.selected_mine
  );

  const show_citation_nav = useSelector(
    (state: IRootState) => state.main.client_options.show_citation_nav
  );

  const show_admin_nav = useSelector(
    (state: IRootState) => state.main.client_options.show_admin_nav
  );

  const [showPublicSubBar, setShowPublicSubbar] = useState(true);

  //* DEBUG STATE * //
  // debug state
  const mainState = useSelector((state: IRootState) => state.main);

  const flatFileState = useSelector((state: IRootState) => state.flatfile);

  const showState = useKeyPress("ç");

  useEffect(() => {
    if (showState === true) {
      console.log("Main State", mainState);
      console.log("Flat File State", flatFileState);
    }
  }, [showState]);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  let AppBreakpoint = APP_BREAKPOINT.EXTRA_LARGE;
  let minWidth = 1920;
  if (windowSize.width >= 1920) {
  } else if (windowSize.width > 1280) {
    minWidth = 1280;
    AppBreakpoint = APP_BREAKPOINT.LARGE;
  } else if (windowSize.width > 960) {
    minWidth = 960;
    AppBreakpoint = APP_BREAKPOINT.MEDIUM;
  } else {
    minWidth = 960;
  }

  const linkStyle = {
    textDecoration: "none",
    color: "#ddcdcd",
  };

  const navItem = (
    onClick: Function,
    children: any,
    borderStyle = "right",
    highlighted = false
  ) => {
    const outerStyle = { flex: 1, cursor: "pointer" } as any;
    if (borderStyle === "right") {
      // outerStyle.borderRight = "solid 1px #4A4A4A";
      outerStyle.borderRight = "solid 1px white";
      outerStyle.marginRight = "1px";
      outerStyle.marginLeft = "1px";
    }
    if (highlighted === true) {
      outerStyle.borderBottom = "8px solid #ffbc00";
    }
    return (
      <div
        onClick={() => onClick()}
        style={{ ...outerStyle }}
        className={borderStyle !== "right" ? "" : "fadeborder"}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            height: 50,
          }}
        >
          <div style={{ alignSelf: "center" }}>{children}</div>
        </div>
      </div>
    );
  };

  const currentRoute = () => {
    return location.pathname.includes("/Information/");
  };

  const handleLogout = () => {
    window.location.href = "/";
    dispatch(logout({}));
    popupStateSettings.close();
  };

  const handleContactUs = () => {
    history.push("/Information/ContactUs");
    popupStateSettings.close();
  };

  const handleAbout = () => {
    history.push("/Information/About");
    popupStateSettings.close();
  };

  const handleSupport = () => {
    history.push("/Information/Support");
    popupStateSettings.close();
  };

  const effectiveWidth = Math.max(windowSize.width, minWidth);
  const subNavStyle = {
    flex: 1,
    textAlign: "center",
    marginTop: 5,
    cursor: "pointer",
    borderRight: "1px solid white",
  } as any;
  const goTab = (tab: MAIN_TAB) => {
    dispatch(setClientOptions({ selected_tab: tab }));
  };

  const getSubNavStyle = (path: string) => {
    return location.pathname.includes(path)
      ? { ...subNavStyle, borderBottomColor: "#ffbc00" }
      : { ...subNavStyle };
  };

  function goToMineSubNav(subnav: string) {
    history.push("/Mines/" + selected_mine?.pk + "/" + subnav);
  }

  function goToAdminSubNav(subnav: string) {
    history.push("/Administration/" + subnav);
  }

  function goToMineState() {
    history.push("/Analytics/MineState");
  }

  function goToInfo(path: string) {
    history.push(`/Information/${path}`);
  }

  let { path, url } = useRouteMatch();

  const PublicAppBar = () => {
    return (
      <AppBar
        position="sticky"
        style={{ backgroundColor: COLORS.BLUE_LIGHT_BACKGROUND }}
      >
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            color: COLORS.BLUE_MEDIUM_BACKGROUND,
            height: 50,
          }}
        >
          {navItem(
            () => {
              goTab(MAIN_TAB.HOME);
            },
            <span>
              <Link to="/" style={linkStyle}>
                Home
              </Link>
            </span>,
            undefined,
            selected_tab === MAIN_TAB.HOME
          )}
          {navItem(
            () => {
              goTab(MAIN_TAB.OUR_SERVICES);
            },
            <span>
              <Link to="/Information/OurServices" style={linkStyle}>
                <span {...bindHover(popupStateOurServices)}>Our Services</span>
              </Link>
              <Menu
                {...bindMenu(popupStateOurServices)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <MenuItem
                  onClick={() => {
                    popupStateOurServices.close();
                    goToInfo("PredictiveCompliance");
                  }}
                >
                  Predictive Compliance
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    popupStateOurServices.close();
                    goToInfo("SuccessStories");
                  }}
                >
                  Success Stories
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    popupStateOurServices.close();
                    goToInfo("PricingAndLicensing");
                  }}
                >
                  Pricing and Licensing
                </MenuItem>
              </Menu>
            </span>,
            undefined,
            selected_tab === MAIN_TAB.OUR_SERVICES
          )}
          {navItem(
            () => {
              goTab(MAIN_TAB.ABOUT_US);
            },
            <span>
              <Link to="/Information/CompanyOverview" style={linkStyle}>
                <span {...bindHover(popupStateAboutUs)}>About Us</span>
              </Link>
              <Menu
                {...bindMenu(popupStateAboutUs)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <MenuItem
                  onClick={() => {
                    popupStateAboutUs.close();
                    goToInfo("CompanyOverview");
                  }}
                >
                  Company Overview
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    popupStateAboutUs.close();
                    goToInfo("OurServices");
                  }}
                >
                  Our Services
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    popupStateAboutUs.close();
                    goToInfo("PressReleases");
                  }}
                >
                  Press Releases
                </MenuItem>
              </Menu>
            </span>,
            undefined,
            selected_tab === MAIN_TAB.ABOUT_US
          )}
          {/* {navItem(() => { goTab(MAIN_TAB.HOME) }, <span>
            <Link to="/" style={linkStyle}>
                About Us
          </Link>
          </span>, undefined, selected_tab === MAIN_TAB.MINES_CONTRACTORS)} */}
          {navItem(
            () => {
              goTab(MAIN_TAB.CONTACT_US);
            },
            <span>
              <Link to="/Information/ContactUs" style={linkStyle}>
                <span {...bindHover(popupStateContactUs)}>Contact Us</span>
              </Link>
              <Menu
                {...bindMenu(popupStateContactUs)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <MenuItem
                  onClick={() => {
                    popupStateContactUs.close();
                    goToInfo("Support");
                  }}
                >
                  Support
                </MenuItem>
              </Menu>
            </span>,
            undefined,
            selected_tab === MAIN_TAB.CONTACT_US
          )}
          <div style={{ flex: 2 }}></div>
        </div>
        {showPublicSubBar && currentRoute() && (
          <div
            style={{
              fontSize: 14,
              borderTop: "solid 1px #4A4A4A",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              color: COLORS.BLUE_MEDIUM_BACKGROUND,
              height: 28,
            }}
          >
            <div
              style={getSubNavStyle("PrivacyPolicy")}
              className="fadebordersmall"
              onClick={() => goToInfo("PrivacyPolicy")}
            >
              Privacy Policy
            </div>
            <div
              style={getSubNavStyle("LegalNotice")}
              className="fadebordersmall"
              onClick={() => goToInfo("LegalNotice")}
            >
              Legal Notice
            </div>
            <div
              style={getSubNavStyle("Disclaimer")}
              className="fadebordersmall"
              onClick={() => goToInfo("Disclaimer")}
            >
              Disclaimer
            </div>
            <div
              style={getSubNavStyle("FAQ")}
              className="fadebordersmall"
              onClick={() => goToInfo("FAQ")}
            >
              FAQ
            </div>
          </div>
        )}
      </AppBar>
    );
  };

  const contractorContext = window.location.pathname.includes("Mines/-1");

  const LoggedInAppBar = () => {
    return (
      <AppBar
        position="sticky"
        style={{ backgroundColor: COLORS.BLUE_LIGHT_BACKGROUND }}
      >
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            color: COLORS.BLUE_MEDIUM_BACKGROUND,
            height: 50,
          }}
        >
          {navItem(
            () => {
              goTab(MAIN_TAB.HOME);
            },
            <span>
              <Link to="/" style={linkStyle}>
                Home
              </Link>
            </span>,
            undefined,
            selected_tab === MAIN_TAB.HOME
          )}
          {navItem(
            () => {
              goTab(MAIN_TAB.MINES_CONTRACTORS);
            },
            <Link to="/Mines" style={linkStyle}>
              Mines / Contractors
            </Link>,
            undefined,
            selected_tab === MAIN_TAB.MINES_CONTRACTORS
          )}
          {navItem(
            () => {
              goTab(MAIN_TAB.MINE_STATE);
            },
            <Link to="/Analytics/MineState" style={linkStyle}>
              Mine State
            </Link>,
            undefined,
            selected_tab === MAIN_TAB.MINE_STATE
          )}
          {navItem(
            () => {
              goTab(MAIN_TAB.OPERATOR_ANALYTICS);
            },
            <Link to="/OperatorAnalytics" style={linkStyle}>
              Operator Analytics
            </Link>,
            undefined,
            selected_tab === MAIN_TAB.OPERATOR_ANALYTICS
          )}
          {accountLogin &&
            (hasFlag(accountLogin.accountFlag, ACCOUNT_FLAGS.system_admin) ||
              hasFlag(accountLogin.accountFlag, ACCOUNT_FLAGS.company_admin)) &&
            navItem(
              () => {
                goTab(MAIN_TAB.ADMINISTRATION);
              },
              <Link to="/Administration/Configuration" style={linkStyle}>
                Administration
              </Link>,
              undefined,
              selected_tab === MAIN_TAB.ADMINISTRATION
            )}

          <div style={{ flex: 2, alignItems: "center" }}></div>
          {navItem(
            () => {},
            <span>
              <span {...bindHover(popupStateSettings)}>
                <span
                  style={{
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AccountCircle fontSize="small" className="transparent75" />
                  &nbsp;
                  <span
                    style={{
                      fontSize: 10,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {accountLogin?.emailAddress || ""}
                  </span>
                  <ArrowDropDown fontSize="small" className="transparent75" />
                </span>
              </span>
              <Menu
                {...bindMenu(popupStateSettings)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                {/* <MenuItem onClick={popupStateSettings.close}>Account</MenuItem> */}
                {hasFlag(
                  accountLogin.accountFlag,
                  ACCOUNT_FLAGS.system_admin
                ) && <MenuItem onClick={handleAbout}>About</MenuItem>}
                <MenuItem onClick={handleContactUs}>Contact Us</MenuItem>
                <MenuItem onClick={handleSupport}>Support</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </span>,
            "none"
          )}
        </div>
        {show_citation_nav && (
          <div
            style={{
              fontSize: 14,
              borderTop: "solid 1px white",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              color: COLORS.BLUE_MEDIUM_BACKGROUND,
              height: 28,
            }}
          >
            <div
              style={getSubNavStyle("Citations")}
              className="fadebordersmall"
              onClick={() => goToMineSubNav("Citations")}
            >
              Citations
            </div>
            {!contractorContext && (
              <div
                style={getSubNavStyle("Inspections")}
                className="fadebordersmall"
                onClick={() => goToMineSubNav("Inspections")}
              >
                Inspections
              </div>
            )}
            {/* <div
              style={getSubNavStyle("Analytics")}
              className="fadebordersmall"
              onClick={() => goToMineSubNav("Analytics")}
            >
              Analytics
            </div> */}
            {!contractorContext && (
              <div
                style={getSubNavStyle("InspectorTrends")}
                className="fadebordersmall"
                onClick={() => goToMineSubNav("InspectorTrends")}
              >
                Inspector Trends
              </div>
            )}
            {!contractorContext && (
              <div
                style={getSubNavStyle("PatternOfViolations")}
                className="fadebordersmall"
                onClick={() => goToMineSubNav("PatternOfViolations")}
              >
                Pattern of Violations
              </div>
            )}
            {/* <div style={subNavStyle} className="fadebordersmall">
              Inspection Overview
            </div> */}
          </div>
        )}
        {show_admin_nav && (
          <div
            style={{
              fontSize: 14,
              borderTop: "solid 1px white",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              color: COLORS.BLUE_MEDIUM_BACKGROUND,
              height: 28,
            }}
          >
            <div
              style={getSubNavStyle("Configuration")}
              className="fadebordersmall"
              onClick={() => goToAdminSubNav("Configuration")}
            >
              Configuration
            </div>
            {!contractorContext && (
              <div
                style={getSubNavStyle("UsageStatistics")}
                className="fadebordersmall"
                onClick={() => goToAdminSubNav("UsageStatistics")}
              >
                Usage Statistics
              </div>
            )}
          </div>
        )}
      </AppBar>
    );
  };

  return (
    <div style={{ fontFamily: "Roboto", width: effectiveWidth }}>
      <Container maxWidth={false}>
        <Grid container justify="center" spacing={2}>
          <Grid style={{ width: minWidth }}>
            <Grid
              style={{
                height: 100,
                background: `url('${process.env.PUBLIC_URL}/header-bg.jpg')`,
              }}
              item
              xs={12}
            >
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <img
                    alt="logo"
                    style={{ height: 65, marginLeft: 16, marginTop: 18 }}
                    src={process.env.PUBLIC_URL + "/logo_grey.png"}
                  />
                </div>
                <div style={{ flex: 1, textAlign: "right" }}></div>
              </div>
            </Grid>
            {jwt_token ? LoggedInAppBar() : PublicAppBar()}

            <Switch>
              <Route exact path="/">
                <Grid
                  style={{
                    maxWidth: minWidth,
                    backgroundColor: COLORS.WHITE_PAPER,
                    padding: 32,
                  }}
                >
                  <Landing />
                </Grid>
              </Route>
              <Route path="/OperatorAnalytics">
                <Grid
                  style={{
                    maxWidth: minWidth,
                    backgroundColor: COLORS.WHITE_PAPER,
                    padding: 32,
                  }}
                >
                  <OperatorAnalytics maxWidth={minWidth} />
                </Grid>
              </Route>
              {accountLogin &&
                (hasFlag(
                  accountLogin.accountFlag,
                  ACCOUNT_FLAGS.system_admin
                ) ||
                  hasFlag(
                    accountLogin.accountFlag,
                    ACCOUNT_FLAGS.company_admin
                  )) && (
                  <Route path="/Administration">
                    <Grid
                      style={{
                        maxWidth: minWidth,
                        backgroundColor: COLORS.WHITE_PAPER,
                        padding: 32,
                      }}
                    >
                      <AdminRoute />
                    </Grid>
                  </Route>
                )}
              <Route path="/Mines">
                <Grid
                  style={{
                    maxWidth: minWidth,
                    backgroundColor: COLORS.WHITE_PAPER,
                    padding: 32,
                  }}
                >
                  <MineRoute AppBreakpoint={AppBreakpoint} />
                </Grid>
              </Route>
              <Route path="/Company/:companypk">
                <Grid
                  style={{
                    maxWidth: minWidth,
                    backgroundColor: COLORS.WHITE_PAPER,
                    padding: 32,
                  }}
                >
                  <CompanyOverview />
                </Grid>
              </Route>
              <Route path="/Company/Summary/:companyid">
                <MineSummary />
              </Route>
              <Route path="/Inspection/:inspectionpk">
                <Grid
                  style={{
                    maxWidth: minWidth,
                    backgroundColor: COLORS.WHITE_PAPER,
                    padding: 32,
                  }}
                >
                  INSPECTION
                </Grid>
              </Route>
              <Route path="/About">
                <Grid
                  style={{
                    maxWidth: minWidth,
                    backgroundColor: COLORS.WHITE_PAPER,
                    padding: 32,
                  }}
                >
                  <About />
                </Grid>
              </Route>
              <Route path="/Analytics">
                <Grid
                  style={{
                    maxWidth: minWidth,
                    backgroundColor: COLORS.WHITE_PAPER,
                    padding: 32,
                  }}
                >
                  <AnalyticsRoute />
                </Grid>
              </Route>
              <Route path="/Information">
                <Grid
                  style={{
                    maxWidth: minWidth,
                    backgroundColor: COLORS.WHITE_PAPER,
                    padding: 32,
                  }}
                >
                  <InformationRoutes />
                </Grid>
              </Route>
              <Route path="/ForgotPassword">
                <Grid
                  style={{
                    maxWidth: minWidth,
                    backgroundColor: COLORS.WHITE_PAPER,
                    padding: 32,
                  }}
                >
                  <ForgotPassword />
                </Grid>
              </Route>
              <Route path="/ResetPassword">
                <Grid
                  style={{
                    maxWidth: minWidth,
                    backgroundColor: COLORS.WHITE_PAPER,
                    padding: 32,
                  }}
                >
                  <ResetPassword />
                </Grid>
              </Route>
            </Switch>
            <Footer />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default App;
