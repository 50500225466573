export enum ACTIONS {
  ERROR = "ERROR",
  LOADING = "LOADING",
  REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET",
  GET_ALL_MINES_GROUP_BY_COMPANY = "GET_ALL_MINES_GROUP_BY_COMPANY",
  GET_MINES_BY_COMPANY = "GET_MINES_BY_COMPANY",
  GET_MINE = "GET_MINE",
  DELETE_MINE = "DELETE_MINE",
  CREATE_MINE = "CREATE_MINE",
  UPDATE_MINE = "UPDATE_MINE",

  GET_ACCOUNT_LOGINS_BY_COMPANY = "GET_ACCOUNT_LOGINS_BY_COMPANY",

  GET_CITATIONS = "GET_CITATIONS",
  GET_CITATION = "GET_CITATION",
  GET_CITATION_WITH_METADATA = "GET_CITATION_WITH_METADATA",
  GET_MINE_REGULATIOND_WARNING = "GET_MINE_REGULATIOND_WARNING",
  UPDATE_CITATION_NOTE = "UPDATE_CITATION_NOTE",
  DOWNLOAD_CITATION_DOC = "DOWNLOAD_CITATION_DOC",
  UPDATE_CITATIONS = "UPDATE_CITATIONS",
  DELETE_CITATION = "DELETE_CITATION",
  UPLOAD_CITATIONS = "UPLOAD_CITATIONS",
  SAVE_CITATION_SCANS = "SAVE_CITATION_SCANS",
  GET_PATERN_OF_VIOLATIONS = "GET_PATERN_OF_VIOLATIONS",
  GET_CITATION_PENALTY_CALCULATION_ANALYSIS = "GET_CITATION_PENALTY_CALCULATION_ANALYSIS",
  GET_CONFERENCE_TRACKING = "GET_CONFERENCE_TRACKING",
  UPSERT_CONFERENCE_TRACKING = "UPSERT_CONFERENCE_TRACKING",
  GET_CONFERENCE_TRACKING_BY_CITATION_PK = "GET_CONFERENCE_TRACKING_BY_CITATION_PK",
  GET_CITATION_MGMT_CUSTOM_FIELDS = "GET_CITATION_MGMT_CUSTOM_FIELDS",
  UPDATE_CITATION_MGMT_CUSTOM_FIELD = "UPDATE_CITATION_MGMT_CUSTOM_FIELD",
  DELETE_CITATION_NOTES_FILE = "DELETE_CITATION_NOTES_FILE",

  CREATE_COMPANY = "CREATE_COMPANY",
  UPDATE_COMPANY = "UPDATE_COMPANY",
  GET_COMPANY = "GET_COMPANY",
  GET_COMPANIES = "GET_COMPANIES",
  DELETE_COMPANY = "DELETE_COMPANY",

  SET_CLIENT_OPTIONS = "SET_CLIENT_OPTIONS",

  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",

  GET_ACCOUNTS_BY_FLAG = "GET_ACCOUNTS_BY_FLAG",
  GET_ADMIN_EMAIL_SETTINGS = "GET_ADMIN_EMAIL_SETTINGS",
  UPDATE_ADMIN_EMAIL_SETTING = "UPDATE_ADMIN_EMAIL_SETTING",

  GET_INSPECTION = "GET_INSPECTION",
  GET_INSPECTIONS_BY_MINE = "GET_INSPECTIONS_BY_MINE",
  GET_INSPECTION_BY_EVENT_NUMBER = "GET_INSPECTION_BY_EVENT_NUMBER",
  ADD_INSPECTION = "ADD_INSPECTION",
  UPDATE_INSPECTION = "UPDATE_INSPECTION",
  DELETE_INSPECTION = "DELETE_INSPECTION",
  GET_INSPECTOR_AR_LIST = "GET_INSPECTOR_AR_LIST",
  GET_INSPECTOR_TRENDS = "GET_INSPECTOR_TRENDS",

  GET_MINE_STATE = "GET_MINE_STATE",

  CREATE_USER = "CREATE_USER",
  GET_USER = "GET_USER",
  UPDATE_USER = "UPDATE_USER",
  DELETE_USER = "DELETE_USER",

  CREATE_ADMIN_EMAIL_SETTING = "CREATE_ADMIN_EMAIL_SETTING",
  DELETE_ADMIN_EMAIL_SETTING = "DELETE_ADMIN_EMAIL_SETTING",

  CREATE_COMPANY_EMAIL_SETTING = "CREATE_COMPANY_EMAIL_SETTING",
  UPDATE_COMPANY_EMAIL_SETTING = "UPDATE_COMPANY_EMAIL_SETTING",
  DELETE_COMPANY_EMAIL_SETTING = "DELETE_COMPANY_EMAIL_SETTING",
  GET_COMPANY_EMAIL_SETTING = "GET_COMPANY_EMAIL_SETTING",
  GET_ALL_COMPANY_EMAIL_SETTING = "GET_ALL_COMPANY_EMAIL_SETTING",

  GET_BUSINESS_UNIT = "GET_BUSINESS_UNIT",
  GET_ALL_BUSINESS_UNIT = "GET_ALL_BUSINESS_UNIT",
  CREATE_BUSINESS_UNIT = "CREATE_BUSINESS_UNIT",
  UPDATE_BUSINESS_UNIT = "UPDATE_BUSINESS_UNIT",
  DELETE_BUSINESS_UNIT = "DELETE_BUSINESS_UNIT",

  GET_REGION = "GET_REGION",
  GET_ALL_REGION = "GET_ALL_REGION",
  CREATE_REGION = "CREATE_REGION",
  UPDATE_REGION = "UPDATE_REGION",
  DELETE_REGION = "DELETE_REGION",
  GET_ALL_COLUMNS_MINES_SUMMARY = "GET_ALL_COLUMNS_MINES_SUMMARY",
  GET_COMPANY_SUMMARY = "GET_COMPANY_SUMMARY",
  DOWNLOAD_COMPANY_SUMMARY_EXCEL = "DOWNLOAD_COMPANY_SUMMARY_EXCEL",
  GET_CONTROLLERS = "GET_CONTROLLERS",
  GET_VPIDRPID_HISTORY = "GET_VPIDRPID_HISTORY",
  DOWNLOAD_8K_SUMMARY_EXCEL = "DOWNLOAD_8K_SUMMARY_EXCEL",
  DOWNLOAD_10K_SUMMARY_EXCEL = "DOWNLOAD_10K_SUMMARY_EXCEL",
  DOWNLOAD_BULK_CITATIONS = "DOWNLOAD_BULK_CITATIONS",
  GET_ADMIN_USER = "GET_ADMIN_USER",

  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  GET_CITATION_WARNINGS = "GET_CITATION_WARNINGS",

  GET_CONTRACTORS = "GET_CONTRACTORS",

  UPDATE_SELECTED_COLUMN_INDEXES = "UPDATE_SELECTED_COLUMN_INDEXES",

  GET_ACTIVE_COMPANIES = "GET_ACTIVE_COMPANIES",
  GET_USERS_ACTIVITY = "GET_USERS_ACTIVITY",
}
