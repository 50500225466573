
import { FormControlLabel, MenuItem, Radio, RadioGroup, Select } from '@material-ui/core';
import { WrapperVariant } from '@material-ui/pickers/wrappers/Wrapper';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { gravityChanceToString, gravityResultExpectedToString, GRAVITY_CHANCE, GRAVITY_RESULT_EXPECTED, NEGLIGENCE, PRIMARY_OR_MILL, typeOfActionToString, typeOfIssuanceToString, typeOfNegligenceString, TYPE_OF_ACTION, TYPE_OF_INSPECTION, TYPE_OF_ISSUANCE } from '../../enums/citation-enums';
import { ICitation, ICitationWithMeta } from '../../interfaces/model/MSHAPCT/citation.interface';
import { IMine } from '../../interfaces/model/MSHAPCT/mine.interface';
import { DATE_PART, isValidDateSQL, setDatePart } from '../../utils/date-utils';
import { validText } from '../../utils/validation';
import CheckboxWrap from '../form/CheckboxWrap';
import KeyboardDatePickerWrap from '../form/KeyboardDatePickerWrap';
import KeyboardTimePickerWrap from '../form/KeyboardTimePickerWrap';
import TextFieldWrap from '../form/TextFieldWrap'

interface ICitationDocumentProps {
    citation: ICitation    
    mine?: IMine
    index?: number
    mode: 'view' | 'edit'
    handleCitationChange: (citation: ICitation, index?: number) => void
}

// function KeyboardWrap(wrapProps: any) {
//     if (wrapProps.variant === 'view') {
//         delete wrapProps.varient
//       return <InputBase
//       {...wrapProps}
//       inputProps={{ 'aria-label': 'naked' }}
//     />
//     }
//     return <KeyboardTimePicker {...wrapProps} />
//   }

const getRadioValue = (value: any, type: string) => {

    let val = '';
    switch(type){
        case 'GRAVITY_CHANCE':
            val = gravityChanceToString(value as GRAVITY_CHANCE)
            break;
        case 'GRAVITY_RESULT_EXPECTED':
            val = gravityResultExpectedToString(value as GRAVITY_RESULT_EXPECTED)
            break;
        case 'S_AND_S':
            if (!value){
            val = 'No'
            }
            else{
                val = 'Yes'
            }
            break;
        case 'NEGLIGENCE':
            val = typeOfNegligenceString(value as NEGLIGENCE)
            break;
        case 'TYPE_OF_ISSUANCE':
            val = typeOfIssuanceToString(value as TYPE_OF_ISSUANCE)
            break;
        case 'INITIAL_ACTION':
            val = typeOfActionToString(value as TYPE_OF_ACTION)
            break;
        case 'PRIMARY_OR_MILL':
            if (value === 1){
                val = "Primary"
            }
            else{
                val = "Mill"
            }
            break;
        default:
            break
    }
    return <div>{val}</div>
}




const CitationDocument: React.FC<ICitationDocumentProps> = (props: ICitationDocumentProps) => {

    let history = useHistory();
   
    const { citation, handleCitationChange, mode } = props; 
    const [ citationInput, setCitationInput ] = useState<ICitationWithMeta>(citation);   
    const [ gravityChance, setGravityChance ] = useState<GRAVITY_CHANCE|null>(citationInput.gravityChance || null);
    const [ gravityResultExpected, setGravityResultExpected ] = useState<GRAVITY_RESULT_EXPECTED|null>(citationInput.gravityResultExpected || null);
    const [ significant, setSignificant ] = useState<boolean|null>(citationInput.significant!);
    const [negligence, setNegligence] = useState<NEGLIGENCE | null>(citationInput.negligence || null);
    const [typeOfIssuance, setTypeOfIssuance] = useState<TYPE_OF_ISSUANCE | null>(citationInput.typeOfIssuance || null);
    const [initialAction, setInitialAction] = useState<TYPE_OF_ISSUANCE | null>(citationInput.initialAction || null);
    const [primaryOrMill, setPrimaryOrMill] = useState<PRIMARY_OR_MILL | null>(citationInput.primaryOrMill || null);

    const handleSetCitationInput = (citation: ICitationWithMeta) => {
        handleCitationChange(citation, props.index);
        setCitationInput(citation);
    }
    return (
        <div className="citation-document-main">  
            <div style={{ display: 'flex', fontSize: '12px', lineHeight: '1.5', height: '40px' }}>
                <div style={{ flex: 3 }}>
                    <span style={{ flex: 1 }}>Mine Citation / Order</span>
                </div>
                <div style={{ flex: 3 }}>
                    <span style={{ flex: 1 }}><b>U.S. Department of Labor</b></span><br />
                    <span style={{ flex: 1 }}>Mine Safety and Health Administration</span>
                </div>
               <div style={{ flex: 1 }}>
                    <CheckboxWrap
                        mode={mode}
                        checked={citationInput.citationVacated}
                        onChange={(event) => {
                            console.log("this is the onchange event", event?.target?.value);
                            if (citationInput.citationVacated){
                                handleSetCitationInput({ ...citationInput, citationVacated: false })
                            }
                            else {
                                handleSetCitationInput({ ...citationInput, citationVacated: true })
                            }
                        }}
                        name="No Vacated"
                        color="primary"
                        id="1a_Vacated"
                        label="Vacated"
                        value={citationInput.citationVacated || false}
                    />
                    </div> 
            </div>

            {/* @*Section 1 Header*@ */}
            <div className="citation-section" style={{ display: 'flex', fontSize: '12px', lineHeight: 1, height: '20px' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <span style={{ flex: 1 }}><b>Section I -- Violation Data</b></span>
                </div>
            </div>

            {/* @*1. 2. 3.*@ */}
            <div className="citation-section" style={{ display: 'flex', fontSize: '12px', lineHeight: 1, height: '70px' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="1_date" style={{ flex: 1, marginBottom: '4px' }}>1. Date(Mo/Day/Year)</label>
                        <div style={{ flex: 1 }}>
                            <KeyboardDatePickerWrap
                                mode={mode}
                                size="small"
                                inputVariant="outlined"
                                disableToolbar
                                variant={"view" as WrapperVariant }
                                format="MM/dd/yyyy"
                                margin="none"
                                id="1_date"
                                value={citationInput.dateIssued}
                                onChange={(date) => {
                                    const checkDt = setDatePart(date, citationInput.dateIssued, DATE_PART.DATE)
                                 //   if (checkDt.valid){
                                        handleSetCitationInput({ ...citationInput, dateIssued: checkDt.value })
                                   // }
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }} />
                        </div>
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="2_time_24clock" style={{ flex: 1, marginBottom: '4px' }}>2. Time(24 hr Clock)</label>
                        <KeyboardTimePickerWrap
                            mode={mode}
                            size="small"
                            inputVariant="outlined"
                            ampm={false}
                            id="2_time_24clock"
                            margin="none"
                            value={citationInput.dateIssued}
                            onChange={(date) => { 
                                const checkDt = setDatePart(date, citationInput.dateIssued, DATE_PART.TIME)
                                if (checkDt.valid){
                                    handleSetCitationInput({ ...citationInput, dateIssued: checkDt.value })
                                }
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}/>
                    </div>
                </div>
                <div style={{ flex: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="3_citation_no" style={{ flex: 1, marginBottom: '4px' }}>3. Citation/Order Number</label>
                        <TextFieldWrap 
                            mode={mode}
                            size="small" 
                            id="3_citation_no" 
                            variant="outlined"
                            value={citationInput.citationNumber || ''}
							error={!validText(citationInput.citationNumber)}
                            onChange={(event) => handleSetCitationInput({...citationInput, citationNumber: event?.target.value})}                        
                        />
                    </div>
                </div>
            </div>

            {/* @*4. 5.*@ */}
            <div className="citation-section" style={{ display: 'flex', fontSize: '12px', lineHeight: 1, height: '70px' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="4_served_to" style={{ flex: 1, marginBottom: '4px' }}>4. Served to</label>
                        <TextFieldWrap 
                            mode={mode}
                            style={{width: '100%'}}
                            size="small" 
                            id="4_served_to" 
                            variant="outlined"
                            value={citationInput.servedTo || ''}
                            onChange={(event) => handleSetCitationInput({ ...citationInput, servedTo: event?.target.value })}
                        />
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="5_operator" style={{ flex: 1, marginBottom: '4px' }}>5. Operator</label>
                        <TextFieldWrap
                            mode={mode}
                            style={{width: '100%'}}
                            size="small" 
                            id="5_operator" 
                            variant="outlined"
                            value={citationInput.operatorName || ''}
                            onChange={(event) => handleSetCitationInput({ ...citationInput, operatorName: event?.target.value })} />
                    </div>
                </div>
            </div>

            {/* @*6. 7.*@ */}
            <div className="citation-section" style={{ display: 'flex', fontSize: '12px', lineHeight: 1 }}>
                <div style={{ fontSize: 16, flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-row">
                        <label htmlFor="6_mine" style={{ flex: 1, marginBottom: '4px' }}>6. Mine</label><br />
                        <div style={{paddingTop: 8}}>{props.mine? props.mine.mineName : null}</div>
                    </div>
                </div>
                <div style={{ fontSize: 16, flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-row">
                        <label htmlFor="7_mine_id" style={{ flex: 1, marginBottom: '4px' }}>7. Mine ID</label><br />
                        <div style={{paddingTop: 8}}>{props.mine? props.mine.mineId : null}</div>
                    </div>
                </div>
            </div>
            <div className="citation-section">
                {/* @*8.*@ */}
                <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                        <label htmlFor="8_condition_practice" style={{ flex: 1, marginBottom: '4px' }}>8. Condition or Practice</label><br />
                    </div>
                    <div style={{ flex: 1 }}>
                    <CheckboxWrap
                        mode={mode}
                        checked={citationInput.writtenNotice}
                        onChange={(event) => {
                            console.log("this is the onchange event", event?.target?.value);
                            if (citationInput.writtenNotice){
                                handleSetCitationInput({ ...citationInput, writtenNotice: false })
                            }
                            else {
                                handleSetCitationInput({ ...citationInput, writtenNotice: true })
                            }
                        }}
                        name="No Likelyhood"
                        color="primary"
                        id="8a_written_notice"
                        label="8a. Written Notice"
                        value={citationInput.writtenNotice || false}
                    />
                    </div>
                </div>
                <div style={{ display: 'flex', fontSize: '12px', lineHeight: 1, flex: 1 }}>
                    <TextFieldWrap 
                        mode={mode}
                        size="small"
                        variant="outlined"
                        id="8_condition_practice"
                        multiline
                        rows={4}
                        style={{ width: '100%' }}
                        value={citationInput.condition || ''}
                        onChange={(event) => handleSetCitationInput({ ...citationInput, condition: event?.target.value })}
                    />
                </div>
                {/* @*8. LABELS 8.A *@ */}
                <div style={{ display: 'flex', fontSize: '12px', height: '38px', flex: 1 }}>
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <div className="form-check form-check-inline">
                            <div style={{ fontSize: '16px' }}>
                               
                            </div>

                        </div>
                    </div>
                </div>
                
            </div>
            {/* @*9.*@ */}
            <div className="citation-section" style={{ display: 'flex', fontSize: '12px', lineHeight: 1, height: '70px' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-row">
                        <span style={{ flex: 1 }}>9. Violation</span>
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="9b_section_of_act" style={{ flex: 1, marginBottom: '4px' }}>B. Section of Act</label>
                        <TextFieldWrap
                            mode={mode}
                            id="9b_section_of_act" 
                            variant="outlined" 
                            size="small"
                            value={citationInput.sectionOfAct || ''}
                            onChange={(event) => handleSetCitationInput({ ...citationInput, sectionOfAct: event?.target.value })}
                        />
                    </div>
                </div>
                <div style={{ flex: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="9c_Part_Section_Title_30_CFR" style={{ flex: 1, marginBottom: '4px' }}>C. Part/Section of Title 30 CFR</label>
                        <TextFieldWrap
                            mode={mode}
                            id="9c_Part_Section_Title_30_CFR" 
                            variant="outlined" 
                            size="small"
                            value={citationInput.sectionOfCfr || ''}
                            onChange={(event) => handleSetCitationInput({ ...citationInput, sectionOfCfr: event?.target.value })} />
                    </div>
                </div>
            </div>
            {/* @*Section 2 Header*@ */}
            <div className="citation-section" style={{ display: 'flex', fontSize: '12px', lineHeight: 1 }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '20px' }}>
                    <span style={{ flex: 1 }}><b>Section II -- Inspector's Evaluation</b></span>
                </div>
            </div>
            {/* @*10. Gravity*@ */}
            <div className="citation-section" style={{ display: 'flex', fontSize: '12px', lineHeight: '1.5' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100px' }}>
                    <span style={{ flex: 1 }}>10. Gravity:</span>
                </div>
                <div style={{ flex: 6, height: '100px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '33px' }}>
                        <div style={{ flex: 1 }}>A. Injury or Illness</div>
                        <div style={{ flex: 4, paddingTop: '8px' }}>
                            {mode === 'edit' ? <RadioGroup row value={gravityChance} onChange={(event) => {
                                const x = Number(event.target.value) as GRAVITY_CHANCE;
                                handleSetCitationInput({...citationInput, gravityChance: x });
                                setGravityChance(x);
                            }}>
                                <FormControlLabel 
                                    classes={{ label: 'tipography-font-size' }}
                                    value={GRAVITY_CHANCE.NO_LIKELIHOOD}  control={<Radio />} label="No likelihood"/>
                                <FormControlLabel 
                                    classes={{ label: 'tipography-font-size' }}
                                    value={GRAVITY_CHANCE.UNLIKELY} control={<Radio />} label="Unlikely"/>
                                <FormControlLabel 
                                    classes={{ label: 'tipography-font-size' }}
                                    value={GRAVITY_CHANCE.REASONABLY_LIKELY} control={<Radio />} label="Reasonably likely"/>
                                <FormControlLabel 
                                    classes={{ label: 'tipography-font-size' }}
                                    value={GRAVITY_CHANCE.HIGHLY_LIKELY} control={<Radio />} label="Highly likely"/>
                                <FormControlLabel 
                                    classes={{ label: 'tipography-font-size' }}
                                    value={GRAVITY_CHANCE.OCCURED} control={<Radio />} label="Ocurred"/>
                            </RadioGroup> : getRadioValue(gravityChance, 'GRAVITY_CHANCE')}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '33px' }}>
                        <div style={{ flex: 1 }}>B. Injury or Illness cound be reasonably expected to:</div>
                        <div style={{ flex: 4 }}>
                            {mode === 'edit' ? <RadioGroup row value={gravityResultExpected} onChange={(event) => {
                                const x = Number(event.target.value) as GRAVITY_RESULT_EXPECTED;
                                handleSetCitationInput({ ...citationInput, gravityResultExpected: x });
                                setGravityResultExpected(x);
                            }}>
                                <FormControlLabel 
                                    classes={{ label: 'tipography-font-size' }}
                                    value={GRAVITY_RESULT_EXPECTED.NO_LOST_WORKDAYS} control={<Radio />} label="No lost workdays" />
                                <FormControlLabel 
                                    classes={{ label: 'tipography-font-size' }}
                                    value={GRAVITY_RESULT_EXPECTED.LOST_WORKDAYS_OR_RESTRICTED_DUTY} control={<Radio />} label="Lost workdays or Restricted Duty" />
                                <FormControlLabel 
                                    classes={{ label: 'tipography-font-size' }}
                                    value={GRAVITY_RESULT_EXPECTED.PERMANENTLY_DISABLING} control={<Radio />} label="Perm. Disabling" />
                                <FormControlLabel 
                                    classes={{ label: 'tipography-font-size' }}
                                    value={GRAVITY_RESULT_EXPECTED.FATAL} control={<Radio />} label="Fatal" />
                            </RadioGroup> : getRadioValue(gravityResultExpected, 'GRAVITY_RESULT_EXPECTED')}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '33px' }}>
                        <div style={{ flex: 1 }}>C. Significant and Substantial</div>
                        <div style={{ flex: 2 }}>
                            {mode === 'edit' ? <RadioGroup row value={significant} onChange={(event) => {
                                const x = event.target.value === 'true';
                                handleSetCitationInput({ ...citationInput, significant: x });
                                setSignificant(x);
                            }}>
                                <FormControlLabel
                                    classes={{ label: 'tipography-font-size' }}
                                    value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel
                                    classes={{ label: 'tipography-font-size' }}
                                    value={false} control={<Radio />} label="No" />
                            </RadioGroup> : getRadioValue(significant, 'S_AND_S')}
                        </div>
                        <div style={{ flex: 1 }}>D. Number of Persons Affected:</div>
                        <div style={{ flex: 1 }}>
                            <TextFieldWrap
                                mode={mode}
                                id="10_gravity_d_number_persons_affected" 
                                variant="outlined" 
                                size="small"
                                value={citationInput.personsAffected || ''}
                                onChange={(event) => handleSetCitationInput({ ...citationInput, personsAffected: event?.target.value })}/>
                        </div>
                    </div>
                </div>
            </div>
            {/* @*11. Negligence*@ */}
            <div className="citation-section" style={{ display: 'flex', fontSize: '12px', lineHeight: '1.5' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <span style={{ flex: 1 }}>11. Negligence (check one)</span>
                </div>
                <div style={{ flex: 6 }}>
                    {mode === 'edit' ? <RadioGroup row value={negligence} onChange={(event) => {
                            const x = Number(event.target.value) as NEGLIGENCE;
                            handleSetCitationInput({ ...citationInput, negligence: x });
                            setNegligence(x);
                        }}>
                        <FormControlLabel 
                            classes={{ label: 'tipography-font-size' }}
                            value={NEGLIGENCE.NONE} control={<Radio />} label="A. None" />
                        <FormControlLabel 
                            classes={{ label: 'tipography-font-size' }}
                            value={NEGLIGENCE.LOW} control={<Radio />} label="B. Low" />
                        <FormControlLabel 
                            classes={{ label: 'tipography-font-size' }}
                            value={NEGLIGENCE.MODERATE} control={<Radio />} label="C. Moderate" />
                        <FormControlLabel 
                            classes={{ label: 'tipography-font-size' }}
                            value={NEGLIGENCE.HIGH} control={<Radio />} label="D. High" />
                        <FormControlLabel 
                            classes={{ label: 'tipography-font-size' }}
                            value={NEGLIGENCE.RECKLESS_DISREGARD} control={<Radio />} label="E. Reckless Disregarded" />
                    </RadioGroup>: getRadioValue(negligence, 'NEGLIGENCE')}
                </div>
            </div>
            {/* @*12. Type of Action*@ */}
            <div className="citation-section" style={{ display: 'flex', fontSize: '12px', height: '70px' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <label className="mr-sm-2" htmlFor="inlineFormCustomSelect">12. Type of Action &nbsp;</label>
                    <Select
                    className={mode === "edit" ? "edit" : "view"}
                        id="inlineFormCustomSelect" 
                        variant="outlined" 
                        style={{ height: 40 }}
                        value={citationInput.typeOfAction} 
                        onChange={(event) => handleSetCitationInput({...citationInput, typeOfAction: Number(event.target.value)})}
                        displayEmpty>
                        <MenuItem value='' disabled>Select</MenuItem>
                        <MenuItem value={TYPE_OF_ACTION._103_J}>103(j)</MenuItem>
                        <MenuItem value={TYPE_OF_ACTION._103_K}>103(k)</MenuItem>
                        <MenuItem value={TYPE_OF_ACTION._104_A}>104(a)</MenuItem>
                        <MenuItem value={TYPE_OF_ACTION._104_B}>104(b)</MenuItem>
                        <MenuItem value={TYPE_OF_ACTION._104_D_1}>104(d)(1)</MenuItem>
                        <MenuItem value={TYPE_OF_ACTION._104_D_2}>104(d)(2)</MenuItem>
                        <MenuItem value={TYPE_OF_ACTION._104_E}>104(e)</MenuItem>
                        <MenuItem value={TYPE_OF_ACTION._104_F}>104(f)</MenuItem>
                        <MenuItem value={TYPE_OF_ACTION._104_G}>104(g)</MenuItem>
                        <MenuItem value={TYPE_OF_ACTION._107_A}>107(a)</MenuItem>
                        <MenuItem value={TYPE_OF_ACTION._110_A}>110(a)</MenuItem>
                        <MenuItem value={TYPE_OF_ACTION._314_B}>314(b)</MenuItem>
                    </Select>
                </div>
                <div style={{ flex: 3, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-row">
                        <label htmlFor="9b_section_of_act" style={{ flex: 1, marginBottom: '4px' }}>13. Type of Issuance (check one)</label>
                        {mode === "edit" ? <RadioGroup row value={typeOfIssuance} onChange={(event) => {
                            const x = Number(event.target.value) as TYPE_OF_ISSUANCE;
                            handleSetCitationInput({ ...citationInput, typeOfIssuance: x });
                            setTypeOfIssuance(x);
                        }}>
                            <FormControlLabel 
                                classes={{ label: 'tipography-font-size' }}
                                value={TYPE_OF_ISSUANCE.CITATION} control={<Radio />} label="Citation" />
                            <FormControlLabel 
                                classes={{ label: 'tipography-font-size' }}
                                value={TYPE_OF_ISSUANCE.ORDER} control={<Radio />} label="Order" />
                            <FormControlLabel 
                                classes={{ label: 'tipography-font-size' }}
                                value={TYPE_OF_ISSUANCE.SAFEGUARD} control={<Radio />} label="Safeguard" />
                            <FormControlLabel 
                                classes={{ label: 'tipography-font-size' }}
                                value={TYPE_OF_ISSUANCE.WRITTEN_NOTICE} control={<Radio />} label="Written notice" />
                        </RadioGroup>: getRadioValue(typeOfIssuance, 'TYPE_OF_ISSUANCE')}
                    </div>
                </div>
            </div>
            {/* @*14. Initial Action*@ */}
            <div className="citation-section" style={{ display: 'flex', fontSize: '12px', height: '70px' }}>
                <div style={{ flex: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-row">
                        <label htmlFor="9b_section_of_act" style={{ flex: 1, marginBottom: '4px' }}>14. Initial Action &nbsp;</label>
                        {mode === 'edit' ? <RadioGroup row value={initialAction} onChange={(event) => {
                            const x = Number(event.target.value) as TYPE_OF_ISSUANCE;
                            handleSetCitationInput({ ...citationInput, initialAction: x });
                            setInitialAction(x);
                        }}>
                            <FormControlLabel 
                                classes={{ label: 'tipography-font-size' }}
                                value={TYPE_OF_ISSUANCE.CITATION} control={<Radio />} label="Citation" />
                            <FormControlLabel 
                                classes={{ label: 'tipography-font-size' }}
                                value={TYPE_OF_ISSUANCE.ORDER} control={<Radio />} label="Order" />
                            <FormControlLabel 
                                classes={{ label: 'tipography-font-size' }}
                                value={TYPE_OF_ISSUANCE.SAFEGUARD} control={<Radio />} label="Safeguard" />
                            <FormControlLabel 
                                classes={{ label: 'tipography-font-size' }}
                                value={TYPE_OF_ISSUANCE.WRITTEN_NOTICE} control={<Radio />} label="Written notice" />
                        </RadioGroup>: getRadioValue(initialAction, 'INITIAL_ACTION')}
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="14e_citation_no" style={{ flex: 1, marginBottom: '4px' }}>E. Citation Order/Number</label>
                        <TextFieldWrap
                            mode={mode}
                            id="14e_citation_no" 
                            variant="outlined" 
                            size="small" 
                            value={citationInput.initialCitationNumber || ''}
                            onChange={(event) => handleSetCitationInput({ ...citationInput, initialCitationNumber: event?.target.value })}
                            />
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="14f_date" style={{ flex: 1, marginBottom: '4px' }}>F. Date (Mo/Day/Year)</label>
                        <KeyboardDatePickerWrap
                            mode={mode}
                            size="small"
                            inputVariant="outlined"
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="none"
                            id="14f_date"
							//error={!isValidDateSQL(citationInput.initialCitationDate)}
                            value={citationInput.initialCitationDate}
                            onChange={(date) => { 
                                const checkDt = setDatePart(date, citationInput.initialCitationDate, DATE_PART.TIME)
                             //   if (checkDt.valid){
                                    handleSetCitationInput({ ...citationInput, initialCitationDate: checkDt.value })
                               // }
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }} />
                    </div>
                </div>
            </div>
            {/* @*15. Area of Equipment*@ */}
            <div className="citation-section">
                <div style={{ display: 'flex', fontSize: '12px' }}>
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <div className="flex-row" style={{ width: '100%' }}>
                            <label htmlFor="15_area_or_equipment" style={{ flex: 1, marginBottom: '4px' }}>15. Area or equipment</label>
                            <div style={{ width: '100%' }}>
                                <TextFieldWrap
                                    mode={mode}
                                    size="small"
                                    variant="outlined"
                                    id="15_area_or_equipment"
                                    multiline
                                    rows={4}
                                    style={{ width: '100%' }}
                                    value={citationInput.area || ''}
                                    onChange={(event) => handleSetCitationInput({ ...citationInput, area: event?.target.value })} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* @*16.*@ */}
            <div className="citation-section" style={{ display: 'flex', fontSize: '12px', lineHeight: 1, height: '70px' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-row">
                        <span style={{ flex: 1 }}>16. Termination Due</span>
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="16a_date" style={{ flex: 1, marginBottom: '4px' }}>A. Date (Mo/Day/Year)</label>		
                        <KeyboardDatePickerWrap
                            mode={mode}
                            size="small"
                            inputVariant="outlined"
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="none"
                            id="16a_date"
                            value={citationInput.terminationDueDate}
							error={!isValidDateSQL(citationInput.terminationDueDate)}
                            onChange={(date) => { 
                                const checkDt = setDatePart(date, citationInput.terminationDueDate, DATE_PART.DATE)
                                if (checkDt.valid){
                                    handleSetCitationInput({ ...citationInput, terminationDueDate: checkDt.value })
                                }
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }} />
                    </div>
                </div>
                <div style={{ flex: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="16b_time_24clock" style={{ flex: 1, marginBottom: '4px' }}>B. Time (24 hr Clock)</label>
                        <KeyboardTimePickerWrap
                            mode={mode}
                            size="small"
                            inputVariant="outlined"
                            ampm={false}
                            id="16b_time_24clock"
                            margin="none"
                            value={citationInput.terminationDueDate}
							error={!isValidDateSQL(citationInput.terminationDueDate)}
                            onChange={(date) => {  
                                const checkDt = setDatePart(date, citationInput.terminationDueDate, DATE_PART.TIME)
                                if (checkDt.valid){
                                    handleSetCitationInput({ ...citationInput, terminationDueDate: checkDt.value })
                                }
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }} />
                    </div>
                </div>
            </div>
            {/* @*Section 3 Header*@ */}
            <div className="citation-section" style={{ display: 'flex', fontSize: '12px', lineHeight: 1, height: '20px' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <span style={{ flex: 1 }}><b>Section III -- Termination Action</b></span>
                </div>
            </div>
            {/* @*17. Action to terminate*@ */}
            <div className="citation-section">
                <div style={{ display: 'flex', fontSize: '12px' }}>
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <div className="flex-row" style={{ width: '100%' }}>
                            <label htmlFor="17_action_to_terminate" style={{ flex: 1, marginBottom: '4px' }}>17. Action to Terminate</label>
                            <div style={{ width: '100%' }}>
                                <TextFieldWrap
                                    mode={mode}
                                    size="small"
                                    variant="outlined"
                                    id="17_action_to_terminate"
                                    multiline
                                    rows={4}
                                    style={{ width: '100%' }}
                                    value={citationInput.actionToTerminate || ''}
                                    onChange={(event) => handleSetCitationInput({ ...citationInput, actionToTerminate: event?.target.value })} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* @*18.*@ */}
            <div className="citation-section" style={{ display: 'flex', fontSize: '12px', lineHeight: 1, height: '70px' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-row">
                        <span style={{ flex: 1 }}>18. Terminated</span>
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="18a_date" style={{ flex: 1, marginBottom: '4px' }}>A. Date (Mo/Day/Year)</label>
						<KeyboardDatePickerWrap
                            mode={mode}
                            size="small"
                            inputVariant="outlined"
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="none"
                            id="18a_date"
                            value={citationInput.terminationDate}
                          //  error={!isValidDateSQL(citationInput.terminationDate)}
                            onChange={(date) => {
                                const checkDt = setDatePart(date, citationInput.terminationDate, DATE_PART.DATE)
                               // if (checkDt.valid){
                                    handleSetCitationInput({ ...citationInput, terminationDate: checkDt.value })
                                //}
                            }
                            }
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }} />
                    </div>
                </div>
                <div style={{ flex: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="18b_time_24clock" style={{ flex: 1, marginBottom: '4px' }}>B. Time (24 hr Clock)</label>
                        <KeyboardTimePickerWrap
                            mode={mode}
                            size="small"
                            inputVariant="outlined"
                            ampm={false}
                            id="16b_time_24clock"
                            margin="none"
                            value={citationInput.terminationDate}
                            //error={!isValidDateSQL(citationInput.terminationDate)}
                            onChange={(date) => {
                                const checkDt = setDatePart(date, citationInput.terminationDate, DATE_PART.TIME)
                              //  if (checkDt.valid){
                                    handleSetCitationInput({ ...citationInput, terminationDate: checkDt.value })
                                //}
                            }
                            }
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }} />
                    </div>
                </div>
            </div>
            {/* @*Section 4 Header*@ */}
            <div className="citation-section" style={{ display: 'flex', fontSize: '12px', lineHeight: 1, height: '20px' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <span style={{ flex: 1 }}><b>Section IV -- Automated System Data</b></span>
                </div>
            </div>
            {/* @*19. Type of Inspection (activity code)*@ */}
            <div className="citation-section" style={{ display: 'flex', fontSize: '12px', height: '70px' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <label className="mr-sm-2" htmlFor="inlineFormCustomSelect">Type of Inspection (activity code) &nbsp;</label>
                    <div style={{ width: '100px' }} className="input-group input-group-sm">
                        <Select
                            className={mode === "edit" ? "edit" : "view"}
                            id="inlineFormCustomSelect"
                            variant="outlined"
                            value={citationInput.typeOfInspection}
                            style={{ height: 40 }}
                            onChange={(event) => handleSetCitationInput({ ...citationInput, typeOfInspection: Number(event.target.value) })}
                            displayEmpty>
                            <MenuItem value="" disabled>Select</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E01}>E01</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E04}>E04</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E16}>E16</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E15}>E15</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E08}>E08</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E02}>E02</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E03}>E03</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E06}>E06</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E07}>E07</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E17}>E17</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E18}>E18</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E19}>E19</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E20}>E20</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E21}>E21</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E22}>E22</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E23}>E23</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E24}>E24</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E25}>E25</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E27}>E27</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E28}>E28</MenuItem>
                            <MenuItem value={TYPE_OF_INSPECTION.E33}>E33</MenuItem> 
                        </Select>
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="20_event_no" style={{ flex: 1, marginBottom: '4px' }}>20. Event Number</label>
                        <TextFieldWrap
                            mode={mode}
                            id="20_event_no" 
                            variant="outlined" 
                            size="small"
                            value={citationInput.eventNumber || ''}
                            onChange={(event) => handleSetCitationInput({ ...citationInput, eventNumber: event?.target.value })} />
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="9b_section_of_act" style={{ flex: 1 }}>21. Primary or Mill &nbsp;</label>
                        {mode === 'edit' ? <RadioGroup row value={primaryOrMill} onChange={(event) => {
                            const x = Number(event.target.value) as PRIMARY_OR_MILL;
                            handleSetCitationInput({ ...citationInput, primaryOrMill: x });
                            setPrimaryOrMill(x);
                        }}>
                            <FormControlLabel 
                                classes={{ label: 'tipography-font-size' }}
                                value={PRIMARY_OR_MILL.PRIMARY} control={<Radio />} label="Primary" />
                            <FormControlLabel 
                                classes={{ label: 'tipography-font-size' }}
                                value={PRIMARY_OR_MILL.MILL} control={<Radio />} label="Mill" />
                        </RadioGroup>: getRadioValue(primaryOrMill, 'PRIMARY_OR_MILL')}
                    </div>
                </div>
            </div>
            {/* @*22 23.*@ */}
            <div className="citation-section citation-section-last" style={{ display: 'flex', fontSize: '12px', lineHeight: 1, height: '70px' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="22_ar_name" style={{ flex: 1, marginBottom: '4px' }}>22. AR Name</label>
                        <TextFieldWrap
                            mode={mode}
                            id="20_event_no" 
                            variant="outlined"
							value={citationInput.arName || ''}
							onChange={(event) => handleSetCitationInput({ ...citationInput, arName: event?.target.value })} 
                            size="small" />
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="23_ar_number" style={{ flex: 1, marginBottom: '4px' }}>23. AR Number</label>
                        <TextFieldWrap
                            mode={mode}
                            id="23_ar_number" 
                            variant="outlined" 
                            size="small"
                            value={citationInput.arnumber || ''}
                            onChange={(event) => handleSetCitationInput({ ...citationInput, arnumber: event?.target.value })} />
                    </div>
                </div>
            </div>
        </div>
    )



}

export default CitationDocument