import { omit } from "lodash";

export function autoReduce (action: any, state: any) {
    if (action.normalized) {
        Object.keys(action.normalized.entities).forEach((key) => {
            state[key] = {
                ...state[key],
                ...action?.normalized?.entities[key],
            };
        });
    }
    if (action.removeKeys) {
        for (let i = 0; i < action.removeKeys.length; i += 1) {
            state[action.removeKeys[i].entities] = omit(
                state[action.removeKeys[i].entities],
                action.removeKeys[i].ids
            );
        }
    }
    if  (action.setKeys) {
        for (let i = 0; i < action.setKeys.length; i += 1) {
            state[action.setKeys[i].stateKey][action.setKeys[i].key] = action.setKeys[i].value
        }
    }
    if (action.overrides){
        action.overrides.map((x: any) => {
            if (x.value){
                state[x.key] = x.value;
            }
            return null;
        });
    }

    return { ...state };
}