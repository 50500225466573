import React from "react";
import MaterialTable from "material-table";
import { tableIcons } from "../shared/MaterialTableShared";
import { IAccountLogin } from "../../interfaces/model/MSHAPCT/account-login.interface";
import { indexOf } from "lodash";

interface IAccountsTable {
  data: IAccountLogin[];
  title?: string;
}

const UsersUsageTable: React.FC<IAccountsTable> = (props) => {
  const columns = [
    //{ title: "PK", field: "pk", sorting: true },
    { title: "First Name", field: "firstName", sorting: true },
    { title: "Last Name", field: "lastName", sorting: true },
    { title: "Email", field: "emailAddress", sorting: true },
    { title: "No. Days Accessed", field: "numberOfHit", sorting: true },
    {
      title: "Last Access Date",
      field: "lastAccessDate",
      sorting: true,
      render: (rowData: IAccountLogin) => {
        return (
          <div>
            {rowData.lastAccessDate?.substring(
              0,
              rowData.lastAccessDate?.indexOf("T")
            )}
          </div>
        );
      },
    },
  ];
  console.log("props.data", Math.min(props.data.length, 10));

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        title={props.title || "Users"}
        columns={columns}
        data={props.data}
        options={{
          sorting: true,
          headerStyle: {
            zIndex: 8,
          },
          paging: true,
          pageSize: 5,
          draggable: false,
          //actionsColumnIndex: -1
        }}
        onRowClick={(event, rowData, togglePanel) => {
          if (rowData && rowData.pk) {
          }
        }}
      />
    </>
  );
};

export default UsersUsageTable;
