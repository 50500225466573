export const isNull = (val: any) => {
    if (val === null || val === undefined){
        return true;
    }
    return false
}

export const numberToCurrency = (num: any) => {
    if (isNaN(num)){
        return ""
    }
    const toNumber = Number(num);
    return '$' + toNumber.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

export const numberPeriodInputFilter = (input: any) => {

	input = input.replace(/\b0+/g, '')
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*?)\..*/g, '$1')
    .replace(/([0-9]{0,6}(\.[0-9]{0,2})?).*/g, '$1')

	//let re = new RegExp('^[0-9]*\.[0-9]{2}$ or ^[0-9]*\.[0-9][0-9]$')
	// let re = new RegExp(/s*([^[:]+):"([^"]+)"/g);
	// let newVal = input.match(re);
	// return newVal;
	// let newVal = input.replace(/^[0-9]*\.[0-9]{2}$ or ^[0-9]*\.[0-9][0-9]$,'');
	// return newVal
	
	// let newVal = input.replace(/[^0-9\.]/g,'');
	// newVal = newVal.replace(/(?<=\..*)\./g, '');
	return input;
}