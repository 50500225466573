import React, { useEffect, useState } from "react";
import { Button, Grid, DialogContent, Tooltip } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Loading from "../shared/Loading";
import { useSnackbar } from "notistack";
import { useSelector } from "../../store";
import { IRootState } from "../../interfaces/store";
import CitationNoteModal from "./CitationNoteModal";
import { useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import { strftime } from "../../utils/strftime";
import { ICitation } from "../../interfaces/model/MSHAPCT/citation.interface";
import {
  gravityChanceToString,
  gravityResultExpectedToString,
  GRAVITY_CHANCE,
  GRAVITY_RESULT_EXPECTED,
  NEGLIGENCE,
  typeOfNegligenceString,
} from "../../enums/citation-enums";
import { ICitationMetaData } from "../../interfaces/model/MSHAPCT/citation-meta-data.interface";
import {
  downloadCitationFile,
  getCitationWithMetada,
  updateCitationNote,
  deleteCitationNotesFile
} from "../../store/actions/citationActions";
import { numberToCurrency } from "../../utils/general-utils";
import { ICitationMgmtCustomField } from "../../interfaces/model/MSHAPCT/citation-mgmt-custom-field.interface";
import DeleteIcon from "@material-ui/icons/Delete";
import StandardModal from "../shared/StandardModal"

interface ICitationNoteDetailsModalProps {
  itemData: any;
  open: boolean;
  onClose: Function;
  title: string;
  onSubmit: Function;
  isEditMode: boolean;
  currentCompanyCustomFields: ICitationMgmtCustomField[],
  minePk: string
}

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-root": {
      paddingLeft: "0px !important",
      borderBottom: "none",
    },
  },
});

const CitationNoteDetailsModal: React.FC<ICitationNoteDetailsModalProps> = (
  props
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [processsing, setProcesssing] = useState(false);
  const dispatch = useDispatch();

  const citationsMetaData = useSelector(
    (state: IRootState) => state.main.citation_meta_data
  );

  const [modalKeyId, setModalKeyId] = useState<number>(0);
  const [openImages, setOpenImages] = React.useState(false);
  const [openDocuments, setOpenDocuments] = React.useState(false);
  const [citation, setCitation] = useState<ICitation>(props.itemData);
  const [citationMetaData, setCitationMetaData] = useState<ICitationMetaData>(
    {}
  );
  const [isCitationNotesModalOpen, setIsCitationNotesModalOpen] =
    useState(false);

  const [confirmDeleteMode, setConfirmDeleteMode] = useState(false);
  const [selectedDeleteFile, setSelectedDeleteFile] = useState('');

  useEffect(() => {
    if (props.itemData.pk) {
      setProcesssing(true);
      dispatch(
        getCitationWithMetada(
          props.itemData.pk,
          onCitationMetadaSuccess,
          onCitationMetaDataFail
        )
      );

      const sourceCitationsMetaData = Object.values(citationsMetaData).filter(
        (item) => item.citationFk === Number(props.itemData.pk)
      )[0];
      setCitationMetaData(sourceCitationsMetaData);
      setCitation(props.itemData);
    }
  }, [props.itemData]);

  useEffect(() => {
    if (citationsMetaData) {
      const sourceCitationsMetaData = Object.values(citationsMetaData).filter(
        (item) => item.citationFk === Number(props.itemData.pk)
      )[0];
      if (sourceCitationsMetaData) {
        setCitationMetaData(sourceCitationsMetaData);
      }
    }
  }, [citationsMetaData]);

  const onCitationMetadaSuccess = () => {
    setProcesssing(false);
  };
  const onCitationMetaDataFail = () => {
    setProcesssing(false);
  };

  const onSubmitCitationNotesModal = (
    data: any,
    onSuccess: Function,
    onFail?: Function
  ) => {
    if (data) {
      dispatch(updateCitationNote(data, onSucessUpload(onSuccess), onFail));
    }
  };

  const onSucessUpload = (onSuccess: Function) => () => {
    onSuccess();
    setIsCitationNotesModalOpen(false);
    setModalKeyId(modalKeyId + 1);
  };

  const onDownloadFile = (fileName: string) => () => {
    const data = { citationPk: citation.pk, fileName };
    dispatch(
      downloadCitationFile(data, null, (errorMessage: any) => {
        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      })
    );
  };

  const onDeleteFile = () => {
	enqueueSnackbar("File Deleted", {
		variant: "success",
	  });
	dispatch(
        getCitationWithMetada(
          props.itemData.pk,
          onCitationMetadaSuccess,
          onCitationMetaDataFail
        )
	);
  }

  const renderCustomValue = (fieldId: string, customValue?: string) => {
    if (fieldId.includes("CustomText")){
      return <span>
        {customValue}
      </span>
    }
    else if (fieldId.includes("CustomDate")){
      return <span>
        {customValue}
      </span>
    }
    else if (fieldId.includes("CustomWholeNumber")){
      return <span>
        {customValue}
      </span>
    }
    else if (fieldId.includes("CustomDecimalNumber")){
      return <span>
        {customValue}
      </span>
    }
    else if (fieldId.includes("CustomLongText")){
      return <span>
        {customValue}
      </span>
    }
    else if (fieldId.includes("CustomMoney")){
      return <span>
        {customValue}
      </span>
    }
    return null;
  }


  const renderCustomField = (fieldId: string, customValue?: any) => {
    if (customValue){
      const customFieldData = props.currentCompanyCustomFields.find((x) => x.fieldId === fieldId)
	  // allow disabling of in_use / custom fields
      if (customFieldData?.fieldInUse === true){
        return (      
          <Grid item xs={12} lg={6}>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div style={{ flex: "50%" }}>
              <span>
                <b>{customFieldData?.fieldDisplayName || customFieldData?.fieldId}:</b>
              </span>
            </div>
            <div style={{ flex: "50%", textAlign: "left" }}>
              <span>{renderCustomValue(fieldId, customValue)}</span>
            </div>
          </div>
        </Grid>
          )
      }
    }
    return null
  }

  const classes = useStyles();



  return (
    <>
      <Loading loading={processsing} />
	  <StandardModal
                open={confirmDeleteMode}
                onClose={() => setConfirmDeleteMode(false)}
                actions={[
                { title: "Cancel", callback: () => {
                    setConfirmDeleteMode(false);
                } },
                { title: "Yes", callback: () => {

					dispatch(deleteCitationNotesFile({fileName: selectedDeleteFile, minePk: props.minePk}, () => {
						onDeleteFile()
						setSelectedDeleteFile('');
						setConfirmDeleteMode(false);
					},
					() => {
						enqueueSnackbar("File could not be deleted.", {
							variant: "error",
						  });
						  setSelectedDeleteFile('');
						  setConfirmDeleteMode(false);
					}
					))

             
                }},
                ]}
                customActions={[]}
                paragraphs={[`Are you sure you want to delete ${selectedDeleteFile}. This action cannot be undone.`]}
                title={'Delete File'}
            />
      <CitationNoteModal
        key={`citation-note-modal-${modalKeyId}`}
        citation={citation}
        citationMetaData={citationMetaData}
        open={isCitationNotesModalOpen}
        onClose={() => setIsCitationNotesModalOpen(false)}
        title={"Citation Notes: " + citation.citationNumber}
        onSubmit={onSubmitCitationNotesModal}
        isEditMode={citation.pk ? true : false}
        currentCompanyCustomFields={props.currentCompanyCustomFields}
      ></CitationNoteModal>
      <Dialog
        onClose={() => props.onClose()}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
        open={props.open}
        color="primary"
        // fullScreen={true}
      >
        <MuiDialogTitle style={{ background: "rgb(118, 156, 167)" }}>
          <div
            style={{
              padding: 0,
              display: "flex",
            }}
          >
            <div style={{ flex: 1, alignSelf: "center" }}>
              <Typography variant="h6" style={{ alignItems: "center" }}>
                {(props.isEditMode ? "" : "") + props.title}
              </Typography>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <IconButton aria-label="close" onClick={() => props.onClose()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </MuiDialogTitle>
        <DialogContent dividers style={{ background: "white" }}>
          <Grid
            container
            spacing={2}
            justify="center"
            style={{ fontFamily: "Roboto" }}
          >
            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>Citation Number:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <span>{props.itemData.citationNumber}</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>Date Issued:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <span>
                    {props.itemData.dateIssued
                      ? strftime(
                          "%m/%d/%Y",
                          new Date(props.itemData.dateIssued as string)
                        )
                      : ""}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>Chance of Injury Or Illness:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <span>
                    {gravityChanceToString(
                      props.itemData.gravityChance as GRAVITY_CHANCE
                    )}
                  </span>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>Expected Injury Or Illness:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <span>
                    {gravityResultExpectedToString(
                      props.itemData
                        .gravityResultExpected as GRAVITY_RESULT_EXPECTED
                    )}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>Number of persons affected:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <span>{props.itemData.personsAffected || ""}</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>Negligence:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <span>
                    {typeOfNegligenceString(
                      props.itemData.negligence as NEGLIGENCE
                    )}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>Significant and Substantial:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <span>{props.itemData.Substantial ? "Yes" : "No"}</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>Part/Section of CFR:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <span>{props.itemData.sectionOfCfr}</span>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>MSHA Proposed Penalty Amount:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <span>{numberToCurrency(citationMetaData?.mshaProposedPenalty)}</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>MSHA Current Penalty Amount:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <span>{numberToCurrency(citationMetaData?.mshaCurrentPenalty)}</span>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>MSHA Final Order Date:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <span>
                    {citationMetaData?.mshaFinalOrderDate
                      ? strftime(
                          "%m/%d/%Y",
                          new Date(
                            citationMetaData?.mshaFinalOrderDate as string
                          )
                        )
                      : ""}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>MSHA Case Number:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <span>{citationMetaData?.mshaCaseNumber}</span>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>Condition or Pactice:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <span>{props.itemData.condition}</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>Notes:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <span>{citationMetaData?.notes}</span>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>Images:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <Table
                    aria-label="collapsible table"
                    style={{ margin: 0 }}
                    classes={{ root: classes.customTable }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell scope="row" align="left">
                          {(citationMetaData?.imagesList
                            ? citationMetaData?.imagesList.length
                            : "0") + " File(s)"}
                        </TableCell>
                        <TableCell padding="none">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenImages(!openImages)}
                          >
                            {openImages ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            borderBottom: "none",
                          }}
                          colSpan={6}
                        >
                          <Collapse in={openImages} timeout="auto">
                            <Box margin={1}>
                              <Table size="small" aria-label="purchases">
                                <TableBody>
                                  {citationMetaData?.imagesList?.map((row) => (
                                    <TableRow key={row}>
                                      <TableCell component="th" scope="row">
                                        <a
                                          href="#donwload"
                                          onClick={onDownloadFile(row)}
                                        >
                                          {row}
                                        </a>
                                      </TableCell>
									  <TableCell component="th" scope="row">
									  <Tooltip title="Delete" placement="top">
										<DeleteIcon
											style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
											className="transparent75"
											onClick={() => {
									
												setSelectedDeleteFile(row);
												setConfirmDeleteMode(true)
											}}
										/>
										</Tooltip>
										</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <span>
                    <b>PDF Documents:</b>
                  </span>
                </div>
                <div style={{ flex: "50%", textAlign: "left" }}>
                  <Table
                    aria-label="collapsible table"
                    style={{ margin: 0 }}
                    classes={{ root: classes.customTable }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell scope="row" align="left">
                          {(citationMetaData?.documentsList
                            ? citationMetaData?.documentsList.length
                            : "0") + " File(s)"}
                        </TableCell>
                        <TableCell padding="none">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenDocuments(!openDocuments)}
                          >
                            {openDocuments ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            borderBottom: "none",
                          }}
                          colSpan={6}
                        >
                          <Collapse in={openDocuments} timeout="auto">
                            <Box margin={1}>
                              <Table size="small" aria-label="purchases">
                                <TableBody>
                                  {citationMetaData?.documentsList?.map(
                                    (row) => (
                                      <TableRow key={row}>
                                        <TableCell component="th" scope="row">
                                          <a
                                            href="#donwload"
                                            onClick={onDownloadFile(row)}
                                          >
                                            {row}
                                          </a>
                                        </TableCell>
										<TableCell component="th" scope="row">
									  <Tooltip title="Delete" placement="top">
										<DeleteIcon
											style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
											className="transparent75"
											onClick={() => {
											
												setSelectedDeleteFile(row);
												setConfirmDeleteMode(true)
											}}
										/>
										</Tooltip>
										</TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Grid>
            {renderCustomField('CustomText1', citationMetaData?.customText1)}
            {renderCustomField('CustomText2', citationMetaData?.customText2)}
            {renderCustomField('CustomText3', citationMetaData?.customText3)}
            {renderCustomField('CustomText4', citationMetaData?.customText4)}
            {renderCustomField('CustomText5', citationMetaData?.customText5)}
            {renderCustomField('CustomText6', citationMetaData?.customText6)}
            {renderCustomField('CustomLongText1', citationMetaData?.customLongText1)}
			{renderCustomField('CustomLongText2', citationMetaData?.customLongText2)}
			{renderCustomField('CustomLongText3', citationMetaData?.customLongText3)}
			{renderCustomField('CustomLongText4', citationMetaData?.customLongText4)}
			{renderCustomField('CustomLongText5', citationMetaData?.customLongText5)}
			{renderCustomField('CustomLongText6', citationMetaData?.customLongText6)}
            {renderCustomField('CustomDate1', citationMetaData?.customDate1)}
            {renderCustomField('CustomDate2', citationMetaData?.customDate2)}
            {renderCustomField('CustomDate3', citationMetaData?.customDate3)}
            {renderCustomField('CustomDate4', citationMetaData?.customDate4)}
            {renderCustomField('CustomDate5', citationMetaData?.customDate5)}
            {renderCustomField('CustomDate6', citationMetaData?.customDate6)}
            {renderCustomField('CustomMoney1', citationMetaData?.customMoney1)}
            {renderCustomField('CustomMoney2', citationMetaData?.customMoney2)}
            {renderCustomField('CustomMoney3', citationMetaData?.customMoney3)}
            {renderCustomField('CustomMoney4', citationMetaData?.customMoney4)}
            {renderCustomField('CustomMoney5', citationMetaData?.customMoney5)}
            {renderCustomField('CustomMoney6', citationMetaData?.customMoney6)}
            {renderCustomField('CustomWholeNumber1', citationMetaData?.customWholeNumber1)}
            {renderCustomField('CustomWholeNumber2', citationMetaData?.customWholeNumber2)}
            {renderCustomField('CustomWholeNumber3', citationMetaData?.customWholeNumber3)}
            {renderCustomField('CustomWholeNumber4', citationMetaData?.customWholeNumber4)}
            {renderCustomField('CustomWholeNumber5', citationMetaData?.customWholeNumber5)}
            {renderCustomField('CustomWholeNumber6', citationMetaData?.customWholeNumber6)}
            {renderCustomField('CustomDecimalNumber1', citationMetaData?.customDecimalNumber1)}
            {renderCustomField('CustomDecimalNumber2', citationMetaData?.customDecimalNumber2)}
            {renderCustomField('CustomDecimalNumber3', citationMetaData?.customDecimalNumber3)}
            {renderCustomField('CustomDecimalNumber4', citationMetaData?.customDecimalNumber4)}
            {renderCustomField('CustomDecimalNumber5', citationMetaData?.customDecimalNumber5)}
            {renderCustomField('CustomDecimalNumber6', citationMetaData?.customDecimalNumber6)}



          </Grid>
        </DialogContent>
        <DialogActions style={{ background: "rgb(118, 156, 167)" }}>
          <Button
            variant={"contained"}
            autoFocus
            onClick={() => setIsCitationNotesModalOpen(true)}
            color="primary"
          >
            Edit Notes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CitationNoteDetailsModal;
