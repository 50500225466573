export const RegulationURLFromSectionOfCfr = (sectionOfCfr: any) => {
    let splitSection = "";
    if (sectionOfCfr) {
      let split = sectionOfCfr.split(".");
      splitSection = split[0];
      return `https://www.ecfr.gov/cgi-bin/retrieveECFR?gp=1&SID=541dd84c02514500a1cc4a5f87a481dc&h=L&mc=true&n=pt30.1.${splitSection}&r=PART&ty=HTML`
    } else {
      console.log("Error parsing sectionOfCfr for URL");
      return '';
    }
}

