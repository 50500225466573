import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  FormLabel,
  FormGroup,
  Switch,
  DialogContent,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Loading from "../shared/Loading";
import { useDispatch, useSelector } from "react-redux";
import { arrayFromKeyedObject } from "../../utils/collection-utils";
import {
  ICompany,
  ICompanyWithMines,
} from "../../interfaces/model/MSHAPCT/company.interface";
import { IRootState } from "../../interfaces/store";
import { IMine } from "../../interfaces/model/MSHAPCT/mine.interface";
import { useParams } from "react-router-dom";
import {
  createUser,
  getUser,
  updateUser,
} from "../../store/actions/userActions";
import { useSnackbar } from "notistack";
import CheckBoxList from "../form/CheckBoxList";
import { hasFlag } from "../../utils/bitwise-utils";
import { ACCOUNT_FLAGS } from "../../enums/account-enums";
import { validPasswordRegEx } from '../../constants/constants'

interface IUserModalProps {
  open: boolean;
  onClose: Function;
  onReset: Function;
  accountLoginPk?: number;
}

const UserModal: React.FC<IUserModalProps> = (props) => {
  const [isUpdateMode, setIsUpdateMode] = useState(false);

  const [company, setCompany] = useState({} as ICompany);
  const [emailAddress, setEmailAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [isAdministrator, setIsAdministrator] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [operatorAnalyticsAccess, setOperatorAnalyticsAccess] = useState(false);
  const [companyMines, setCompanyMines] = useState([] as ICompanyWithMines[]);
  const [selectedMines, setSelectedMines] = useState([] as Number[]);
  const [errors, setErrors] = useState({} as any);


  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { companypk } = useParams() as any;
  const [processing, setProcessing] = useState(false);
  const companies = useSelector((state: IRootState) => state.main.companies);
  const mines = useSelector((state: IRootState) => state.main.mines);

  const [selectionLists, setSelectionLists] = useState({
    selectedRegions: [] as any,
    selectedBusinessUnits: [] as any,
  });

  const regions = useSelector((state: IRootState) => state.main.regions);
  const businessUnits = useSelector(
    (state: IRootState) => state.main.business_units
  );

  const accountLogin = useSelector(
    (state: IRootState) => state.main.account_login
  );

  const IsSystemAdmin = hasFlag(accountLogin?.accountFlag, ACCOUNT_FLAGS.system_admin)
  

  const [regionList, setRegionList] = useState([] as any);
  const [businessUnitList, setBusinessUnitList] = useState([] as any);

  useEffect(() => {
    const sourceRegionList = Object.values(regions).filter(
      (item: any) => item.companyFk === Number(companypk)
    );
    setRegionList(sourceRegionList);
  }, [regions]);

  useEffect(() => {
    const sourceBusinessUnitList = Object.values(businessUnits).filter(
      (item: any) => item.companyFk === Number(companypk)
    );
    setBusinessUnitList(sourceBusinessUnitList);
  }, [businessUnits]);

  useEffect(() => {
    if (props.accountLoginPk !== 0) {
      setIsUpdateMode(true);
      dispatch(
        getUser(
          { userId: props.accountLoginPk, companyId: companypk },
          onGetUserDataSucess,
          onGetUserDataFail
        )
      );
    }
  }, [props.accountLoginPk]);

  const onGetUserDataSucess = (data: any) => {
    if (data) {
      setEmailAddress(data.emailAddress);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setIsAdministrator(data.isAdministrator);
      setIsReadOnly(data.isReadOnly);
      setIsEnabled(data.isEnabled);
      setOperatorAnalyticsAccess(data.operatorAnalyticsAccess)
      setSelectedMines(data.mineList);
      setSelectionLists({
        selectedRegions: data.regionList,
        selectedBusinessUnits: data.businessUnitList,
      });
    }
  };

  const onGetUserDataFail = (data: any) => {};

  useEffect(() => {
    const currentCompany = companies[companypk];
    const minesArray = arrayFromKeyedObject(mines);
    const companyMines: ICompanyWithMines[] = minesArray.filter(
      (mine) => mine.companyFk === Number(companypk)
    );
    setCompanyMines(companyMines);
    setCompany(currentCompany);
  }, [companies, companypk, mines]);

  const onCancelAction = () => {
    props.onReset();
  };

  const onUpdateAction = () => {
    if (!formIsValid()) {
		enqueueSnackbar('Form is not valid, please check all fields.', {
            variant: "error",
          });
		return;
	}
    setProcessing(true);
    dispatch(
      updateUser(
        {
          companyId: companypk,
          emailAddress,
          firstName,
          lastName,
          password,
          isAdministrator,
          isReadOnly,
          isEnabled,
          operatorAnalyticsAccess,
          mineList: selectedMines,
          regionList: selectionLists.selectedRegions,
          businessUnitList: selectionLists.selectedBusinessUnits,
        },
        (primaryKey: number) => {
          setProcessing(false);
          props.onClose();
          props.onReset();
          enqueueSnackbar("User was updated.", { variant: "success" });
        },
        (message: string) => {
          setProcessing(false);
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      )
    );
  };

  const onSubmitAction = () => {
    if (!formIsValid()){ 
		enqueueSnackbar('Form is not valid, please check all fields.', {
            variant: "error",
          });
		return;
	}

    setProcessing(true);

    dispatch(
      createUser(
        {
          companyId: companypk,
          emailAddress,
          firstName,
          lastName,
          password,
          isAdministrator,
          isReadOnly,
          isEnabled,
		  operatorAnalyticsAccess,
          mineList: selectedMines,
          regionList: selectionLists.selectedRegions,
          businessUnitList: selectionLists.selectedBusinessUnits,
        },
        (primaryKey: number) => {
          setProcessing(false);
          enqueueSnackbar("User was created.", { variant: "success" });
          props.onClose();
          props.onReset();
        },
        (message: string) => {
          setProcessing(false);
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      )
    );
  };

  const onMineSelected = (event: any, isSelected: boolean) => {
    const newValue = Number(event.target.value);

    if (!isSelected) {
      const newState = [...selectedMines].filter((mine) => mine !== newValue);
      setSelectedMines(newState);
    } else {
      if (!selectedMines.includes(newValue)) {
        const newState = [...selectedMines, newValue];
        setSelectedMines(newState);
      }
    }
  };

  function formIsValid() {
    const _errors: any = {};
	  if (!emailAddress) _errors.emailAddress = "Email Adress is required";
	  if (emailAddress && !isEmail(emailAddress))
		  _errors.emailAddress = "Enter a valid e-mail account";
	  if (!firstName) _errors.firstName = "First Name is required";
	  if (!lastName) _errors.lastName = "Last Name is required";

	  _errors.password = setPasswordErrString(password);
	  if (!_errors.password){
		  delete _errors.password
	  }
	  if (!isUpdateMode || IsSystemAdmin) {

		  if (!password || !password.length){
				if(!isUpdateMode){
					_errors.password = "password is required";
				}
		  }

		  // check password in create mode and for system admins if changing / length
		  if (!isUpdateMode || (IsSystemAdmin && password.length)){
			if (!password) {
				_errors.password = "password is required";
			}
			if (!retypePassword) {
				_errors.retypePassword = "Re-Type Password is required";
			}
			else if (password !== retypePassword) {
				_errors.retypePassword = "Passwords are not the same";
			}
			else {
				delete _errors.retypePassword
			}
		  }

	  }
	  else if (IsSystemAdmin && password.length > 0){

	  }
    setErrors(_errors);
console.log("invalid", _errors);
    return Object.keys(_errors).length === 0;
  }

	function setPasswordErrString(password: string) {
		const _errors = {
			...errors
		};
		if (!isUpdateMode || IsSystemAdmin){
			let errString = ''
			if (IsSystemAdmin){
				if (password === ''){
					delete _errors.password
					setErrors(_errors);
					return null; //accept no change for system admins
				}
			}
			let isValid = validPasswordRegEx.test(password)

			if (!isValid){
				errString = "Minimum length of 6, 1 capital and 1 number."
				_errors.password = errString
			}
			else{
				delete _errors.password
			}
			setErrors(_errors);
			return errString;
		}
		return null
	
	}

	function retypePasswordIsValid(confirmPassword: string) {
		let isValid: boolean = true;
		if (password !== confirmPassword) {
			isValid = false;
			const _errors = {
				...errors,
				retypePassword: "Passwords are not the same",
			};
			setErrors(_errors);
		}
		else {
			const _errors = {
				...errors,
				retypePassword: null,
			};
			setErrors(_errors);
		}

		return isValid;
	}



  function isEmail(search: string): boolean {
    let serchfind: boolean;
    const regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    serchfind = regexp.test(search);
    return serchfind;
  }

  const mineStatus = (minepk: number) => {
    if (selectedMines.includes(minepk)) return true;
    else return false;
  };

  const onChangeSelectionFromCheckBoxList = (item: any, property: any) => {
    const lists: any = { ...selectionLists };
    const currentSelectionList = lists[property];
    const found = currentSelectionList.filter(
      (element: any) => element === item.pk
    );
    if (found.length > 0) {
      lists[property] = currentSelectionList.filter(
        (element: any) => element !== item.pk
      );
      setSelectionLists(lists);
    } else {
      currentSelectionList.push(item.pk);
      lists[property] = currentSelectionList;
      setSelectionLists(lists);
    }
  };

  return (
    <>
      <Loading loading={processing} />
      <Dialog
        onClose={() => props.onClose()}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth={'md'}
      >
        <MuiDialogTitle>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 3, marginTop: 10 }}>
              <Typography variant="h6">
                {!isUpdateMode ? "Add User" : "Edit User"}
              </Typography>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <IconButton aria-label="close" onClick={() => props.onClose()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </MuiDialogTitle>
        <DialogContent dividers>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <div className={errors.emailAddress ? "error " : ""}>
                  <InputLabel
                    htmlFor="emailAddress"
                    style={{ display: "inline-block" }}
                  >
                    Email Address:
                  </InputLabel>
                  <em>{errors.emailAddress}</em>
                  <TextField
                    disabled={isUpdateMode}
                    fullWidth
                    id="emailAddress"
                    size="small"
                    variant="outlined"
                    value={emailAddress}
                    onChange={(event) => setEmailAddress(event?.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6}>
                    <div className={errors.firstName ? "error " : ""}>
                      <InputLabel
                        htmlFor="firstName"
                        style={{ display: "inline-block" }}
                      >
                        First Name:
                      </InputLabel>
                      <em>{errors.firstName}</em>
                      <TextField
                        id="firstName"
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={firstName}
                        onChange={(event) => setFirstName(event?.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <div className={errors.lastName ? "error " : ""}>
                      <InputLabel
                        htmlFor="lastName"
                        style={{ display: "inline-block" }}
                      >
                        Last Name:
                      </InputLabel>
                      <em>{errors.lastName}</em>
                      <TextField
                        id="lastName"
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={lastName}
                        onChange={(event) => setLastName(event?.target.value)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="administratorSwitch"
                          checked={isAdministrator}
                          onChange={(event) =>
                            setIsAdministrator(event?.target.checked)
                          }
                        />
                      }
                      label="Administrator"
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="readOnlySwitch"
                          checked={isReadOnly}
                          onChange={(event) =>
                            setIsReadOnly(event?.target.checked)
                          }
                        />
                      }
                      label="Read-Only"
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="enabledSwitch"
                          checked={isEnabled}
                          onChange={(event) =>
                            setIsEnabled(event?.target.checked)
                          }
                        />
                      }
                      label="Enabled"
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="operatorAnalyticsSwitch"
                          checked={operatorAnalyticsAccess}
                          onChange={(event) =>
                            setOperatorAnalyticsAccess(event?.target.checked)
                          }
                        />
                      }
                      label="Operator Analytics"
                    />
                  </Grid>
                </Grid>
              </Grid>
              {(IsSystemAdmin || !isUpdateMode) && (
                <Grid item xs={12} lg={12}>
                  <div className={errors.password ? "error " : ""}>
                    <InputLabel
                      htmlFor="password"
                      style={{ display: "inline-block" }}
                    >
                      Password:
                    </InputLabel>
                    <em>{errors.password}</em>
                    <TextField
                      id="password"
                      type="password"
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={password}
                      onChange={(event) => {
						  setPasswordErrString(event?.target.value)
						  setPassword(event?.target.value)
						}}
                    />
                  </div>
                </Grid>
              )}
              {(IsSystemAdmin || !isUpdateMode) && (
                <Grid item xs={12} lg={12}>
                  <div className={errors.retypePassword ? "error " : ""}>
                    <InputLabel
                      htmlFor="retypepassword"
                      style={{ display: "inline-block" }}
                    >
                      Re-type Password:
                    </InputLabel>
                    <em>{errors.retypePassword}</em>
                    <TextField
                      id="retypepassword"
                      type="password"
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={retypePassword}
                      onChange={(event) => {
                        setRetypePassword(event?.target.value);
                        retypePasswordIsValid(event?.target.value);
                      }}
                    />
                  </div>
                </Grid>
              )}
              <Grid item xs={12} lg={12}>
                <FormLabel component="legend">Assign Responsibility</FormLabel>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormGroup>
                      {companyMines.map((mine: IMine) => (
                        <FormControlLabel
                          key={mine.pk}
                          control={
                            <Checkbox
                              value={mine.pk}
                              checked={mineStatus(Number(mine.pk))}
                              name={mine.mineName}
                              onChange={onMineSelected}
                            />
                          }
                          label={mine.mineName}
                        />
                      ))}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <InputLabel
                      htmlFor="RegionName"
                      style={{ display: "inline-block" }}
                    >
                      Regions:
                    </InputLabel>
                    <CheckBoxList
                      id={`checkboxList-regions${1}`}
                      onChange={onChangeSelectionFromCheckBoxList}
                      dataSource={regionList}
                      keyName="selectedRegions"
                      selectionLists={selectionLists}
                      displayFieldName="regionName"
                      displayFieldValue="pk"
                    ></CheckBoxList>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <InputLabel
                      htmlFor="RegionName"
                      style={{ display: "inline-block" }}
                    >
                      Business Units:
                    </InputLabel>
                    <CheckBoxList
                      id={`checkboxList-business-unit${1}`}
                      onChange={onChangeSelectionFromCheckBoxList}
                      dataSource={businessUnitList}
                      keyName="selectedBusinessUnits"
                      selectionLists={selectionLists}
                      displayFieldName="businessUnitName"
                      displayFieldValue="pk"
                    ></CheckBoxList>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            autoFocus
            onClick={() => onCancelAction()}
            color="secondary"
          >
            Cancel
          </Button>
          {isUpdateMode && (
            <Button
              variant={"outlined"}
              autoFocus
              onClick={() => onUpdateAction()}
              color="primary"
            >
              Update
            </Button>
          )}
          {!isUpdateMode && (
            <Button
              variant={"outlined"}
              autoFocus
              onClick={() => onSubmitAction()}
              color="primary"
            >
              Create
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserModal;
