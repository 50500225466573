import React from "react";
import { Container } from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";
import ReleaseNotes from "./ReleaseNotes";
interface IAbout {}

const About: React.FC<IAbout> = () => {

  return (
    <Container
      style={{ minHeight: "100vh", backgroundColor: COLORS.WHITE_PAPER }}
    >
		<ReleaseNotes />
    </Container>
  );
};

export default About;
