import { ACTIONS } from "../../enums/actions";

export const getAccountsByFlag = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_ACCOUNTS_BY_FLAG,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getAdminEmailSettings = (
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_ADMIN_EMAIL_SETTINGS,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const updateAdminEmailSetting = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.UPDATE_ADMIN_EMAIL_SETTING,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const createAdminEmailSettings = (
  payload: any,
  onSuccess?: Function,
  onFail: Function | null = null
) => ({
  type: ACTIONS.CREATE_ADMIN_EMAIL_SETTING,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const deleteAdminEmailSettings = (
  payload: any,
  onSuccess?: Function,
  onFail?: Function
) => ({
  type: ACTIONS.DELETE_ADMIN_EMAIL_SETTING,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const updatedSelectedColumnIndexes = (
  payload: any,
  onSuccess?: Function,
  onFail?: Function
) => ({
  type: ACTIONS.UPDATE_SELECTED_COLUMN_INDEXES,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getActiveCompanies = (
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_ACTIVE_COMPANIES,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getUsersActivityByCompany = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_USERS_ACTIVITY,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});
