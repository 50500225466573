import { Button, CircularProgress, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { ICitation, ICitationWithMeta } from '../../interfaces/model/MSHAPCT/citation.interface';
import { IRootState } from '../../interfaces/store';
import { useSelector } from '../../store';
import { getCitation,getCitationWithMetada, updateCitations,deleteCitation  } from '../../store/actions/citationActions';
import { useSnackbar } from 'notistack';
import CitationDocument from './CitationDocument'
import { Edit, Visibility } from '@material-ui/icons';
import CitationAnalytics from '../analytics/CitationAnalytics';
import CitationModificationTracking from './CitationModificationTracking';
import { toStringDropTimezonesKeys } from '../../utils/date-utils';
import DeleteIcon from "@material-ui/icons/Delete";
interface ICitationRouteProps {

}

const CitationRoute: React.FC<ICitationRouteProps> = (props) => {

    let history = useHistory();

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const citations = useSelector(
        (state: IRootState) => state.main.citations
    );
    const citation_meta_data = useSelector(
        (state: IRootState) => state.main.citation_meta_data
    );
    
    
    const { minepk, citationpk} = useParams() as any;
    const citationPk = citationpk;
    let citation = citations[citationPk];
    let citationMetadata = Object.values(citation_meta_data).filter(
        (item) => item.citationFk === Number(citationPk)
      )[0];

    if(citationMetadata)
    {
        citation.citationVacated =citationMetadata.vacated;
    }
    const [citationData, setCitationData] = useState<ICitationWithMeta>(citation);    
    const [citationMetaData, setCitationMetaData] = useState<ICitation>(citationMetadata);
    
    let { path, url } = useRouteMatch();    
    const mine = useSelector((state: IRootState) => state.main.mines[minepk]);

    useEffect(() => {
        if (!citation) {        
            dispatch(getCitationWithMetada(citationPk));
        }
        if (!citationData) {          
            setCitationData(citation); 
        }      

        if(!citationMetaData)
        {
              setCitationMetaData(citationMetaData);
        }

    }, [citation, citationData,citationMetaData, setCitationData,setCitationMetaData]);


    const handleUpdateClick = () => {
        let sendData = citationData as any;
        toStringDropTimezonesKeys(sendData, ['dateIssued', 'initialCitationDate',  'terminationDueDate', 'terminationDate'])
        dispatch(updateCitations({ citations: [sendData] }, () => {
            enqueueSnackbar('Update successful', { variant: 'success' });
            dispatch(getCitationWithMetada(citationPk));
            window.scrollTo(0, 0);
        }))
    }
    const handleDeleteClick = () => {       
        
        if (window.confirm('Are you sure you want to delete this citation?')) {
            dispatch(deleteCitation({ citationPk }, ()=>{
                    console.log("success")
                    enqueueSnackbar("Delete completed! ", { variant: "success" });
                    history.push(`/Mines/${minepk}/Citations`)
                },
                ()=>{                 
                    console.log("error occured")
                    enqueueSnackbar("Failed to delete !! ", { variant: "error" });
                    history.push(`/Mines/${minepk}/Citations/${citationPk}/`)

                }));
            
        } 
    }	


    if (!citation) {
        return (
            <CircularProgress />
        )
    }

    const switchMode = (newRoute: string) => {
        history.push(`/Mines/${minepk}/Citations/${citationPk}/` + newRoute)
    }
    // LOGIC HERE FOR EDITING, SCANNING THEN EDITING, or VIEWING?
    
    return <div>
        <Switch>
            <Route path={`${path}/View`}>
                <>
                    <IconButton onClick={() => switchMode('Edit')} aria-label="edit">
                        <Edit />
                    </IconButton>
                    <CitationDocument mine={mine} key={'view'} mode={'view'} citation={citation} handleCitationChange={(citation => setCitationData(citation))} />
                </>
            </Route>
            <Route path={`${path}/Edit`}>
                <>
                    <IconButton onClick={() => switchMode('View')} style={{ marginBottom: 10 }} aria-label="edit">
                        <Visibility />
                    </IconButton>

                    
                    <DeleteIcon 
                        style={{ marginRight: 12, fontSize: 25, cursor: "pointer" }}
                        className="transparent75"
                        onClick={handleDeleteClick} 
                        />


                    <CitationDocument mine={mine} key={'edit'} mode={'edit'} citation={citation} handleCitationChange={(citation => setCitationData(citation))} />
                    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                        <Button onClick={handleUpdateClick} variant="outlined" style={{ marginTop: 16 }}>
                            Update
                        </Button>
                    </div>
                </>
            </Route>
            <Route path={`${path}/CitationModificationTracking`}>
                <>
                    <CitationModificationTracking 
                    
                    />
                </>
            </Route>
            <Route path={`${path}/Analytics`}>
                <CitationAnalytics />
            </Route>
        </Switch>

    </div>
}

export default CitationRoute