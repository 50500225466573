import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Checkbox,
  Grid,
  InputLabel,
  DialogContent,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Loading from "../shared/Loading";
import { useSnackbar } from "notistack";
import { useSelector } from "../../store";
import { IRootState } from "../../interfaces/store";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

interface IRegionsModalProps {
  itemData: any;
  open: boolean;
  onClose: Function;
  title: string;
  onSubmit: Function;
  isEditMode: boolean;
  isCompany: boolean;
}

const RegionsModal: React.FC<IRegionsModalProps> = (props) => {
  const [errors, setErrors] = useState({} as any);
  const { enqueueSnackbar } = useSnackbar();
  const [processsing, setProcesssing] = useState(false);

  const [companyFk, setCompanyFk] = useState(props.itemData.companyFk);
  const [pk, setPk] = useState(props.itemData.pk);
  const [RegionName, setRegionName] = useState(props.itemData.regionName);

  const mines = useSelector((state: IRootState) => state.main.mines);
  const businessUnits = useSelector(
    (state: IRootState) => state.main.business_units
  );

  const [selectionLists, setSelectionLists] = useState({
    selectedMines: [] as any,
    selectedBusinessUnits: [] as any,
  });

  const [mineList, setMineList] = useState([] as any);
  const [businessUnitList, setBusinessUnitList] = useState([] as any);

  useEffect(() => {
    if (props.itemData.selectedMines && props.itemData.selectedBusinessUnits) {
      const sourceMineList = Object.values(mines).filter(
        (item) => item.companyFk === Number(props.itemData.companyFk)
      );
      setMineList(sourceMineList);

      const mineSelection: any[] = sourceMineList.filter((item: any) =>
        props.itemData.selectedMines.includes(item.pk)
      );

      const sourceBusinessUnitList = Object.values(businessUnits).filter(
        (item: any) => item.companyFk === Number(props.itemData.companyFk)
      );

      setBusinessUnitList(sourceBusinessUnitList);

      const businessUnitSelection: any[] = sourceBusinessUnitList.filter(
        (item: any) => props.itemData.selectedBusinessUnits.includes(item.pk)
      );

      setSelectionLists({
        ...selectionLists,
        selectedMines: mineSelection,
        selectedBusinessUnits: businessUnitSelection,
      });
    }

    setCompanyFk(props.itemData.companyFk);
    setRegionName(props.itemData.regionName);
    setPk(props.itemData.pk);
  }, [props.itemData]);

  const submitForm = () => {
    setProcesssing(true);
    if (!formIsValid()) return;

    const body = {
      pk,
      companyFk,
      RegionName,
      selectedMines: selectionLists.selectedMines.map(function (item: any) {
        return item.pk;
      }),
      selectedBusinessUnits: selectionLists.selectedBusinessUnits.map(function (
        item: any
      ) {
        return item.pk;
      }),
    };

    props.onSubmit(
      body,
      () => {
        setProcesssing(false);
        enqueueSnackbar("Changes were saved successfully..", {
          variant: "success",
        });
      },
      (message?: string) => {
        setProcesssing(false);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    );
  };

  const isSelectedFromList = (item: any, property: any): boolean => {
    const lists: any = { ...selectionLists };
    const data = lists[property].filter((el: any) => el.pk === item.pk);
    return data.length > 0 ? true : false;
  };

  const onChangeSelectedFromList = (item: any, property: any) => () => {
    const lists: any = { ...selectionLists };
    const currentSelectionList = lists[property];
    const found = currentSelectionList.filter(
      (element: any) => element.pk === item.pk
    );
    if (found.length > 0) {
      lists[property] = currentSelectionList.filter(
        (element: any) => element.pk !== item.pk
      );
      setSelectionLists(lists);
    } else {
      currentSelectionList.push(item);
      lists[property] = currentSelectionList;
      setSelectionLists(lists);
    }
  };

  function formIsValid() {
    const _errors: any = {};

    if (!RegionName) _errors.regionName = "Region Name is required";
    if (
      !selectionLists.selectedMines.length &&
      !selectionLists.selectedBusinessUnits.length
    )
      _errors.selectedMines = "You must select one or more mines/regions";

    setErrors(_errors);

    return Object.keys(_errors).length === 0;
  }

  return (
    <>
      <Loading loading={processsing} />
      <Dialog
        onClose={() => props.onClose()}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        open={props.open}
      >
        <MuiDialogTitle>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 3 }}>
              <Typography variant="h6">
                {(props.isEditMode ? "Edit " : "Add ") + props.title}
              </Typography>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <IconButton aria-label="close" onClick={() => props.onClose()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </MuiDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <div className={errors.regionName ? "error " : ""}>
                <InputLabel
                  htmlFor="RegionName"
                  style={{ display: "inline-block" }}
                >
                  Region Name:
                </InputLabel>
                <em>{errors.regionName}</em>
                <TextField
                  fullWidth
                  id="RegionName"
                  size="small"
                  variant="outlined"
                  onChange={(event) => {
                    setRegionName(event?.target.value);
                  }}
                  value={RegionName}
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputLabel
                htmlFor="RegionName"
                style={{ display: "inline-block" }}
              >
                Region Mines:
              </InputLabel>
              <em>{errors.selectedMines}</em>
              <List dense={true} style={{ maxHeight: 300 }}>
                {mineList.map((value: any) => {
                  const labelId = `checkbox-list-label-${value.pk}`;

                  return (
                    <ListItem
                      key={value.pk}
                      role={undefined}
                      dense
                      button
                      onClick={onChangeSelectedFromList(value, "selectedMines")}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                          checked={isSelectedFromList(value, "selectedMines")}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={value.mineName} />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputLabel
                htmlFor="RegionName"
                style={{ display: "inline-block" }}
              >
                Region Business Units:
              </InputLabel>
              <em>{errors.selectedBusinessUnits}</em>
              <List dense={true} style={{ maxHeight: 300 }}>
                {businessUnitList.map((value: any) => {
                  const labelId = `checkbox-list-label-${value.pk}`;

                  return (
                    <ListItem
                      key={value.pk}
                      role={undefined}
                      dense
                      button
                      onClick={onChangeSelectedFromList(
                        value,
                        "selectedBusinessUnits"
                      )}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                          checked={isSelectedFromList(
                            value,
                            "selectedBusinessUnits"
                          )}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={value.businessUnitName}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            autoFocus
            onClick={() => submitForm()}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RegionsModal;
