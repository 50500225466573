import React from "react";
import { AppBar, Container, Toolbar, Typography } from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";

interface IFooterProps {}

const Footer: React.FC<IFooterProps> = () => {
  const date = new Date();

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: COLORS.BLUE_LIGHT_BACKGROUND,
        boxShadow: "none",
      }}
    >
      <Container
        style={{
          justifyContent: "center",
        }}
      >
        <Toolbar
          style={{
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body1"
            style={{
              color: COLORS.BLUE_MEDIUM_BACKGROUND,
            }}
          >
            © Copyright {date.getFullYear()} IWT
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Footer;
