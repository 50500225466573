import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IRootState } from "../../interfaces/store";
import { useSelector } from "../../store";
import { login } from "../../store/actions/authActions";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import Loading from "../shared/Loading";

interface ILoginProps {}

const Login: React.FC<ILoginProps> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const accountLogin = useSelector(
    (state: IRootState) => state.main.account_login
  );
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [processing, setProcessing] = useState(false)
  // useEffect(() => {
  //     console.log("STATE CHANGE", accountLogin);
  // }, [accountLogin]);

  const handleForgotPassword = () => {
    history.push(`/ForgotPassword`);
  };

	const handleLogin = () => {
		setProcessing(true);
		dispatch(
			login(
				{ email, password },
				() => {
					enqueueSnackbar("Login Success", { variant: "success" });
					history.push(`/Mines`);
				},
				() => {
					enqueueSnackbar("Could not find email / password.", {
						variant: "error",
					})
					setProcessing(false);
				}
			)
		);
	};

  // if (!citation) {
  //     return (
  //         <CircularProgress />
  //     )
  // }

  return (
    <>
	  <Loading loading={processing} />
      <Card style={{ width: 400 }}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            Login
          </Typography>
          <div style={{ marginTop: 16 }}>
            <TextField
              label="Email"
              size="small"
              fullWidth={true}
              id="3_citation_no"
              variant="outlined"
              value={email}
              onChange={(event) => setEmail(event?.target.value)}
            />
          </div>
          <div style={{ marginTop: 16 }}>
            <TextField
              label="Password"
              size="small"
              fullWidth={true}
              id="3_citation_no"
              variant="outlined"
              type="password"
              value={password}
              onChange={(event) => setPassword(event?.target.value)}
            />
          </div>
          <div style={{ marginTop: 16 }}>
            <Button onClick={() => handleLogin()}>Login</Button>
          </div>
          <div style={{ marginTop: 16 }}>
            <Button onClick={() => handleForgotPassword()} size="small">
              Forgot Password?
            </Button>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default Login;
