import { call, put } from "redux-saga/effects";
import { ACTIONS } from "../../enums/actions";
import { HttpResponse } from "../../interfaces/api/HttpResponse";
import { IDispatchAction } from "../../interfaces/store";
import HttpClient from "../../lib/HttpClient";
import GetFriendlyError from "../../utils/general";
import { normalize, schema } from "normalizr";
import { accountLogin_schema, companyAccount_schema } from "./schemas";
const client = new HttpClient();

async function getUserAPI(data: {
  userId: number;
  companyId: Number;
}): Promise<HttpResponse<{ x: string }>> {
  return client.get(`api/users/${data.userId}/company/${data.companyId}`);
}

async function getAdminUserAPI(data: {
  userId: number;
}): Promise<HttpResponse<{ x: string }>> {
  return client.get(`api/users/${data.userId}`);
}

async function createUserAPI(data: any): Promise<HttpResponse<any>> {
  return client.post("api/users", data);
}

async function deleteUserAPI(data: any): Promise<HttpResponse<any>> {
  return client.delete(`api/users/${data.userId}/company/${data.companyId}`);
}

async function updateUserAPI(data: any): Promise<HttpResponse<any>> {
  return client.put("api/users", data);
}

const userSaga = {
  *deleteUser(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        deleteUserAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        const isAccountDeleted: boolean = response.data;
        if (isAccountDeleted) {
          let removeKeys = [];
          removeKeys.push({
            entities: "account_logins",
            ids: Array.of(payload.userId),
          });
          yield put({
            type: "DELETE_USER_SUCCESS",
            removeKeys: removeKeys,
          });
        }
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getUser(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(getUserAPI, payload)) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getAdminUser(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getAdminUserAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
  *createUser(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        createUserAPI,
        payload
      )) as HttpResponse<any>;

      if (response.status === 200 || response.status === 201) {
        yield put({
          type: "CREATE_USER_SUCCESS",
          normalized: normalize(response.data.accountLogins, [
            accountLogin_schema,
          ]),
        });

        if (response.data.companyAccounts) {
          yield put({
            type: "CREATE_USER_SUCCESS",
            normalized: normalize(response.data.companyAccounts, [
              companyAccount_schema,
            ]),
          });
        }

        if (action.onSuccess) {
          action.onSuccess(response.data.pk);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *updateUser(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        updateUserAPI,
        payload
      )) as HttpResponse<any>;

      if (response.status === 200 || response.status === 201) {
        yield put({
          type: "UPDATE_USER_SUCCESS",
          normalized: normalize(response.data.accountLogins, [
            accountLogin_schema,
          ]),
        });

        if (response.data.companyAccounts) {
          yield put({
            type: "UPDATE_SUCCESS",
            normalized: normalize(response.data.companyAccounts, [
              companyAccount_schema,
            ]),
          });
        }

        if (action.onSuccess) {
          action.onSuccess(response.data.pk);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
};

export default userSaga;
