export enum APP_BREAKPOINT {
  EXTRA_SMALL,
  SMALL,
  MEDIUM,
  LARGE,
  EXTRA_LARGE,
}

export enum COLORS {
  WHITE_BACKGROUND = 'white',
  BLUE_LIGHT_BACKGROUND = '#324659',
  BLUE_MEDIUM_BACKGROUND = '#ddcdcd',
  BLUE_MEDIUM_DARK_BACKGROUND = '#1d2833',
  WHITE_PAPER = '#EDEDED',
  GREY_DARK = '#555555',
  GREY_LIGHT = '#d3d3d3',
  RED = '#FF0000',
  LINK_PURPLE = '#541a8b'
}

export enum MARGIN_STANDARD {
  SECTION = 32
}

export const TOTAL_TOP_BAR_HEIGHT = 315;

// export const textLinkStyle = {
//   textDecoration: 'none', 
//   color: COLORS.LINK_PURPLE
// }