import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export interface IStandardModalAction {
    title: string
    callback: Function
}


interface IStandardModalProps {
    open: boolean
    onClose: Function
	onOpen?: Function
    paragraphs: string[]
    actions: IStandardModalAction[]
    customActions: any
    title?: string
    children?: any
    maxWidth?: any
}

const defaultTitle = 'Confirm Action'

const StandardModal: React.FC<IStandardModalProps> = (props) => {

	useEffect(() => {
		if (props.open){
			//on open
			if (props.onOpen){
				props.onOpen();
			}
		}
	}, [props.open])


    return (
        <Dialog fullWidth={true} maxWidth={props.maxWidth || "md"} onClose={() => props.onClose()} aria-labelledby="customized-dialog-title" open={props.open}>
            <MuiDialogTitle>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 3, marginTop: 10 }}>
                        <Typography variant="h6">{props.title? props.title : defaultTitle}</Typography>
                    </div>
                    <div style={{ flex: 1, textAlign: 'right' }}>
                        <IconButton aria-label="close" onClick={() => props.onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </MuiDialogTitle>
            <DialogContent dividers>
                {props.paragraphs.map((paragraph) => {
                    return (
                        <Typography gutterBottom>
                            {paragraph}
                        </Typography>
                    )
                })}
                {props.children}
            </DialogContent>
            <DialogActions>
                {props.actions.map((action) => {
                    return (
                        <div>
                            <Button autoFocus onClick={() => action.callback()} color="primary">
                                {action.title}
                            </Button>
                        </div>)
                })}
                {props.customActions.map((action: any) => {
                    return (
                        <div>
                            {action}
                        </div>
                    )
                })}
            </DialogActions>
        </Dialog>
    );
}

export default StandardModal