import React from "react";
import { Container } from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";
interface IPressReleasesProps {}

const PressReleases: React.FC<IPressReleasesProps> = () => {
  const predictiveComplianceKeyFacts = () => {
    return (
      <section>
        <b>
          <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
            PREDICTIVE COMPLIANCE KEY FACTS:
          </p>
        </b>
        <ul>
          <li>
            Predictive Compliance is a “subscription” based
            software-as-a-service provider. Services available include:
          </li>
          <ul>
            <li>Instant Predictive Penalty Calculation Analytics;</li>
            <li>Citation Management and Reporting Capabilities</li>
            <li>Inspector Assessment Data</li>
            <li>Mine Summary Information Page</li>
            <li>Instant Dodd-Frank Act SEC Report Data Compilation System</li>
            <li>Current Pattern of Violation Criteria Tracking; and</li>
            <li>Simultaneous Visibility of Multiple ID Operators</li>
          </ul>
          <li>
            Predictive Compliance proprietary software is designed to be user
            friendly so all mining companies and contractors can easily automate
            their citation management.
          </li>
          <li>
            Predictive Compliance users can scan and upload citations as they
            get them. The Predictive Compliance program instantly can calculate
            the predicted penalty, show the impact that the citation would have
            on compliance costs and allow the company /contractor to plan how
            best to contest and manage the citation.
          </li>
          <li>
            Predictive Compliance analytics also allows prediction of future
            citation costs and helps users more effectively allocate their
            resources for safer, more efficient operations.
          </li>
          <li>
            Predictive Compliance’s new MineState feature allows instant
            benchmarking of compliance performance against all similarly
            situated mines in any state.
          </li>
          <li>Predictive Compliance works with any off-the-shelf scanner.</li>
          <li>
            Predictive Compliance was created in 2009 by MSHA compliance
            attorney Mark Savit, with over thirty years of experience dealing
            with MSHA both in the agency and outside of it. Mark Savit was the
            recipient of the International Society of Mine Safety Professionals
            H.L Boling Above and Beyond Safety Award (May 2012) and the Society
            for Mining, Metallurgy, and Exploration’s (SME) 2008 Daniel C.
            Jackling Award, presented for significant contributions to technical
            progress in mining, geology, and geophysics.
          </li>
        </ul>
        <br></br>
        <br></br>
        Predictive Compliance can be contacted at: Zack Savit (801) 231-8536
        <br></br>
        Predictive Compliance 1940 Blake St. Suite 300 Denver, Colorado 80202
        303-335-7462 zsavit@predictivecompliance.com
        www.predictivecompliance.com
      </section>
    );
  };

  return (
    <Container
      style={{ minHeight: "100vh", backgroundColor: COLORS.WHITE_PAPER }}
    >
      <h1>Press Releases</h1>
      <section>
        <h3>
          NEW TOOL FOR PATTERN OF VIOLATIONS (POV) MONITORING ANNOUNCED BY
          PREDICTIVE COMPLIANCE
        </h3>
        <article>
          Denver, Colorado – Monday, April 1, 2013 — Predictive Compliance, the
          most comprehensive MSHA citation management and compliance analytics
          software system, is pleased to announce it has developed a “one click”
          tool enabling operators with more than one mine to quickly identify
          and monitor their MSHA Pattern of Violations (POV) status.
        </article>
        <br></br>
        <article>
          Without the new Predictive Compliance “one click” tool, an operator
          would have to look up each mine individually on MSHA’s website to
          determine their POV status. The new Predictive Compliance tool
          eliminates this time consuming and costly step. Moreover, MSHAs
          website data is based on a “latest twelve month cycle” which can be
          misleading as it may not reflect the applicable date-range over which
          MSHA’s POV data are measured. Predictive Compliance’s new tool reduces
          the risk for such errors because it can easily adjust the date range
          searched and will reflect more up-to-date, accurate data for analysis.
        </article>
        <br></br>
        <article>
          This new capability was developed to help operators address the recent
          MSHA change in the POV rule which places increasing responsibility on
          operators to monitor their own compliance. Under the old rule,
          operators were issued a warning called a Potential Pattern of
          Violations (PPOV) notice and given a chance to correct errors in
          MSHA’s data and implement corrective action plans before being placed
          in POV status. The new MSHA rule eliminates this PPOV warning. Now,
          operators are expected to monitor their own status and proactively
          identify errors in MSHA’s data and, if necessary, implement action
          plans on their own. Predictive Compliance’s new “one-click” tool
          provides operators the ability to identify and monitor the POV status
          of all of their sites on a single screen in real time in an easy to
          use format.
        </article>
        <br></br>
        <article>
          Using Predictive Compliance’s new tool can help operators avoid MSHA’s
          most serious sanctions. Unless operators are keeping very careful
          track of every mine site and citation over time, they can get stuck in
          a POV status that is very difficult to get out of where every
          “Significant and Substantial” (S&S) citation becomes a closure order
          and operators cannot escape it unless they go through an entire
          inspection without an S&S citation.
        </article>
        <br></br>
        <article>
          Predictive Compliance continues to offer mining companies and
          contractors a unique approach to easily automate their MSHA compliance
          management and more effectively allocate their resources for safer,
          more efficient operations. Our user friendly software allows mining
          organizations to upload citations as they are received, instantly
          calculate the predicted penalty, and show the impact that citation
          (and future citations) would have on compliance costs, resources and
          operations. Predictive Compliance also helps decision-makers when
          planning to contest a citation and provides tools to identify and
          track potential patterns of violation, quickly compile citation data
          needed to respond to the new Dodd-Frank Act SEC reporting
          requirements. With the addition of “MineState” this past year,
          Predictive Compliance users can also benchmark their performance
          against other, similar mines.
        </article>
        <br></br>
        <article>
          The development of this new “one-click” tool of POV monitoring
          increases Predictive Compliance’s ability to help the mining industry
          realize true risk mitigation and then implement measures to avoid such
          risk in the future.
        </article>
        <br></br>
        <article>
          If you’re interested in learning more about these Predictive
          Compliance solutions please visit{" "}
          <a href="https://www.predictivecompliance.com">
            www.predictivecompliance.com.
          </a>
          <br></br>
          {predictiveComplianceKeyFacts()}
        </article>
        <br></br>
      </section>
      <br></br>
      <br></br>
      <section>
        <h3>
          PREDICTIVE COMPLIANCE ENTERS INTO STRATEGIC RELATIONSHIP WITH BROWZ TO
          ENHANCE SUPPORT TO THE MINING INDUSTRY
        </h3>
        <article>
          Denver, Colorado – Wednesday September 19, 2012, — Predictive
          Compliance, the most innovative MSHA citation management and
          compliance analytics software system, is pleased to announce it has
          formed a strategic relationship with BROWZ, the leading provider of
          contractor prequalification and compliance management services, to
          provide enhanced support to the mining industry. Under this
          arrangement, mining companies and contractors will have unprecedented
          access to Predictive Compliance’s state-of-the-art MSHA citation
          management, prevention, forecasting and benchmarking tools, as well as
          to BROWZ’ patented contractor management software and support
          services.
        </article>
        <br></br>
        <article>
          “Our strategic relationship with a company which has expertise in
          different areas of MSHA compliance which complement Predictive
          Compliance’s proprietary citation management software and analytical
          services makes enormous sense.” said Mark Savit, renowned MSHA
          compliance attorney and General Manager of Predictive Compliance.
          “BROWZ is a recognized leader in contractor management with a global
          reach in the mining industry. We are excited about working together to
          develop future products that will expand and enhance services and
          support to the industry of both Predictive Compliance and BROWZ.”
        </article>
        <br></br>
        <article>
          Started only three years ago, Predictive Compliance offers a unique
          approach to mining companies and contractors to easily automate their
          MSHA compliance management and more effectively allocate their
          resources for safer, more efficient operations. Our user friendly
          software allows mining organizations to upload citations as they are
          received, instantly calculate the predicted penalty, and show the
          impact that citation (and future citations) would have on compliance
          costs, resources and operations. Predictive Compliance also helps
          decision-makers when planning to contest a citation and provides tools
          to identify and track potential patterns of violation, quickly compile
          citation data needed to respond to the new Dodd-Frank Act SEC
          reporting requirements and to benchmark their performance against
          other, similar mines.
        </article>
        <br></br>
        <article>
          Founded in 2002, BROWZ pioneered modern compliance management
          solutions. First developing software modules specific to MSHA, risk,
          and EHS, today BROWZ is the leading provider of contractor
          prequalification and ongoing compliance management solutions in the
          mining industry. With a growing demand for contractor screening
          services around the globe, BROWZ supports multiple industries beyond
          mining which include manufacturing, chemical, energy, pharmaceuticals,
          cement and aggregates. BROWZ’ patented technology has been localized
          in five languages and supported in offices around the world.
        </article>
        <br></br>
        <article>
          Both companies’ solutions, when used in tandem, help the mining
          industry realize true risk mitigation and then implement measures to
          avoid such risk in the future. Their strategic relationship will
          create benefits for customers of both companies without adding to
          costs.
        </article>
        <br></br>
        <article>
          If you’re interested in learning more about either of these solutions
          please visit&nbsp;
          <a href="https://www.predictivecompliance.com">
            www.predictivecompliance.com
          </a>{" "}
          or <a href="https://www.browz.com">www.browz.com</a>
          <br></br>
          {predictiveComplianceKeyFacts()}
        </article>
        <br></br>
      </section>
      <br></br>
      <br></br>
      <section>
        <h3>
          NEW “MINESTATE” FEATURE ALLOWS INSTANT BENCHMARKING OF COMPLIANCE
          PERFORMANCE BY STATE
        </h3>
        <article>
          Denver, Colorado - Friday, September 7, 2012 — Predictive Compliance,
          the most innovative MSHA citation management and compliance analytics
          software system, is pleased to announce it has developed a new
          “MineState” feature which allows instant benchmarking of a mining
          company’s compliance performance against all similarly situated mines
          in any State in the nation. Benchmarking compliance performance is
          critical to identifying and understanding compliance costs-- which
          have a direct effect on a company’s net profit. “MineState” provides a
          powerful new tool to help companies gage their compliance costs and
          more effectively direct their scarce compliance management resources.
        </article>
        <br></br>
        <article>
          In the last five years, MSHA penalty costs have risen approximately
          500%. They are now a quantifiable cost per unit and visibility of
          these costs is more important than ever to individual mines, mining
          companies, industry organizations and investors. Used in conjunction
          with Predictive Compliance’s proprietary citation management and
          analytics software system, “MineState” provides a comprehensive,
          real-time picture of a company’s compliance management efforts.
        </article>
        <br></br>
        <article>
          “MineState” will be sold at a reduced rate to Predictive Compliance
          users or as a separate product for those who are interested solely in
          having access to this benchmarking data and reporting capability.
        </article>
        <br></br>
        <article>
          “No other product exists to allow companies to see how they are doing
          compared to similarly situated mines. Currently, for example, there is
          no way for a safety manager or operations manager to know how the
          company’s non-fatal days lost rate compares to other similar mines –
          whether underground metal or non-metal or surface- to-surface mines.”
          said Mark Savit, renowned MSHA compliance attorney and General Manager
          of Predictive Compliance. “Without the “MineState” benchmarking
          capability, compliance management is like throwing darts in the dark.”
        </article>
        <br></br>
        <article>
          Predictive Compliance offers a unique approach to mining companies and
          contractors to easily automate their MSHA compliance management and
          more effectively allocate their resources for safer, more efficient
          operations. Our user friendly software allows mining organizations to
          upload citations as they are received, instantly calculate the
          predicted penalty, and show the impact that citation (and future
          citations) would have on compliance costs, resources and operations.
          Predictive Compliance also helps decision-makers when planning to
          contest a citation and provides tools to identify and track potential
          patterns of violation, quickly compile citation data needed to respond
          to the new Dodd-Frank Act SEC reporting requirements. With the
          addition of “MineState”, Predictive Compliance users will be able to
          benchmark their performance against other, similar mines.
        </article>
        <article>
          The development of MineState increases Predictive Compliance’s ability
          to help the mining industry realize true risk mitigation and then
          implement measures to avoid such risk in the future.
        </article>
        <br></br>
        <article>
          If you’re interested in learning more about these solutions please
          visit&nbsp;
          <a href="https://www.predictivecompliance.com">
            www.predictivecompliance.com
          </a>
          <br></br>
          {predictiveComplianceKeyFacts()}
        </article>
        <br></br>
      </section>
    </Container>
  );
};

export default PressReleases;
