
import React, { useEffect } from 'react';
import * as ChartJs from 'chart.js';
import { IBarChartDataInterface } from '../../interfaces/stored_procs/chart-interfaces';
// @ts-ignore
ChartJs.Chart.register.apply(null, Object.values(ChartJs).filter((chartClass) => (chartClass.id)));

interface ILineGraph {
    chartData: IBarChartDataInterface,
    uuid: string,
    indexAxis?: string
    title?: string
    height?: number
}

const BarGraph: React.FC<ILineGraph> = (props) => {

    useEffect(() => {
        const ctx = document.getElementById(props.uuid);
        // @ts-ignore
        new ChartJs.Chart(ctx, {
            type: "bar",
            data: {
                labels: props.chartData.labels,
                datasets: props.chartData.datasets
            },
            options: {
                indexAxis: props.indexAxis || "x",
                scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true
                    }
                },
                plugins: {
                    title: {
                        display: props.title ? true : false,
                        text: props.title
                    }
                }
            }
        });
    }, []);

    return (
        <div className="App">
            <canvas id={props.uuid} width="300" height={props.height ? props.height : 100} />
        </div>
    );
}

export default BarGraph








