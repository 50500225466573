
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useRouteMatch } from "react-router-dom";
import { getInspectorARList, getInspectorTrends } from '../../store/actions/inspectionActions';
import { CircularProgress, Paper, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface IInspectorTrends {

}

const InspectorTrends: React.FC<IInspectorTrends> = (props) => {

    const [inspectorList, setInspectorList] = useState([] as any[])
    const [selectedInspector, setSelectedInspector] = useState(null as any)
    const [inspectorTrends, setInspectorTrends] = useState([] as any)
    const [iTrendTotals, setITrendTotals] = useState({
        "numIssuances_6monthsAllMines": 0,
        "numIssuances_6monthsThisMine": 0,
        "numIssuances_6_12monthsAllMines": 0,
        "numIssuances_6_12monthsThisMine": 0,
        "numIssuances_12_18monthsAllMines": 0,
        "numIssuances_12_18monthsThisMine": 0,
        "numIssuances_0_18monthsAllMines": 0,
        "numIssuances_0_18monthsThisMine": 0
    })
    const dispatch = useDispatch();
    let { path, url } = useRouteMatch();
    const {minepk} = useParams() as any;
    
    useEffect(() => {
        dispatch(getInspectorARList((result: any) => {
            setInspectorList(result)
        }))
    }, []);

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    {inspectorList.length !== 0 ? <Autocomplete
                        size="small"
                        freeSolo
                        options={inspectorList.map((x: any) => x.number + " - " + x.name)}
                        renderInput={(params) => (
                            <TextField {...params} label="Inspector" margin="normal" variant="outlined" />
                        )}
                        onChange={(event, newValue) => {
                            setSelectedInspector(newValue)
                            const cleanValArr = newValue?.split('-');
                            if (cleanValArr && cleanValArr[0]){
                                const cleanVal = cleanValArr[0].trim();
                                dispatch(getInspectorTrends({inspectorar: cleanVal, minepk, contractorpk: -1}, (result: any) => {
                                    const newTrendTotals = {...iTrendTotals} as any
                                    result.forEach((iTrend: any) => {
                                        Object.keys(iTrend).map((key) => {
                                            if (newTrendTotals[key]!==null){
                                                const iTrendParts = iTrend[key].split(" ");
                                                newTrendTotals[key]+=Number(iTrendParts[0])
                                            }
                                        })
                                    })
                                    setITrendTotals(newTrendTotals);
                                    setInspectorTrends(result)
                                }))
                            }
                        }}
                    />: <CircularProgress color="inherit" />}
                </div>
                <div style={{ flex: 1 }}>

                </div>
            </div>
            <div>
                <Paper className={"custom-pc-table-criteria"}>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th colSpan={2}>6 Months</th>
                                <th colSpan={2}>6-12 Months</th>
                                <th colSpan={2}>12-18 Months</th>
                                <th colSpan={2}>0-18 Months</th>
                            </tr>
                            <tr>
                                <th>Regulation</th>
                                <th>All Mines</th>
                                <th>This Mine</th>
                                <th>All Mines</th>
                                <th>This Mine</th>
                                <th>All Mines</th>
                                <th>This Mine</th>
                                <th>All Mines</th>
                                <th>This Mine</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Totals</td>
                                <td>{iTrendTotals.numIssuances_6monthsAllMines}</td>
                                <td>{iTrendTotals.numIssuances_6monthsThisMine}</td>
                                <td>{iTrendTotals.numIssuances_6_12monthsAllMines}</td>
                                <td>{iTrendTotals.numIssuances_6_12monthsThisMine}</td>
                                <td>{iTrendTotals.numIssuances_12_18monthsAllMines}</td>
                                <td>{iTrendTotals.numIssuances_12_18monthsThisMine}</td>
                                <td>{iTrendTotals.numIssuances_0_18monthsAllMines}</td>
                                <td>{iTrendTotals.numIssuances_0_18monthsThisMine}</td>
                            </tr>
                            {inspectorTrends.map((iTrend: any) => {
                                return(
                                    <tr>
                                        <td>{iTrend.regulation}</td>
                                        <td>{iTrend.numIssuances_6monthsAllMines}</td>
                                        <td>{iTrend.numIssuances_6monthsThisMine}</td>
                                        <td>{iTrend.numIssuances_6_12monthsAllMines}</td>
                                        <td>{iTrend.numIssuances_6_12monthsThisMine}</td>
                                        <td>{iTrend.numIssuances_12_18monthsAllMines}</td>
                                        <td>{iTrend.numIssuances_12_18monthsThisMine}</td>
                                        <td>{iTrend.numIssuances_0_18monthsAllMines}</td>
                                        <td>{iTrend.numIssuances_0_18monthsThisMine}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </Paper>
            </div>
            {/* <Select
                variant="outlined"
                style={{ height: 40 }}
                value={selectedInspector}
                onChange={(event) => {
                    console.log(event.target.value)
                    setSelectedInspector(event.target.value);
                }}
                displayEmpty>
                {inspectorList.map((x: any) => {
                    return (
                        <MenuItem value={x.number}>{x.number} - {x.name}</MenuItem>
                    )
                })}
            </Select> */}
        </div>
    );
}

export default InspectorTrends








