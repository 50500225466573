
import { ICitation } from "../model/MSHAPCT/citation.interface";
import { ICitationMetaData } from "../model/MSHAPCT/citation-meta-data.interface";
import { ICompany } from "../model/MSHAPCT/company.interface";
import { IMine } from "../model/MSHAPCT/mine.interface";
import { IAccountLogin } from "../model/MSHAPCT/account-login.interface";
import { IAccountLoginValidation } from "../model/MSHAPCT/account-login-validation";
import { IInspection } from "../model/MSHAPCT/inspection.interface";
import { ICompanyAccount } from "../model/MSHAPCT/company-account.interface";
import { IAdminEmailSettings } from "../model/MSHAPCT/admin-email-settings.interface";
import {
  ICompanyEmailSettingModel,
  INotificationColumn,
} from "../model/MSHAPCT/company-email-settings";
import { IBusinessUnitModel } from "../model/MSHAPCT/business-unit.interface";
import { IRegionModel } from "../model/MSHAPCT/regions.interface";
import { ICitationMgmtCustomField } from "../model/MSHAPCT/citation-mgmt-custom-field.interface";
import { ICitationWarning } from "../model/MSHAPCT/citation-warning.interface";
import { IContractor } from "../model/MSHAPCT/contractor-interface";
// import Post from '../model/Post';
// import User from '../model/User';

// export interface INormalized<T = Record<string, IMainEntities>> {
//   [key: number]: T;
// }

// export interface IMainStateObjs {
//   [key: string]: INormalized<IMainEntities>;
// }

interface IKeyedString {
  [key: string]: string | number[];
}

export interface IKeyToRemove extends IKeyedString {
  entities: string;
  ids: number[];
}

export interface ISetKey {
  stateKey: string;
  key: string;
  value: any
}

export enum MAIN_TAB {
  HOME = "Home",
  MINES_CONTRACTORS = "Mines / Contractors",
  MINE_STATE = "Mine State",
  OPERATOR_ANALYTICS = "Operator Analytics",
  ADMINISTRATION = "Administration",
  OUR_SERVICES = "Our Services",
  ABOUT_US = "About Us",
  CONTACT_US = "Contact Us",
}

// export enum PAGE {
//   LANDING = 'Landing',
// }
export interface IClientOptions {
  selected_tab: MAIN_TAB;
  show_citation_nav: boolean;
  selected_mine: IMine;
  [key: string]: any;
}

export interface IControllerMeta {
  name: string,
  id: string
}

export interface IMainState {
  account_login_validations: { [key: string]: IAccountLoginValidation };
  account_login: IAccountLogin;
  account_logins: { [key: string]: IAccountLogin };
  company_email_settings: { [key: string]: ICompanyEmailSettingModel };
  business_units: { [key: string]: IBusinessUnitModel };
  regions: { [key: string]: IRegionModel };
  company_email_settings_citation_columns: {
    [key: string]: INotificationColumn;
  };
  company_email_settings_inspection_columns: {
    [key: string]: INotificationColumn;
  };
  admin_email_settings: { [key: string]: IAdminEmailSettings };
  citation_meta_data: { [key: string]: ICitationMetaData };
  citations: { [key: string]: ICitation };
  citation_warnings: { [key: string]: ICitationWarning}
  citation_mgmt_custom_fields: { [key: string]: ICitationMgmtCustomField };
  client_options: IClientOptions;
  companies: { [key: string]: ICompany };
  company_accounts: { [key: string]: ICompanyAccount };
  inspections: { [key: string]: IInspection };
  jwt_token: string;
  mines: { [key: string]: IMine };
  mine_context: { [key: string]: any };
  citation_form_data: any[];
  controller_meta: IControllerMeta,
  contractors: { [key: string]: IContractor };
}
