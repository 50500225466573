import { Tooltip } from "@material-ui/core"
import React from "react"
import { CITATION_TABLE_FILTER } from "../../enums/citation-enums"

export const FilterIconHtml = (imgname: string, tooltiptext: string, tooltip: boolean) => {
    const iconStyle = { width: 20, borderRadius: '50%', cursor: 'pointer' }
    if (tooltip) {
        return <Tooltip title={tooltiptext}><img src={process.env.PUBLIC_URL + '/citation_icons/' + imgname} style={iconStyle} /></Tooltip>
    }
    return <img src={process.env.PUBLIC_URL + '/citation_icons/' + imgname} style={iconStyle} />
}

export const FilterIcon = (filter: CITATION_TABLE_FILTER, tooltip: boolean) => {
    switch (filter) {
        case CITATION_TABLE_FILTER.OUTSTANDING:
            return FilterIconHtml('icon-outstanding.png', 'Outstanding', tooltip)
        case CITATION_TABLE_FILTER.PAID:
            return FilterIconHtml('icon-paid.png', 'Paid', tooltip)
        case CITATION_TABLE_FILTER.IN_CONTEST:
            return FilterIconHtml('icon-in-contest.png', 'Contested', tooltip)
        case CITATION_TABLE_FILTER.SPECIAL_ASSESSMENT:
            return FilterIconHtml('icon-special-assessment.png', 'Special Assessment', tooltip)
        case CITATION_TABLE_FILTER.TERMINATED:
            return FilterIconHtml('icon-terminated.png', 'Terminated', tooltip)
        case CITATION_TABLE_FILTER.VACATED:
            return FilterIconHtml('icon-vacated.png', 'Vacated', tooltip)
        case CITATION_TABLE_FILTER.RPID:
            return FilterIconHtml('icon-rpid.png', 'RPID', tooltip)
        case CITATION_TABLE_FILTER.PENALTY_DISCREPANCY_UP:
            return FilterIconHtml('icon-penalty-discrepancy-up.gif', 'Discrepancy', tooltip)
        case CITATION_TABLE_FILTER.PENALTY_DISCREPANCY_DOWN:
            return FilterIconHtml('icon-penalty-discrepancy-down.gif', 'Discrepancy', tooltip)
    }
}
