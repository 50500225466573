import { combineReducers } from 'redux';
import { IRootState } from '../../interfaces/store';
import main from './main';
import flatfile from './flatfile';

const rootReducer = combineReducers<IRootState>({
  main,
  flatfile
});

export default rootReducer;
