import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IRootState } from "../../interfaces/store";
import { useSelector } from "../../store";
import { useHistory } from "react-router";
import { arrayFromKeyedObject } from "../../utils/collection-utils";
import CompaniesTable from "./CompaniesTable";
import { getAllMinesGroupByCompany } from "../../store/actions/mineActions";
import {
  ICompany,
  ICompanyWithMineCount,
} from "../../interfaces/model/MSHAPCT/company.interface";
import { IMine } from "../../interfaces/model/MSHAPCT/mine.interface";
import { COLORS } from "../../enums/layout-enums";
import { setClientOptions } from "../../store/actions/clientActions";
import { MAIN_TAB } from "../../interfaces/store/main";
import {
  getAccountsByFlag,
  getAdminEmailSettings,
} from "../../store/actions/accountActions";
import { IAccountLogin } from "../../interfaces/model/MSHAPCT/account-login.interface";
import AccountsTable from "./AccountTable";
import AddCompanyModal from "./AddCompanyModal";
import { ACCOUNT_TYPE } from "../../enums/citation-enums";
import AdminEmailSettingsTable from "./AdminEmailSettingsTable";
import { ACCOUNT_FLAGS } from "../../enums/account-enums";
import { hasFlag } from "../../utils/bitwise-utils";
import AdminUserModal from "./AdminUserModal";

interface IAdministrationProps {}

const Administration: React.FC<IAdministrationProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [companyDataKey, setCompanyDataKey] = useState(0);
  const [accountDataKey, setAccountDataKey] = useState(0);
  const [adminEmailDataKey, setAdminEmailDataKey] = useState(0);
  const [companiesWithMines, setCompaniesWithMines] = useState(
    [] as ICompanyWithMineCount[]
  );
  const [systemAdminAccounts, setSystemAdminAccounts] = useState([] as any[]);
  const [systemAdminEmails, setSystemAdminEmails] = useState([] as any[]);
  const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);
  const [addAdminUserModalOpen, setAddAdminUserModalOpen] = useState(false);
  const [addAdminUserModalId, setAddAdminUserModalId] = useState(1);
  const [selectedAccountLogin, setSelectedAccountLogin] = useState(0);
  const companies = useSelector((state: IRootState) => state.main.companies);

  const mines = useSelector((state: IRootState) => state.main.mines);

  const accountLogins = useSelector(
    (state: IRootState) => state.main.account_logins
  );

  const accountLogin = useSelector(
    (state: IRootState) => state.main.account_login
  );

  const adminEmailSettings = useSelector(
    (state: IRootState) => state.main.admin_email_settings
  );

  useEffect(() => {
    dispatch(getAllMinesGroupByCompany());
    dispatch(setClientOptions({ selected_tab: MAIN_TAB.ADMINISTRATION }));
    dispatch(getAccountsByFlag({ flags: [9] }));
    dispatch(getAdminEmailSettings());
  }, []);

  useEffect(() => {
    const accounts = arrayFromKeyedObject(accountLogins);
    setSystemAdminAccounts(
      accounts.filter(
        (a: IAccountLogin) => a.accountFlag === ACCOUNT_TYPE.SYSTEM_ADMIN
      )
    );
    setAccountDataKey(accountDataKey + 1);
  }, [accountLogins]);

  useEffect(() => {
    const companiesWithMines = arrayFromKeyedObject(
      companies
    ) as ICompanyWithMineCount[];
    const minesArr = arrayFromKeyedObject(mines);

    if (!companiesWithMines.length) {
      return;
    }

    minesArr.forEach((mine: IMine) => {
      const company = companiesWithMines.find(
        (company: ICompany) => mine.companyFk == company.pk
      );
      if (company) {
        if (!company.mineCount) {
          company.mineCount = 0;
        }
        company.mineCount++;
      }
    });

    if (minesArr.length === 0) {
      const company = companiesWithMines.map(
        (company: ICompanyWithMineCount) => {
          company.mineCount = 0;
          return company;
        }
      );
    }

    setCompaniesWithMines(companiesWithMines);
    setCompanyDataKey(companyDataKey + 1);
  }, [mines, companies]);

  useEffect(() => {
    console.log("adminEmailSettings", adminEmailSettings);
    const adminEmails = arrayFromKeyedObject(adminEmailSettings);
    setSystemAdminEmails(adminEmails);
    setAdminEmailDataKey(adminEmailDataKey + 1);
  }, [adminEmailSettings]);

  const onOpenModal = (accountLoginPk: number = 0) => {
    setAddAdminUserModalOpen(true);
  };

  const onCloseModal = () => {
    onResetModal();
  };

  const onResetModal = () => {
    setAddAdminUserModalOpen(false);
    setSelectedAccountLogin(0);
    setAddAdminUserModalId(addAdminUserModalId + 1);
  };

  const onSelectedAccountLogin = (pk: number) => {
    setSelectedAccountLogin(pk);
    setAddAdminUserModalOpen(true);
  };
  console.log("systemAdminAccounts", systemAdminAccounts);
  return (
    <>
      <AddCompanyModal
        open={addCompanyModalOpen}
        onClose={() => setAddCompanyModalOpen(false)}
      />
      <AdminUserModal
        key={`admin-user-modal-${addAdminUserModalId}`}
        open={addAdminUserModalOpen}
        onClose={onCloseModal}
        onReset={onResetModal}
        accountLoginPk={selectedAccountLogin}
      />
      <div style={{ backgroundColor: COLORS.WHITE_PAPER }}>
        {(hasFlag(accountLogin.accountFlag, ACCOUNT_FLAGS.system_admin) ||
          hasFlag(accountLogin.accountFlag, ACCOUNT_FLAGS.company_admin)) && (
          <>
            {hasFlag(accountLogin.accountFlag, ACCOUNT_FLAGS.system_admin) && (
              <Button
                onClick={() => setAddCompanyModalOpen(true)}
                variant="outlined"
                size="small"
              >
                Add Company
              </Button>
            )}
            <div style={{ marginTop: 16, marginBottom: 16 }}>
              <CompaniesTable
                key={"company_" + companyDataKey}
                data={[...companiesWithMines]}
              />
            </div>
            {hasFlag(accountLogin.accountFlag, ACCOUNT_FLAGS.system_admin) && (
              <>
                <div style={{ marginTop: 16 }}>
                  <Button
                    style={{ marginBottom: 5 }}
                    onClick={() => {
                      onOpenModal();
                    }}
                    variant="outlined"
                    size="small"
                  >
                    Add System Admin User
                  </Button>
                  <AccountsTable
                    title={"System Administrators"}
                    key={"account_" + accountDataKey}
                    data={[...systemAdminAccounts]}
                    onSelectedItem={onSelectedAccountLogin}
                  />
                </div>
                <div style={{ marginTop: 16 }}>
                  <AdminEmailSettingsTable
                    title={"Admin Email Notification Groups"}
                    key={"admin_email_setting_" + adminEmailDataKey}
                    data={[...systemAdminEmails]}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default Administration;
