
export enum INSPECTION_STATUS
{
    IN_PROGRESS=1,
    COMPLETE=2
}

export const inspectionStatusToString = (inspectionStatus: INSPECTION_STATUS) => {
    switch(inspectionStatus){
        case INSPECTION_STATUS.IN_PROGRESS: 
        return "In Progress";
        case INSPECTION_STATUS.COMPLETE: 
        return "Complete";
    }
}