import { ACTIONS } from "../../enums/actions";

export const login = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
    type: ACTIONS.LOGIN,
    payload,
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const logout = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
    type: ACTIONS.LOGOUT,
    payload,
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const forgotPassword = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
    type: ACTIONS.FORGOT_PASSWORD,
    payload,
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const resetPassword = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
    type: ACTIONS.RESET_PASSWORD,
    payload,
    onSuccess: onSuccess || null,
    onFail: onFail || null
});