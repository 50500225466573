
import React from "react";

interface IReleaseNotes {
}

const release_notes = [	
	{
		date: '2023-08-08',
		version: '1.0.19',
		environment: 'production',
		notes: [
			{
				area: 'UI Rebranding',
				note: 'New icon added & theme changed'
			}
		]
	},{
		date: '2023-08-03',
		version: '1.0.18',
		environment: 'production',
		notes: [
			{
				area: 'Fix Citation Download Inspection Type Issue',
				note: 'Downloading the citation will now show the inspection type in their original format'
			}
		]
	},{
		date: '2023-04-20',
		version: '1.0.17',
		environment: 'production',
		notes: [
			{
				area: 'Add Weekly, Monthly, Quarterly, Yearly Buttons Filter',
				note: 'User can now select range by clicking on the buttons'
			}
		]
	},{
		date: '2023-04-13',
		version: '1.0.16',
		environment: 'production',
		notes: [
			{
				area: 'Citation Date Range Reset Fix',
				note: 'Date range preference is stored, Henceforth user selected date range will automatically popup till the next login'
			}
		]
	},{
		date: '2023-03-31',
		version: '1.0.15',
		environment: 'production',
		notes: [
			{
				area: 'Session Expiration Issue Fix',
				note: 'If JWT gets expired, the screen will automatically redirect to Login Page'
			}
		]
	},{
		date: '2023-03-14',
		version: '1.0.14',
		environment: 'production',
		notes: [
			{
				area: '2023 Penalty Update',
				note: 'Citations will now be calculated on the basis of new 2023 updates'
			},
			{
				area: 'Update v1 email details',
				note: 'Removed earlier references of from, to & SendGrid details'
			}
		]
	},
	{
		date: '2023-02-27',
		version: '1.0.13',
		environment: 'production',
		notes: [
			{
				area: 'Recaptcha Issue Fixed',
				note: 'Forgot password recaptcha issue fixed'
			},
			{
				area: 'Email Issue Fixed',
				note: 'Notifications emails will now be sent that earlier were not being sent'
			},
			{
				area: 'Started Versioning V2',
				note: 'Each deployed versions can now be seen'
			}
		]
	},{
		date: '2023-02-20',
		version: '1.0.12',
		environment: 'production',
		notes: [
			{
				area: 'Sendgrid changes',
				note: 'Update Email templates & API key',
			}
		]
	},
	{
		date: '2023-02-12',
		version: '1.0.11',
		environment: 'production',
		notes: [
			{
				area: 'Make 18A & 18B field optional',
				note: ' Citation can be uploaded when 18A & 18B are empty',
			}
		]
	},{
		date: '2023-02-09',
		version: '1.0.10',
		environment: 'production',
		notes: [
			{
				area: 'Make 14E & 14F field optional',
				note: ' Citation can be uploaded when 14E & 14F are empty',
			}
		]
	},{
		date: '2023-01-30',
		version: '1.0.9',
		environment: 'production',
		notes: [
			{
				area: 'Citation Delete',
				note: ' Ability to delete citations.',
			}
		]
	},
	{
		date: '2022-11-10',
		version: '1.0.8',
		environment: 'production',
		notes: [
			{
				area: 'Citation Scan',
				note: 'Fixed date validation issues preventing saving scan results.',
			}
		]
	},
	{
		date: '2022-11-03',
		version: '1.0.7',
		environment: 'production',
		notes: [
			{
				area: '10k/10q',
				note: 'Fixed calculation totals in 10k and 10q.',
			},
			{
				area: 'Citation Edit',
				note: 'Fixed issues with date inputs, where the time chanages would effect the date, and visa versa.',
			},
			{
				area: 'Citation View',
				note: 'Fixed an issue where Mine name and mine id were not being displayed.',
			},
			{
				area: 'Citation Edit',
				note: 'Fixed an issue where time zones would change times after saving.',
			}
		]
	},
	{
		date: '2022-08-24',
		version: '1.0.6',
		environment: 'production',
		notes: [
			{
				area: 'OCR',
				note: 'Improved OCR model by adding training documents, including significant improvements to served to, initial action and written notice.',
			}
		]
	},
	{
		date: '2022-07-08',
		version: '1.0.5',
		environment: 'production',
		notes: [
			{
				area: 'Logging',
				note: 'Added authentication logs.',
			}
		]
	},
	{
		date: '2022-07-07',
		version: '1.0.4',
		environment: 'production',
		notes: [
			{
				area: 'Citation Scan',
				note: 'Improved logic for matching citation uploads to mines.',
			},
			{
				area: 'Citation Scan',
				note: 'Ability to identify unique citations with existing citation numbers.',
			}
		]
	},
	{
		date: '2022-05-25',
		version: '1.0.3',
		environment: 'production',
		notes: [
			{
				area: 'Citation Scan',
				note: 'Upgraded to latest Azure Forms library.',
			},
			{
				area: 'Citation Scan',
				note: 'Fixed issue where empty date fields would show up as invalid.',
			}	
		]
	},
	{
		date: '2022-04-22',
		version: '1.0.2',
		environment: 'production',
		notes: [
			{
				area: 'Citation Table',
				note: 'Column selections are persistent across sessions.',
			},
			{
				area: 'Citation Export',
				note: 'Added date issued, terminated, and termination times.',
			},
			{
				area: 'Citation Table',
				note: 'Penalty calc GFE has been promoted to a selectable column and will show up in the report if selected.',
			},
			{
				area: 'Citation Export',
				note: 'Fixed Injury or Illness values exporting with the Likelihood values.',
			},
			{
				area: 'Citation Export',
				note: 'Condition or practice column new lines are converted to spaces for the export process.',
			},
			{
				area: 'Citation Export',
				note: 'Trailing zeroes in section of act are preserved.',
			}
		]
	},
	{
		date: '2022-03-25',
		version: '1.0.1',
		environment: 'production',
		notes: [
			{
				area: 'Citations: ',
				note: 'Export citation, with all columns selected will now export custom company fields.',
			},
			{
				area: 'Citation Notes',
				note: 'Custom note fields now display a generic name if no display name is provided.',
			},
			{
				area: 'Citation Notes',
				note: 'Fixed an issue where custom long text fields would not appear in the citation notes modal.',
			}
		]
	},
	{
		date: '2022-02-05',
		version: '1.0.0',
		environment: 'production',
		notes: [
			{
				area: 'General: ',
				note: 'Added about section added with release notes.',
			},
			{
				area: 'Admin',
				note: 'Delete company now deletes all associated data.',
			},
			{
				area: 'Landing',
				note: 'Removed featured company section from site landing.',
			}
		]
	}
]

const getEnvironmentDiv = (env: string) => {
	if (env === 'beta' || env === 'development' || env === 'staging') {
		return <span>{env}</span>
	}
	return null;
}

const ReleaseNotes: React.FC<IReleaseNotes> = (props) => {

	return (
		<div>
			<h1>Version: {release_notes[0].version}</h1>

			<h2>History</h2>

			{release_notes.map((rn, rnIndex) => {
				return (
					<div key={rnIndex}>
						<section>
							<article>
								Version {rn.version} {getEnvironmentDiv(rn.environment)}
								<br />
								Release Date: {rn.date}
								<ul>
									{rn.notes.map((note, noteIndex) => {
										return (
											<li key={noteIndex}>{note.area}: {note.note}</li>
										)
									})}
								</ul>
							</article>
						</section>
						<hr></hr>
					</div>
				)
			})}
		</div>

	)

}
export default ReleaseNotes