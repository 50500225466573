export const OA_DASH_CITATIONS = 2200;
export const OA_DASH_CITATION_PENALTY_HEIGHT = 635
export const OA_DASH_INSPECTION_BASICS = 1950;
export const OA_DASH_TOP_10 = 1650;
export const OA_DASH_SPACER = 80;
export const OA_DASH_TREND_ANALYSIS_HEIGHT = 2000;

export {}
export const DEFAULT_CONTROLLER_NAME = 'Newmont%20Goldcorp%20Corporation'

