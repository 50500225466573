import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  FormGroup,
  DialogContent,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Loading from "../shared/Loading";
import { useSnackbar } from "notistack";
import { useSelector } from "../../store";
import { IRootState } from "../../interfaces/store";
import { arrayFromKeyedObject } from "../../utils/collection-utils";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";

interface IEditAdminEmailSettingsModalProps {
  itemData: any;
  open: boolean;
  onClose: Function;
  title: string;
  onSubmit: Function;
  isEditMode: boolean;
  isCompany: boolean;
}

const EditAdminEmailSettingsModal: React.FC<IEditAdminEmailSettingsModalProps> =
  (props) => {
    const citationColumnsGlobalState = useSelector(
      (state: IRootState) => state.main.company_email_settings_citation_columns
    );

    const inspectionColumnsGlobalState = useSelector(
      (state: IRootState) =>
        state.main.company_email_settings_inspection_columns
    );

    const [citationColumns, setCitationColumns] = useState([] as any);
    const [inspectionColumns, setInspectionColumns] = useState([] as any);

    const [selectedCitationColumns, setSelectedCitationColumns] = useState(
      [] as any
    );
    const [selectedInspectionColumns, setSelectedInspectionColumns] = useState(
      [] as any
    );

    useEffect(() => {
      const columns = arrayFromKeyedObject(citationColumnsGlobalState);
      setCitationColumns(columns);
    }, [citationColumnsGlobalState]);

    useEffect(() => {
      const columns = arrayFromKeyedObject(inspectionColumnsGlobalState);
      setInspectionColumns(columns);
    }, [inspectionColumnsGlobalState]);

    const { enqueueSnackbar } = useSnackbar();
    const [processsing, setProcesssing] = useState(false);
    const [companyFK, setCompanyFK] = useState(props.itemData.companyFk);
    const [groupName, setGroupName] = useState(props.itemData.groupName);
    const [toEmailAddress, setToEmailAddress] = useState(
      props.itemData.toEmailAddress
    );
    const [ccEmailAddress, setCcEmailAddress] = useState(
      props.itemData.ccEmailAddress
    );
    const [negligenceNone, setNegligenceNone] = useState(
      props.itemData.negligenceNone
    );
    const [negligenceLow, setNegligenceLow] = useState(
      props.itemData.negligenceLow
    );
    const [negligenceModerate, setNegligenceModerate] = useState(
      props.itemData.negligenceModerate
    );
    const [negligenceHigh, setNegligenceHigh] = useState(
      props.itemData.negligenceHigh
    );
    const [negligenceRecklessDisregard, setNegligenceRecklessDisregard] =
      useState(props.itemData.negligenceRecklessDisregard);
    const [likelihoodNoLikelihood, setLikelihoodNoLikelihood] = useState(
      props.itemData.likelihoodNoLikelihood
    );
    const [likelihoodUnlikely, setLikelihoodUnlikely] = useState(
      props.itemData.likelihoodUnlikely
    );
    const [likelihoodReasonablyLikely, setLikelihoodReasonablyLikely] =
      useState(props.itemData.likelihoodReasonablyLikely);
    const [likelihoodHighlyLikely, setLikelihoodHighlyLikely] = useState(
      props.itemData.likelihoodHighlyLikely
    );
    const [likelihoodOccured, setLikelihoodOccured] = useState(
      props.itemData.likelihoodReasonablyLikely
    );
    const [injuryOrIllnessNoLostWorkdays, setInjuryOrIllnessNoLostWorkdays] =
      useState(props.itemData.injuryOrIllnessNoLostWorkdays);
    const [injuryOrIllnessLostWorkdays, setInjuryOrIllnessLostWorkdays] =
      useState(props.itemData.injuryOrIllnessLostWorkdays);
    const [
      injuryOrIllnessPermanentlyDisabling,
      setInjuryOrIllnessPermanentlyDisabling,
    ] = useState(props.itemData.injuryOrIllnessPermanentlyDisabling);
    const [injuryOrIllnessFatal, setInjuryOrIllnessFatal] = useState(
      props.itemData.injuryOrIllnessFatal
    );
    const [significantAndSubstantial, setSignificantAndSubstantial] = useState(
      props.itemData.significantAndSubstantial
    );
    const [minimumNumberOfPersonsAffected, setMinimumNumberOfPersonsAffected] =
      useState(
        props.itemData.minimumNumberOfPersonsAffected
          ? props.itemData.minimumNumberOfPersonsAffected
          : ""
      );
    const [sendCitationNotifications, setSendCitationNotifications] = useState(
      props.itemData.sendCitationNotifications
    );
    const [citationNotificationSchedule, setCitationNotificationSchedule] =
      useState(props.itemData.citationNotificationSchedule);

    const [sendInspectionNotifications, setSendInspectionNotifications] =
      useState(props.itemData.sendInspectionNotifications);
    const [inspectionNotificationSchedule, setInspectionNotificationSchedule] =
      useState(props.itemData.inspectionNotificationSchedule);
    const [sendEventNotifications, setSendEventNotifications] = useState(
      props.itemData.sendEventNotifications
    );
    const [eventNotificationSchedule, setEventNotificationSchedule] = useState(
      props.itemData.eventNotificationSchedule
    );

    const [povMediumRisk, setPovMediumRisk] = useState(
      props.itemData.povMediumRisk
    );
    const [povHighRisk, setPovHighRisk] = useState(
      props.itemData.povMediumRisk
    );
    const [sendPovNotifications, setSendPovNotifications] = useState(
      props.itemData.sendPovNotifications
    );
    const [povNotificationSchedule, setPovNotificationSchedule] = useState(
      props.itemData.povNotificationSchedule
    );
    const [minimumCitationAmount, setMinimumCitationAmount] = useState(
      props.itemData.minimumCitationAmount
        ? props.itemData.minimumCitationAmount
        : ""
    );

    const handleCitationNotificationSchedule = (value: number): void => {
      setCitationNotificationSchedule(value);
    };

    const handlePovNotificationSchedule = (value: number): void => {
      setPovNotificationSchedule(value);
    };

    useEffect(() => {
      if (props.itemData.selectedCitationColumns) {
        setSelectedCitationColumns(
          citationColumns.filter((item: any) =>
            props.itemData.selectedCitationColumns.includes(item.pk!)
          )
        );
      }

      if (props.itemData.selectedInspectionColumns) {
        setSelectedInspectionColumns(
          inspectionColumns.filter((item: any) =>
            props.itemData.selectedInspectionColumns.includes(item.pk!)
          )
        );
      }

      setCompanyFK(props.itemData.companyFk);
      setGroupName(props.itemData.groupName);
      setToEmailAddress(props.itemData.toEmailAddress);
      setCcEmailAddress(props.itemData.ccEmailAddress);
      setNegligenceNone(props.itemData.negligenceNone);
      setNegligenceLow(props.itemData.negligenceLow);
      setNegligenceModerate(props.itemData.negligenceModerate);
      setNegligenceHigh(props.itemData.negligenceHigh);
      setNegligenceRecklessDisregard(
        props.itemData.negligenceRecklessDisregard
      );
      setLikelihoodNoLikelihood(props.itemData.likelihoodNoLikelihood);
      setLikelihoodUnlikely(props.itemData.likelihoodUnlikely);
      setLikelihoodReasonablyLikely(props.itemData.likelihoodReasonablyLikely);
      setLikelihoodHighlyLikely(props.itemData.likelihoodHighlyLikely);
      setLikelihoodOccured(props.itemData.likelihoodReasonablyLikely);
      setInjuryOrIllnessNoLostWorkdays(
        props.itemData.injuryOrIllnessNoLostWorkdays
      );
      setInjuryOrIllnessLostWorkdays(
        props.itemData.injuryOrIllnessLostWorkdays
      );
      setInjuryOrIllnessPermanentlyDisabling(
        props.itemData.injuryOrIllnessPermanentlyDisabling
      );
      setInjuryOrIllnessFatal(props.itemData.injuryOrIllnessFatal);
      setSignificantAndSubstantial(props.itemData.significantAndSubstantial);
      setMinimumNumberOfPersonsAffected(
        props.itemData.minimumNumberOfPersonsAffected
          ? props.itemData.minimumNumberOfPersonsAffected
          : ""
      );
      setSendCitationNotifications(props.itemData.sendCitationNotifications);
      setCitationNotificationSchedule(
        props.itemData.citationNotificationSchedule
      );

      setSendInspectionNotifications(
        props.itemData.sendInspectionNotifications
      );
      setInspectionNotificationSchedule(
        props.itemData.inspectionNotificationSchedule
      );
      setSendEventNotifications(props.itemData.sendEventNotifications);
      setEventNotificationSchedule(props.itemData.eventNotificationSchedule);

      setPovMediumRisk(props.itemData.povMediumRisk);
      setPovHighRisk(props.itemData.povHighRisk);
      setSendPovNotifications(props.itemData.sendPovNotifications);
      setPovNotificationSchedule(props.itemData.povNotificationSchedule);
      setMinimumCitationAmount(
        props.itemData.minimumCitationAmount
          ? props.itemData.minimumCitationAmount
          : ""
      );
    }, [props.itemData]);

    const submitForm = () => {
      setProcesssing(true);
      const { pk } = props.itemData;
      const body = {
        pk,
        companyFK,
        groupName,
        toEmailAddress,
        ccEmailAddress,
        negligenceNone,
        negligenceLow,
        negligenceModerate,
        negligenceHigh,
        negligenceRecklessDisregard,
        likelihoodNoLikelihood,
        likelihoodUnlikely,
        likelihoodReasonablyLikely,
        likelihoodHighlyLikely,
        likelihoodOccured,
        injuryOrIllnessNoLostWorkdays,
        injuryOrIllnessLostWorkdays,
        injuryOrIllnessPermanentlyDisabling,
        injuryOrIllnessFatal,
        significantAndSubstantial,
        minimumNumberOfPersonsAffected,
        sendCitationNotifications,
        citationNotificationSchedule,
        sendInspectionNotifications,
        inspectionNotificationSchedule,
        sendEventNotifications,
        eventNotificationSchedule,
        povMediumRisk,
        povHighRisk,
        sendPovNotifications,
        povNotificationSchedule,
        minimumCitationAmount,
        selectedCitationColumnIndexes: selectedCitationColumns
          .map(function (item: any) {
            return item.pk;
          })
          .join(","),
        selectedInspectionColumnIndexes: selectedInspectionColumns
          .map(function (item: any) {
            return item.pk;
          })
          .join(","),
      };

      props.onSubmit(
        body,
        () => {
          setProcesssing(false);
          enqueueSnackbar("Changes were saved successfully..", {
            variant: "success",
          });
        },
        (message?: string) => {
          setProcesssing(false);
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      );
    };

    const onRenderColumnValues = (selected: any) => {
      var data = selected.map(function (item: any) {
        return item.displayName;
      });

      return data.join(", ");
    };

    const isCitationColumnSelected = (selected: any): boolean => {
      var data = selectedCitationColumns.filter(
        (el: any) => el.pk === selected.pk
      );
      return data.length > 0 ? true : false;
    };

    const onChangeSelectedCitationColumns = (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      setSelectedCitationColumns(event.target.value);
    };

    const isInspectionColumnSelected = (selected: any): boolean => {
      var data = selectedInspectionColumns.filter(
        (el: any) => el.pk === selected.pk
      );
      return data.length > 0 ? true : false;
    };

    const onChangeSelectedInspectionColumns = (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      setSelectedInspectionColumns(event.target.value);
    };

    return (
      <>
        <Loading loading={processsing} />
        <Dialog
          onClose={() => props.onClose()}
          aria-labelledby="customized-dialog-title"
          maxWidth="lg"
          open={props.open}
        >
          <MuiDialogTitle>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 3 }}>
                <Typography variant="h6">
                  {(props.isEditMode ? "Edit " : "Add ") + props.title}
                </Typography>
              </div>
              <div style={{ flex: 1, textAlign: "right" }}>
                <IconButton aria-label="close" onClick={() => props.onClose()}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </MuiDialogTitle>
          <DialogContent dividers>
            <Grid container wrap="nowrap">
              <Grid
                container
                spacing={2}
                style={{
                  padding: "2rem",
                  zIndex: 500,
                }}
              >
                <Grid container direction="column" item xs={12}>
                  <InputLabel htmlFor="my-input">Group Name:</InputLabel>
                  <TextField
                    size="small"
                    id="3_citation_no"
                    variant="outlined"
                    value={groupName}
                    onChange={(event) => setGroupName(event?.target.value)}
                  />
                </Grid>
                <Grid container direction="column" item xs={12}>
                  <InputLabel htmlFor="my-input">Negligence</InputLabel>
                  <Grid>
                    <FormControlLabel
                      label="None"
                      control={
                        <Checkbox
                          checked={negligenceNone}
                          onChange={(event) => {
                            setNegligenceNone(event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                    />
                    <FormControlLabel
                      label="Low"
                      control={
                        <Checkbox
                          checked={negligenceLow}
                          onChange={(event) => {
                            setNegligenceLow(event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                    />
                    <FormControlLabel
                      label="Moderate"
                      control={
                        <Checkbox
                          checked={negligenceModerate}
                          onChange={(event) => {
                            setNegligenceModerate(event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                    />
                    <FormControlLabel
                      label="High"
                      control={
                        <Checkbox
                          checked={negligenceHigh}
                          onChange={(event) => {
                            setNegligenceHigh(event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                    />
                    <FormControlLabel
                      label="Reckless Disregard"
                      control={
                        <Checkbox
                          checked={negligenceRecklessDisregard}
                          onChange={(event) => {
                            setNegligenceRecklessDisregard(
                              event.target.checked
                            );
                          }}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container direction="column" item xs={12}>
                  <InputLabel htmlFor="my-input">Likelihood</InputLabel>
                  <Grid>
                    <FormControlLabel
                      label="None"
                      control={
                        <Checkbox
                          checked={likelihoodNoLikelihood}
                          onChange={(event) => {
                            setLikelihoodNoLikelihood(event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                    />
                    <FormControlLabel
                      label="Low"
                      control={
                        <Checkbox
                          checked={likelihoodUnlikely}
                          onChange={(event) => {
                            setLikelihoodUnlikely(event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                    />
                    <FormControlLabel
                      label="Moderate"
                      control={
                        <Checkbox
                          checked={likelihoodReasonablyLikely}
                          onChange={(event) => {
                            setLikelihoodReasonablyLikely(event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                    />
                    <FormControlLabel
                      label="High"
                      control={
                        <Checkbox
                          checked={likelihoodHighlyLikely}
                          onChange={(event) => {
                            setLikelihoodHighlyLikely(event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                    />
                    <FormControlLabel
                      label="Reckless Disregard"
                      control={
                        <Checkbox
                          checked={likelihoodOccured}
                          onChange={(event) => {
                            setLikelihoodOccured(event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container direction="column" item xs={12}>
                  <InputLabel htmlFor="my-input">Injury/Illness</InputLabel>
                  <Grid>
                    <FormControlLabel
                      label="None"
                      control={
                        <Checkbox
                          checked={injuryOrIllnessNoLostWorkdays}
                          onChange={(event) => {
                            setInjuryOrIllnessNoLostWorkdays(
                              event.target.checked
                            );
                          }}
                          color="primary"
                        />
                      }
                    />
                    <FormControlLabel
                      label="Low"
                      control={
                        <Checkbox
                          checked={injuryOrIllnessLostWorkdays}
                          onChange={(event) => {
                            setInjuryOrIllnessLostWorkdays(
                              event.target.checked
                            );
                          }}
                          color="primary"
                        />
                      }
                    />
                    <FormControlLabel
                      label="Moderate"
                      control={
                        <Checkbox
                          checked={injuryOrIllnessPermanentlyDisabling}
                          onChange={(event) => {
                            setInjuryOrIllnessPermanentlyDisabling(
                              event.target.checked
                            );
                          }}
                          color="primary"
                        />
                      }
                    />
                    <FormControlLabel
                      label="High"
                      control={
                        <Checkbox
                          checked={injuryOrIllnessFatal}
                          onChange={(event) => {
                            setInjuryOrIllnessFatal(event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                    />
                    <FormControlLabel
                      label="Reckless Disregard"
                      control={
                        <Checkbox
                          checked={significantAndSubstantial}
                          onChange={(event) => {
                            setSignificantAndSubstantial(event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container direction="column" item xs={12}>
                  <InputLabel htmlFor="my-input">
                    Min # of Persons Affected:
                  </InputLabel>
                  <TextField
                    size="small"
                    id="3_citation_no"
                    variant="outlined"
                    value={minimumNumberOfPersonsAffected}
                    onChange={(event) =>
                      setMinimumNumberOfPersonsAffected(event?.target.value)
                    }
                  />
                </Grid>
                {!props.isCompany && (
                  <>
                    <Grid container direction="column" item xs={12}>
                      <InputLabel htmlFor="my-input">
                        Minimum Citation Amount
                      </InputLabel>
                      <TextField
                        size="small"
                        id="3_citation_no"
                        variant="outlined"
                        value={minimumCitationAmount}
                        onChange={(event) =>
                          setMinimumCitationAmount(event?.target.value)
                        }
                      />
                    </Grid>
                  </>
                )}
                <Grid container direction="column" item xs={12}>
                  <InputLabel htmlFor="my-input">
                    Pattern of Violations
                  </InputLabel>
                  <Grid>
                    <FormControlLabel
                      label="Medium Risk"
                      control={
                        <Checkbox
                          checked={povMediumRisk}
                          onChange={(event) => {
                            setPovMediumRisk(event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                    />
                    <FormControlLabel
                      label="High Risk"
                      control={
                        <Checkbox
                          checked={povHighRisk}
                          onChange={(event) => {
                            setPovHighRisk(event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{
                  padding: "2rem",
                }}
              >
                <Grid
                  container
                  direction="column"
                  item
                  justify="space-evenly"
                  xs={12}
                >
                  <InputLabel htmlFor="my-input">To:</InputLabel>
                  <TextField
                    size="small"
                    id="3_citation_no"
                    variant="outlined"
                    value={toEmailAddress}
                    onChange={(event) => setToEmailAddress(event?.target.value)}
                  />
                  <InputLabel htmlFor="my-input" style={{ marginTop: 10 }}>
                    Cc:
                  </InputLabel>
                  <TextField
                    size="small"
                    id="3_citation_no"
                    variant="outlined"
                    value={ccEmailAddress}
                    onChange={(event) => setCcEmailAddress(event?.target.value)}
                  />
                </Grid>
                <Grid
                  container
                  direction="column"
                  item
                  justify="space-evenly"
                  xs={12}
                >
                  <Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6}>
                        <InputLabel htmlFor="my-input">
                          Notifications
                        </InputLabel>
                        <FormControl>
                          <FormControlLabel
                            label="Send Citation Notifications"
                            control={
                              <Checkbox
                                name="Citation Notifications"
                                checked={sendCitationNotifications}
                                onChange={(event) => {
                                  setSendCitationNotifications(
                                    event.target.checked
                                  );
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            className="adminSettings-checkbox-subradio"
                            disabled={!sendCitationNotifications}
                            onChange={(e) =>
                              handleCitationNotificationSchedule(1)
                            }
                            value={citationNotificationSchedule}
                            control={
                              <Radio
                                checked={
                                  citationNotificationSchedule === 1 &&
                                  sendCitationNotifications
                                    ? true
                                    : false
                                }
                              />
                            }
                            label="Daily"
                          />
                          <FormControlLabel
                            className="adminSettings-checkbox-subradio"
                            disabled={!sendCitationNotifications}
                            onChange={(e) =>
                              handleCitationNotificationSchedule(2)
                            }
                            value={citationNotificationSchedule}
                            control={
                              <Radio
                                checked={
                                  citationNotificationSchedule === 2 &&
                                  sendCitationNotifications
                                    ? true
                                    : false
                                }
                              />
                            }
                            label="Weekly"
                          />
                        </FormControl>
                      </Grid>
                      {props.isCompany && (
                        <Grid item xs={12} lg={6}>
                          <FormControl>
                            <InputLabel>Citation columns to display</InputLabel>
                            <Select
                              id="citation-mutiple-checkbox"
                              multiple
                              value={selectedCitationColumns}
                              onChange={onChangeSelectedCitationColumns}
                              input={<Input />}
                              renderValue={onRenderColumnValues}
                              style={{
                                width: 250,
                              }}
                            >
                              {citationColumns.map((option: any) => (
                                <MenuItem key={option.pk} value={option}>
                                  <Checkbox
                                    checked={isCitationColumnSelected(option)}
                                  />
                                  <ListItemText primary={option.displayName} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                    <FormGroup>
                      <FormControlLabel
                        label="Send POV Notifications"
                        control={
                          <Checkbox
                            name="POV"
                            checked={sendPovNotifications}
                            onChange={(event) => {
                              setSendPovNotifications(event.target.checked);
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        disabled={!sendPovNotifications}
                        className="adminSettings-checkbox-subradio"
                        onChange={(e) => handlePovNotificationSchedule(1)}
                        value=""
                        control={
                          <Radio
                            checked={
                              povNotificationSchedule === 1 &&
                              sendPovNotifications
                                ? true
                                : false
                            }
                          />
                        }
                        label="Daily"
                      />
                      <FormControlLabel
                        disabled={!sendPovNotifications}
                        className="adminSettings-checkbox-subradio"
                        onChange={(e) => handlePovNotificationSchedule(2)}
                        value=""
                        control={
                          <Radio
                            checked={
                              povNotificationSchedule === 2 &&
                              sendPovNotifications
                                ? true
                                : false
                            }
                          />
                        }
                        label="Weekly"
                      />
                      {props.isCompany && (
                        <>
                          <Grid container spacing={3}>
                            <Grid item xs={12} lg={6}>
                              <InputLabel htmlFor="my-input">
                                <br></br>
                              </InputLabel>
                              <FormControl>
                                <FormControlLabel
                                  label="Inspection Notifications"
                                  control={
                                    <Checkbox
                                      name="inspection-notifications"
                                      checked={sendInspectionNotifications}
                                      onChange={(event) => {
                                        setSendInspectionNotifications(
                                          event.target.checked
                                        );
                                      }}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  disabled={!sendInspectionNotifications}
                                  className="adminSettings-checkbox-subradio"
                                  onChange={(e) =>
                                    setInspectionNotificationSchedule(1)
                                  }
                                  value=""
                                  control={
                                    <Radio
                                      checked={
                                        inspectionNotificationSchedule === 1 &&
                                        sendInspectionNotifications
                                          ? true
                                          : false
                                      }
                                    />
                                  }
                                  label="Daily"
                                />
                                <FormControlLabel
                                  disabled={!sendInspectionNotifications}
                                  className="adminSettings-checkbox-subradio"
                                  onChange={(e) =>
                                    setInspectionNotificationSchedule(2)
                                  }
                                  value=""
                                  control={
                                    <Radio
                                      checked={
                                        inspectionNotificationSchedule === 2 &&
                                        sendInspectionNotifications
                                          ? true
                                          : false
                                      }
                                    />
                                  }
                                  label="Weekly"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <FormControl>
                                <InputLabel>
                                  Inspection columns to display
                                </InputLabel>
                                <Select
                                  id="citation-mutiple-checkbox"
                                  multiple
                                  value={selectedInspectionColumns}
                                  onChange={onChangeSelectedInspectionColumns}
                                  input={<Input />}
                                  renderValue={onRenderColumnValues}
                                  style={{
                                    width: 250,
                                  }}
                                >
                                  {inspectionColumns.map((option: any) => (
                                    <MenuItem key={option.pk} value={option}>
                                      <Checkbox
                                        checked={isInspectionColumnSelected(
                                          option
                                        )}
                                      />
                                      <ListItemText
                                        primary={option.displayName}
                                      />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>

                          <FormControlLabel
                            label="Event Notifications"
                            control={
                              <Checkbox
                                name="event-notifications"
                                checked={sendEventNotifications}
                                onChange={(event) => {
                                  setSendEventNotifications(
                                    event.target.checked
                                  );
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            disabled={!sendEventNotifications}
                            className="adminSettings-checkbox-subradio"
                            onChange={(e) => setEventNotificationSchedule(1)}
                            value=""
                            control={
                              <Radio
                                checked={
                                  eventNotificationSchedule === 1 &&
                                  sendEventNotifications
                                    ? true
                                    : false
                                }
                              />
                            }
                            label="Daily"
                          />
                          <FormControlLabel
                            disabled={!sendEventNotifications}
                            className="adminSettings-checkbox-subradio"
                            onChange={(e) => setEventNotificationSchedule(2)}
                            value=""
                            control={
                              <Radio
                                checked={
                                  eventNotificationSchedule === 2 &&
                                  sendEventNotifications
                                    ? true
                                    : false
                                }
                              />
                            }
                            label="Weekly"
                          />
                        </>
                      )}
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant={"outlined"}
              autoFocus
              onClick={() => submitForm()}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

export default EditAdminEmailSettingsModal;
