import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  InputLabel,
  DialogContent,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Loading from "../shared/Loading";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { createMine, updateMine } from "../../store/actions/mineActions";
import { useSnackbar } from "notistack";

const MineModal: React.FC<any> = (props) => {
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [processsing, setProcesssing] = useState(false);

  const [mineName, setMineName] = useState("");
  const [mineId, setMineId] = useState("");
  const [annualUnits, setAnnualUnits] = useState("");

  const [currentMine, setCurrentMine] = useState({});
  const [errors, setErrors] = useState({} as any);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { companypk } = useParams() as any;

  useEffect(() => {
    setCurrentMine(props.data);
    if (props.data.mineId) setIsUpdateMode(true);

    setMineName(props.data?.mineName);
    setMineId(props.data?.mineId);
    setAnnualUnits(props.data?.annualUnits);
  }, [props.data]);

  const onCancelAction = () => {
    props.onClose();
    props.onReset();
  };

  const mapModalObject = () => {
    return {
      ...currentMine,
      companyFk: companypk,
      mineName: mineName,
      mineId: mineId,
      annualUnits: annualUnits,
    };
  };

  const onSubmitAction = () => {
    if (!formIsValid()) return;

    setProcesssing(true);

    dispatch(
      createMine(
        mapModalObject(),
        (primaryKey: number) => {
          setProcesssing(false);
          enqueueSnackbar("Mine was created.", { variant: "success" });
          props.onClose();
          props.onReset();
        },
        (message: string) => {
          setProcesssing(false);
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      )
    );
  };

  function formIsValid() {
    const _errors: any = {};
    if (!mineName) _errors.mineName = "Mine name is required";
    if (!mineId) _errors.mineId = "Mine ID is required";
    if (isUpdateMode) {
      if (!annualUnits) _errors.annualUnits = "Annual Units is required";
    }
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  const onUpdateAction = () => {
    if (!formIsValid()) return;

    setProcesssing(true);

    dispatch(
      updateMine(
        mapModalObject(),
        () => {
          setProcesssing(false);
          enqueueSnackbar("Mine was saved.", { variant: "success" });
          props.onClose();
          props.onReset();
        },
        (message: string) => {
          setProcesssing(false);
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      )
    );
  };

  return (
    <>
      <Loading loading={processsing} />
      <Dialog
        onClose={() => props.onClose()}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <MuiDialogTitle>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 3, marginTop: 10 }}>
              <Typography variant="h6">
                {!isUpdateMode ? "Add Mine" : "Edit Mine"}
              </Typography>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <IconButton aria-label="close" onClick={() => props.onClose()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </MuiDialogTitle>
        <DialogContent dividers>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <div className={errors?.mineName ? "error " : ""}>
                  <InputLabel
                    htmlFor="mineName"
                    style={{ display: "inline-block" }}
                  >
                    Mine Name:
                  </InputLabel>
                  <em>{errors?.mineName}</em>
                  <TextField
                    id="mineName"
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={mineName}
                    onChange={(event) => setMineName(event?.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className={errors?.mineId ? "error " : ""}>
                  <InputLabel
                    htmlFor="mineId"
                    style={{ display: "inline-block" }}
                  >
                    Mine ID:
                  </InputLabel>
                  <em>{errors?.mineId}</em>
                  <TextField
                    id="mineId"
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={mineId}
                    onChange={(event) => setMineId(event?.target.value)}
                  />
                </div>
              </Grid>
              {isUpdateMode && (
                <Grid item xs={12} md={6} lg={6}>
                  <div className={errors?.annualUnits ? "error " : ""}>
                    <InputLabel
                      htmlFor="annualUnits"
                      style={{ display: "inline-block" }}
                    >
                      Annual Units:
                    </InputLabel>
                    <em>{errors?.annualUnits}</em>
                    <TextField
                      id="annualUnits"
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={annualUnits}
                      onChange={(event) => setAnnualUnits(event?.target.value)}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            autoFocus
            onClick={() => onCancelAction()}
            color="secondary"
          >
            Cancel
          </Button>
          {isUpdateMode && (
            <Button
              variant={"outlined"}
              autoFocus
              onClick={() => onUpdateAction()}
              color="primary"
            >
              Update
            </Button>
          )}
          {!isUpdateMode && (
            <Button
              variant={"outlined"}
              autoFocus
              onClick={() => onSubmitAction()}
              color="primary"
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MineModal;
