import { call, put } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { HttpResponse } from '../../interfaces/api/HttpResponse';
import { IDispatchAction } from '../../interfaces/store';
import HttpClient from '../../lib/HttpClient';
import GetFriendlyError from '../../utils/general';
import { schema } from 'normalizr';
const client = new HttpClient();
const accountLoginSchema = new schema.Entity('account_login', {}, { idAttribute: 'pk' });

async function loginAPI(
  data: any,
): Promise<HttpResponse<{x: string}>> {
  return client.post('api/auth/login', data);
}

async function forgotPasswordAPI(
  data: any,
): Promise<HttpResponse<{x: string}>> {
  return client.post('api/auth/forgot_password', data);
}

async function resetPasswordAPI(
  data: any,
): Promise<HttpResponse<{x: string}>> {
  return client.post('api/auth/reset_password', data);
}

const authSaga = {

  * forgotPassword(action: IDispatchAction): Generator {
  try {
    const payload = action.payload as any;
    const response = (yield call(forgotPasswordAPI, payload)) as HttpResponse<any>;
    console.log('forgot password response', response);
    if (response.status === 200 || response.status === 201) {
      if (action.onSuccess) {
        action.onSuccess();
      }
      yield put({
        type: 'FORGOT_PASSWORD_SUCCESS',
        data: response.data
      })
    }
  } catch (e) {
      if (action.onFail) {
          action.onFail();
        }
    const error = e as Error;
    const friendlyError = GetFriendlyError(error.message);
    yield put({ type: ACTIONS.ERROR, message: friendlyError });
  }
},

* resetPassword(action: IDispatchAction): Generator {
  try {
    const payload = action.payload as any;
    const response = (yield call(resetPasswordAPI, payload)) as HttpResponse<any>;
    console.log('reset password response', response);
    console.log('response?.data?.success', response?.data?.success);
    if ((response.status === 200 || response.status === 201) && response?.data?.success === true) {
      yield put({
        type: 'RESET_PASSWORD_SUCCESS',
        data: response.data
      })
      if (action.onSuccess) {
        action.onSuccess();
      }
    }
    else{
      console.log("throwing error?");
      throw Error("something went wrong")
    }
  } catch (e) {
      if (action.onFail) {
          action.onFail();
        }
    const error = e as Error;
    const friendlyError = GetFriendlyError(error.message);
    yield put({ type: ACTIONS.ERROR, message: friendlyError });
  }
},

    * login(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(loginAPI, payload)) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess();
        }
        yield put({
          type: 'LOGIN_SUCCESS',
          data: response.data
        })
      }
    } catch (e) {
        if (action.onFail) {
            action.onFail();
          }
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  * logout(action: IDispatchAction): Generator {
  try {
      yield put({
        type: 'LOGOUT_SUCCESS',
        data: {}
      })
    
  } catch (e) {
      if (action.onFail) {
          action.onFail();
        }
    const error = e as Error;
    const friendlyError = GetFriendlyError(error.message);
    yield put({ type: ACTIONS.ERROR, message: friendlyError });
  }
},
};

export default authSaga;
