import { ACTIONS } from "../../enums/actions";

export const getCompanyEmailSettings = (
  payload: any,
  onSuccess?: (data: any) => void,
  onFail?: (data: any) => void
) => ({
  type: ACTIONS.GET_COMPANY_EMAIL_SETTING,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getAllCompanyEmailSettings = (
  payload: any,
  onSuccess?: (data: any) => void,
  onFail?: (data: any) => void
) => ({
  type: ACTIONS.GET_ALL_COMPANY_EMAIL_SETTING,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const createCompanyEmailSettings = (
  payload: any,
  onSuccess?: Function,
  onFail: Function | null = null
) => ({
  type: ACTIONS.CREATE_COMPANY_EMAIL_SETTING,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const updateCompanyEmailSettings = (
  payload: any,
  onSuccess?: Function,
  onFail: Function | null = null
) => ({
  type: ACTIONS.UPDATE_COMPANY_EMAIL_SETTING,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const deleteCompanyEmailSettings = (
  payload: any,
  onSuccess?: Function,
  onFail?: Function
) => ({
  type: ACTIONS.DELETE_COMPANY_EMAIL_SETTING,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});
