import { call, put } from "redux-saga/effects";
import { ACTIONS } from "../../enums/actions";
import { HttpResponse } from "../../interfaces/api/HttpResponse";
import { IDispatchAction } from "../../interfaces/store";
import HttpClient, { objectToQuery } from "../../lib/HttpClient";
import GetFriendlyError from "../../utils/general";
import { normalize, schema } from "normalizr";
const client = new HttpClient();
const inspectionSchema = new schema.Entity(
  "inspections",
  {},
  { idAttribute: "pk" }
);
const flatFileInspectionSchema = new schema.Entity(
  "inspections",
  {},
  { idAttribute: "eventNo" }
);

async function getInspectorARListAPI(
  data: any
): Promise<HttpResponse<{ x: any }>> {
  return client.get("api/inspection/get-inspector-list", data);
}

async function getInspectorTrendsAPI(
  data: any
): Promise<HttpResponse<{ x: any }>> {
  console.log("call api", `api/inspection/get-inspector-trends?inspectorar=${data.inspectorar}&minepk=${data.minepk}&contractorpk=${data.contractorpk}`);
  return client.get(`api/inspection/get-inspector-trends?inspectorar=${data.inspectorar}&minepk=${data.minepk}&contractorpk=${data.contractorpk}`, data);
}

async function getInspectionByMineAPI(
  data: any
): Promise<HttpResponse<{ x: any }>> {
  return client.get("api/inspection/get-by-mine?minepk=" + data.minepk, data);
}

async function addInspectionAPI(data: any): Promise<HttpResponse<{ x: any }>> {
  return client.post("api/inspection/create", data);
}

async function updateInspectionAPI(data: any): Promise<HttpResponse<{ x: any }>> {
  return client.post("api/inspection/update", data);
}

async function deleteInspectionAPI(data: any): Promise<HttpResponse<{ x: any }>> {
  return client.post("api/inspection/delete", data);
}

async function getInspectionByEventNumberAPI(
  data: any
): Promise<HttpResponse<{ x: any }>> {
  return client.get(
    "api/inspection/get-by-event-number" + objectToQuery(data),
    data
  );
}

async function getInspectionAPI(data: any): Promise<HttpResponse<{ x: any }>> {
  return client.get("api/inspection/get?pk=" + data.pk, data);
}

const inspectionSaga = {
  *getInspectionByEventNumber(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        getInspectionByEventNumberAPI,
        action.payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        yield put({
          type: "GET_INSPECTION_BY_EVENT_NUMBER_SUCCESS",
          normalized: normalize(response.data, flatFileInspectionSchema),
          stateKey: "flatfile",
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *addInspection(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        addInspectionAPI,
        action.payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        yield put({
          type: "ADD_INSPECTIONS_SUCCESS",
          normalized: normalize(response.data, inspectionSchema)
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *updateInspection(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        updateInspectionAPI,
        action.payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        yield put({
          type: "UPDATE_INSPECTIONS_SUCCESS",
          normalized: normalize(response.data, inspectionSchema)
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *deleteInspection(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        deleteInspectionAPI,
        action.payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        let removeKeys = [];
        let payload = action.payload as any;
        removeKeys.push({
          entities: "inspections",
          ids: Array.of(payload.pk)
        });
        yield put({
          type: "DELETE_INSPECTIONS_SUCCESS",
          removeKeys: removeKeys,
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getInspectorARList(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        getInspectorARListAPI,
        action.payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
        // yield put({
        //   type: "GET_INSPECTION_SUCCESS",
        //   normalized: normalize(response.data, inspectionSchema),
        // });
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getInspectorTrends(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        getInspectorTrendsAPI,
        action.payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
        // yield put({
        //   type: "GET_INSPECTION_SUCCESS",
        //   normalized: normalize(response.data, inspectionSchema),
        // });
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getInspection(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        getInspectionAPI,
        action.payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess();
        }
        yield put({
          type: "GET_INSPECTION_SUCCESS",
          normalized: normalize(response.data, inspectionSchema),
        });
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getInspectionsByMine(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        getInspectionByMineAPI,
        action.payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        console.log("put inspectionsByMine")
        yield put({
          type: "GET_INSPECTION_BY_MINE_SUCCESS",
          overrides: [
            {
              key: "inspections",
              value: normalize(response.data, [inspectionSchema]).entities
                .inspections,
            },
          ],
        });
        console.log("after put inspectionsByMine")
        if (action.onSuccess) {
          action.onSuccess();
        }
  
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
};

export default inspectionSaga;
