import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { IRootState } from "../../interfaces/store";
import { getCompany } from "../../store/actions/companyActions";
import { ICompany } from "../../interfaces/model/MSHAPCT/company.interface";
import { useParams } from "react-router-dom";
import { TOTAL_TOP_BAR_HEIGHT } from "../../enums/layout-enums";

interface ICompanyOverview {}

const CompanyOverview: React.FC<ICompanyOverview> = (props) => {
  const dispatch = useDispatch();
  const { companypk } = useParams<{ companypk: string }>();

  const [company, setCompany] = useState(null as ICompany | null);

  const companies = useSelector((state: IRootState) => state.main.companies);

  useEffect(() => {
    dispatch(getCompany({ companypk }));
  }, []);

  useEffect(() => {
    if (companies && companies[companypk]) {
      setCompany(companies[companypk]);
    }
  }, [companies]);

  if (!company) {
    return null;
  }

  return (
    <div style={{minHeight: window.innerHeight - TOTAL_TOP_BAR_HEIGHT}}>
      <h2>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Company Profile</div>
          {/* <div>
            <Tooltip title="Delete">
              <IconButton aria-label="delete">
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton aria-label="edit">
                <Edit />
              </IconButton>
            </Tooltip>
          </div> */}
        </div>
      </h2>
      <hr />
      <b>Company Name:</b> {company.companyName} <br />
      <br />
      <b>Publicaly Traded:</b>{" "}
      {company.isPubliclyTraded ? <span>Yes</span> : <span>No</span>} <br />
      <br />
      <b>Is Mine State Allowed:</b>{" "}
      {company.isMineStateAllowed ? <span>Yes</span> : <span>No</span>} <br />
      <br />
      <b>Contractor Company:</b> Unknown <br />
      <br />
      <b>Always Override MSHA Import:</b>{" "}
      {company.isPubliclyTraded ? <span>Yes</span> : <span>No</span>} <br />
      <br />
      <b>Enable Email Notification:</b>{" "}
      {company.isPubliclyTraded ? <span>Yes</span> : <span>No</span>} <br />
      <br />
      <div>
        <b>Contractor Name:</b>{" "}
        {company.isPubliclyTraded ? <span>Yes</span> : <span>No</span>} <br />
        <br />
        <b>Contractor ID:</b>{" "}
        {company.isPubliclyTraded ? <span>Yes</span> : <span>No</span>} <br />
        <br />
      </div>
    </div>
  );
};

export default CompanyOverview;
