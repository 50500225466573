import { OutlinedTextFieldProps, TextField } from '@material-ui/core';
import React from 'react';

interface ITextFieldWrap extends OutlinedTextFieldProps {
    mode: "edit" | "view"
}

const TextFieldWrap: React.FC<ITextFieldWrap> = (props) => {

    if (props.mode === "edit") {
        return (
            <TextField
                {...props} />
        )
    }
    else if (props.mode === "view"){
        return (
            <div style={{fontSize: 16}}>{props.value as string}</div>
        )
    }
    return null
}

export default TextFieldWrap