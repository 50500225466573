export const CitationMgmtCustomFieldIds = [
    'CustomDate1',
    'CustomDate2',
    'CustomDate3',
    'CustomDate4',
    'CustomDate5',
    'CustomDate6',
    'CustomDecimalNumber1',
    'CustomDecimalNumber2',
    'CustomDecimalNumber3',
    'CustomDecimalNumber4',
    'CustomDecimalNumber5',
    'CustomDecimalNumber6',
    'CustomLongText1',
    'CustomLongText2',
    'CustomLongText3',
    'CustomLongText4',
    'CustomLongText5',
    'CustomLongText6',
    'CustomMoney1',
    'CustomMoney2',
    'CustomMoney3',
    'CustomMoney4',
    'CustomMoney5',
    'CustomMoney6',
    'CustomText1',
    'CustomText2',
    'CustomText3',
    'CustomText4',
    'CustomText5',
    'CustomText6',
    'CustomWholeNumber1',
    'CustomWholeNumber2',
    'CustomWholeNumber3',
    'CustomWholeNumber4',
    'CustomWholeNumber5',
    'CustomWholeNumber6',
]

export const validPasswordRegEx = new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\\S+$).{6,}$");

export const ENABLE_CITATION_TABLE_SUMMARY = false