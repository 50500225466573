import { call, put } from "redux-saga/effects";
import { ACTIONS } from "../../enums/actions";
import { HttpResponse } from "../../interfaces/api/HttpResponse";
import { IDispatchAction } from "../../interfaces/store";
import HttpClient from "../../lib/HttpClient";
import GetFriendlyError from "../../utils/general";
import { normalize, schema } from "normalizr";
const client = new HttpClient();
const contractorSchema = new schema.Entity("contractors", {}, { idAttribute: "pk" });


async function getContractorsAPI(data: {
    companypk: number;
}): Promise<HttpResponse<{ x: string }>> {
    return client.get("api/contractor/get");
}

const contractorSaga = {

    *getContractors(action: IDispatchAction): Generator {
        try {
            const payload = action.payload as any;
            const response = (yield call(
                getContractorsAPI,
                payload
            )) as HttpResponse<any>;
            if (response.status === 200 || response.status === 201) {
                console.log("normalized", normalize(response.data, [contractorSchema]));
                yield put({
                    type: "GET_CONTRACTORS_SUCCESS",
                    normalized: normalize(response.data, [contractorSchema]),
                });
                if (action.onSuccess) {
                    action.onSuccess();
                }
            }
        } catch (e) {
            const error = e as Error;
            const friendlyError = GetFriendlyError(error.message);
            yield put({ type: ACTIONS.ERROR, message: friendlyError });
        }
    },
}

export default contractorSaga;