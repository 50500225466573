import React from "react";
import { Container } from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";
interface IDisclaimerProps {}

const Disclaimer: React.FC<IDisclaimerProps> = () => {
  return (
    <Container
      style={{ minHeight: "100vh", backgroundColor: COLORS.WHITE_PAPER }}
    >
      <h1>Disclaimer</h1>
      <section>
        <i>
          * Remember, we are not MSHA and cannot guarantee that our penalty
          calculations will always exactly reflect the assessment arrived at by
          them. Although the system is set up to alert you to any differences
          between the predicted penalty and the actual penalty, you are
          ultimately responsible for checking and verifying all data that you
          enter and making sure that you are using the most accurate data
          regarding the penalty. Predictive Compliance is just a planning tool
          and cannot be responsible for the results of any course of action
          resulting from the use of its data.
        </i>
      </section>
    </Container>
  );
};

export default Disclaimer;
