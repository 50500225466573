import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch, useParams } from "react-router-dom";
import { setClientOptions } from "../../store/actions/clientActions";
import CitationRoute from "../citations/CitationRoute";
import MineCitations from "./MineCitations";
import MineInspections from "./MineInspections";
import { IRootState } from "../../interfaces/store";
import { IMine } from "../../interfaces/model/MSHAPCT/mine.interface";
import { getMine, getMineRegulationWarning } from "../../store/actions/mineActions";
import { Button } from "@material-ui/core";
import UploadCitationModal from "../citations/UploadCitationModal";
import ScanResults from "../citations/ScanResults";
import PatternOfViolations from "../analytics/PatternOfViolations";
import InspectorTrends from "../analytics/InspectorTrends";
import { TOTAL_TOP_BAR_HEIGHT } from "../../enums/layout-enums";
import { sectionListFromMineTypes } from "../../enums/citation-enums";

interface IMineOverviewRoute {
  AppBreakpoint: any;
}

const MineOverviewRoute: React.FC<IMineOverviewRoute> = (props) => {
  const dispatch = useDispatch();

  const { minepk } = useParams<{ minepk: string }>();
  let { path, url } = useRouteMatch();
  const [mine, setMine] = useState(null as IMine | null);
  const [uploadCitationModalOpen, setUploadCitationModalOpen] = useState(false);

  const mines = useSelector((state: IRootState) => state.main.mines);
  const numCitationPerRegulationForMine = useSelector((state: IRootState) => state.main.mine_context.regulationWarning);
  const [regulationWarning, setRegulationWarning] = useState("")

  const selected_mine = useSelector(
    (state: IRootState) => state.main.client_options.selected_mine
  );

  useEffect(() => {
    dispatch(setClientOptions({ show_citation_nav: true }));
    dispatch(getMine({ pk: Number(minepk) }));
	dispatch(getMineRegulationWarning({ pk: Number(minepk) }, (warningResponse: string) => {
		setRegulationWarning(warningResponse)
	}));
    return () => {
      dispatch(setClientOptions({ show_citation_nav: false }));
    };
  }, []);

  useEffect(() => {
    if (mines && mines[minepk]) {
      setMine(mines[minepk]);
      if (selected_mine?.pk !== Number(minepk)) {
        dispatch(setClientOptions({ selected_mine: mines[minepk] }));
      }
    }
  }, [mines]);

  const renderMineName = () => {
    return <h2 style={{ marginTop: 0 }}>{mine ? mine.mineName : ""}</h2>;
  };

  return (
    <>
      <Switch>
        <Route path={`${path}/CitationScanResults`}>
          <ScanResults />
        </Route>
        <Route path={`${path}/Citations/:citationpk`}>
          <div style={{ width: "100%", minHeight: window.innerHeight - TOTAL_TOP_BAR_HEIGHT }}>
            {renderMineName()}
            <CitationRoute />
          </div>
        </Route>
        <Route path={`${path}/Citations`}>
          <div style={{ width: "100%", minHeight: window.innerHeight - TOTAL_TOP_BAR_HEIGHT }}>
            <UploadCitationModal
              open={uploadCitationModalOpen}
              onClose={() => setUploadCitationModalOpen(false)}
			  mineName={mine?.mineName || "mine"}
            />
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ flex: 1 }}>{renderMineName()}</div>
              <div style={{ flex: 1, textAlign: "right" }}>
                <Button
                  onClick={() => {
                    setUploadCitationModalOpen(true);
                  }}
                  variant="outlined"
                  size="small"
                >
                  Upload Citations
                </Button>
              </div>
            </div>
            {regulationWarning != '' ? <div style={{color: 'red'}}>
              {regulationWarning}
            </div> : null}
            <MineCitations />
          </div>
        </Route>
        <Route path={`${path}/Contractor/:contractorpk`}>
          <div style={{ width: "100%", minHeight: window.innerHeight - TOTAL_TOP_BAR_HEIGHT }}>
            <UploadCitationModal
              open={uploadCitationModalOpen}
              onClose={() => setUploadCitationModalOpen(false)}
			  mineName={mine?.mineName || "mine"}
            />
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ flex: 1 }}>{renderMineName()}</div>
              <div style={{ flex: 1, textAlign: "right" }}>
                <Button
                  onClick={() => {
                    setUploadCitationModalOpen(true);
                  }}
                  variant="outlined"
                  size="small"
                >
                  Upload Citation
                </Button>
              </div>
            </div>
            <MineCitations />
          </div>
        </Route>
        <Route path={`${path}/Inspections`}>
          <div style={{ minHeight: window.innerHeight - TOTAL_TOP_BAR_HEIGHT }}>
            {renderMineName()}
            <MineInspections />
          </div>
        </Route>
        <Route path={`${path}/PatternOfViolations`}>
          <div style={{ minHeight: window.innerHeight - TOTAL_TOP_BAR_HEIGHT }}>
            {renderMineName()}
            <PatternOfViolations />
          </div>
        </Route>
        <Route path={`${path}/InspectorTrends`}>
          <div style={{ minHeight: window.innerHeight - TOTAL_TOP_BAR_HEIGHT }}>
            {renderMineName()}
            <InspectorTrends />
          </div>
        </Route>
        <Route path={`${path}/Analytics`}>
          <div style={{ minHeight: window.innerHeight - TOTAL_TOP_BAR_HEIGHT }}></div>
        </Route>
      </Switch>
    </>
  );
};

export default MineOverviewRoute;
