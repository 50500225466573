import jwtDecode from "jwt-decode";

export interface Token {
  name: string;
  exp: number;
  CompanyId?: string;
  ContractorId?: string;
  ContractorName?: string;
  role: any;
  IsSysAdmin?: boolean;
}

export const jwtToken = () => {
  const tk = `${localStorage.getItem("jwt")}`;
  if (tk == "null") return null;
  const convertedToken = jwtDecode<Token>(tk);
  if (typeof convertedToken?.role?.filter !== "undefined") {
    const adminRole = convertedToken.role?.filter(
      (c: string) => c == "SYSTEM_ADMIN"
    );
    convertedToken.IsSysAdmin = !!adminRole;
  } else {
    convertedToken.IsSysAdmin = convertedToken.role == "SYSTEM_ADMIN";
  }
  return convertedToken;
};
