import React, { useEffect } from "react";
import * as ChartJs from "chart.js";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { ILineChartDataInterface } from "../../interfaces/stored_procs/chart-interfaces";
// @ts-ignore
ChartJs.Chart.register.apply(null,Object.values(ChartJs).filter((chartClass) => chartClass.id));

interface ILineGraph {
  chartData: ILineChartDataInterface;
  uuid: string;
  title?: string;
  height?: number;
  minYValue?: number;
  maxYValue?: number;
  stepsY?: number;
  scales?: any;
}

const LineGraph: React.FC<ILineGraph> = (props) => {
  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();
  useEffect(() => {
    const ctx = document.getElementById(props.uuid);
    // @ts-ignore
    const chartInstance = new ChartJs.Chart(ctx, {
      type: "line",
      data: {
        labels: props.chartData.labels,
        datasets: props.chartData.datasets,
      },
      options: {
        plugins: {
          title: {
            display: props.title ? true : false,
            text: props.title,
          },
        },
        scales: props.scales || {
            y: {
                max: props.maxYValue || 25,
                min: props.minYValue || 0,
                ticks: {
                    stepSize: props.stepsY || 0.5
                }
            }
        }
      },
    });

    return () => {
      chartInstance.destroy();
    }
  }, [props]);

  return (
    <div className="App">
      <canvas id={props.uuid} width="300" height={props.height || 100} />
    </div>
  );
};

export default LineGraph;
