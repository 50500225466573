import { schema } from "normalizr";

export const accountLogin_schema = new schema.Entity(
  "account_logins",
  {},
  { idAttribute: "pk" }
);
export const adminEmailSettings_schema = new schema.Entity(
  "admin_email_settings",
  {},
  { idAttribute: "pk" }
);
export const companyAccount_schema = new schema.Entity(
  "company_accounts",
  {},
  { idAttribute: "pk" }
);
