import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { MAIN_TAB } from "../../interfaces/store/main";
import { setClientOptions } from "../../store/actions/clientActions";
import MineDashboard from "./MineDashboard";
import MineOverviewRoute from "./MineOverviewRoute";
import MineSummary from "./MineSummary";

interface IMineRoute {
  AppBreakpoint: any;
}

const MineRoute: React.FC<IMineRoute> = (props) => {
  const dispatch = useDispatch();

  let { path, url } = useRouteMatch();
  useEffect(() => {
    dispatch(setClientOptions({ selected_tab: MAIN_TAB.MINES_CONTRACTORS }));
  }, []);

  return (
    <Switch>
      <Route exact path={path}>
        <MineDashboard AppBreakpoint={props.AppBreakpoint} />
      </Route>
      <Route path={`${path}/company/summary/:companyid`}>
        <MineSummary />
      </Route>
      <Route path={`${path}/:minepk`}>
        <MineOverviewRoute AppBreakpoint={props.AppBreakpoint} />
      </Route>
    </Switch>
  );
};

export default MineRoute;
