import React from "react";
import { Container, Grid } from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";
import BuildRoundedIcon from "@material-ui/icons/BuildRounded";
interface IOurServicesProps {}

const OurServices: React.FC<IOurServicesProps> = () => {
  return (
    <Container
      style={{ minHeight: "100vh", backgroundColor: COLORS.WHITE_PAPER }}
    >
      <h1>Our Services</h1>
      <section>
        <Grid>
          <Grid container alignItems="center">
            <BuildRoundedIcon />{" "}
            <h3 style={{ paddingLeft: "15px" }}>Instant Penalty Calculation</h3>
          </Grid>
          <ul>
            <li>
              Upload a new citation using your own scanner and we instantly
              calculate what the <i>likely penalty will be.</i>
            </li>
            <li>
              We can also tell you how the new citation may affect your
              penalties for future citations (VPID and RPID) as well as the
              possibility that you could be facing additional evaluation for
              Pattern of Violations consideration.
            </li>
          </ul>
        </Grid>
        <Grid>
          <Grid container alignItems="center">
            <BuildRoundedIcon />
            <h3 style={{ paddingLeft: "15px" }}>
              Dispute Impact Analysis/Conference Planning
            </h3>
          </Grid>
          <ul>
            <li>
              Our proprietary analytical program will enable you to instantly
              determine the possible impact of contesting each of the criteria
              on the citation -- including likelihood, severity, negligence and
              number of persons affected. It will immediately show you how much
              money a successful contest can save you (or what an upward
              modification will cost you!).
            </li>
          </ul>
        </Grid>
        <Grid>
          <Grid container alignItems="center">
            <BuildRoundedIcon />{" "}
            <h3 style={{ paddingLeft: "15px" }}>Citation Management</h3>
          </Grid>
          <ul>
            <li>
              Create your own reports. You can transfer all of your citation
              data to a single page along with notes, pictures and any other
              data you may need for the future. For instance, you can sort by
              cost center, by individual supervisor or by inspector. You can
              even use the data to quantify the impact of compliance costs on
              production. You can send the integrated data, in the format of
              your choice, to your corporate management, consultants and counsel
              without the need for them to create their own separate
              databases--saving time and money.
            </li>
            <li>
              All of your citation data can be managed from one central
              location. Multiple mine operations will be able to compare
              mine-to-mine data as well as plan and coordinate all needed
              activities with counsel and consultants with little or need for
              additional report preparation.
            </li>
          </ul>
        </Grid>
      </section>
    </Container>
  );
};

export default OurServices;
