import { put } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store';
import GetFriendlyError from '../../utils/general';

const clientSaga = {

    * setClientOptions(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
        yield put({
          type: 'SET_CLIENT_OPTIONS_SUCCESS',
          payload: action.payload
        })
        if (action.onSuccess){
          action.onSuccess()
        }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
      if (action.onFail){
        action.onFail()
      }
    }
  },

};

export default clientSaga;
