import { Button, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import MaterialTable from "material-table";
import { tableIcons } from "../shared/MaterialTableShared";
import EditIcon from "@material-ui/icons/Edit";
import { IAdminEmailSettings } from "../../interfaces/model/MSHAPCT/admin-email-settings.interface";
import EditAdminEmailSettingsModal from "./EditAdminEmailSettingsModal";
import { useDispatch } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogBox from "../form/DialogBox";
import { useSnackbar } from "notistack";

import {
  updateAdminEmailSetting,
  createAdminEmailSettings,
  deleteAdminEmailSettings,
} from "../../store/actions/accountActions";

interface IAdminEmailSettingsTable {
  data: IAdminEmailSettings[];
  title?: string;
}

const AdminEmailSettingsTable: React.FC<IAdminEmailSettingsTable> = (props) => {
  const dispatch = useDispatch();

  const [editEmailSettingsModalOpen, setEditEmailSettingsModalOpen] =
    useState(false);

  const [selectedRow, setSelectedRow] = useState(0);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [rowData, setRowData] = useState({} as any);
  const { enqueueSnackbar } = useSnackbar();
  const columns = [
    { title: "Group Name", field: "groupName", sorting: true },
    { title: "To Email Address(es)", field: "toEmailAddress", sorting: true },
    { title: "Cc Email Address(es)", field: "ccEmailAddress", sorting: true },
    {
      title: "Actions",
      render: (row: any) => {
        return (
          <div>
            <Tooltip title="Edit" placement="top">
              <EditIcon
                style={{ fontSize: 16 }}
                className="transparent75"
                onClick={() => {
                  if (row && row.pk) {
                    setRowData(row);
                    setEditEmailSettingsModalOpen(true);
                  }
                }}
              />
            </Tooltip>
            <Tooltip title="Delete" placement="top">
              <DeleteIcon
                style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
                className="transparent75"
                onClick={() => {
                  if (row.pk) {
                    setIsDeleteDialogOpen(true);
                    setSelectedRow(row.pk);
                  }
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onUpdateActionModal = (
    data: any,
    onSuccess: Function,
    onFail?: Function
  ) => {
    dispatch(updateAdminEmailSetting(data, onSuccess, onFail));
  };

  const onCreateActionModal = (
    data: any,
    onSuccess: Function,
    onFail?: Function
  ) => {
    dispatch(createAdminEmailSettings({ data: data }, onSuccess, onFail));
  };

  const onSubmitActionModal = (
    data: any,
    onSuccess: Function,
    onFail?: Function
  ) => {
    if (data.pk) {
      onUpdateActionModal(data, onSuccess, onFail);
    } else {
      onCreateActionModal(data, onSuccess, onFail);
    }
  };

  const emptySet = {
    pk: 0,
    companyFK: 0,
    groupName: "",
    toEmailAddress: "",
    ccEmailAddress: "",
    negligenceNone: false,
    negligenceLow: false,
    negligenceModerate: false,
    negligenceHigh: false,
    negligenceRecklessDisregard: false,
    likelihoodNoLikelihood: false,
    likelihoodUnlikely: false,
    likelihoodReasonablyLikely: false,
    likelihoodHighlyLikely: false,
    likelihoodOccured: false,
    injuryOrIllnessNoLostWorkdays: false,
    injuryOrIllnessLostWorkdays: false,
    injuryOrIllnessPermanentlyDisabling: false,
    injuryOrIllnessFatal: false,
    significantAndSubstantial: false,
    minimumNumberOfPersonsAffected: "",
    sendCitationNotifications: false,
    citationNotificationSchedule: 0,
    sendInspectionNotifications: false,
    inspectionNotificationSchedule: 0,
    sendEventNotifications: false,
    eventNotificationSchedule: 0,
    povMediumRisk: false,
    povHighRisk: false,
    sendPovNotifications: false,
    povNotificationSchedule: 0,
    minimumCitationAmount: "",
    selectedCitationColumns: [],
    selectedInspectionColumns: [],
  };

  const onOkDialogAction = () => {
    onDeleteEmailSettingAction(selectedRow);
    setIsDeleteDialogOpen(false);
  };

  const onCancelOrCloseDialogAction = () => {
    setIsDeleteDialogOpen(false);
    setSelectedRow(0);
  };

  const onDeleteEmailSettingAction = (settingId: number) => {
    dispatch(
      deleteAdminEmailSettings(
        settingId,
        onDeleteEmailSettingSuccess,
        onDeleteEmailSettingFail
      )
    );
  };

  const onDeleteEmailSettingSuccess = (wasAdded: boolean) => {
    enqueueSnackbar("Admin Email Setting was successfully deleted.", {
      variant: "success",
    });
  };

  const onDeleteEmailSettingFail = (message: string) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
  };

  return (
    <>
      <DialogBox
        id="dialogbox1"
        open={isDeleteDialogOpen}
        onClose={onCancelOrCloseDialogAction}
        onCancel={onCancelOrCloseDialogAction}
        onOk={onOkDialogAction}
        title="Are you sure you want to delete the email setting?"
        innerText="After deleting the email setting you will not be able to roll it back."
      ></DialogBox>

      <EditAdminEmailSettingsModal
        itemData={rowData}
        open={editEmailSettingsModalOpen}
        onClose={() => setEditEmailSettingsModalOpen(false)}
        title="Admin Email Settings"
        onSubmit={onSubmitActionModal}
        isEditMode={rowData.pk ? true : false}
        isCompany={false}
      />

      <Button
        style={{ marginBottom: 5 }}
        onClick={() => {
          setRowData(emptySet);
          setEditEmailSettingsModalOpen(true);
        }}
        variant="outlined"
        size="small"
      >
        Add Admin Email Settings
      </Button>

      <MaterialTable
        icons={tableIcons}
        title={props.title || "Admin Email Notification Groups"}
        columns={columns}
        data={props.data}
        options={{
          sorting: true,
          headerStyle: {
            zIndex: 8,
          },
          paging: true,
          pageSize: Math.min(props.data.length, 10),
          draggable: false,
        }}
        onRowClick={(event, rowData, togglePanel) => {
          if (rowData) {
          }
        }}
      />
    </>
  );
};

export default AdminEmailSettingsTable;
