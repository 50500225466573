import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Checkbox,
  Grid,
  InputLabel,
  DialogContent,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Loading from "../shared/Loading";
import { useSnackbar } from "notistack";
import { ICitationMetaData } from "../../interfaces/model/MSHAPCT/citation-meta-data.interface";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { NumberFormatCustom } from "../shared/NumberInput";
import { ICitationMgmtCustomField } from "../../interfaces/model/MSHAPCT/citation-mgmt-custom-field.interface";
import { NumberFormatCustomDecimal } from "../shared/NumberInputDecimal";

interface ICitationNoteModalProps {
  citation: any;
  citationMetaData: any;
  open: boolean;
  onClose: Function;
  title: string;
  onSubmit: Function;
  isEditMode: boolean;
  currentCompanyCustomFields: ICitationMgmtCustomField[]
}


const CleanSetNumberString = (data: any, key: any) => {
  if (data?.[key] !== undefined){
    if (typeof data[key] === 'string') {
      data[key] = data[key].replace(/,/g, '');
    }
  }
}

const CitationNoteModal: React.FC<ICitationNoteModalProps> = (props) => {
  const [errors, setErrors] = useState({} as any);
  const { enqueueSnackbar } = useSnackbar();
  const [processsing, setProcesssing] = useState(false);

  const [fileCollection, setFileCollection] = useState<any>(null);
  const [citationMetaData, setCitationMetaData] = useState<ICitationMetaData>();
  const [buttonsTitle, setButtonsTitle] = useState<any>({
    citationImages: "Upload",
    citationDocuments: "Upload",
  });

  useEffect(() => {
    setCitationMetaData(props.citationMetaData);
    setButtonsTitle({
      ...buttonsTitle,
      citationImages: "Upload",
      citationDocuments: "Upload",
    });
  }, [props.citationMetaData]);

  const submitForm = (event: any) => {
    event.preventDefault();
    setProcesssing(true);
    if (!formIsValid()) return;

    const imagesFileCollection = fileCollection?.citationImages;
    const documentsFileCollection = fileCollection?.citationDocuments;
    var formData = new FormData();

    if (imagesFileCollection) {
      for (const key of Object.keys(imagesFileCollection)) {
        formData.append("files", imagesFileCollection[key]);
      }
    }

    if (documentsFileCollection) {
      for (const key of Object.keys(documentsFileCollection)) {
        formData.append("files", documentsFileCollection[key]);
      }
    }

    const keysToClean = ['customWholeNumber1', 'customWholeNumber2', 'customWholeNumber3', 'customWholeNumber4', 'customWholeNumber5', 'customWholeNumber6',
    'customDecimalNumber1', 'customDecimalNumber2', 'customDecimalNumber3', 'customDecimalNumber4', 'customDecimalNumber5', 'customDecimalNumber6',
    'customMoney1', 'customMoney2', 'customMoney3', 'customMoney4', 'customMoney5', 'customMoney6'
  ]
    keysToClean.forEach((key) => {
      CleanSetNumberString(citationMetaData, key)
    })
    const data = JSON.stringify(citationMetaData);




    formData.append("citationMetaData", data);

    props.onSubmit(
      formData,
      () => {
        setProcesssing(false);
        enqueueSnackbar("Changes were saved successfully..", {
          variant: "success",
        });
      },
      (message?: string) => {
        setProcesssing(false);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    );
  };

  function formIsValid() {
    const _errors: any = {};
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function onValueChange(event: any) {
    const updatedCitationMetaData = {
      ...citationMetaData,
      [event.target.name]: event.target.value,
    };
    setCitationMetaData(updatedCitationMetaData);
  }

  // function onValueChangeNumber(event: any) {
  //   console.log("raw", event.target.value);
  //   console.log("WHATS THIS?", event.target.value.replace(/[^0-9.]/g, ''));
  //   const updatedCitationMetaData = {
  //     ...citationMetaData,
  //     [event.target.name]: event.target.value.replace(/[^0-9.]/g, ''),
  //   };
  //   setCitationMetaData(updatedCitationMetaData);
  // }

  // const NumberFormatCustom = (props: any) => {
  //   const { inputRef, onChange, ...other } = props;
  
  //   return (
  //     <NumberFormat
  //       {...other}
  //       getInputRef={inputRef}
  //       onValueChange={(values) => {
  //         onChange({
  //           target: {
  //             name: props.name,
  //             value: values.value,
  //           },
  //         });
  //       }}
  //       thousandSeparator
  //       isNumericString
  //       // prefix="$"
  //     />
  //   );
  // }

  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  function onBooleanValueChange(event: any) {
    const updatedCitationMetaData = {
      ...citationMetaData,
      [event.target.name]: event.target.checked,
    };
    setCitationMetaData(updatedCitationMetaData);
  }

  const onFileChange = (event: any) => {
    const fileCount = Number(event.target.files.length);
    if (fileCount > 1) {
      setButtonsTitle({
        ...buttonsTitle,
        [event.target.name]: `${fileCount} files`,
      });
    } else if (fileCount === 1) {
      setButtonsTitle({
        ...buttonsTitle,
        [event.target.name]: `${event.target.files[0].name}`,
      });
    } else {
      setButtonsTitle({
        ...buttonsTitle,
        [event.target.name]: `Upload`,
      });
    }
    setFileCollection({
      ...fileCollection,
      [event.target.name]: event.target.files,
    });
  };

  const onDateChange = (name: any) => (date: Date | null) => {
    const updatedCitationMetaData = {
      ...citationMetaData,
      [name]: date,
    };
    setCitationMetaData(updatedCitationMetaData);
  };

  const customFieldTextInput = (fieldId: string, multiline: boolean) => {
    const fieldIdUpperCase = fieldId.charAt(0).toUpperCase() + fieldId.slice(1);
    const matchedCustomField = props.currentCompanyCustomFields.find((x) => x.fieldId === fieldIdUpperCase  && x.fieldInUse === true);
    if (matchedCustomField) {
      return (
        <Grid item xs={12} lg={12}>
          <div className={errors[fieldId] ? "error " : ""}>
            <InputLabel htmlFor={fieldId} style={{ display: "inline-block" }}>
              {matchedCustomField?.fieldDisplayName || fieldId}:
            </InputLabel>
            <em>{errors[fieldId]}</em>
            <TextField
              fullWidth
              id={fieldId}
              name={fieldId}
              size="small"
              multiline={multiline}
              variant="outlined"
              onChange={onValueChange}
              // @ts-ignore
              value={citationMetaData?.[fieldId]}
            />
          </div>
        </Grid>
      )
    }
    else {
      return null
    }
  }


  const customNumberInput = (fieldId: string, money: boolean, decimal: boolean) => {
    const fieldIdUpperCase = fieldId.charAt(0).toUpperCase() + fieldId.slice(1);
    const matchedCustomField = props.currentCompanyCustomFields.find((x) => x.fieldId === fieldIdUpperCase  && x.fieldInUse === true);
    if (matchedCustomField) {
      return (
        <Grid item xs={12} lg={12}>
          <div className={errors[fieldId] ? "error " : ""}>
            <InputLabel htmlFor={fieldId} style={{ display: "inline-block" }}>
              {matchedCustomField?.fieldDisplayName || fieldId}:
            </InputLabel>
            <em>{errors[fieldId]}</em>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                       // @ts-ignore
                  value={citationMetaData?.[fieldId]}
                  onBlur={onValueChange}
                  name={fieldId}
                  id={fieldId}
                  InputProps={{
                    inputComponent: decimal ? NumberFormatCustomDecimal : NumberFormatCustom,
                    startAdornment: (
                      money ? <span>$</span> : <span></span>
                    ),
                  }}
                />
          </div>
        </Grid>
      )
    }
    else {
      return null
    }
  }



  const customDateInput = (fieldId: string) => {
    const fieldIdUpperCase = fieldId.charAt(0).toUpperCase() + fieldId.slice(1);
    const matchedCustomField = props.currentCompanyCustomFields.find((x) => x.fieldId === fieldIdUpperCase && x.fieldInUse === true);

    if (matchedCustomField) {
      return (
        <Grid item xs={12} lg={12}>
          <div className={errors[fieldId] ? "error " : ""}>
            <InputLabel htmlFor={fieldId} style={{ display: "inline-block" }}>
              {matchedCustomField?.fieldDisplayName || fieldId}:
            </InputLabel>
            <em>{errors[fieldId]}</em>
            <KeyboardDatePicker
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  size="small"
                  disableToolbar
                  format="MM/dd/yyyy"
                  id={fieldId}
                  name={fieldId}
                  // @ts-ignore
                  value={citationMetaData?.[fieldId]}
                  onChange={onDateChange(fieldId)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
          </div>
        </Grid>
      )
    }
    else {
      return null
    }
  }

  return (
    <form>
      <Loading loading={processsing} />
      <Dialog
        onClose={() => props.onClose()}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
        open={props.open}
        color="primary"
      >
        <MuiDialogTitle>
          <div
            style={{
              padding: 0,
              display: "flex",
            }}
          >
            <div style={{ flex: 1, alignSelf: "center" }}>
              <Typography variant="h6">
                {(props.isEditMode ? "Edit " : "Add ") + props.title}
              </Typography>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <IconButton aria-label="close" onClick={() => props.onClose()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </MuiDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1} justify="center">
            <Grid item xs={12} lg={6}>
              <div className={errors.vacated ? "error " : ""}>
                <InputLabel
                  htmlFor="vacated"
                  style={{ display: "inline-block" }}
                >
                  Vacated:
                </InputLabel>
                <em>{errors.regionName}</em>
                <Checkbox
                  onChange={onBooleanValueChange}
                  checked={citationMetaData?.vacated}
                  name="vacated"
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className={errors.none ? "error " : ""}></div>
            </Grid>

            <Grid item xs={12} lg={6}>
              <div className={errors.mshaProposedPenalty ? "error " : ""}>
                <InputLabel
                  htmlFor="mshaProposedPenalty"
                  style={{ display: "inline-block" }}
                >
                  MSHA Proposed Penalty Amount:
                </InputLabel>
                <em>{errors.mshaProposedPenalty}</em>
                {/* <TextField
                  fullWidth
                  // type="number"
                  id="mshaProposedPenalty"
                  name="mshaProposedPenalty"
                  size="small"
                  variant="outlined"
                  onBlur={onValueChange}
                  value={numberToCurrencyLight(citationMetaData?.mshaProposedPenalty || "")}
                  InputProps={{
                    startAdornment: (
                      <span>$</span>
                    ),
                  }}
                /> */}
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  // label="react-number-format"
                  value={citationMetaData?.mshaProposedPenalty || ""}
                  onBlur={onValueChange}
                  name="mshaProposedPenalty"
                  id="formatted-numberformat-input"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    startAdornment: (
                      <span>$</span>
                    ),
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12} lg={6}>
              <div className={errors.mshaCurrentPenalty ? "error " : ""}>
                <InputLabel
                  htmlFor="mshaCurrentPenalty"
                  style={{ display: "inline-block" }}
                >
                  MSHA Current Penalty Amount:
                </InputLabel>
                <em>{errors.mshaCurrentPenalty}</em>
                <TextField
                  fullWidth
                  id="mshaCurrentPenalty"
                  name="mshaCurrentPenalty"
                  size="small"
                  variant="outlined"
                  onBlur={onValueChange}
                  value={citationMetaData?.mshaCurrentPenalty || ""}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    startAdornment: (
                      <span>$</span>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className={errors.mshaFinalOrderDate ? "error " : ""}>
                <InputLabel
                  htmlFor="mshaFinalOrderDate"
                  style={{ display: "inline-block" }}
                >
                  MSHA Final Order Date:
                </InputLabel>
                <em>{errors.mshaFinalOrderDate}</em>
                <KeyboardDatePicker
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  size="small"
                  disableToolbar
                  format="MM/dd/yyyy"
                  id="mshaFinalOrderDate"
                  name="mshaFinalOrderDate"
                  value={citationMetaData?.mshaFinalOrderDate}
                  onChange={onDateChange("mshaFinalOrderDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                {/* <TextField
                  fullWidth
                  id="mshaFinalOrderDate"
                  name="mshaFinalOrderDate"
                  size="small"
                  variant="outlined"
                  onChange={onValueChange}
                  type="date"
                  value={new Date()}
                /> */}
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className={errors.mshaCaseNumber ? "error " : ""}>
                <InputLabel
                  htmlFor="mshaCaseNumber"
                  style={{ display: "inline-block" }}
                >
                  MSHA Case Number:
                </InputLabel>
                <em>{errors.mshaCaseNumber}</em>
                <TextField
                  fullWidth
                  id="mshaCaseNumber"
                  name="mshaCaseNumber"
                  size="small"
                  variant="outlined"
                  onChange={onValueChange}
                  value={citationMetaData?.mshaCaseNumber || ""}
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={12}>
              <div className={errors.notes ? "error " : ""}>
                <InputLabel htmlFor="notes" style={{ display: "inline-block" }}>
                  Notes:
                </InputLabel>
                <em>{errors.notes}</em>
                <TextField
                  fullWidth
                  id="notes"
                  name="notes"
                  size="small"
                  variant="outlined"
                  onChange={onValueChange}
                  value={citationMetaData?.notes || ""}
                />
              </div>
            </Grid>
            {customFieldTextInput('customText1', false)}
            {customFieldTextInput('customText2', false)}
            {customFieldTextInput('customText3', false)}
            {customFieldTextInput('customText4', false)}
            {customFieldTextInput('customText5', false)}
            {customFieldTextInput('customText6', false)}
            {customFieldTextInput('customLongText1', true)}
            {customFieldTextInput('customLongText2', true)}
            {customFieldTextInput('customLongText3', true)}
            {customFieldTextInput('customLongText4', true)}
            {customFieldTextInput('customLongText5', true)}
            {customFieldTextInput('customLongText6', true)}
            {customNumberInput('customWholeNumber1', false, false)}
            {customNumberInput('customWholeNumber2', false, false)}
            {customNumberInput('customWholeNumber3', false, false)}
            {customNumberInput('customWholeNumber4', false, false)}
            {customNumberInput('customWholeNumber5', false, false)}
            {customNumberInput('customWholeNumber6', false, false)}
            {customNumberInput('customDecimalNumber1', false, true)}
            {customNumberInput('customDecimalNumber2', false, true)}
            {customNumberInput('customDecimalNumber3', false, true)}
            {customNumberInput('customDecimalNumber4', false, true)}
            {customNumberInput('customDecimalNumber5', false, true)}
            {customNumberInput('customDecimalNumber6', false, true)}
            {customNumberInput('customMoney1', true, true)}
            {customNumberInput('customMoney2', true, true)}
            {customNumberInput('customMoney3', true, true)}
            {customNumberInput('customMoney4', true, true)}
            {customNumberInput('customMoney5', true, true)}
            {customNumberInput('customMoney6', true, true)}
            {customDateInput('customDate1')}
            {customDateInput('customDate2')}
            {customDateInput('customDate3')}
            {customDateInput('customDate4')}
            {customDateInput('customDate5')}
            {customDateInput('customDate6')}
            <Grid item xs={12} lg={6}>
              <div className={errors.regionName ? "error " : ""}>
                <InputLabel
                  htmlFor="RegionName"
                  style={{ display: "inline-block" }}
                >
                  Images:
                </InputLabel>
                <em>{errors.regionName}</em>
                <br />
                <Button variant="contained" component="label" fullWidth>
                  {buttonsTitle.citationImages}
                  <input
                    accept="image/*"
                    type="file"
                    hidden
                    id="citationImages"
                    name="citationImages"
                    onChange={onFileChange}
                    multiple
                  />
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className={errors.regionName ? "error " : ""}>
                <InputLabel
                  htmlFor="RegionName"
                  style={{ display: "inline-block" }}
                >
                  PDF Documents:
                </InputLabel>
                <em>{errors.regionName}</em>
                <br />
                <Button variant="contained" component="label" fullWidth>
                  {buttonsTitle.citationDocuments}
                  <input
                    accept="application/pdf, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    type="file"
                    hidden
                    id="citationDocuments"
                    name="citationDocuments"
                    onChange={onFileChange}
                    multiple
                  />
                </Button>
              </div>
            </Grid>

            <Grid item xs={12} lg={6}></Grid>
            <Grid item xs={12} lg={6}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"contained"}
            autoFocus
            onClick={submitForm}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default CitationNoteModal;
