import React, { useEffect, useState } from "react";
import SandSView from "./DashCitations";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import InspectionBasics from "./InspectionBasics";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../interfaces/store";
import {
  OA_DASH_TREND_ANALYSIS_HEIGHT,
  OA_DASH_CITATIONS,
  OA_DASH_INSPECTION_BASICS,
  OA_DASH_TOP_10,
  DEFAULT_CONTROLLER_NAME,
  OA_DASH_SPACER,
} from "./AnalyticsConstants";
import ContactUs from "../informative/ContactUs";
import { FormControl, FormLabel, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getControllers } from "../../store/actions/analyticsActions";
import TrendAnalysisView from "./TrendAnalysisView";
import Top10ByStandardView from "./Top10ByStandard";
import { hasFlag } from "../../utils/bitwise-utils";
import { ACCOUNT_FLAGS } from "../../enums/account-enums";

interface IOperatorAnalyticsProps {
  maxWidth: number;
}
interface IController {
  controllerName: string;
  controllerId: Number;
}
enum ISubNavSelection {
  CITATION_SUMMARY = 0,
  TOP_10S = 1,
  TREND_ANALYSIS = 2,
  INSPECTION_ANALYSIS = 3,
}

const OperatorAnalytics: React.FC<IOperatorAnalyticsProps> = (props) => {
  // useEffect (() => {
  //   const script = document.createElement("script");

  //   script.src = "https://10ay.online.tableau.com/javascripts/api/viz_v1.js";
  //   script.async = true;

  //   document.body.appendChild(script);
  // }, [])

  // TODO fix

  const [selectedNav, setSelectedNav] = useState<ISubNavSelection>(
    ISubNavSelection.CITATION_SUMMARY
  );
  const controllerMeta = useSelector(
    (state: IRootState) => state.main.controller_meta
  );
  const accountLogin = useSelector(
    (state: IRootState) => state.main.account_login
  );

  const IsSystemAdmin = hasFlag(
    accountLogin?.accountFlag,
    ACCOUNT_FLAGS.system_admin
  );

  console.log("IS A SYSTEM ADMIN?", IsSystemAdmin);

  const dispatch = useDispatch();
  const getDims = (ww: number, hh: number) => {
    const aspect = ww / hh;
    const w = props.maxWidth * 0.9;
    const h = w / aspect;
    console.log("returning", {
      width: w,
      height: h,
    });
    return {
      width: w,
      height: h,
    };
  };

  //   useEffect(() => {
  //     const script = document.createElement("script");

  //     script.src = "https://10ay.online.tableau.com/javascripts/api/viz_v1.js";
  //     script.async = true;

  //     document.body.appendChild(script);
  // }, [])

  const [controllers, setControllers] = useState<IController[]>([
    {
      controllerName: "",
      controllerId: 0,
    },
  ]);

  useEffect(() => {
    console.log("calling on mount");
    dispatch(
      getControllers(null, (data: IController[]) => {
        console.log("get controller data", data);
        setControllers(data);
      })
    );
  }, []);

  const [selectedController, setSelectedController] = useState<IController>();

  const controllerSelectorDefaultProps = {
    options: controllers,
    getOptionLabel: (option: IController) =>
      option.controllerName ? option.controllerName : "",
    onChange: handleChangeControllers,
    value: selectedController,
    getOptionSelected: (option: IController, value: IController) =>
      option.controllerName === value.controllerName,
  };

  function handleChangeControllers(event: any, newValue: IController | null) {
    if (newValue) {
      setSelectedController(newValue);
    }
  }
  const renderNoAccessView = () => {
    return (
      <div style={{ minHeight: "100vh" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, paddingRight: 32 }}>
            <h1>Operator Analytics</h1>

            <p>
              Thanks for taking a look at Operator Analytics. We’ve worked
              directly with industry experts to surface the flexible reports and
              charts that are critical to mining operators. Reach out to Zach
              for a demo and to add Operator Analytics to your subscription!
            </p>

            <ContactUs />
          </div>
          <div style={{ flex: 1 }}>
            <img
              alt={"oa_image_1"}
              style={{ width: "100%", maxWidth: 800, marginBottom: 32 }}
              src={process.env.PUBLIC_URL + "/oa_example_001.png"}
            />
            <img
              alt={"oa_image_1"}
              style={{ width: "100%", maxWidth: 800 }}
              src={process.env.PUBLIC_URL + "/oa_example_002.png"}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderView = () => {
    let safeControllerName = controllerMeta.name
      ? controllerMeta.name
      : DEFAULT_CONTROLLER_NAME;

    if (IsSystemAdmin && selectedController) {
      if (selectedController?.controllerId === 0) {
        return <div>Select a Controller</div>;
      }
      safeControllerName = selectedController!.controllerName;
    }

    let encodedControllerName = encodeURI(safeControllerName);
    encodedControllerName = encodedControllerName.replace(",", "%5C%2C");

    const controllerNameParam = "ControllerNameParam=" + encodedControllerName;

    switch (selectedNav) {
      case ISubNavSelection.CITATION_SUMMARY:
        return (
          <>
            {/* Dash-SS */}
            <SandSView controllerParam={controllerNameParam} />
            <br />
            <br />
            {/* <CitationBasics controllerParam={controllerNameParam} />
        <br /><br />
        <SeverityDashView controllerParam={controllerNameParam}/> */}
          </>
        );
      case ISubNavSelection.INSPECTION_ANALYSIS:
        return (
          <>
            <InspectionBasics controllerParam={controllerNameParam} />
            <br />
            <br />
          </>
        );
      case ISubNavSelection.TOP_10S:
        return <Top10ByStandardView controllerParam={controllerNameParam} />;
      // return
      case ISubNavSelection.TREND_ANALYSIS:
        return (
          <>
            <TrendAnalysisView
              height={OA_DASH_TREND_ANALYSIS_HEIGHT}
              controllerParam={controllerNameParam}
            />
          </>
        );
    }
  };

  const handleNav = (nav: ISubNavSelection) => {
    setSelectedNav(nav);
  };

  const subNavStyle = {
    cursor: "pointer",
    padding: 16,
    margin: 8,
    width: 250,
    boxShadow: "0px 4px 4px 0px #00000040",
    backgroundColor: "#D3D3D3",
    color: "#4a4a4a",
    display: "inline-block",
  };

  const subNavStyleSelected = {
    ...subNavStyle,
    backgroundColor: "#4A4A4A",
    color: "white",
  };

  const getNavClass = (selection: ISubNavSelection) => {
    if (selectedNav === selection) {
      return subNavStyleSelected;
    } else {
      return subNavStyle;
    }
  };

  const getHeightStandard = () => {
    switch (selectedNav) {
      case ISubNavSelection.CITATION_SUMMARY:
        return OA_DASH_CITATIONS + OA_DASH_SPACER; //+ OA_DASH_CITATION_BASICS_HEIGHT + OA_DASH_SEVERITY_DASH_HEIGHT + (OA_DASH_SPACER * 3)
      case ISubNavSelection.TOP_10S:
        return OA_DASH_TOP_10 + OA_DASH_SPACER;
      case ISubNavSelection.TREND_ANALYSIS:
        return OA_DASH_TREND_ANALYSIS_HEIGHT + OA_DASH_SPACER;
      case ISubNavSelection.INSPECTION_ANALYSIS:
        return OA_DASH_INSPECTION_BASICS + OA_DASH_SPACER;
      default:
        return 1000;
    }
  };

  const getHeight = () => {
    return getHeightStandard() + 100;
  };

  if (!accountLogin.operatorAnalyticsAccess) {
    return renderNoAccessView();
  }

  console.log("controllerSelectorDefaultProps", controllerSelectorDefaultProps);
  return (
    <div className="App" style={{ height: "100vh", minHeight: getHeight() }}>
      <h3>Operator Analytics</h3>

      {IsSystemAdmin && (
        <div>
          <FormControl style={{ width: 300 }} component="fieldset">
            <FormLabel component="legend">Controller</FormLabel>
            <Autocomplete
              {...controllerSelectorDefaultProps}
              id="controllers"
              renderInput={(params) => (
                <TextField {...params} margin="normal" />
              )}
            />
          </FormControl>
        </div>
      )}
      <div
        style={{
          display: "flex",
          marginBottom: 32,
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <div
            onClick={() => handleNav(ISubNavSelection.CITATION_SUMMARY)}
            style={{
              ...getNavClass(ISubNavSelection.CITATION_SUMMARY),
              textAlign: "center",
            }}
          >
            Citation Summary
          </div>
          <div
            onClick={() => handleNav(ISubNavSelection.TOP_10S)}
            style={{
              ...getNavClass(ISubNavSelection.TOP_10S),
              textAlign: "center",
            }}
          >
            Top 10s
          </div>
          <div
            onClick={() => handleNav(ISubNavSelection.TREND_ANALYSIS)}
            style={{
              ...getNavClass(ISubNavSelection.TREND_ANALYSIS),
              textAlign: "center",
            }}
          >
            Trend Analysis
          </div>
          <div
            onClick={() => handleNav(ISubNavSelection.INSPECTION_ANALYSIS)}
            style={{
              ...getNavClass(ISubNavSelection.INSPECTION_ANALYSIS),
              textAlign: "center",
            }}
          >
            Inspection Analysis
          </div>
        </div>
      </div>

      <TransitionGroup>
        <CSSTransition
          key={selectedNav}
          timeout={1000}
          classNames={"transition-fade"}
        >
          <div
            key={"view-" + selectedController?.controllerId}
            style={{
              position: "absolute",
              width: props.maxWidth - 64,
              overflow: "auto",
            }}
          >
            {renderView()}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default OperatorAnalytics;
