/* eslint-disable no-param-reassign */

import { IReduceAction } from "../../interfaces/store";
import { IMainState, MAIN_TAB } from "../../interfaces/store/main";
import { autoReduce } from "./shared";

let existingLogin = localStorage.getItem("accountLogin");
let existingControllerMeta = localStorage.getItem("controllerMeta");
if (existingLogin)
  try {
    existingLogin = JSON.parse(existingLogin);
  } catch (e) {}

  let existingControllerMetaParsed = {name: '', id: ''}
  if (existingControllerMeta)
  try {
    existingControllerMetaParsed = JSON.parse(existingControllerMeta);
  } catch (e) {}

export const mainStateObject = {
  mines: {},
  companies: {},
  citations: {},
  citation_meta_data: {},
  citation_warnings: {},
  citation_mgmt_custom_fields: {},
  account_login: existingLogin ? existingLogin : {},
  controller_meta: existingControllerMeta ? existingControllerMetaParsed : { name: '', id: ''},
  contractors: {},
  client_options: {
    selected_tab: MAIN_TAB.HOME,
    show_citation_nav: false,
    selected_mine: {},
    test: null,
  },
  mine_context: {
    numCitationPerRegulationForMine: 0,
	regulationWarning: ''
  },
  company_account_mine: {},
  account_logins: {},
  company_email_settings: {},
  business_units: {},
  regions: {},
  company_email_settings_citation_columns: {},
  company_email_settings_inspection_columns: {},
  account_login_validations: {},
  company_accounts: {},
  inspections: {},
  jwt_token: localStorage.getItem("jwt") || "",
  admin_email_settings: {},
  citation_form_data: [
    //   {
    //     "f_1_Date": "2020-01-15T11:00:00",
    //     "f_2_Time": 0,
    //     "f_3_Citation_Order_Number": 9194537,
    //     "f_4_Served_To": "Mike Middlemas",
    //     "f_5_Operator": "PEABODY GATEWAY NORTH MINING, LLC",
    //     "f_6_Mine": "GATEWAY NORTH MINE",
    //     "f_7_Mine_ID": 1103235,
    //     "f_8_Condition_Or_Practice": "Accumulations of combustible material in the form of coal fines and belt pressings have been allowed to accumulate at the 3rd Panel North (Unit #1) conveyor belt take-up. The accumulations were located on the steel framing under the stationary roller measuring 8 feet in length, 18 inches in width, 7 inches in depth, with 5 1/2 feet in length of the belt coming in contact with the accumulations. The accumulations on the steel framing under the carriage roller measured 4 1/2 feet in length, 18 inches in width, 12 inches in depth with 2 feet in length of the belt coming in contact with the accumulations. The belt was immediately removed from service by mine management,",
    //     "f_8a_Written_Notice": false,
    //     "f_8_See_Continuation_Form": false,
    //     "f_9a_Violation_Type": 2,
    //     "f_9b_Section_Of_Act": null,
    //     "f_9c_Section_Of_CFR": "75.400",
    //     "f_10a_Gravity_Chance": 3,
    //     "f_10b_Gravity_Result_Expected": 2,
    //     "f_10c_Significant": true,
    //     "f_10d_Number_Of_Persons_Affected": 1,
    //     "f_11_Negligence": 3,
    //     "f_12_Type_Of_Action": 0,
    //     "f_13_Type_Of_Issuance": 1,
    //     "f_14_Initial_Action": 0,
    //     "f_14e_Citation_Order_Number": 0,
    //     "f_14f_Date": "0001-01-01T00:00:00",
    //     "f_15_Area_Or_Equipment": null,
    //     "f_16a_Termination_Due_Date": "2020-01-15T11:30:00",
    //     "f_16b_Termination_Due_Time": 0,
    //     "f_17_Action_To_Terminate": "The accumulations were removed from the mine by shoveling.",
    //     "f_18a_Terminated_Date": "2020-01-15T11:25:00",
    //     "f_18b_Terminated_Time": 0,
    //     "f_19_Type_Of_Inspection": 1,
    //     "f_20_Event_Number": "7006956",
    //     "f_21_Primary_Or_Mill": 0,
    //     "f_22_AR_Name": "Brandon Lampley",
    //     "f_23_AR_Number": 25374
    // }
  ],
};

const initialState: IMainState = { ...mainStateObject };

const predictiveComplianceReducer = (
  state: any = initialState,
  action: IReduceAction
): IMainState => {
  if (action && (action.stateKey === "main" || !action.stateKey)) {
    if (action.normalized || action.removeKeys || action.overrides || action.setKeys) {
      return autoReduce(action, state);
    }

    switch (action.type) {
      case "LOGIN_SUCCESS":
        console.log("ACTION.DATA", action.data);
        localStorage.setItem("jwt", action.data.token);
        localStorage.setItem(
          "accountLogin",
          JSON.stringify(action.data.accountLogin)
        );
        const controllerMeta = {
          name: action.data.controllerName,
          id: action.data.controllerId
        }
        localStorage.setItem("controllerMeta", JSON.stringify(controllerMeta));
        state.account_login = action.data.accountLogin;
        state.jwt_token = action.data.token;
        state.controller_meta = controllerMeta;
        break;
	case "UPDATE_ACCOUNT_LOGIN_SUCCESS":
		let obj = { ...action.data }

		if (state.account_login) {
			obj = {
				...state.account_login,
				...obj
			}
		}
		state.account_login = obj
		localStorage.setItem(
			"accountLogin",
			JSON.stringify(obj)
		);
		break;
      case "LOGOUT_SUCCESS":
        localStorage.removeItem("jwt");
        localStorage.removeItem("accountLogin");
        localStorage.removeItem("controllerMeta");
        state.account_login = {};
        state.jwt_token = "";
        break;
      case "SET_CLIENT_OPTIONS_SUCCESS":
        state.client_options = {
          ...state.client_options,
          ...action.payload,
        };
        break;
      case "GET_ADMIN_EMAIL_SETTINGS_SUCCESS":
        state.admin_email_settings = {
          ...state.admin_email_settings,
          ...action.payload,
        };
        break;
      case "SET_STATE_STATISTICS_SUCCESS":
        state.state_statistics[action.data.key] = action.data.results;
        break;
      default:
        return state;
    }
  }
  return state;
};

export default predictiveComplianceReducer;
