import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  FormControlLabel,
  Grid,
  InputLabel,
  Switch,
  DialogContent,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Loading from "../shared/Loading";
import { useDispatch } from "react-redux";
import {
  createUser,
  getAdminUser,
  updateUser,
} from "../../store/actions/userActions";
import { useSnackbar } from "notistack";

interface IAdminUserModalProps {
  open: boolean;
  onClose: Function;
  onReset: Function;
  accountLoginPk?: number;
}

const AdminUserModal: React.FC<IAdminUserModalProps> = (props) => {
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [oldEmailAddress, setOldEmailAddress] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [isAdministrator, setIsAdministrator] = useState(true);
  const [isEnabled, setIsEnabled] = useState(false);
  const [errors, setErrors] = useState({} as any);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (props.accountLoginPk !== 0) {
      setIsUpdateMode(true);
      dispatch(
        getAdminUser(
          { userId: props.accountLoginPk },
          onGetUserDataSucess,
          onGetUserDataFail
        )
      );
    }
  }, [props.accountLoginPk]);

  const onGetUserDataSucess = (data: any) => {
    if (data) {
      setOldEmailAddress(data.emailAddress);
      setEmailAddress(data.emailAddress);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setIsAdministrator(data.isAdministrator);
      setIsEnabled(data.isEnabled);
    }
  };

  const onGetUserDataFail = (data: any) => {};

  const onCancelAction = () => {
    props.onReset();
  };

  const onUpdateAction = () => {
    if (!formIsValid()) return;
    setProcessing(true);
    dispatch(
      updateUser(
        {
          newEmailAddress: emailAddress,
          emailAddress: oldEmailAddress,
          firstName,
          lastName,
          password,
          isAdministrator,
          isEnabled,
          authorityLevel: 1,
        },
        (primaryKey: number) => {
          setProcessing(false);
          props.onClose();
          props.onReset();
          enqueueSnackbar("User was updated.", { variant: "success" });
        },
        (message: string) => {
          setProcessing(false);
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      )
    );
  };

  const onSubmitAction = () => {
    if (!formIsValid()) return;

    setProcessing(true);

    dispatch(
      createUser(
        {
          emailAddress,
          firstName,
          lastName,
          password,
          isAdministrator,
          isEnabled,
          authorityLevel: 1,
        },
        (primaryKey: number) => {
          setProcessing(false);
          enqueueSnackbar("User was created.", { variant: "success" });
          props.onClose();
          props.onReset();
        },
        (message: string) => {
          setProcessing(false);
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      )
    );
  };

  function formIsValid() {
    const _errors: any = {};

    if (!emailAddress) _errors.emailAddress = "Email Adress is required";
    if (emailAddress && !isEmail(emailAddress))
      _errors.emailAddress = "Enter a valid e-mail account";
    if (!firstName) _errors.firstName = "First Name is required";
    if (!lastName) _errors.lastName = "Last Name is required";

    if (!isUpdateMode) {
      if (!password) _errors.password = "password is required";
      if (!retypePassword)
        _errors.retypePassword = "Re-Type Password is required";
      if (password !== retypePassword)
        _errors.retypePassword = "Passwords are not the same";
    }

    setErrors(_errors);

    return Object.keys(_errors).length === 0;
  }

  const passwordIsValid = (event: any) => {
    const confirmPassword = event.target.value;
    let isValid: boolean = true;
    if (password !== confirmPassword) {
      isValid = false;
      const _errors = {
        ...errors,
        retypePassword: "Passwords are not the same",
      };
      setErrors(_errors);
    } else {
      const _errors = {
        ...errors,
      };
      delete _errors.retypePassword;
      setErrors(_errors);
    }

    return isValid;
  };

  function isEmail(search: string): boolean {
    let serchfind: boolean;
    const regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    serchfind = regexp.test(search);
    return serchfind;
  }

  return (
    <>
      <Loading loading={processing} />
      <Dialog
        onClose={() => props.onClose()}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth={"md"}
      >
        <MuiDialogTitle>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 3, marginTop: 10 }}>
              <Typography variant="h6">
                {!isUpdateMode ? "Add Admin User" : "Edit Admin User!"}
              </Typography>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <IconButton aria-label="close" onClick={() => props.onClose()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </MuiDialogTitle>
        <DialogContent dividers>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <div className={errors.emailAddress ? "error " : ""}>
                  <InputLabel
                    htmlFor="emailAddress"
                    style={{ display: "inline-block" }}
                  >
                    Email Address:
                  </InputLabel>
                  <em>{errors.emailAddress}</em>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="emailAddress"
                    size="small"
                    variant="outlined"
                    value={emailAddress}
                    onChange={(event) => setEmailAddress(event?.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6}>
                    <div className={errors.firstName ? "error " : ""}>
                      <InputLabel
                        htmlFor="firstName"
                        style={{ display: "inline-block" }}
                      >
                        First Name:
                      </InputLabel>
                      <em>{errors.firstName}</em>
                      <TextField
                        autoComplete="off"
                        id="firstName"
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={firstName}
                        onChange={(event) => setFirstName(event?.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <div className={errors.lastName ? "error " : ""}>
                      <InputLabel
                        htmlFor="lastName"
                        style={{ display: "inline-block" }}
                      >
                        Last Name:
                      </InputLabel>
                      <em>{errors.lastName}</em>
                      <TextField
                        autoComplete="off"
                        id="lastName"
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={lastName}
                        onChange={(event) => setLastName(event?.target.value)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled
                          name="administratorSwitch"
                          checked={isAdministrator}
                          onChange={(event) =>
                            setIsAdministrator(event?.target.checked)
                          }
                        />
                      }
                      label="System Admin"
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="enabledSwitch"
                          checked={isEnabled}
                          onChange={(event) =>
                            setIsEnabled(event?.target.checked)
                          }
                        />
                      }
                      label="Enabled"
                    />
                  </Grid>
                </Grid>
              </Grid>
              {!isUpdateMode && (
                <Grid item xs={12} lg={12}>
                  <div className={errors.password ? "error " : ""}>
                    <InputLabel
                      htmlFor="password"
                      style={{ display: "inline-block" }}
                    >
                      Password:
                    </InputLabel>
                    <em>{errors.password}</em>
                    <TextField
                      id="password"
                      type="password"
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={password}
                      onChange={(event) => setPassword(event?.target.value)}
                    />
                  </div>
                </Grid>
              )}
              {!isUpdateMode && (
                <Grid item xs={12} lg={12}>
                  <div className={errors.retypePassword ? "error " : ""}>
                    <InputLabel
                      htmlFor="retypepassword"
                      style={{ display: "inline-block" }}
                    >
                      Re-type Password:
                    </InputLabel>
                    <em>{errors.retypePassword}</em>
                    <TextField
                      id="retypepassword"
                      type="password"
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={retypePassword}
                      onChange={(event) => {
                        setRetypePassword(event?.target.value);
                      }}
                      onKeyUp={passwordIsValid}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            autoFocus
            onClick={() => onCancelAction()}
            color="secondary"
          >
            Cancel
          </Button>
          {isUpdateMode && (
            <Button
              variant={"outlined"}
              autoFocus
              onClick={() => onUpdateAction()}
              color="primary"
            >
              Update
            </Button>
          )}
          {!isUpdateMode && (
            <Button
              variant={"outlined"}
              autoFocus
              onClick={() => onSubmitAction()}
              color="primary"
            >
              Create
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminUserModal;
