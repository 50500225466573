export const hasFlag = (val: any, checkFlag: any) => {
    if ((val & checkFlag) === checkFlag) {
        return true;
    }
    return false;
}

export const addFlag = (val: any, flag: any) => {
    return val | flag
}

export const removeFlag = (val: any, flag: any) => {
    return val ^ flag
}