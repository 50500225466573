import { call, put } from "redux-saga/effects";
import { ACTIONS } from "../../enums/actions";
import { HttpResponse } from "../../interfaces/api/HttpResponse";
import { IDispatchAction } from "../../interfaces/store";
import HttpClient, { objectToQuery } from "../../lib/HttpClient";
import GetFriendlyError from "../../utils/general";
import { IMineStateResponse } from "../../interfaces/stored_procs/mine-state-response.interface";
const client = new HttpClient();

async function getVPIDRPIDHistoryAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {
  return client.post("api/analytics/get-vpid-rpid-history", data);
}

async function getMineStateAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {
  return client.get("api/analytics/get-mine-state" + objectToQuery(data));
}

async function getControllersAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {
  return client.get("api/analytics/controllers");
}
const analyticsSaga = {
  *getMineState(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getMineStateAPI,
        payload
      )) as HttpResponse<IMineStateResponse>;
      console.log("raw response analytics", response);
      if (response.status === 200 || response.status === 201) {
        // yield put({
        //   type: 'SET_STATE_STATISTICS_SUCCESS',
        //   data: {key: 'violation_trends', results: response.data}
        // })
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getControllers(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        getControllersAPI,
        action.payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getVPIDRPIDHistory(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        getVPIDRPIDHistoryAPI,
        action.payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
};

export default analyticsSaga;
