import { all, call, put } from "redux-saga/effects";
import { ACTIONS } from "../../enums/actions";
import { HttpResponse } from "../../interfaces/api/HttpResponse";
import { IDispatchAction } from "../../interfaces/store";
import HttpClient from "../../lib/HttpClient";
import GetFriendlyError from "../../utils/general";
import { normalize, schema } from "normalizr";
import { ICitation } from "../../interfaces/model/MSHAPCT/citation.interface";
import { useHistory } from "react-router-dom";
import { Console } from "console";
import { useSnackbar } from "notistack";

const client = new HttpClient();
const citationSchema = new schema.Entity(
  "citations",
  {},
  { idAttribute: "pk" }
);
const citationWarningSchema = new schema.Entity(
  "citation_warnings",
  {},
  { idAttribute: "citationPk" }
);
const citationMetaDataSchema = new schema.Entity(
  "citation_meta_data",
  {},
  { idAttribute: "pk" }
);
const citationMgmtCustomFieldsSchema = new schema.Entity(
  "citation_mgmt_custom_fields",
  {},
  { idAttribute: "pk" }
);

async function updateCitationMgmtCustomFieldAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {
  // return client.get('citation/maintenance');
  return client.post("api/Citation/upsert-citation-mgmt-custom-fields", data);
}

async function getCitationMgmtCustomFieldsAPI(data: {
  companypk: number;
}): Promise<HttpResponse<{ x: string }>> {
  return client.get(
    "api/citation/get-citation-mgmt-custom-fields?companypk=" + data.companypk
  );
}

async function getConferenceTrackingByCitationPkAPI(data: {
  pk: number;
}): Promise<HttpResponse<{ x: string }>> {
  // return client.get('citation/maintenance');
  return client.get("api/conferencetracking/GetByCitationPk?pk=" + data.pk);
}

async function getCitationPenaltyCalculationAnalysisAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {
  // return client.get('citation/maintenance');
  return client.post(
    "api/Citation/citation-penalty-calculation-analysis",
    data
  );
}

async function getCitationWarningsAPI(
  pk: number
): Promise<HttpResponse<{ x: string }>> {
  return client.get("api/Analytics/citation/" + pk + "/warnings");
}

async function downloadBulkCitationsAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {

  return client.getFileByParameter(`api/Citation/report/download-bulk-citations`, data);
}
async function getConferenceTrackingAPI(data: {
  pk: number;
}): Promise<HttpResponse<{ x: string }>> {
  // return client.get('citation/maintenance');
  return client.get("api/conferencetracking/getbypk?pk=" + data.pk);
}

async function upsertConferenceTrackingAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {
  return client.post("api/conferencetracking/upsert", data);
}

async function getCitationsAPI(data: {
  minepk: number;
  startDate: Date;
  endDate: Date;
  contractorpk: number;
}): Promise<HttpResponse<{ x: string }>> {
  return client.get(
    "api/citation/citations?minepk=" +
      data.minepk +
      "&startDate=" +
      data.startDate.toDateString() +
      "&endDate=" +
      data.endDate.toDateString() + 
      "&contractorpk=" + data.contractorpk
  );
}

async function getCitationAPI(data: {
  citationPk: number;
}): Promise<HttpResponse<{ x: string }>> {
  // return client.get('citation/maintenance');
  return client.get("api/citation/citation?citationPk=" + data.citationPk);
}

async function getCitationWithMetadataAPI(data: {
  citationPk: number;
}): Promise<HttpResponse<{ x: string }>> {
  // return client.get('citation/maintenance');
  return client.get("api/citation/citation-metadata/" + data);
}

async function updateCitationsAPI(data: {
  citations: ICitation[];
}): Promise<HttpResponse<{ x: string }>> {
  return client.post("api/Citation/update", data.citations);
}


async function deleteCitationAPI(data: {
  citationPk: number;
}): Promise<HttpResponse<{ x: string }>> {
  return client.delete(`api/Citation/delete-citation?citationPk=${data.citationPk}`);
}

async function uploadCitationsAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {
  return client.postFormData("api/Citation/scan", data);
}

// async function uploadCitationsAPI(
// 	data: any
//   ): Promise<any> {
// 	return { status: 200, data: [
// 		{
// 			"f_1_Date": "2021-12-30T09:10:00",
// 			"f_2_Time": 0,
// 			"f_3_Citation_Order_Number": 9152911,
// 			"f_4_Served_To": "Jim Allington (Production Supervisor)",
// 			"f_5_Operator": "PEABODY POWDER RIVER MINING LLC",
// 			"f_6_Mine": "NORTH ANTELOPE ROCHELLE MINE",
// 			"f_7_Mine_ID": 4801353,
// 			"f_8_Condition_Or_Practice": "Standard 77.1605 (b) was cited 3 times in two years at mine 4801353 (3 to the operator, 0 to a contractor) .",
// 			"f_8a_Written_Notice": false,
// 			"f_8_See_Continuation_Form": false,
// 			"f_9a_Violation_Type": 2,
// 			"f_9b_Section_Of_Act": null,
// 			"f_9c_Section_Of_CFR": "77.1605 (b)",
// 			"f_10a_Gravity_Chance": 2,
// 			"f_10b_Gravity_Result_Expected": 3,
// 			"f_10c_Significant": false,
// 			"f_10d_Number_Of_Persons_Affected": 1,
// 			"f_11_Negligence": 3,
// 			"f_12_Type_Of_Action": 1,
// 			"f_13_Type_Of_Issuance": 1,
// 			"f_14_Initial_Action": 0,
// 			"f_14e_Citation_Order_Number": 0,
// 			"f_14f_Date": null,
// 			"f_15_Area_Of_Equipment": null,
// 			"f_16a_Termination_Due_Date": "2021-12-30T10:30:00",
// 			"f_16b_Termination_Due_Time": 0,
// 			"f_17_Action_To_Terminate": "The brake can bolts were tighten and service break worked correctly. This citation is now",
// 			"f_18a_Terminated_Date": "2021-12-30T10:15:00",
// 			"f_18b_Terminated_Time": 0,
// 			"f_19_Type_Of_Inspection": 1,
// 			"f_20_Event_Number": "6462897",
// 			"f_21_Primary_Or_Mill": 0,
// 			"f_22_AR_Name": "Chad M. Simpson",
// 			"f_23_AR_Number": 25004
// 		}
// 	]}
//   }

async function downloadCitationDocAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {
  return client.getFile(`api/blobstorage/${data.citationPk}/${data.fileName}`);
}
async function updateCitationNotesAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {
  return client.uploadFiles("api/Citation/citation-notes", data);
}

async function deleteCitationNotesFileAPI(
	data: any
  ): Promise<HttpResponse<{ x: string }>> {
	return client.post("api/Citation/delete-notes-file", data);
  }

async function saveCitationScansAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {
  return client.post("api/Citation/save-scan", data);
}

async function getPaternOfViolationsAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {
  return client.get(
    `api/Citation/pattern-of-violations?minePk=${data.minePk}&povCalcEndDate=${data.povCalcEndDate}`
  );
}

const citationSaga = {
  *getCitations(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      //console.log(new Date(), "calling get citations");
      const response = (yield call(
        getCitationsAPI,
        payload
      )) as HttpResponse<any>;
	  
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess();
        }
        // override value.
        if (payload.override) {
          yield put({
            type: "GET_CITATIONS_BY_COMPANY_SUCCESS",
            overrides: [
              {
                key: "citations",
                value: normalize(response.data.citations, [citationSchema])
                  .entities.citations,
              },
              {
                key: "citation_meta_data",
                value: normalize(response.data.citationMetaData, [
                  citationMetaDataSchema,
                ]).entities.citation_meta_data,
              },
              {
                key: "citation_warnings",
                value: normalize(response.data.citationWarnings, [
                  citationWarningSchema,
                ]).entities.citation_warnings,
              }
            ],
          });
          //console.log("done normalizing");
        } else {
          // merge in value
          yield all([
            put({
              type: "GET_CITATIONS_OVERVIEW_SUCCESS",
              normalized: normalize(response.data.citations, [citationSchema]),
            }),
            put({
              type: "GET_CITATION_META_SUCCESS",
              normalized: normalize(response.data.citationMetaData, [
                citationMetaDataSchema,
              ]),
            })
          ]);
        }
        yield put({
          type: "GET_MINE_NUM_ISSUED_PER_REGULATION_SUCCESS",
          setKeys: [{
            stateKey: 'mine_context',
            key: 'numCitationPerRegulationForMine',
            value: response.data.numCitationPerRegulationForMine
          }],
        })
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
  *getCitation(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getCitationAPI,
        payload
      )) as HttpResponse<any>;
      yield put({
        type: "GET_SINGLE_CITATION_SUCCESS",
        normalized: normalize(response.data, citationSchema),
      });
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
  *deleteCitationNotesFile(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;

      const response = (yield call(
        deleteCitationNotesFileAPI,
        payload
      )) as HttpResponse<any>;

	  if (response?.data === true && action.onSuccess) {
		action.onSuccess();
	  }
	  else if (response?.data === false && action.onFail) {
		action.onFail();
	  }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
  *getCitationWithMetada(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getCitationWithMetadataAPI,
        payload
      )) as HttpResponse<any>;

      if (response.status === 200 || response.status === 201) {
        const metadataFiles = {
          ...response.data.metaData,
          imagesList: [...response.data.images],
          documentsList: [...response.data.documents],
        };
        yield all([
          put({
            type: "GET_CITATION_WITH_METADATA_SUCCESS",
            normalized: normalize([response.data.citation], [citationSchema]),
          }),
          put({
            type: "GET_CITATION_WITH_METADATA_SUCCESS",
            normalized: normalize([metadataFiles], [citationMetaDataSchema]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess();
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
  *updateCitations(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        updateCitationsAPI,
        payload
      )) as HttpResponse<any>;
      yield put({
        type: "UPDATE_CITATIONS_SUCCESS",
        normalized: normalize(response.data, [citationSchema]),
      });
      if (action.onSuccess) {
        action.onSuccess();
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  }, 
  *deleteCitation(action: IDispatchAction): Generator {
try {
      const payload = action.payload as any;
      const response = (yield call(
        deleteCitationAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
           yield put({
             type: "DELETE_CITATIONS_SUCCESS",
            
           });
           if (action.onSuccess) {
             action.onSuccess();
           }
         } else {
           if (action.onFail) {
             action.onFail();
           }
         }
       } catch (e) {
         const error = e as Error;
         const friendlyError = GetFriendlyError(error.message);
         yield put({ type: ACTIONS.ERROR, message: friendlyError });
       }
     },
  *updateCitationNote(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        updateCitationNotesAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        const metadataFiles = {
          ...response.data.metaData,
          imagesList: [...response.data.images],
          documentsList: [...response.data.documents],
        };

        yield all([
          put({
            type: "UPDATE_CITATION_NOTE_SUCCESS",
            normalized: normalize([response.data.citation], [citationSchema]),
          }),
          put({
            type: "UPDATE_CITATION_NOTE_SUCCESS",
            normalized: normalize([metadataFiles], [citationMetaDataSchema]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess();
        }

        // yield put({
        //   type: "UPDATE_CITATION_NOTE_SUCCESS",
        //   overrides: [
        //     {
        //       key: "citation_meta_data",
        //       value: response.data,
        //     },
        //   ],
        // });
        // if (action.onSuccess) {
        //   action.onSuccess();
        // }
      }
    } catch (e) {
      const error = e as Error;
      if (action.onFail) {
        action.onFail(error.message);
      }
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
  *uploadCitations(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        uploadCitationsAPI,
        payload
      )) as HttpResponse<any>;
		// const response = {
		// 	status: 200, 
		// 	data: [{"f_1_Date":"2021-12-29T12:00:00","f_2_Time":0,"f_3_Citation_Order_Number":9152910,"f_4_Served_To":"Casey Whitney (Electrical Supervisor)","f_5_Operator":"PEABODY POWDER RIVER MINING LLC","f_6_Mine":"NORTH ANTELOPE ROCHELLE MINE","f_7_Mine_ID":4801353,"f_8_Condition_Or_Practice":"A pit sub CO#PS13 located on the perimeter road in the Porcupine Pit East does not have the #2 cable providing power to PPS#7 properly protected for over current because the relay is tapped at 240 amps and the maximum for a #2 cable is 199 amps. Standard 77.800 was cited 4 times in two years at mine 4801353 (4 to the operator, 0 to a contractor) .","f_8a_Written_Notice":false,"f_8_See_Continuation_Form":false,"f_9a_Violation_Type":2,"f_9b_Section_Of_Act":null,"f_9c_Section_Of_CFR":"77.800","f_10a_Gravity_Chance":2,"f_10b_Gravity_Result_Expected":2,"f_10c_Significant":false,"f_10d_Number_Of_Persons_Affected":1,"f_11_Negligence":3,"f_12_Type_Of_Action":1,"f_13_Type_Of_Issuance":1,"f_14_Initial_Action":0,"f_14e_Citation_Order_Number":0,"f_14f_Date":"0001-01-01T00:00:00","f_15_Area_Of_Equipment":null,"f_16a_Termination_Due_Date":"2021-12-29T13:00:00","f_16b_Termination_Due_Time":0,"f_17_Action_To_Terminate":"The relay was properly set to protect the #2 cable. This citation is now terminated.","f_18a_Terminated_Date":"2021-12-29T12:30:00","f_18b_Terminated_Time":0,"f_19_Type_Of_Inspection":1,"f_20_Event_Number":"6462897","f_21_Primary_Or_Mill":0,"f_22_AR_Name":"Chad M. Simpson","f_23_AR_Number":25004}]
		// }
	
      if (response.status === 200 || response.status === 201) {
		 // console.log("uploadCitations response", response.data)
		// console.log(JSON.stringify(response.data))
        yield put({
          type: "UPLOAD_CITATIONS_SUCCESS",
          overrides: [
            {
              key: "citation_form_data",
              value: response.data,
            },
          ],
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
  *downloadBulkCitations(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;

      const response = (yield call(
        downloadBulkCitationsAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", payload.fileName);
        document.body.appendChild(link);
        link.click();
        if (action.onSuccess) {
          action.onSuccess();
        }
      }
    } catch (e) {
      const error = e as Error;
      if (action.onFail) {
        action.onFail(error.message);
      }
    }
  },
  *downloadCitationFile(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        downloadCitationDocAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", payload.fileName);
        document.body.appendChild(link);
        link.click();
      }
    } catch (e) {
      const error = e as Error;
      if (action.onFail) {
        action.onFail(error.message);
      }
    }
  },
  *saveCitationScans(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        saveCitationScansAPI,
        payload
      )) as HttpResponse<any>;
      yield all([
        put({
          type: "SAVE_CITATION_SCANS_SUCCESS_CLEAR_FORM_DATA",
          overrides: [
            {
              key: "citation_form_data",
              value: [],
            },
          ],
        }),
        put({
          type: "SAVE_CITATION_SCANS_SUCCESS",
          normalized: normalize(response.data, [citationSchema]),
        }),
      ]);
	  if (response.data?.length === 0){
		if (action.onFail) {
			action.onFail();
		  }
	  }
	  else{
		if (action.onSuccess) {
			action.onSuccess();
		  }
	  }

    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getPaternOfViolations(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getPaternOfViolationsAPI,
        payload
      )) as HttpResponse<any>;
      if (action.onSuccess) {
        action.onSuccess(response);
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getConferenceTrackingByCitationPk(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getConferenceTrackingByCitationPkAPI,
        payload
      )) as HttpResponse<any>;
      if (action.onSuccess) {
        action.onSuccess(response.data);
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getCitationPenaltyCalculationAnalysis(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getCitationPenaltyCalculationAnalysisAPI,
        payload
      )) as HttpResponse<any>;

      if (action.onSuccess) {
        action.onSuccess(response.data);
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getCitationWarnings(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getCitationWarningsAPI,
        payload
      )) as HttpResponse<any>;
      if (action.onSuccess) {
        action.onSuccess(response.data);
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
  *getConferenceTracking(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getConferenceTrackingAPI,
        payload
      )) as HttpResponse<any>;
      if (action.onSuccess) {
        action.onSuccess(response.data);
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *upsertConferenceTracking(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        upsertConferenceTrackingAPI,
        payload
      )) as any;
      if (response.citationMetaData) {
        yield put({
          type: "UPSERT_CONFERENCE_TRACKING_SUCCESS",
          normalized: normalize(response.data.citationMetaData, [
            citationMetaDataSchema,
          ]),
        });
      }
      if (action.onSuccess) {
        action.onSuccess(response.data);
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getCitationMgmtCustomFields(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
   
      const response = (yield call(
        getCitationMgmtCustomFieldsAPI,
        payload
      )) as any;
  
      if (response.data) {
        yield put({
          type: "GET_CITATATION_MGMT_CUSTOM_FIELDS_SUCCESS",
          normalized: normalize(response.data, [
            citationMgmtCustomFieldsSchema,
          ]),
        });
      }
      if (action.onSuccess) {
        action.onSuccess(response.data);
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *updateCitationMgmtCustomField(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
    
      const response = (yield call(
        updateCitationMgmtCustomFieldAPI,
        payload
      )) as any;

      if (response.data) {
        yield put({
          type: "UPDATE_CITATATION_MGMT_CUSTOM_FIELD_SUCCESS",
          normalized: normalize(response.data, citationMgmtCustomFieldsSchema),
        });
      }
      if (action.onSuccess) {
        action.onSuccess(response.data);
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
};

export default citationSaga;
