import React from "react";
import { Container, Grid } from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";
interface ISuccessStoriesProps {}

const SuccessStories: React.FC<ISuccessStoriesProps> = () => {
  return (
    <Container
      style={{ minHeight: "100vh", backgroundColor: COLORS.WHITE_PAPER }}
    >
      <h1>Success Stories</h1>
      <section>
        <Grid container>
          <img
            alt="Newmont Mining Company logo"
            src="https://www.predictivecompliance.com/IMG/assets/NewmontLogo.png"
          />
          <p
            style={{
              fontSize: "16px",
              fontWeight: "bolder",
              paddingLeft: "15px",
            }}
          >
            LETTER FROM NEWMONT MINING COMPANY:
          </p>
        </Grid>
        <br></br>
        <article>
          I have found the Predictive Compliance software to be a very valuable
          tool in helping miners and mine management to make important decisions
          in the days leading up to a MSHA inspection. The citation management
          portion of the software has helped identify trouble area’s that exist
          at any of the different sites I work with. This information helps
          managers know before hand which regulations they need to avoid, and
          due to the analytics portion of the software, how badly they need to
          avoid them. The predictive cost analysis helps mine management make
          decisions about whether to authorize overtime of bring in additional
          support to avoid citations.
        </article>
        <br></br>
        <article>
          In using this software to organize a preemptive strike at one of the
          mine sites in our area, we were able to bring the total number of
          housekeeping citations from about 6 per inspection down to 1. The
          software helps bring your weaknesses to light so that your site can
          work towards turning those weaknesses into strengths.
        </article>
        <br></br>
        <article>
          Another feature that I have found most useful is the instant penalty
          calculation; I know instantly the cost of a citation or order, where
          if I had to wait for the MSHA website to return a number it may be
          weeks before that information is public. This information has come in
          handy when holding people accountable for there actions and has helped
          to put others to action in correcting conditions that could result in
          violations.
        </article>
        <br></br>
        <article>
          The software helps us manage our citations, and manage our business in
          an efficient manner to maintain compliance to the regulations set
          forth for us by the federal government.
        </article>
        <br></br>
        <article>
          The software helps put instant weight to an MSHA inspection were in
          the past, the information came to light so long after the inspection
          occurred that no one seemed to remember what happened.
        </article>
        <br></br>
        <article>
          I recommend the software to any group that is trying to get a better
          handle on their compliance issues, it will help you manage your
          business in a quick, organized, and effective way, and will eliminate
          guess work, and confusion. It will help you make real time decisions
          about choices that need to be made when you don’t have time to wait
          for information to come in.
        </article>
        <br></br>
        <article>
          Thanks,<br></br>
          -Mr. Jon Upsahw, Newmont Mining Company, SLP Data Analyst, September
          21, 2009
        </article>
      </section>
    </Container>
  );
};

export default SuccessStories;
