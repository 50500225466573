import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../../interfaces/store";
import {
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import MaterialTable, {
  MTableBody,
} from "material-table";
import { tableIcons } from "../shared/MaterialTableShared";
import { useDispatch } from "react-redux";
import { IMine } from "../../interfaces/model/MSHAPCT/mine.interface";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import {
  getAllColumnsMinesSummary,
  getMinesByCompany,
} from "../../store/actions/mineActions";
import Loading from "../shared/Loading";
import { useSnackbar } from "notistack";
import {
  getCompanySummary,
  downloadExcelSummaryReport,
  download8KReport,
  download10KReport,
} from "../../store/actions/companyActions";
import { MineComparison } from "../../interfaces/model/MSHAPCT/mine-comparison.interface";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { cloneDeep } from "lodash";
import { numberToCurrency } from "../../utils/general-utils";

interface IMineummary {}

function not(a: readonly IMine[], b: readonly IMine[]) {
  const items = a.filter((value) => b.indexOf(value) === -1);
  return items;
}

function intersection(a: readonly IMine[], b: readonly IMine[]) {
  const items = a.filter((value) => b.indexOf(value) !== -1);
  return items;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      color: "Blue",
      zIndex: 100,
    },
    button: {
      width: 200,
      marginRight: 15,
      marginTop: 15,
      marginBotton: 15,
    },
    buttonLeft: {
      width: 200,
      marginLeft: 15,
      marginTop: 15,
      marginBotton: 15,
    },
    panelSpacing: {
      marginTop: 20,
      marginBottom: 30,
    },
  })
);

const MineSummary: React.FC<IMineummary> = (props) => {
  const classes = useStyles();
  const { companyid } = useParams() as any;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [processsing, setProcesssing] = useState(false);
  const mines = useSelector((state: IRootState) => state.main.mines);
  let history = useHistory();
  const [checked, setChecked] = React.useState<readonly IMine[]>([]);
  const [left, setLeft] = React.useState<readonly IMine[]>([]);
  const [right, setRight] = React.useState<readonly IMine[]>([]);

  const oneYearsAgo = new Date();
  oneYearsAgo.setFullYear( oneYearsAgo.getFullYear() - 1 );

  const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(
    oneYearsAgo
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
    new Date()
  );

  const [pageLoad, setPageLoad] = useState<Boolean>(false);
  const [initializedMines, setInitializedMines] = useState<Boolean>(false);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (companyid) {
      dispatch(getMinesByCompany({ companypk: companyid }, () => {
		dispatch(getAllColumnsMinesSummary(companyid, (dd: any) => {
			getColumnData(dd)
			setInitializedMines(true);
		}));
	  }));
      
    }
  }, [companyid]);

  useEffect(() => {
    const minesList = Object.values(mines).filter(
      (al) => al.companyFk === Number(companyid)
    );

    setRight(minesList.sort(comparer));
	if (initializedMines){
		setPageLoad(true);
	}

  }, [mines, initializedMines]);

  const [hideUnasseableChecked, setHideUnasseableChecked] =
    React.useState(false);
  const [vacatedChecked, setVacatedCheckedChecked] = React.useState(false);
  const [selectedColumns, setSelectedColumns] = useState([] as any);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleStartDateChange = (date: Date | null) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setSelectedEndDate(date);
  };

  const onChangeSelectedColumnsColumns = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedColumns(event.target.value);
  };

  const [summaryColumns, setSummaryColumns] = useState([] as any);

  const isSummaryColumnSelected = (selected: any): boolean => {
    var data = selectedColumns.filter((el: any) => el.index === selected.index);
    return data.length > 0 ? true : false;
  };

  const getColumnData = (data: any) => {
    setSummaryColumns(data);

    const defaultColumns = Object.values(data).filter((al: any) => al.visible);
    setSelectedColumns(defaultColumns);
  };


  // useEffect(() => {
  //   handleAllRight();
  //   setPageLoad(true);
  // }, [selectedColumns]);

  useEffect(() => {
    if (pageLoad) {
      submitReportParameters();
      setPageLoad(false);
    }
  }, [pageLoad]);

  const onRenderColumnValues = (selected: any) => {
    var data = selected.map(function (item: any) {
      return item.header;
    });

    return data.join(", ");
  };

  const handleToggle = (value: IMine) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const [selectionButtonLabel, setSelectionButtonLabel] =
    useState("Select All");

  useEffect(() => {
    if (right.length > 0) setSelectionButtonLabel("Deselect All");
    else setSelectionButtonLabel("Select All");
  }, [right]);

  const toggleSelection = () => {
    if (selectionButtonLabel === "Select All") {
      handleAllRight();
      setSelectionButtonLabel("Deselect All");
    } else {
      handleAllLeft();
      setSelectionButtonLabel("Select All");
    }
  };

  const ClearSelection = () => {
    handleAllLeft();
  };

  const handleAllRight = () => {
    setRight(right.concat(left).sort(comparer));
    setChecked([]);
    setLeft([]);
  };

  const comparer = (a: any, b: any) => {
    if (a.mineName < b.mineName) {
      return -1;
    }
    if (a.mineName > b.mineName) {
      return 1;
    }
    return 0;
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked).sort(comparer));
    setLeft(not(left, leftChecked).sort(comparer));
    setChecked([]);
    // setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked).sort(comparer));
    setRight(not(right, rightChecked).sort(comparer));
    // setChecked(not(checked, rightChecked));
    setChecked([]);
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right).sort(comparer));
    setChecked([]);
    setRight([]);
  };
  const handleHideUnasseableChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHideUnasseableChecked(event.target.checked);
  };
  const handleVacatedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVacatedCheckedChecked(event.target.checked);
  };

  const submitReportParameters = () => {

    const data = {
      selectedColumns: selectedColumns,
      selectedMines: right,
      startDateRange: selectedStartDate,
      endDateRange: selectedEndDate,
      isVacatedChecked: vacatedChecked,
      isHideUnasseableChecked: hideUnasseableChecked,
      companyid: companyid,
    };
    setProcesssing(true);
    dispatch(getCompanySummary(data, submitSuccessed, submitError));
  };

  const mapReportData = () => {
    return {
      selectedColumns: selectedColumns,
      selectedMines: right,
      startDateRange: selectedStartDate,
      endDateRange: selectedEndDate,
      isVacatedChecked: vacatedChecked,
      isHideUnasseableChecked: hideUnasseableChecked,
      companyid: companyid,
    };
  };
  const downloadExcelReport = () => {
    const data = mapReportData();
    setProcesssing(true);
    dispatch(
      downloadExcelSummaryReport(
        data,
        () => {
          setProcesssing(false);
          enqueueSnackbar("Report data generated successfully..", {
            variant: "success",
          });
        },
        submitError
      )
    );
  };

  const download8KExcelReport = () => {
    const data = mapReportData();
    setProcesssing(true);
    dispatch(
      download8KReport(
        data,
        () => {
          setProcesssing(false);
          enqueueSnackbar("Report data generated successfully..", {
            variant: "success",
          });
        },
        submitError
      )
    );
  };

  const download10KExcelReport = () => {
    const data = mapReportData();
    setProcesssing(true);
    dispatch(
      download10KReport(
        data,
        () => {
          setProcesssing(false);
          enqueueSnackbar("Report data generated successfully..", {
            variant: "success",
          });
        },
        submitError
      )
    );
  };

  const reportCols = [
    { 
      title: "Mine", 
      field: "mineName", 
      sorting: true,
      render: (rowData: any) => {
        return (
          <div onClick={() => {
            history.push(`/Mines/${rowData.minePK}/Citations`)
          }}>
            {rowData.mineName}
          </div>
        );
      },
    },
    { title: "Business Unit", field: "businessUnitName", sorting: true },
    { title: "Citations", field: "numIssuances", sorting: true },
    { title: "S&S", field: "numSignificantIssuances", sorting: true },
    { 
		title: "S&S Rate", 
		field: "rateOfSignificantIssuances", sorting: true,
		render: (rowData: any) => {
			return(
				rowData.rateOfSignificantIssuances.toFixed(2) + '%'
			)

		}
	},
    { 
		title: "MSHA Penalty Total", 
		field: "totalMshaCurrentPenalty", 
		sorting: true,
		render: (rowData: any) => {
			return(
				numberToCurrency(
					Math.round(
						rowData.totalMshaCurrentPenalty *
						100
					) / 100
				  )
			)

		}
	},
    {
      title: "MSHA Penalty Average",
      field: "averageMshaCurrentPenalty",
      sorting: true,
	  render: (rowData: any) => {
		return(
			numberToCurrency(
				Math.round(
					rowData.averageMshaCurrentPenalty *
					100
				) / 100
			  )
		)

	}
    },
    // { title: "Permanently", field: "permanently", sorting: true },
    { title: "MSHA Inspection Hours", field: "numMshaDownloadedInspectionHours", sorting: true },
  ];

  const [reportData, setReportData] = React.useState<MineComparison[]>([]);
  const [reportSummaryData, setReportSummaryData] = React.useState<
    MineComparison[]
  >([]);
  const [reportColumn, setReportColumn] = React.useState<any[]>([]);
  const [tableKey, setTableKey] = React.useState<string>("table_");

  useEffect(() => {

    setReportColumn(reportCols);
    setTableKey("table_" + new Date().toUTCString());
  }, []);

  const submitSuccessed = (data: any[]) => {
    const columnList = selectedColumns.map((column: any) => {
      const returnCol = {
      title: column.header,
      field: column.dataField,
      cellStyle: {
        width: 150,
        maxWidth: 150,
      },
      headerStyle: {
        width: 150,
        maxWidth: 150,
      },
      sorting: true,
    } as any
    if (returnCol.title === 'Mine'){
      returnCol.render = (rowData: any) => {
        if (rowData.mineName === 'Totals'){
          return rowData.mineName
        }
        return (
          <div className="linklike" onClick={() => {
            history.push(`/Mines/${rowData.minePK}/Citations`)
          }}>
            {rowData.mineName}
          </div>
        );
      }
    }  
    return returnCol;
  })

    // console.log("setting report columns", columnList)
    // setReportColumn(columnList);

    setTableKey("table_" + new Date().toUTCString());
    setProcesssing(false);

    if (data.length > 1) {
	  data.forEach((x) => {
		  x.totalMshaCurrentPenalty = Number(x.totalMshaCurrentPenalty.replace(/[^0-9-]+/g,""))/100
		  x.averageMshaCurrentPenalty = Number(x.averageMshaCurrentPenalty.replace(/[^0-9-]+/g,""))/100
		  x.numIssuances = Number(x.numIssuances);
		  x.numSignificantIssuances = Number(x.numSignificantIssuances);
		  x.rateOfSignificantIssuances = Number(x.rateOfSignificantIssuances.replace('%', ''));
		  x.numMshaDownloadedInspectionHours = Number(x.numMshaDownloadedInspectionHours);
		})
      setReportData(data);
      setReportSummaryData(data.filter((c: any) => c.mineName == "Totals"));

      enqueueSnackbar("Report data generated successfully..", {
        variant: "success",
      });
    }
  };
  const submitError = (message: string) => {
    setProcesssing(false);
    enqueueSnackbar(message, {
      variant: "error",
    });
  };

  const customList = (items: readonly IMine[]) => (
    <Paper style={{ width: "25em", height: 230, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items.map((value: IMine) => {
          const labelId = `transfer-list-item-${value.pk}-label`;

          return (
            <ListItem
              key={value.pk}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.mineName}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <>
      <Loading loading={processsing} />
      <Grid container>
        <Grid item>
          <div style={{ marginTop: "1.3em", marginRight: "1.3em" }}>
            View statistics for issuances between
          </div>
        </Grid>
        <Grid item>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            value={selectedStartDate}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
        <Grid item>
          <div style={{ margin: "1.3em" }}> and </div>
        </Grid>
        <Grid item>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            value={selectedEndDate}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={classes.buttonLeft}
            onClick={(evet: any) => toggleSelection()}
          >
            {selectionButtonLabel}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item>{customList(left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList(right)}</Grid>
      </Grid>
      <div className={classes.panelSpacing}>
        <Grid container>
          <Grid item xs={6} lg={6}>
            <div style={{ display: "flex" }}>
              <div>
                <Checkbox
                  checked={hideUnasseableChecked}
                  onChange={handleHideUnasseableChange}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
              <div style={{ marginTop: "0.7em" }}>
                {" "}
                Hide Un-assessable Citations
              </div>
              <div>
                <Checkbox
                  checked={vacatedChecked}
                  onChange={handleVacatedChange}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
              <div style={{ marginTop: "0.7em" }}> Hide Vacated Citations</div>
            </div>
          </Grid>
          <Grid item xs={6} lg={6}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid item>
                <strong style={{ marginRight: 20 }}>Columns to Display</strong>
                <Select
                  id="citation-mutiple-checkbox"
                  multiple
                  label="Columns"
                  value={selectedColumns}
                  onChange={onChangeSelectedColumnsColumns}
                  input={<Input />}
                  renderValue={onRenderColumnValues}
                  style={{
                    width: 200,
                  }}
                >
                  {summaryColumns.map((option: any) => (
                    <MenuItem key={option.index} value={option}>
                      <Checkbox checked={isSummaryColumnSelected(option)} />
                      <ListItemText primary={option.header} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12} lg={12} md={12} alignItems="flex-end">
          <Grid item xs={6} lg={6}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={submitReportParameters}
            >
              Update
            </Button>
          </Grid>
          <Grid item xs={6} lg={6}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid item>
                <a href="#" onClick={downloadExcelReport}>
                  Export to Excel |
                </a>
                <a
                  href="#"
                  onClick={download8KExcelReport}
                  style={{ marginLeft: "1em" }}
                >
                  8K Export |
                </a>
                <a
                  href="#"
                  onClick={download10KExcelReport}
                  style={{ marginLeft: "1em" }}
                >
                  10Q/10K Export{" "}
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid style={{ marginTop: 10 }} container xs={12} lg={12} md={12}>
        <Grid item xs={12} lg={12} md={12}>
          {reportColumn.length > 0 && reportData.length > 0 && (
            <MaterialTable
              key={tableKey}
              icons={tableIcons}
              columns={reportColumn}
              title={"Mine Statistics"}
              data={reportData}
              options={{
                // sorting: true,
                paging: true,
                pageSize: reportData.length,
                draggable: false,
              }}
              components={{
                Body: (props) => {
                  const detailData = Object.values(
                    cloneDeep(props.renderData)
                  ).filter((c: any) => c.mineName != "Totals");

                  const summaryProps = cloneDeep(props);
                  const detailsProps = cloneDeep(props);
                  summaryProps.renderData = reportSummaryData;
                  summaryProps.pageSize = 1;
                  summaryProps.currentPage = 0;
                  summaryProps.columns.forEach((element: any) => {
                    element.cellStyle = {
                      fontWeight: "bold",
                      backgroundColor: "rgb(237, 237, 237)",
                    };
                    return element;
                  });
                  detailsProps.renderData = detailData;
                  return (
                    <>
                      <MTableBody {...summaryProps} />
                      <MTableBody {...detailsProps} />
                    </>
                  );
                },
              }}
            />
          )}
          {reportData.length == 0 && (
            <div className="row">
              <div
                className="col-12"
                style={{
                  background: "white",
                  minHeight: 100,
                  padding: 20,
                  textAlign: "center",
                }}
              >
                Mine Statistics Results
              </div>
            </div>
          )}
          <br />
          <br />
          <span>
            * Indicates that an inspection has been ongoing for more than 2
            years.
          </span>
        </Grid>
      </Grid>
    </>
  );
};

export default MineSummary;
