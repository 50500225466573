import { all, call, put } from "redux-saga/effects";
import { ACTIONS } from "../../enums/actions";
import { HttpResponse } from "../../interfaces/api/HttpResponse";
import { IDispatchAction } from "../../interfaces/store";
import HttpClient from "../../lib/HttpClient";
import GetFriendlyError from "../../utils/general";
import { normalize, schema } from "normalizr";
const client = new HttpClient();
const mineSchema = new schema.Entity("mines", {}, { idAttribute: "pk" });
const companySchema = new schema.Entity("companies", {}, { idAttribute: "pk" });

async function getAllMinesGroupByCompanyAPI(data: {}): Promise<
  HttpResponse<{ x: any }>
> {
  return client.get("api/mine/all-mines-group-by-company", data);
}

async function getMinesByCompanyAPI(
  data: any
): Promise<HttpResponse<{ x: any }>> {
  return client.get(
    "api/mine/mines-by-company?companypk=" + data.companypk,
    data
  );
}

async function getMineAPI(data: any): Promise<HttpResponse<{ x: any }>> {
  return client.get("api/mine/get?pk=" + data.pk, data);
}

async function getAllColumnMinesSummaryAPI(pk: any): Promise<HttpResponse<{ x: any }>> {
  return client.get(`api/company/${pk}/summary/report/columns`);
}

async function getMineRegulationWarningAPI(payload: any): Promise<HttpResponse<{ x: any }>> {
	console.log("the payload", payload)
	return client.get(`api/mine/${payload.pk}/regulation-warning`);
  }

async function createMineAPI(data: any): Promise<HttpResponse<any>> {
  return client.post(`api/mine`, data);
}

async function updateMineAPI(data: any): Promise<HttpResponse<any>> {
  return client.put("api/mine", data);
}

async function deleteMineAPI(id: Number): Promise<HttpResponse<any>> {
  return client.delete(`api/mine/${id}`);
}

const mineSaga = {
  *createMine(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        createMineAPI,
        payload
      )) as HttpResponse<any>;

      if (response.status === 200 || response.status === 201) {
        yield all([
          put({
            type: "CREATE_MINE_SUCCESS",
            normalized: normalize(response.data, [mineSchema]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess(response.data.pk);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *updateMine(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        updateMineAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        yield put({
          type: "UPDATE_MINE_SUCCESS",
          normalized: normalize(response.data, [mineSchema]),
        });
        if (action.onSuccess) {
          action.onSuccess(response.data.pk);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *deleteMine(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        deleteMineAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        const isItemDelete: boolean = response.data;
        if (isItemDelete) {
          let removeKeys = [];
          removeKeys.push({
            entities: "mines",
            ids: Array.of(payload),
          });
          yield put({
            type: "DELETE_MINE_SUCCESS",
            removeKeys: removeKeys,
          });

          if (action.onSuccess) {
            action.onSuccess(response.data);
          }
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
  *getMine(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        getMineAPI,
        action.payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
        yield put({
          type: "GET_MINE_SUCCESS",
          normalized: normalize(response.data, mineSchema),
        });
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getAllColumnsMinesSummary(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        getAllColumnMinesSummaryAPI,
        action.payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
		//	console.log("RAW DATA", JSON.stringify(response.data))
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getMinesByCompany(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        getMinesByCompanyAPI,
        action.payload
      )) as HttpResponse<any>;

      if (response.status === 200 || response.status === 201) {
        yield put({
          type: "GET_MINE_BY_COMPANY_SUCCESS",
          normalized: normalize(response.data, [mineSchema]),
        });

        if (action.onSuccess) {
          action.onSuccess();
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getAllMinesGroupByCompany(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        getAllMinesGroupByCompanyAPI,
        {}
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess();
        }
        yield all([
          put({
            type: "GET_ALL_MINES_GROUP_BY_COMPANY_SUCCESS",
            normalized: normalize(response.data.mines, [mineSchema]),
          }),
          put({
            type: "GET_ALL_MINES_GROUP_BY_COMPANY_SUCCESS",
            normalized: normalize(response.data.companies, [companySchema]),
          }),
        ]);
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getMineRegulationWarning(action: IDispatchAction): Generator {
    try {
      const response = (yield call(
        getMineRegulationWarningAPI,
        action.payload
      )) as HttpResponse<any>;
	  console.log('getMineRegulationWarning response', response)
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
        yield put({
			type: "GET_MINE_NUM_ISSUED_PER_REGULATION_SUCCESS",
			setKeys: [{
			  stateKey: 'mine_context',
			  key: 'regulationWarning',
			  value: response.data
			}]
		})
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
};

export default mineSaga;
