import React, { useEffect, useState } from "react";
import { Button, Container, Paper } from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { WrapperVariant } from "@material-ui/pickers/wrappers/Wrapper";
import { add, format, isValid } from "date-fns";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getPaternOfViolations } from "../../store/actions/citationActions";
import { get } from "lodash";

interface IPatternOfViolationsProps { }

const PatternOfViolations: React.FC<IPatternOfViolationsProps> = (props) => {

  const [povEndDate, setPovEndDate] = useState<Date>(new Date())
  const [loading, setLoading] = useState(true);
  const [patternOfViolations, setPatternOfViolations] = useState<any []>([]);
  const [numGroupAMet, setNumGroupAMet] = useState(0);
  const [numGroupBMet, setNumGroupBMet] = useState(0);
  const dispatch = useDispatch();
  const {minepk} = useParams() as any;
  const history = useHistory();
  
  useEffect (() => {
    fetchPatternOfViolations();
  }, [])

  const fetchPatternOfViolations = () => {
    if (!isValid(povEndDate)){
      return;
    }
    dispatch(getPaternOfViolations(
      {
        minePk: minepk,
        povCalcEndDate: format(povEndDate, "yyyy-MM-dd")
      },
      (results: any) => {
        console.log("results on success", results)
        setLoading(false)
        setPatternOfViolations(results.data)
        let level0 = get(results, 'data[0].level', 0)
        let level1 = get(results, 'data[1].level', 0)
        let level2 = get(results, 'data[2].level', 0)
        let level3 = get(results, 'data[3].level', 0)
        let level4 = get(results, 'data[4].level', 0)
        let level5 = get(results, 'data[5].level', 0)
        let totalA = 0;
        let totalB = 0;
        if (Number(level0) >= 1){
          totalA++;
        }
        if (Number(level1) >= 1){
          totalA++;
        }
        if (Number(level2) >= 1){
          totalA++;
        }
        if (Number(level3) >= 1){
          totalA++;
        }
        if (Number(level4) >= 1){
          totalB++;
        }
        if (Number(level5) >= 1){
          totalB++;
        }
        setNumGroupAMet(totalA);
        setNumGroupBMet(totalB);
      }
    ))
  }

  const povLowRiskTable = () => {
    return (
      <Paper className="custom-pc-table-pov">
        <div style={{ flex: 1, padding: "1rem" }}>
          <p>Pattern of Violations - Low Risk</p>
          <p
            style={{
              color: "green",
            }}
          >
            - This mine has met {numGroupAMet} out of 4 Group A criteria <br></br>- This mine
            has met {numGroupBMet} out of 2 Group B criteria
          </p>
        </div>
        <div style={{ flex: 1, padding: "1rem" }}>
          <p>Entered Inspection Hours: 60</p>
          <Button variant="outlined" size="small" onClick={()=> history.push(`/Mines/${minepk}/Inspections`)}>
            View Inspection Hours
          </Button>
        </div>
      </Paper>
    );
  };

  const renderDateRange = () => {
    if (!isValid(povEndDate)){
      return null;
    }
    //isValid
    return ( <>
    {format(add(povEndDate, {years: -1}), "MM/dd/yyyy")} to {format(povEndDate, "MM/dd/yyyy")}
    </>)
  }

  const informationTable = () => {
    return (
      <div className="custom-pc-table-information">
        <p>Please enter the following information.</p>
        <Paper>
          <div style={{ flex: 1, padding: "1.5rem" }}>
            POV Calculation End Date
            <p>POV Date Range</p>
          </div>
          <div style={{ flex: 1, padding: "1.5rem" }}>
            <KeyboardDatePicker
              size="small"
              inputVariant="outlined"
              disableToolbar
              variant={"view" as WrapperVariant}
              format="MM/dd/yyyy"
              margin="none"
              value={povEndDate}
              onChange={(date) => { 
                setPovEndDate(date as Date) 
                fetchPatternOfViolations();
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }} />
            <p>
            {renderDateRange()}
            </p>
            {/* <Button variant="outlined" size="small">
              Update POV
            </Button> */}
          </div>
        </Paper>
      </div>
    );
  };

  const renderCell = (attributePath: string) => {
    return <div contentEditable='true' dangerouslySetInnerHTML={{ __html: get(patternOfViolations, attributePath, '') }}></div>
  }

  const renderLevel = (attributePath: string) => {
    let value:any =  get(patternOfViolations, attributePath, '0.0')
    if (isNaN(value) ){
      return '0.0'
    }
    else{
      value = Number(value);
    }
    return <div contentEditable='true' dangerouslySetInnerHTML={{ __html: value.toFixed(1) }}></div>
  }

  const renderRiskRow = (rowIndex: number) => {
    return (
      <>
        <tr>
          <td>{rowIndex + 1}</td>
          <td>
            {renderCell('[' + rowIndex + '].criterionDescription')}
          </td>
          <td>
            {renderCell('[' + rowIndex + '].statusText')}
          </td>
          <td>{renderLevel('[' + rowIndex + '].level')}</td>
        </tr>
        <tr>
          <td colSpan={4}>{renderCell('[' + rowIndex + '].message')}</td>
        </tr>
      </>
    )
  }

  const criteriaA = () => {
    return (
      <Paper className="custom-pc-table-criteria">
        <table>
          <thead>
            <tr>
              <th>Criteria</th>
              <th>Criteria Group A</th>
              <th>Details</th>
              <th>Risk</th>
            </tr>
          </thead>
          <tbody>
            {renderRiskRow(0)}
            {renderRiskRow(1)}
            {renderRiskRow(2)}
            {renderRiskRow(3)}
          </tbody>
        </table>
      </Paper>
    );
  };

  const criteriaB = () => {
    return (
      <Paper className="custom-pc-table-criteria">
        <table>
          <tbody>
            <tr style={{ textAlign: "center" }}>
              <td>Criteria</td>
              <td>Criteria Group B</td>
              <td>Details</td>
              <td>Risk</td>
            </tr>
            {renderRiskRow(4)}
            {renderRiskRow(5)}
          </tbody>
        </table>
      </Paper>
    );
  };

  return (
    <Container
      className="custom-pc-table-main"
      style={{
        backgroundColor: COLORS.WHITE_PAPER,
        minHeight: "100vh",
        padding: 0,
      }}
    >
      {povLowRiskTable()}
      <br></br>
      {informationTable()}
      <br></br>
      <br></br>
      {!loading && <>
      {criteriaA()}
      <br></br>
      {criteriaB()}
      </>}
      <br></br>
      <p>
        Note that pattern of violations rely on data other than that presented
        here. The data presented here is based upon the 'Initial Screen Criteria
        (30 CFR § 104.2)'.
      </p>
    </Container>
  );
};

export default PatternOfViolations;

