import { Button, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import MaterialTable from "material-table";
import { tableIcons } from "../shared/MaterialTableShared";
import EditIcon from "@material-ui/icons/Edit";
import { ICompanyEmailSettingModel } from "../../interfaces/model/MSHAPCT/company-email-settings";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import DialogBox from "../form/DialogBox";
import {
  createCompanyEmailSettings,
  deleteCompanyEmailSettings,
  getCompanyEmailSettings,
  updateCompanyEmailSettings,
} from "../../store/actions/emailSettingsActions";
import EditAdminEmailSettingsModal from "./EditAdminEmailSettingsModal";
import { useParams } from "react-router";

interface ICompanyEmailSettingsTable {
  data: ICompanyEmailSettingModel[];
  title?: string;
}

const CompanyEmailSettingsTable: React.FC<ICompanyEmailSettingsTable> = (
  props
) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState({} as any);
  const [selectedRow, setSelectedRow] = useState(0);
  const [editEmailSettingsModalOpen, setEditEmailSettingsModalOpen] =
    useState(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const onSubmitActionModal = (
    data: any,
    onSuccess: Function,
    onFail?: Function
  ) => {
    if (data.pk) {
      dispatch(
        updateCompanyEmailSettings(
          data,
          () => {
            onSuccessAction(onSuccess);
          },
          onFail
        )
      );
    } else {
      dispatch(
        createCompanyEmailSettings(
          { data: data, companyId: companypk },
          () => {
            onSuccessAction(onSuccess);
          },
          onFail
        )
      );
    }
  };

  const { companypk } = useParams() as any;
  const onSuccessAction = (fnModalAction: any) => {
    fnModalAction();
    setEditEmailSettingsModalOpen(false);
  };

  const emptySet = {
    pk: 0,
    companyFK: 0,
    groupName: "",
    toEmailAddress: "",
    ccEmailAddress: "",
    negligenceNone: false,
    negligenceLow: false,
    negligenceModerate: false,
    negligenceHigh: false,
    negligenceRecklessDisregard: false,
    likelihoodNoLikelihood: false,
    likelihoodUnlikely: false,
    likelihoodReasonablyLikely: false,
    likelihoodHighlyLikely: false,
    likelihoodOccured: false,
    injuryOrIllnessNoLostWorkdays: false,
    injuryOrIllnessLostWorkdays: false,
    injuryOrIllnessPermanentlyDisabling: false,
    injuryOrIllnessFatal: false,
    significantAndSubstantial: false,
    minimumNumberOfPersonsAffected: "",
    sendCitationNotifications: false,
    citationNotificationSchedule: 0,
    sendInspectionNotifications: false,
    inspectionNotificationSchedule: 0,
    sendEventNotifications: false,
    eventNotificationSchedule: 0,
    povMediumRisk: false,
    povHighRisk: false,
    sendPovNotifications: false,
    povNotificationSchedule: 0,
    minimumCitationAmount: "",
    selectedCitationColumns: [],
    selectedInspectionColumns: [],
  };

  const ongetCompanyEmailSettingDetail = (settingId: any) => {
    dispatch(
      getCompanyEmailSettings(settingId, (data) => {
        setRowData(data);
        setEditEmailSettingsModalOpen(true);
      })
    );
  };

  const onDeleteEmailSettingAction = (settingId: number) => {
    dispatch(
      deleteCompanyEmailSettings(
        settingId,
        onDeleteEmailSettingSuccess,
        onDeleteEmailSettingFail
      )
    );
  };

  const onDeleteEmailSettingSuccess = (wasAdded: boolean) => {
    enqueueSnackbar("Campany Email Setting was successfully deleted.", {
      variant: "success",
    });
  };

  const onDeleteEmailSettingFail = (message: string) => {
    enqueueSnackbar("Unable to delete company email setting.", {
      variant: "error",
    });
  };

  const onOkDialogAction = () => {
    onDeleteEmailSettingAction(selectedRow);
    setIsDeleteDialogOpen(false);
  };

  const onCancelOrCloseDialogAction = () => {
    setIsDeleteDialogOpen(false);
    setSelectedRow(0);
  };

  const columns = [
    { title: "Group Name", field: "groupName", sorting: true },
    { title: "To Email Address(es)", field: "emailTo", sorting: true },
    { title: "Cc Email Address(es)", field: "copyTo", sorting: true },
    {
      title: "Actions",
      render: (row: any) => {
        return (
          <div>
            <Tooltip title="Edit" placement="top">
              <EditIcon
                style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
                className="transparent75"
                onClick={() => {
                  if (row && row.pk) {
                    ongetCompanyEmailSettingDetail(row.pk);
                  }
                }}
              />
            </Tooltip>

            <Tooltip title="Delete" placement="top">
              <DeleteIcon
                style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
                className="transparent75"
                onClick={() => {
                  if (row.pk) {
                    setIsDeleteDialogOpen(true);
                    setSelectedRow(row.pk);
                  }
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <DialogBox
        id="dialogbox1"
        open={isDeleteDialogOpen}
        onClose={onCancelOrCloseDialogAction}
        onCancel={onCancelOrCloseDialogAction}
        onOk={onOkDialogAction}
        title="Are you sure you want to delete the email setting?"
        innerText="After deleting the email setting you will not be able to roll it back."
      ></DialogBox>

      <EditAdminEmailSettingsModal
        itemData={rowData}
        open={editEmailSettingsModalOpen}
        onClose={() => setEditEmailSettingsModalOpen(false)}
        title="Company Email Settings"
        onSubmit={onSubmitActionModal}
        isEditMode={rowData.pk ? true : false}
        isCompany={true}
      />

      <Button
        style={{ marginBottom: 5 }}
        onClick={() => {
          setRowData(emptySet);
          setEditEmailSettingsModalOpen(true);
        }}
        variant="outlined"
        size="small"
      >
        Add Company Email Settings
      </Button>
      <MaterialTable
        title="Company Email Notification Groups"
        icons={tableIcons}
        columns={columns}
        data={props.data}
        options={{
          sorting: true,
          headerStyle: {
            zIndex: 8,
          },
          paging: true,
          pageSize: 5,
          draggable: false,
          //actionsColumnIndex: -1
        }}
      />
    </>
  );
};

export default CompanyEmailSettingsTable;
