export enum ACCOUNT_VALIDATION_TYPE
{
    NEW_ACCOUNT = 1,
    RESET_PASSWORD = 2
}

export const ACCOUNT_FLAGS = {
    anonymouse: 0,
    system_admin: 1,
    company_admin: 2,
    company_account: 4,
    is_enabled: 8,
    is_read_only: 16,
    allow_8k_export: 32
}