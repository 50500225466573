import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { IRootState } from "../../interfaces/store";
import { getAllMinesGroupByCompany } from "../../store/actions/mineActions";
import {
  ICompany,
  ICompanyWithMines,
} from "../../interfaces/model/MSHAPCT/company.interface";
import { IMine } from "../../interfaces/model/MSHAPCT/mine.interface";
import { APP_BREAKPOINT } from "../../enums/layout-enums";
import { useHistory } from "react-router-dom";
import { MAIN_TAB } from "../../interfaces/store/main";
import { setClientOptions } from "../../store/actions/clientActions";
import Loading from "../shared/Loading";

import ExpandMoreIcon from "@material-ui/icons/ExpandMoreOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { orderBy } from "lodash";
import EditIcon from "@material-ui/icons/EditOutlined";
import { getContractors } from "../../store/actions/contractorActions";
import { jwtToken } from "../../utils/token-utils";
interface IMineDashboard {
  AppBreakpoint: APP_BREAKPOINT;
}

const MineDashboard: React.FC<IMineDashboard> = (props) => {
  const dispatch = useDispatch();

  let history = useHistory();

  const [companiesWithMines, setCompaniesWithMines] = useState(
    [] as ICompanyWithMines[]
  );
  const [loading, setLoading] = useState(true);
  const [expandedCompanies, setExpandedCompanies] = useState([] as number[]);
  const [expandAllState, setExpandAllState] = useState(false);
  const [expandAllEnabled, setExpandAllEnabled] = useState(false);

  const contractors = useSelector((state: IRootState) =>
    orderBy(Object.values(state.main.contractors), "contractorName")
  );

  useEffect(() => {
    dispatch(
      getAllMinesGroupByCompany(() => {
        console.log("setting false?");
        setLoading(false);
      })
    );
    dispatch(getContractors(() => {}));
    dispatch(setClientOptions({ selected_tab: MAIN_TAB.MINES_CONTRACTORS }));
  }, []);

  const mines = useSelector((state: IRootState) => state.main.mines);

  const companies = useSelector((state: IRootState) => state.main.companies);

  const handleClickMine = (pk: number) => {
    history.push("/Mines/" + pk + "/Citations");
  };

  const handleClickContractor = (pk: any) => {
    history.push("/Mines/-1/Contractor/" + pk);
  };

  useEffect(() => {
    const companiesWithMines = Object.values(companies) as ICompanyWithMines[];
    const minesArr = Object.values(mines);

    if (!companiesWithMines.length || !minesArr.length) {
      return;
    }

    minesArr.forEach((mine: IMine) => {
      const company = companiesWithMines.find(
        (company: ICompany) => mine.companyFk == company.pk
      );
      if (company) {
        if (!company.mines) {
          company.mines = [];
        }
        company.mines.push(mine);
      }
    });
    for (let i = 0; i < companiesWithMines.length; i++) {
      if (companiesWithMines[i]?.mines?.length) {
        companiesWithMines[i].mines = orderBy(
          companiesWithMines[i].mines,
          "mineName"
        );
      }
    }

    if (companiesWithMines.length < 5) {
      setExpandedCompanies(companiesWithMines.map((x) => x.pk || -1));
    } else {
      setExpandAllEnabled(true);
    }
    setCompaniesWithMines(orderBy(companiesWithMines, "companyName"));
  }, [mines, companies]);

  let columns = 4;

  if (props.AppBreakpoint === APP_BREAKPOINT.MEDIUM) {
    columns = 3;
  } else if (
    props.AppBreakpoint === APP_BREAKPOINT.SMALL ||
    props.AppBreakpoint === APP_BREAKPOINT.EXTRA_SMALL
  ) {
    columns = 2;
  }

  const handleExpandCompany = (pk: number) => {
    const index = expandedCompanies.indexOf(pk);
    let newExpandedCompanies = [...expandedCompanies];
    if (index > -1) {
      console.log("was there", pk);
      newExpandedCompanies.splice(index, 1);
    } else {
      console.log("wasn't there", pk);
      newExpandedCompanies.push(pk);
    }
    setExpandedCompanies(newExpandedCompanies);
  };

  const toggleExpanded = () => {
    setExpandAllState(!expandAllState);
    if (expandAllState === false) {
      setExpandedCompanies(companiesWithMines.map((x) => x.pk || -1));
    } else {
      setExpandedCompanies([]);
    }
  };

  return (
    <>
      <Loading loading={loading} />
      <div style={{ fontSize: 10 }}>
        This page contains link to the mines and contractors that you have
        access to. If you don't see a mine that you should be able to access,
        please contact your company representative.
      </div>
      {(jwtToken()?.IsSysAdmin || !jwtToken()?.ContractorId) && (
        <>
          <h2>
            Mines{" "}
            {expandAllEnabled && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => toggleExpanded()}
              >
                {expandAllState === true ? (
                  <span>Minimize All</span>
                ) : (
                  <span>Expand All</span>
                )}
              </Button>
            )}
          </h2>
          <div style={{ columnCount: columns }}>
            {companiesWithMines.map((companyWithMine, companyindex) => {
              return companyWithMine.mines?.length ? (
                <div style={{ breakInside: "avoid-column", marginBottom: 8 }}>
                  <Accordion
                    expanded={expandedCompanies.includes(companyWithMine.pk!)}
                    onChange={() => handleExpandCompany(companyWithMine.pk!)}
                    TransitionProps={{ unmountOnExit: true }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography>{companyWithMine.companyName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div key={companyindex}>
                        <Tooltip
                          title={"Edit Company"}
                          style={{ marginRight: 8 }}
                        >
                          <EditIcon
                            onClick={() => {
                              history.push("/Company/" + companyWithMine.pk);
                            }}
                            style={{
                              cursor: "pointer",
                              fontSize: 24,
                            }}
                          />
                        </Tooltip>
                        <Tooltip title={"Mine Summary"}>
                          <span
                            style={{
                              marginLeft: 8,
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              history.push(
                                "/Mines/company/summary/" + companyWithMine.pk
                              );
                            }}
                          >
                            Mine Summary
                          </span>
                          {/* <ViewCompactIcon
                        onClick={() => {
                          history.push("/Mines/company/summary/" + companyWithMine.pk);
                        }}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          fontSize: 24,
                        }}
                      /> */}
                        </Tooltip>
                        <hr />
                        <div>
                          {companyWithMine.mines.map(
                            (mine: IMine, mineindex) => {
                              return (
                                <div
                                  style={{ cursor: "pointer" }}
                                  key={mineindex}
                                  onClick={() => {
                                    handleClickMine(mine.pk!);
                                  }}
                                >
                                  {mine.mineName} ({mine.mineId})
                                </div>
                              );
                            }
                          )}
                        </div>
                        <br />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ) : null;
            })}
          </div>
        </>
      )}
      {(jwtToken()?.ContractorId || jwtToken()?.IsSysAdmin) && (
        <div>
          <h2>Contractors</h2>
          <div style={{ columnCount: columns }}>
            {contractors.map((contractor, contractorIndex) => {
              return (
                <div style={{ breakInside: "avoid-column" }}>
                  <Typography>{contractor.contractorName}</Typography>

                  <div
                    style={{
                      fontSize: 12,
                      marginBottom: 16,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleClickContractor(contractor.pk);
                    }}
                  >
                    &nbsp;&nbsp;{contractor.contractorName} (
                    {contractor.contractorId})
                    {/* {JSON.stringify(contractor)} */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default MineDashboard;
