import { ACTIONS } from "../../enums/actions";

export const getContractors = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_CONTRACTORS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});
