import { Tooltip } from "@material-ui/core";
import React from "react";
import MaterialTable from "material-table";
import { tableIcons } from "../shared/MaterialTableShared";
import { IAccountLogin } from "../../interfaces/model/MSHAPCT/account-login.interface";
import EditIcon from "@material-ui/icons/Edit";

interface IAccountsTable {
  data: IAccountLogin[];
  title?: string;
  onSelectedItem: Function;
}

const AccountsTable: React.FC<IAccountsTable> = (props) => {
  const columns = [
    { title: "PK", field: "pk", sorting: true },
    { title: "First Name", field: "firstName", sorting: true },
    { title: "Last Name", field: "lastName", sorting: true },
    { title: "Email", field: "emailAddress", sorting: true },
    { title: "Validated", field: "isValidated", sorting: true },
    {
      title: "Actions",
      field: "isValidated",
      render: (rowData: any) => {
        return (
          <div>
            <Tooltip title="Edit" placement="top">
              <EditIcon
                style={{ fontSize: 16 }}
                className="transparent75"
                onClick={() => {
                  if (rowData && rowData.pk) {
                    props.onSelectedItem(rowData.pk);
                  }
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  console.log("props.data", props.data);

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        title={props.title || "Accounts"}
        columns={columns}
        data={props.data}
        options={{
          sorting: true,
          headerStyle: {
            zIndex: 8,
          },
          paging: true,
          pageSize: Math.min(props.data.length, 10),
          draggable: false,
          //actionsColumnIndex: -1
        }}
        onRowClick={(event, rowData, togglePanel) => {
          if (rowData && rowData.pk) {
          }
        }}
        // actions={[
        //     {
        //         icon: () => <EditIcon
        //         fontSize="small"
        //         className="transparent75"
        //         />,
        //         tooltip: 'Edit Account',
        //         onClick: (event, account: any) => {

        //         }
        //     },
        //     {
        //         icon: () => <BarChartIcon
        //         fontSize="small"
        //         className="transparent75"
        //         />,
        //         tooltip: 'Mine State',
        //         onClick: (event, account: any) => {

        //         }
        //     },
        //     {
        //         icon: () => <DeleteIcon
        //         fontSize="small"
        //         className="transparent75"
        //         />,
        //         tooltip: 'Delete Account',
        //         onClick: (event, account: any) => {

        //         }
        //     },
        // ]}
      />
    </>
  );
};

export default AccountsTable;
