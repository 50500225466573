
import { ACTIONS } from "../../enums/actions";

export const getMineState = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
    type: ACTIONS.GET_MINE_STATE,
    payload,
    onSuccess: onSuccess || null,
    onFail: onFail || null
  });

  export const getControllers = (
    payload: any | null,
    onSuccess: Function | null = null,
    onFail: Function | null = null
  ) => ({
    type: ACTIONS.GET_CONTROLLERS,
    payload,
    onSuccess: onSuccess || null,
    onFail: onFail || null,
  });

  export const getVPIDRPIDHistory = (
    payload: any | null,
    onSuccess: Function | null = null,
    onFail: Function | null = null
  ) => ({
    type: ACTIONS.GET_VPIDRPID_HISTORY,
    payload,
    onSuccess: onSuccess || null,
    onFail: onFail || null,
  });

