import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { IRootState } from "../../interfaces/store";
import { getCitations } from "../../store/actions/citationActions";
import { arrayFromKeyedObject } from "../../utils/collection-utils";
import { get, sumBy } from "lodash";
import { useParams } from "react-router-dom";
import CitationTable from "../citations/CitationTable";
import { ICitationTableRow } from "../citations/CitationTable";
import { ICitation } from "../../interfaces/model/MSHAPCT/citation.interface";
import {
  CITATION_TABLE_FILTER,
  MSHA_CITATION_STATUS,
  PREDICTED_PENALTY_DISCREPANCY,
  typeOfActionToString,
  typeOfIssuanceToString,
  TYPE_OF_ACTION,
  TYPE_OF_ISSUANCE,
  typeOfNegligenceString,
  NEGLIGENCE,
} from "../../enums/citation-enums";
import { GetPenaltyDiscrepancy } from "../../services/citation.service";
import Loading from "../shared/Loading";
import { IGenericFilters } from "../shared/generic-filter.interface";
import { FILTER_TYPE } from "../../enums/table-enums";
import { isValidDate } from "../../utils/date-utils";
import { numberToCurrency } from "../../utils/general-utils";
import { Paper } from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";
import { title } from "process";
interface ICitations {}

export const initialGenericFilters = {
  typeOfAction: {
    options: [
      {
        title: typeOfActionToString(TYPE_OF_ACTION._104_A),
        key: TYPE_OF_ACTION._104_A,
      },
      {
        title: typeOfActionToString(TYPE_OF_ACTION._104_D_1),
        key: TYPE_OF_ACTION._104_D_1,
      },
      {
        title: typeOfActionToString(TYPE_OF_ACTION._104_D_2),
        key: TYPE_OF_ACTION._104_D_2,
      },
      {
        title: typeOfActionToString(TYPE_OF_ACTION._104_G),
        key: TYPE_OF_ACTION._104_G,
      },
      {
        title: typeOfActionToString(TYPE_OF_ACTION._107_A),
        key: TYPE_OF_ACTION._107_A,
      },
      {
        title: typeOfActionToString(TYPE_OF_ACTION._104_B),
        key: TYPE_OF_ACTION._104_B,
      },
      {
        title: typeOfActionToString(TYPE_OF_ACTION._104_F),
        key: TYPE_OF_ACTION._104_F,
      },
      {
        title: typeOfActionToString(TYPE_OF_ACTION._103_K),
        key: TYPE_OF_ACTION._103_K,
      },
      {
        title: typeOfActionToString(TYPE_OF_ACTION._104_E),
        key: TYPE_OF_ACTION._104_E,
      },
      {
        title: typeOfActionToString(TYPE_OF_ACTION._110_A),
        key: TYPE_OF_ACTION._110_A,
      },
      {
        title: typeOfActionToString(TYPE_OF_ACTION._103_J),
        key: TYPE_OF_ACTION._103_J,
      },
      {
        title: typeOfActionToString(TYPE_OF_ACTION._314_B),
        key: TYPE_OF_ACTION._314_B,
      },
    ],
    selectedOptions: [],
    multiple: true,
    type: FILTER_TYPE.MATCH,
  },
  typeOfIssuance: {
    options: [
      {
        title: typeOfIssuanceToString(TYPE_OF_ISSUANCE.CITATION),
        key: TYPE_OF_ISSUANCE.CITATION,
      },
      {
        title: typeOfIssuanceToString(TYPE_OF_ISSUANCE.ORDER),
        key: TYPE_OF_ISSUANCE.ORDER,
      },
      {
        title: typeOfIssuanceToString(TYPE_OF_ISSUANCE.SAFEGUARD),
        key: TYPE_OF_ISSUANCE.SAFEGUARD,
      },
      {
        title: typeOfIssuanceToString(TYPE_OF_ISSUANCE.WRITTEN_NOTICE),
        key: TYPE_OF_ISSUANCE.WRITTEN_NOTICE,
      },
    ],
    selectedOptions: [],
    multiple: true,
    type: FILTER_TYPE.MATCH,
  },
  penalty: {
    options: [],
    selectedOptions: [],
    inputValue: "",
    multiple: false,
    type: FILTER_TYPE.EQUAL_LESS_GREATER,
  },
  penaltygfe: {
    options: [],
    selectedOptions: [],
    inputValue: "",
    multiple: false,
    type: FILTER_TYPE.EQUAL_LESS_GREATER,
  },
  negligence: {
    options: [
      {
        title: typeOfNegligenceString(NEGLIGENCE.NONE),
        key: NEGLIGENCE.NONE,
      },
      {
        title: typeOfNegligenceString(NEGLIGENCE.LOW),
        key: NEGLIGENCE.LOW,
      },
      {
        title: typeOfNegligenceString(NEGLIGENCE.HIGH),
        key: NEGLIGENCE.HIGH,
      },
      {
        title: typeOfNegligenceString(NEGLIGENCE.MODERATE),
        key: NEGLIGENCE.MODERATE,
      },
      {
        title: typeOfNegligenceString(NEGLIGENCE.RECKLESS_DISREGARD),
        key: NEGLIGENCE.RECKLESS_DISREGARD,
      },
    ],
    selectedOptions: [],
    multiple: true,
    type: FILTER_TYPE.MATCH,
  },
  sectionOfCfr: {
    options: [],
    selectedOptions: [],
    multiple: true,
    type: FILTER_TYPE.MATCH,
  },
};

const twoYearsAgo = new Date();
twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);

// const useStyles = makeStyles((theme) => ({
//     backdrop: {
//       zIndex: theme.zIndex.drawer + 1,
//       color: '#fff',
//     },
//   }));

const MineCitations: React.FC<ICitations> = (props) => {
  const dispatch = useDispatch();
  const { minepk } = useParams<{ minepk: string }>();
  const { contractorpk } = useParams<{ contractorpk: string }>();

  const [citationRows, setCitationRows] = useState([] as ICitationTableRow[]);
  const [citationFetchCount, setCitationFetchCount] = useState(0);
  const [conferenceTrackingPk, setConferenceTrackingPk] = useState<
    number | undefined
  >(undefined);
  const [citationPk, setCitationPk] = useState<number | undefined>(undefined);
  const [citationMetaPk, setCitationMetaPk] = useState<number | undefined>(
    undefined
  );

  const [conferenceTrackingOpen, setConferenceTrackingOpen] = useState(false);

  const [filters, setFilters] = useState([] as CITATION_TABLE_FILTER[]);
  const [filtersGeneric, setFiltersGeneric] = useState(
    initialGenericFilters as IGenericFilters
  );
  const [startDate, setStartDate] = useState(
    localStorage.getItem("MineCitationFromDate")
      ? new Date(String(localStorage.getItem("MineCitationFromDate")))
      : twoYearsAgo
  );
  const [endDate, setEndDate] = useState(
    sessionStorage.getItem("MineCitationToDate")
      ? new Date(String(sessionStorage.getItem("MineCitationToDate")))
      : new Date()
  );
  const [loading, setLoading] = useState(false);
  const [doUpdate, setDoUpdate] = useState(true);

  const toggleDoUpdate = (val: boolean) => {
    setDoUpdate(val);
  };

  const citations = useSelector((state: IRootState) => state.main.citations);

  const citationWarnings = useSelector(
    (state: IRootState) => state.main.citation_warnings
  );

  const citationMetaData = useSelector(
    (state: IRootState) => state.main.citation_meta_data
  );

  useEffect(() => {
    if (doUpdate === true) {
      if (isValidDate(startDate) && isValidDate(endDate)) {
        setLoading(true);
        setDoUpdate(false);
        const contractorpk_safe = contractorpk ? contractorpk : "-1";
        dispatch(
          getCitations(
            {
              minepk,
              startDate,
              endDate,
              contractorpk: contractorpk_safe,
              override: true,
            },
            () => {
              setLoading(false);
              setCitationFetchCount(citationFetchCount + 1);
            },
            () => setLoading(false)
          )
        );
      }
    }
  }, [startDate, endDate, doUpdate]);

  const handleFilterChange = (newFilters: CITATION_TABLE_FILTER[]) => {
    setFilters(newFilters);
  };

  const handleFiltersGenericChange = (
    key: string,
    selectedOptions: any = null,
    inputValue: any = null
  ) => {
    let newSelections = [];
    if (selectedOptions !== null) {
      newSelections = filtersGeneric[key].multiple
        ? selectedOptions
        : [selectedOptions];
      if (selectedOptions.includes("clear_filters")) {
        newSelections = [];
      }
    }

    const setValue = {
      ...filtersGeneric,
      [key]: {
        ...filtersGeneric[key],
      },
    };
    if (selectedOptions !== null) {
      setValue[key].selectedOptions = newSelections;
    }

    if (inputValue !== null) {
      setValue[key].inputValue = inputValue;
    }
    console.log("setValue", setValue);
    setFiltersGeneric(setValue);
  };

  const handleChangeStart = (e: Date) => {
    setStartDate(e);
    localStorage.setItem("MineCitationFromDate", String(e));
  };

  const handleChangeEnd = (e: Date) => {
    setEndDate(e);
    sessionStorage.setItem("MineCitationToDate", String(e));
  };

  const maybePushFilter = (
    filter: CITATION_TABLE_FILTER,
    filterTags: CITATION_TABLE_FILTER[]
  ) => {
    filterTags.push(filter);
    if (filters.includes(filter)) {
      return 1;
    }
    return 0;
  };
  useEffect(() => {
    const setValue = {
      ...filtersGeneric,
      ["sectionOfCfr"]: {
        ...filtersGeneric["sectionOfCfr"],
      },
    };

    const citationsArr = arrayFromKeyedObject(citations) as ICitation[];

    let sectionOfCfrs: any = [];
    console.log("citationsArr", citationsArr);
    const uniquesections: any[] = citationsArr
      .map((x) => x.sectionOfCfr)
      .filter(
        (value, index, currentvalue) => currentvalue.indexOf(value) === index
      );

    console.log("uniquesections", uniquesections);
    uniquesections.map((x: string) => {
      sectionOfCfrs.push({
        title: x,
        key: x,
      });
    });

    console.log("sectionOfCfrs", sectionOfCfrs);
    setValue["sectionOfCfr"].options = sectionOfCfrs;
    console.log("setValue", setValue);
    setFiltersGeneric(setValue);
  }, [citations]);

  useEffect(() => {
    const citationsArr = arrayFromKeyedObject(citations) as ICitation[];
    const citationMetaArr = arrayFromKeyedObject(citationMetaData);
    const citationWarningsArr = Object.values(citationWarnings);
    let citationRows = [] as any[];

    let normalizedEndDay = new Date(endDate);
    normalizedEndDay.setHours(23, 59, 59);
    // normalizedEndDay = new Date(normalizedEndDay.setDate(normalizedEndDay.getDate() + 1));

    for (let cindex = 0; cindex < citationsArr.length; cindex++) {
      const associatedMeta = citationMetaArr.find(
        (cmd) => cmd.citationFk === citationsArr[cindex].pk
      );
      const validMineCitation =
        Number(citationsArr[cindex].mineFk) === Number(minepk) &&
        !isNaN(Number(minepk));
      const validStartDateCitation =
        new Date(citationsArr[cindex].dateIssued as Date) >= startDate;
      const validEndDateCitation =
        new Date(citationsArr[cindex].dateIssued as Date) <= normalizedEndDay;
      const validContractorCitation = Number(
        contractorpk &&
          Number(associatedMeta.contractorFk) === Number(contractorpk)
      );

      if (
        validStartDateCitation &&
        validEndDateCitation &&
        (validMineCitation || validContractorCitation)
      ) {
        // let citationWarnings = []
        const citationWarningObject = citationWarningsArr.find(
          (x) => x.citationPk === citationsArr[cindex].pk
        );
        const rpidWarnings = citationWarningObject?.warnings?.RPID;
        const hasRPID = rpidWarnings && rpidWarnings.length > 0;

        const filterTags = [] as CITATION_TABLE_FILTER[];
        const specialAssessment = get(associatedMeta, "specialAssessment", 0);
        const citationStatus = get(associatedMeta, "mshaCitationStatus", -1);
        const citationRPID = get(associatedMeta, "rPID", false);
        const vacated = get(associatedMeta, "vacated", -1);
        // if (specialAssessment !== null && specialAssessment !== undefined){
        //     console.log("specialAssessment", specialAssessment)
        // }

        const terminatedDate = citationsArr[cindex].terminationDate;
        const penalty = get(associatedMeta, "penalty", null);
        const mshaProposedPenalty = get(
          associatedMeta,
          "mshaProposedPenalty",
          null
        );
        let filterCount = 0;

        if (specialAssessment === true) {
          filterCount += maybePushFilter(
            CITATION_TABLE_FILTER.SPECIAL_ASSESSMENT,
            filterTags
          );
        }
        if (citationStatus === MSHA_CITATION_STATUS.CLOSED) {
          filterCount += maybePushFilter(
            CITATION_TABLE_FILTER.PAID,
            filterTags
          );
        }
        if (citationStatus !== MSHA_CITATION_STATUS.CLOSED && !vacated) {
          filterCount += maybePushFilter(
            CITATION_TABLE_FILTER.OUTSTANDING,
            filterTags
          );
        }
        if (citationStatus === MSHA_CITATION_STATUS.IN_CONTEST) {
          filterCount += maybePushFilter(
            CITATION_TABLE_FILTER.IN_CONTEST,
            filterTags
          );
        }
        if (vacated === true) {
          filterCount += maybePushFilter(
            CITATION_TABLE_FILTER.VACATED,
            filterTags
          );
        }
        if (citationRPID === true) {
          filterCount += maybePushFilter(
            CITATION_TABLE_FILTER.RPID,
            filterTags
          );
        }
        if (terminatedDate) {
          filterCount += maybePushFilter(
            CITATION_TABLE_FILTER.TERMINATED,
            filterTags
          );
        }
        if (penalty && mshaProposedPenalty) {
          const penaltyDiscrepancy = GetPenaltyDiscrepancy(
            penalty,
            mshaProposedPenalty
          );
          if (
            penaltyDiscrepancy === PREDICTED_PENALTY_DISCREPANCY.ABOVE_THRESHOLD
          ) {
            filterCount += maybePushFilter(
              CITATION_TABLE_FILTER.PENALTY_DISCREPANCY_UP,
              filterTags
            );
          } else if (
            penaltyDiscrepancy === PREDICTED_PENALTY_DISCREPANCY.BELOW_THRESHOLD
          ) {
            filterCount += maybePushFilter(
              CITATION_TABLE_FILTER.PENALTY_DISCREPANCY_DOWN,
              filterTags
            );
          }
        }
        if (hasRPID) {
          filterCount += maybePushFilter(
            CITATION_TABLE_FILTER.RPID,
            filterTags
          );
        }

        if (!filters.length) {
        } else {
          if (filterCount !== filters.length) {
            continue;
          }
        }

        citationRows.push({
          ...citationsArr[cindex],
          significant: citationsArr[cindex].significant ? "Yes" : "No",
          filterTags: filterTags,
          lastContestDate: get(associatedMeta, "lastContestDate", ""),
          modifiedInjuryOrIllness: get(
            associatedMeta,
            "hasInjuryIllnessChanged",
            false
          ),
          modifiedLikelihood: get(
            associatedMeta,
            "hasLikelihoodChanged",
            false
          ),
          modifiedPersonsAffected: get(
            associatedMeta,
            "hasPersonsAffectedChanged",
            false
          ),
          modifiedSandS: get(associatedMeta, "hasSignificantChanged", false),
          modifiedRegulation: get(
            associatedMeta,
            "hasRegulationChanged",
            false
          ),
          modifiedNegligence: get(
            associatedMeta,
            "hasNegligenceChanged",
            false
          ),
          wasContested: get(associatedMeta, "hasBeenContested", false),
          mshaCaseNumber: get(associatedMeta, "mshaCaseNumber", ""),
          mshaCurrentPenalty: get(associatedMeta, "mshaCurrentPenalty", ""),
          mshaFinalOrderDate: get(associatedMeta, "mshaFinalOrderDate", ""),
          mshaProposedPenalty: get(associatedMeta, "mshaProposedPenalty", ""),
          dataSource: get(associatedMeta, "dataSource", null),
          notes: get(associatedMeta, "notes", ""),
          penalty: get(associatedMeta, "penalty", 0),
          penaltygfe: get(associatedMeta, "penalty", 0) * 0.9,
          conferenceTrackingFk: get(
            associatedMeta,
            "conferenceTrackingFk",
            null
          ),
          specialAssessment,
          citationMetaPk: get(associatedMeta, "pk", null),
          rpid: hasRPID,
        });
      }
    }

    Object.keys(filtersGeneric).map((key) => {
      switch (filtersGeneric[key].type) {
        case FILTER_TYPE.MATCH:
          if (filtersGeneric[key].selectedOptions.length) {
            citationRows = citationRows.filter((cr) => {
              return filtersGeneric[key].selectedOptions.includes(cr[key]);
            });
          }
          break;
        case FILTER_TYPE.EQUAL_LESS_GREATER:
          if (filtersGeneric[key].selectedOptions.length) {
            const numVal = Number(filtersGeneric[key].inputValue);
            const direction = get(
              filtersGeneric,
              "[" + key + "].selectedOptions[0]",
              "clear_filters"
            ) as any;
            if (!isNaN(numVal)) {
              if (direction !== "clear_filters") {
                switch (direction) {
                  case "less_than":
                    citationRows = citationRows.filter((cr) => {
                      return Number(cr[key]) < numVal;
                    });
                    break;
                  case "more_than":
                    citationRows = citationRows.filter((cr) => {
                      return Number(cr[key]) > numVal;
                    });
                    break;
                  case "equal":
                    citationRows = citationRows.filter((cr) => {
                      return Number(cr[key]) === numVal;
                    });
                    break;
                }
              }
            }
          }
          break;
      }
    });

    const propertyValues = citationRows.map((obj) => obj["sectionOfCfr"]);
    const uniqueValuesSet = new Set(propertyValues);
    const distinctValues = Array.from(uniqueValuesSet);
    console.log("distinctValues", distinctValues);
    setCitationRows(citationRows);
  }, [
    citations,
    citationMetaData,
    filters,
    filtersGeneric,
    citationFetchCount,
  ]);

  const openConferenceTracking = (
    citationPk?: number,
    citationMetaPk?: number,
    conferenceTrackingPk?: number
  ) => {
    setCitationPk(citationPk);
    setCitationMetaPk(citationMetaPk);
    setConferenceTrackingPk(conferenceTrackingPk);
    setConferenceTrackingOpen(true);
  };

  return (
    <div>
      <Loading loading={loading} />

      <CitationTable
        // key={filters.length}
        key={citationRows.length}
        data={citationRows}
        filters={filters}
        setFilters={setFilters}
        setFiltersGeneric={setFiltersGeneric}
        superHandleFilterChange={handleFilterChange}
        filtersGeneric={filtersGeneric}
        superHandleFiltersGenericChange={handleFiltersGenericChange}
        startDate={startDate}
        endDate={endDate}
        toggleDoUpdate={toggleDoUpdate}
        handleChangeStart={handleChangeStart}
        handleChangeEnd={handleChangeEnd}
        openConferenceTracking={openConferenceTracking}
      />

      <div style={{ fontSize: 14, marginTop: 16 }}>
        * These citations were downloaded from MSHA and may be incomplete
        <br />
        ** These predicted penalties assume no special assessment
      </div>
    </div>
  );
};

export default MineCitations;
