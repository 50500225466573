import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { MAIN_TAB } from "../../interfaces/store/main";
import { setClientOptions } from "../../store/actions/clientActions";
import Administration from "./Administration";
import EditCompany from "./EditCompany";
import UsageStatistics from "./UsageStatistics";

interface IAdminRoute {}

const AdminRoute: React.FC<IAdminRoute> = (props) => {
  const dispatch = useDispatch();

  let { path } = useRouteMatch();
  useEffect(() => {
    dispatch(setClientOptions({ show_admin_nav: true }));
    dispatch(setClientOptions({ selected_tab: MAIN_TAB.ADMINISTRATION }));
    return () => {
      dispatch(setClientOptions({ show_admin_nav: false }));
    };
  }, []);

  return (
    <Switch>
      <Route exact path={`${path}/Configuration`}>
        <Administration />
      </Route>
      <Route exact path={`${path}/UsageStatistics`}>
        <UsageStatistics />
      </Route>
      <Route path={`${path}/Configuration/Company/:companypk/Edit`}>
        <EditCompany />
      </Route>
    </Switch>
  );
};

export default AdminRoute;
