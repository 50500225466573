import { all, call, put } from "redux-saga/effects";
import { ACTIONS } from "../../enums/actions";
import { HttpResponse } from "../../interfaces/api/HttpResponse";
import { IDispatchAction } from "../../interfaces/store";
import HttpClient from "../../lib/HttpClient";
import GetFriendlyError from "../../utils/general";
import { normalize, schema } from "normalizr";
const client = new HttpClient();

const Region_schema = new schema.Entity("regions", {}, { idAttribute: "pk" });

async function getAllRegionAPI(
  itemId: Number
): Promise<HttpResponse<{ x: string }>> {
  return client.get(`api/companies/${itemId}/regions`);
}

async function getRegionAPI(
  itemId: Number
): Promise<HttpResponse<{ x: string }>> {
  return client.get(`api/regions/${itemId}`);
}

async function createRegionAPI(data: any): Promise<HttpResponse<any>> {
  return client.post(`api/regions`, data);
}

async function updateRegionAPI(data: any): Promise<HttpResponse<any>> {
  return client.put("api/regions", data);
}

async function deleteRegionAPI(itemId: Number): Promise<HttpResponse<any>> {
  return client.delete(`api/regions/${itemId}`);
}

const RegionSaga = {
  *deleteRegion(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        deleteRegionAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        const isItemDeleted: boolean = response.data;
        if (isItemDeleted) {
          let removeKeys = [];
          removeKeys.push({
            entities: "regions",
            ids: Array.of(payload),
          });
          yield put({
            type: "DELETE_REGION_SUCCESS",
            removeKeys: removeKeys,
          });

          if (action.onSuccess) {
            action.onSuccess(response.data);
          }
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getRegion(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(getRegionAPI, payload)) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *createRegion(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        createRegionAPI,
        payload.data
      )) as HttpResponse<any>;

      if (response.status === 200 || response.status === 201) {
        yield all([
          put({
            type: "CREATE_REGION_SUCCESS",
            normalized: normalize(response.data, [Region_schema]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess(response.data.pk);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *updateRegion(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        updateRegionAPI,
        payload
      )) as HttpResponse<any>;

      if (response.status === 200 || response.status === 201) {
        yield all([
          put({
            type: "UPDATE_REGION_SUCCESS",
            normalized: normalize(response.data, [Region_schema]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess(response.data.pk);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getAllRegion(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getAllRegionAPI,
        payload
      )) as HttpResponse<any>;

      if (response.status === 200 || response.status === 201) {
        yield all([
          put({
            type: "GET_ALL_REGION_SUCCESS",
            normalized: normalize(response.data, [Region_schema]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess(response.data.pk);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
};

export default RegionSaga;
