import { ACTIONS } from "../../enums/actions";

export const getMine = (
  payload: { pk: number },
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_MINE,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getMinesByCompany = (
  payload: { companypk: number },
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_MINES_BY_COMPANY,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getAllMinesGroupByCompany = (
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_ALL_MINES_GROUP_BY_COMPANY,
  payload: {},
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const createMine = (
  payload: any,
  onSuccess?: Function,
  onFail: Function | null = null
) => ({
  type: ACTIONS.CREATE_MINE,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const updateMine = (
  payload: any,
  onSuccess?: Function,
  onFail: Function | null = null
) => ({
  type: ACTIONS.UPDATE_MINE,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const deleteMine = (
  payload: any,
  onSuccess?: Function,
  onFail?: Function
) => ({
  type: ACTIONS.DELETE_MINE,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getAllColumnsMinesSummary = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_ALL_COLUMNS_MINES_SUMMARY,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getMineRegulationWarning = (
	payload: any,
	onSuccess: Function | null = null,
	onFail: Function | null = null
  ) => ({
	type: ACTIONS.GET_MINE_REGULATIOND_WARNING,
	payload,
	onSuccess: onSuccess || null,
	onFail: onFail || null,
  });
  