import errors from './errors';

export default function GetFriendlyError(error: string): string {
  if (errors[error]) {
    return errors[error];
  }
  return errors.UNKNOWN_ERROR;
}

export const getUrlParam = (param: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(param)
}

export const boolToString = (val: any, trueVal: string, falseVal: string) => {
  let boolval = false;
  if (typeof val === 'boolean'){
    boolval = val;
  }
  else{
    if (typeof val === "string"){
      if (val.toLowerCase() === 'true'){
        boolval = true;
      }
    }
  }
  if (boolval === true){
    return trueVal
  }
  return falseVal
}

export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
