
import { useDispatch } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import MineState from "../mines/MineState";

interface IAnalyticsRouteProps {}

const AnalyticsRoute: React.FC<IAnalyticsRouteProps> = (props) => {
  const dispatch = useDispatch();

  let { path, url } = useRouteMatch();
  // useEffect(() => {
  //   dispatch(setClientOptions({ selected_tab: MAIN_TAB.MINE_STATE }));
  // }, []);

  return (
    <Switch>
      <Route exact path={path + "/MineState"}>
        <div style={{minHeight: '100vh'}}>
        <MineState />
          </div> 
      </Route>
    </Switch>
  );
};

export default AnalyticsRoute;
