import { call, put } from "redux-saga/effects";
import { ACTIONS } from "../../enums/actions";
import { HttpResponse } from "../../interfaces/api/HttpResponse";
import { IDispatchAction } from "../../interfaces/store";
import HttpClient from "../../lib/HttpClient";
import GetFriendlyError from "../../utils/general";
import { normalize, schema } from "normalizr";
import { IMine } from "../../interfaces/model/MSHAPCT/mine.interface";
import { ICompanyAccount } from "../../interfaces/model/MSHAPCT/company-account.interface";
import { IContractor } from "../../interfaces/model/MSHAPCT/contractor-interface";
const client = new HttpClient();
const companySchema = new schema.Entity("companies", {}, { idAttribute: "pk" });

async function getCompanyAPI(data: {
  companypk: number;
}): Promise<HttpResponse<{ x: string }>> {
  return client.get("api/company/company?companypk=" + data.companypk);
}

async function getCompaniesAPI(data: {
  companypk: number;
}): Promise<HttpResponse<{ x: string }>> {
  return client.get("api/company/companies");
}

async function getCompanySummaryAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {
  return client.post(`api/company/${data.companyid}/summary/report`, data);
}
async function downloadExcelReportAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {
  return client.getFileByParameter(
    `api/company/${data.companyid}/summary/report/export`,
    data
  );
}
async function download10KReportAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {
  return client.getFileByParameter(
    `api/company/${data.companyid}/summary/10kReport/export`,
    data
  );
}
async function download8KReportAPI(
  data: any
): Promise<HttpResponse<{ x: string }>> {
  return client.getFileByParameter(
    `api/company/${data.companyid}/summary/8kReport/export`,
    data
  );
}

async function createCompanyAPI(data: any): Promise<HttpResponse<any>> {
  return client.post("api/company/create", data);
}

async function updateCompanyAPI(data: any): Promise<HttpResponse<any>> {
  return client.post("api/company/update", data);
}

async function deleteCompanyAPI(data: any): Promise<HttpResponse<any>> {
  return client.post(`api/company/${data.pk}/delete`, data);
}

const companySaga = {
  *downloadExcelSummaryReport(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      payload.fileName = "excelExport.xlsx";
      const response = (yield call(
        downloadExcelReportAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", payload.fileName);
        document.body.appendChild(link);
        link.click();
        if (action.onSuccess) {
          action.onSuccess();
        }
      }
    } catch (e) {
      const error = e as Error;
      if (action.onFail) {
        action.onFail(error.message);
      }
    }
  },
  *download8KReport(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      payload.fileName = "8kReport.xlsx";
      const response = (yield call(
        download8KReportAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", payload.fileName);
        document.body.appendChild(link);
        link.click();
        if (action.onSuccess) {
          action.onSuccess();
        }
      }
    } catch (e) {
      const error = e as Error;
      if (action.onFail) {
        action.onFail(error.message);
      }
    }
  },
  *download10KReport(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      payload.fileName = "10kReport.xlsx";
      const response = (yield call(
        download10KReportAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", payload.fileName);
        document.body.appendChild(link);
        link.click();
        if (action.onSuccess) {
          action.onSuccess();
        }
      }
    } catch (e) {
      const error = e as Error;
      if (action.onFail) {
        action.onFail(error.message);
      }
    }
  },
  *getCompany(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getCompanyAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        yield put({
          type: "GET_COMPANY_SUCCESS",
          normalized: normalize(response.data, companySchema),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
  *getCompanySummary(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getCompanySummaryAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
		  //console.log("getCompanySummaryAPI raw response", response.data)
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
  *getCompanies(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getCompaniesAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess();
        }
        yield put({
          type: "GET_COMPANIES_SUCCESS",
          normalized: normalize(response.data, [companySchema]),
        });
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *createCompany(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        createCompanyAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        yield put({
          type: "CREATE_COMPANY_SUCCESS",
          normalized: normalize(response.data, companySchema),
        });
        if (action.onSuccess) {
          action.onSuccess(response.data.pk);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *updateCompany(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        updateCompanyAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        yield put({
          type: "UPDATE_COMPANY_SUCCESS",
          normalized: normalize(response.data, companySchema),
        });
        if (action.onSuccess) {
          action.onSuccess(response.data.pk);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *deleteCompany(action: IDispatchAction): Generator {
    console.log('try celeting company now')
    try {
      const payload = action.payload as any;
      const response = (yield call(
        deleteCompanyAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        console.log("response?", response);
        console.log("payload?", payload);
        let removeKeys = []
        removeKeys.push({
          entities: 'companies',
          ids: [payload.pk]
        })
        if (response?.data?.mines?.length){
          const removeMineKeys = response.data.mines.map((x: IMine) => x.pk)
          removeKeys.push({
            entities: 'mines',
            ids: removeMineKeys
          })
        }
        if (response?.data?.contractors?.length){
          const removeContractorKeys = response.data.contractors.map((x: IContractor) => x.pk)
          removeKeys.push({
            entities: 'contractors',
            ids: removeContractorKeys
          })
        }
        if (response?.data?.companyAccounts?.length){
          const removeCompanyAccountKeys = response.data.companyAccounts.map((x: ICompanyAccount) => x.pk)
          removeKeys.push({
            entities: 'companyAccounts',
            ids: removeCompanyAccountKeys
          })
        }
        yield put({
          type: "DELETE_COMPANY_SUCCESS",
          removeKeys: removeKeys,
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
};

export default companySaga;
