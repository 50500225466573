import React from 'react';
import { KeyboardTimePicker, KeyboardTimePickerProps } from "@material-ui/pickers";
import { Typography } from '@material-ui/core';
import { strftime } from '../../utils/strftime';

interface IKeyboardTimePickerWrap extends KeyboardTimePickerProps {
    mode: "edit" | "view"
}

const KeyboardTimePickerWrap: React.FC<IKeyboardTimePickerWrap> = (props: IKeyboardTimePickerWrap) => {
    const { mode, value } = props;

    return mode === 'edit' ? (
        <KeyboardTimePicker
            {...props} />
    ) : (
        <Typography>
            { value ? strftime('%H:%M', new Date(value as string)): ''}
        </Typography>
    )
}

export default KeyboardTimePickerWrap