export const dateFormatted = (date: any, format: string, seperator='/') => {
    try {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        switch (format) {
            case 'yyyymmdd':
                return [year, month, day].join(seperator);
            case 'mmddyyyy':
            default:
                return [month, day, year].join(seperator);
        }
    }
    catch (e) {
        return ""
    }
}

export const simpleYYYYMMDD = (date: any, fallback = "") => {
	if (isValidDate(date)){
		return date.toISOString().split('T')[0]
	}
	else{
		return fallback;
	}
}

export function toStringDropTimezonesKeys(obj: any, keys: string[]) {
  keys.forEach((key) => {
    if (obj && obj[key]) {
      if (isValidDate(obj[key])){
        obj[key] = obj[key].toString().slice(0, 24)
      }
    }
  })
}

export const isValidDate = (date: any) => {
  if (!date){
    return false;
  }
	return new Date(date).toString() !== 'Invalid Date';
}

export enum DATE_PART {
  DATE = 'DATE',
  TIME = 'TIME'
}

export const setDatePart = (date: any, existingValue: any, part: DATE_PART ) => {
    if (isValidDateSQL(date)) {
        let setDate = existingValue
        if (isValidDateSQL(setDate)) {
            setDate = new Date(setDate!);
            // has a valid date already
            if (part === DATE_PART.DATE) {
              setDate?.setDate((date as Date).getDate())
              setDate?.setMonth((date as Date).getMonth())
              setDate?.setFullYear((date as Date).getFullYear())
            }
            if (part === DATE_PART.TIME) {
              setDate?.setHours((date as Date).getHours())
              setDate?.setMinutes((date as Date).getMinutes())
            }
            if (isValidDateSQL(setDate)) {
                return {
                  valid: true,
                  value: setDate
                }
            }
        }
        else { //set from scratch
            return {
              valid: true,
              value: date as Date
            }
        }
    }
    return {
      valid: false,
      value: null
    }
}

export const isValidDateSQL = (date: any) => {
  if (!date){
    return false;
  }
	if (isValidDate(date)){

		const minDate = new Date(1754, 1, 1)
		const maxDate = new Date(9998,1,1)
		const testDate = new Date(date);
		if (testDate < minDate || testDate > maxDate){
			return false;
		}
		return true;
	}
	return false;
}

export enum dateAmountType {
    DAYS,
    WEEKS,
    MONTHS,
    YEARS,
  }
  
  export function addDate(dt: Date, amount: number, dateType: dateAmountType): any {
    switch (dateType) {
      case dateAmountType.DAYS:
        return dt.setDate(dt.getDate() + amount) && dt;
      case dateAmountType.WEEKS:
        return dt.setDate(dt.getDate() + (7 * amount)) && dt;
      case dateAmountType.MONTHS:
        return dt.setMonth(dt.getMonth() + amount) && dt;
      case dateAmountType.YEARS:
        return dt.setFullYear( dt.getFullYear() + amount) && dt;
    }
  }