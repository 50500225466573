import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { tableIcons } from "../shared/MaterialTableShared";
import {
  CitationColumns,
  CITATION_COLUMN,
  CITATION_TABLE_FILTER,
  DATA_SOURCE,
  gravityChanceToString,
  gravityResultExpectedToString,
  keyToCitationColumn,
  typeOfActionToString,
  typeOfInspectionToString,
  typeOfIssuanceToString,
  typeOfNegligenceString,
} from "../../enums/citation-enums";
import {
  Button,
  ButtonGroup,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Paper,
  Select,
  Switch,
  Tooltip,
  colors,
} from "@material-ui/core";
import { FilterIcon } from "./CitationComponents";
import { boolToString } from "../../utils/general";
import { RenderCitationPenalty } from "../../services/citation.service";
import { dateFormatted, simpleYYYYMMDD } from "../../utils/date-utils";
import { useHistory } from "react-router";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { CloudDownloadOutlined } from "@material-ui/icons";
import StandardModal from "../shared/StandardModal";
import { useLocation, useParams } from "react-router-dom";
import CitationMetaFilter from "./CitationMetaFilter";
import GenericMultiFilter from "./GenericMultiFilter";
import { IGenericFilters } from "../shared/generic-filter.interface";
import GenericEqualityComparisonFilter from "./GenericEqualityComparisonFilter";
import BarChartIcon from "@material-ui/icons/BarChart";
import NoteIcon from "@material-ui/icons/Note";
import AccountBalance from "@material-ui/icons/AccountBalance";
import AccountBalanceOutlined from "@material-ui/icons/AccountBalanceOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CitationNoteDetailsModal from "./CitationNoteDetailsModal";
import { useSelector } from "../../store";
import { IRootState } from "../../interfaces/store";
import Loading from "../shared/Loading";
import { useDispatch } from "react-redux";
import {
  downloadBulkCitations,
  getCitationMgmtCustomFields,
} from "../../store/actions/citationActions";
import { useSnackbar } from "notistack";
import { RegulationURLFromSectionOfCfr } from "../../utils/citation-utils";
import { ICitationMgmtCustomField } from "../../interfaces/model/MSHAPCT/citation-mgmt-custom-field.interface";
import { initialGenericFilters } from "../mines/MineCitations";
import { numberToCurrency } from "../../utils/general-utils";
import { ICitationWithMeta } from "../../interfaces/model/MSHAPCT/citation.interface";
import { IMine } from "../../interfaces/model/MSHAPCT/mine.interface";
import { size, sumBy } from "lodash";
import { COLORS } from "../../enums/layout-enums";
import { ENABLE_CITATION_TABLE_SUMMARY } from "../../constants/constants";
import { updatedSelectedColumnIndexes } from "../../store/actions/accountActions";
import { stringify } from "querystring";
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "75vh",
      width: 250,
    },
  },
};

export interface ICitationTableRow extends ICitationWithMeta {
  modifiedInjuryOrIllness: any;
  modifiedLikelihood: any;
  modifiedPersonsAffected: any;
  modifiedSandS: any;
  modifiedRegulation: any;
  modifiedNegligence: any;
  wasContested: any;
  penaltygfe: any;
  rpid: any;
  filters: CITATION_TABLE_FILTER[];
}

interface ICitationTableProps {
  data: ICitationTableRow[];
  superHandleFilterChange: Function;
  startDate: Date;
  endDate: Date;
  handleChangeStart: Function;
  handleChangeEnd: Function;
  filters: CITATION_TABLE_FILTER[];
  setFilters: Function;
  setFiltersGeneric: Function;
  filtersGeneric: IGenericFilters;
  superHandleFiltersGenericChange: Function;
  openConferenceTracking: Function;
  toggleDoUpdate: Function;
}

const CitationTable: React.FC<ICitationTableProps> = (props) => {
  const { minepk } = useParams<{ minepk: string }>();
  const [mine, setMine] = useState({} as IMine);
  const [processsing, setProcesssing] = useState(false);
  const [includeAllColumnsExport, setIncludeAllColumnsExport] = useState(true);

  const [hasFetchedCustomFields, setHasFetchedCustomFields] = useState(false);
  const [currentCompanyCustomFields, setCurrentCompanyCustomFields] = useState(
    [] as ICitationMgmtCustomField[]
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const accountLogin = useSelector(
    (state: IRootState) => state.main.account_login
  );

  const defaultColumns = [
    CITATION_COLUMN.DATE_ISSUED,
    CITATION_COLUMN.CITATION_ORDER_NO,
    CITATION_COLUMN.TYPE_OF_ACTION,
    CITATION_COLUMN.REGULATION,
    CITATION_COLUMN.PENALTY_CALCULATION,
    CITATION_COLUMN.PENALTY_CALCULATION_GFE,
    CITATION_COLUMN.S_AND_S,
    CITATION_COLUMN.TYPE_OF_ISSUANCE,
    CITATION_COLUMN.MSHA_PROPOSED_PENALTY,
  ];

  let initialColumns = [...defaultColumns];

  if (accountLogin.selectedColumnIndexes) {
    let isValid = true;
    const acceptableColumns = Object.values(CITATION_COLUMN) as string[];
    const parsedIndexes = accountLogin.selectedColumnIndexes.split(",");
    if (parsedIndexes.length) {
      parsedIndexes.forEach((pIndex) => {
        if (acceptableColumns.includes(pIndex)) {
        } else {
          isValid = false;
        }
      });
    }
    if (isValid && parsedIndexes.length) {
      initialColumns = parsedIndexes as CITATION_COLUMN[];
    } else {
    }
  }

  const [selectedColumns, setSelectedColumns] = useState(initialColumns);

  const [csvModalOpen, setCsvModalOpen] = useState(false);

  const mines = useSelector((state: IRootState) => state.main.mines);

  useEffect(() => {
    if (!hasFetchedCustomFields) {
      const mineArr = Object.values(mines);
      const currentMine = mineArr.find((x) => x.pk === Number(minepk));
      if (currentMine) {
        setMine(currentMine);
        setHasFetchedCustomFields(true);
        dispatch(
          getCitationMgmtCustomFields(
            { companypk: currentMine.companyFk },
            (data: any) => {
              setCurrentCompanyCustomFields(data);
            }
          )
        );
      }
    }
  }, [mines]);

  const handleColumnsChange = (event: any) => {
    dispatch(
      updatedSelectedColumnIndexes({
        selectedColumnIndexes: event.target.value,
      })
    );
    setSelectedColumns(event.target.value);
  };

  const downloadAllCitations = (
    allCompanyCitations: boolean,
    fileName: string
  ) => {
    const data = {
      minePk: minepk,
      startDate: props.startDate.toDateString(),
      endDate: props.endDate.toDateString(),
      columns: selectedColumns,
      allCompanyCitations,
      filters: props.filters,
      allColumns: includeAllColumnsExport,
      fileName,
    };
    setProcesssing(true);
    dispatch(
      downloadBulkCitations(
        data,
        () => {
          setProcesssing(false);
          setCsvModalOpen(false);
          enqueueSnackbar("Citations downloaded", {
            variant: "success",
          });
        },
        submitError
      )
    );
  };

  const submitError = (message: string) => {
    setProcesssing(false);
    enqueueSnackbar(message, {
      variant: "error",
    });
  };

  const selectColumns = () => {
    const preparedColumns = [] as any[];

    preparedColumns.push({
      title: "Actions",
      field: "",
      filtering: false,
      sorting: false,
      render: (rowData: any) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            <Tooltip title="Citation Notes" placement="top">
              <NoteIcon
                style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
                className="transparent75"
                onClick={() => {
                  if (rowData && rowData.pk) {
                    setIsCitationNotesModalOpen(true);
                    onCitationNoteSelected(rowData.pk);
                  }
                }}
              />
            </Tooltip>

            <Tooltip title="View" placement="top">
              <VisibilityIcon
                style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
                className="transparent75"
                onClick={() => {
                  if (rowData && rowData.pk) {
                    window.scrollTo(0, 0);
                    history.push(
                      `/Mines/${minepk}/Citations/${rowData.pk}/View`
                    );
                  }
                }}
              />
            </Tooltip>

            <Tooltip title="Analytics" placement="top">
              <BarChartIcon
                style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
                className="transparent75"
                onClick={() => {
                  if (rowData && rowData.pk) {
                    window.scrollTo(0, 0);
                    history.push(
                      `/Mines/${minepk}/Citations/${rowData.pk}/Analytics`
                    );
                  }
                }}
              />
            </Tooltip>

            {rowData.conferenceTrackingFk ? (
              <Tooltip title="Conference Tracking" placement="top">
                <AccountBalance
                  style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
                  className="transparent75"
                  onClick={() => {
                    if (rowData && rowData.pk) {
                      window.scrollTo(0, 0);
                      history.push(
                        `/Mines/${minepk}/Citations/${rowData.pk}/CitationModificationTracking`
                      );
                      //props.openConferenceTracking(rowData.pk, rowData.citationMetaPk, rowData.conferenceTrackingFk)
                    }
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Conference Tracking" placement="top">
                <AccountBalanceOutlined
                  style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
                  className="transparent75"
                  onClick={() => {
                    if (rowData && rowData.pk) {
                      window.scrollTo(0, 0);
                      history.push(
                        `/Mines/${minepk}/Citations/${rowData.pk}/CitationModificationTracking`
                      );
                      //props.openConferenceTracking(rowData.pk, rowData.citationMetaPk, null)
                    }
                  }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    });

    selectedColumns.map((sc, index) => {
      let col = null;
      if (index === 2) {
        preparedColumns.push({
          title: "Filters",
          field: "filterTags",
          sorting: true,
          render: (rowData: any) =>
            rowData.filterTags.map((filter: any) => FilterIcon(filter, true)),
          filterComponent: () => (
            <CitationMetaFilter
              superHandleFilterChange={props.superHandleFilterChange}
              selectedFilters={props.filters}
            />
          ),
        });
      }
      switch (sc) {
        case CITATION_COLUMN.DATE_ISSUED:
          col = {
            title: "Date Issued",
            field: "dateIssued",
            sorting: true,
            defaultSort: "desc",
            filtering: false,
            render: (rowData: any) =>
              dateFormatted(rowData.dateIssued, "mmddyyyy"),
          };
          break;
        case CITATION_COLUMN.CITATION_ORDER_NO:
          col = {
            title: "Citation/Order No.",
            field: "citationNumber",
            sorting: true,
            filtering: true,
            render: (rowData: any) => {
              let stars = "";
              if (
                rowData.dataSource === DATA_SOURCE.MSHA_DOWNLOAD ||
                rowData.dataSource === DATA_SOURCE.MSHA_FLATFILE
              ) {
                stars += "*";
              }
              return (
                <>
                  <span
                    className="linklike"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      history.push(
                        `/Mines/${minepk}/Citations/${rowData.pk}/View`
                      );
                    }}
                  >
                    {rowData.citationNumber}
                  </span>
                  {stars}
                </>
              );
            },
          };
          break;
        case CITATION_COLUMN.TYPE_OF_ACTION:
          col = {
            id: 1,
            title: "Type of Action",
            field: "typeOfAction",
            sorting: true,
            render: (rowData: any) =>
              typeOfActionToString(rowData.typeOfAction),
            filterComponent: () => (
              <GenericMultiFilter
                filterKey={"typeOfAction"}
                superHandleFilterChange={props.superHandleFiltersGenericChange}
                filters={props.filtersGeneric}
              />
            ),
            // lookup: {
            //   1 : typeOfActionToString(TYPE_OF_ACTION._104_A),
            //   2 : typeOfActionToString(TYPE_OF_ACTION._104_D_1),
            //   3 : typeOfActionToString(TYPE_OF_ACTION._104_D_2),       // _104_D_2 = 3,
            //   4 : typeOfActionToString(TYPE_OF_ACTION._104_G),        // _104_G = 4,
            //   5 : typeOfActionToString(TYPE_OF_ACTION._107_A),      // _107_A = 5,
            //   6 : typeOfActionToString(TYPE_OF_ACTION._104_B),     // _104_B = 6,
            //   7 : typeOfActionToString(TYPE_OF_ACTION._104_F),    // _104_F = 7,
            //   8 : typeOfActionToString(TYPE_OF_ACTION._103_K),    // _103_K = 8,
            //   9 : typeOfActionToString(TYPE_OF_ACTION._104_E),     // _104_E = 9,
            //   10 : typeOfActionToString(TYPE_OF_ACTION._110_A),   // _110_A = 10,
            //   11: typeOfActionToString(TYPE_OF_ACTION._103_J),  // _103_J = 11,
            //   12: typeOfActionToString(TYPE_OF_ACTION._314_B) // _314_B = 12,
            //  },
            //  filterComponent: (props: any) => <FilterComponent {...props}/>
            // filterComponent: (props: any) => <CitationMetaFilter superHandleFilterChange={handleFilterChange}/>
          };
          break;
        case CITATION_COLUMN.REGULATION:
          col = {
            title: "Regulation",
            field: "sectionOfCfr",
            sorting: true,
            render: (rowData: any) => {
              const regUrl = RegulationURLFromSectionOfCfr(
                rowData.sectionOfCfr
              );
              return (
                <a
                  className="linklike"
                  target="_blank"
                  href={regUrl}
                  rel="noreferrer"
                >
                  {rowData.sectionOfCfr}
                </a>
              );
            },
            filterComponent: () => (
              <GenericMultiFilter
                filterKey={"sectionOfCfr"}
                superHandleFilterChange={props.superHandleFiltersGenericChange}
                filters={props.filtersGeneric}
              />
            ),
          };
          break;
        case CITATION_COLUMN.TYPE_OF_ISSUANCE:
          col = {
            title: "Type of Issuance",
            field: "typeOfIssuance",
            sorting: true,
            render: (rowData: any) =>
              typeOfIssuanceToString(rowData.typeOfIssuance),
            filterComponent: () => (
              <GenericMultiFilter
                filterKey={"typeOfIssuance"}
                superHandleFilterChange={props.superHandleFiltersGenericChange}
                filters={props.filtersGeneric}
              />
            ),
          };
          break;
        case CITATION_COLUMN.SERVED_TO:
          col = { title: "Served To", field: "servedTo", sorting: true };
          break;
        case CITATION_COLUMN.CONDITION_OR_PRACTICE:
          col = {
            title: "Condition/Practice",
            field: "condition",
            sorting: true,
            filtering: false,
          };
          break;
        case CITATION_COLUMN.NEGLIGENCE:
          col = {
            title: "Negligence",
            field: "negligence",
            sorting: true,
            render: (rowData: any) =>
              typeOfNegligenceString(rowData.negligence),
            filterComponent: () => (
              <GenericMultiFilter
                filterKey={"negligence"}
                superHandleFilterChange={props.superHandleFiltersGenericChange}
                filters={props.filtersGeneric}
              />
            ),
          };
          break;
        case CITATION_COLUMN.S_AND_S:
          col = { title: "S&S", field: "significant", sorting: true };
          break;
        case CITATION_COLUMN.LIKELIHOOD:
          col = {
            title: "Likelihood",
            field: "likelihood",
            sorting: true,
            render: (rowData: any) =>
              gravityChanceToString(rowData.gravityChance),
          };
          break;
        case CITATION_COLUMN.ACTION_TO_TERMINATE:
          col = {
            title: "Action to Terminate",
            field: "actionToTerminate",
            sorting: true,
          };
          break;
        case CITATION_COLUMN.AR_NUMBER:
          col = { title: "AR Number", field: "arNumber", sorting: true };
          break;
        case CITATION_COLUMN.AREA_OR_EQUIPMENT:
          col = { title: "Area", field: "areaOfEquipment", sorting: true };
          break;
        case CITATION_COLUMN.EVENT_NUMBER:
          col = { title: "Event Number", field: "eventNumber", sorting: true };
          break;
        case CITATION_COLUMN.INJURY_OR_ILLNESS_EXPECTED:
          col = {
            title: "Injury Expected",
            field: "gravityResultExpected",
            sorting: true,
            render: (rowData: any) => {
              return gravityResultExpectedToString(
                rowData.gravityResultExpected
              );
            },
          };
          break;
        case CITATION_COLUMN.INSPECTION_TYPE:
          col = {
            title: "Ispection Type",
            field: "typeOfInspection",
            sorting: true,
            render: (rowData: any) =>
              typeOfInspectionToString(rowData.typeOfInspection),
          };
          break;
        case CITATION_COLUMN.LAST_CONTEST_DATE:
          col = {
            title: "Last Contest Date",
            field: "lastContestDate",
            sorting: true,
            render: (rowData: any) =>
              dateFormatted(rowData.lastContestDate, "mmddyyyy"),
          };
          break;
        case CITATION_COLUMN.MINE_ID:
          break;
        case CITATION_COLUMN.MINE_NAME:
          col = {
            title: "Mine Name",
            field: "lastContestDate",
            sorting: false,
            render: (rowData: any) => {
              return mine?.mineName;
            },
          };
          break;
        case CITATION_COLUMN.MODIFIED_INJURY_ILLNESS:
          col = {
            title: "Mod. Injury/Illness",
            field: "modifiedInjuryOrIllness",
            sorting: true,
            render: (rowData: any) =>
              boolToString(rowData.modifiedInjuryOrIllness, "Y", "N"),
          };
          break;
        case CITATION_COLUMN.MODIFIED_LIKELIHOOD:
          col = {
            title: "Mod. Likelihood",
            field: "modifiedLikelihood",
            sorting: true,
          };
          break;
        case CITATION_COLUMN.MODIFIED_NEGLIGENCE:
          col = {
            title: "Mod. Negligence",
            field: "modifiedNegligence",
            sorting: true,
          };
          break;
        case CITATION_COLUMN.MODIFIED_PERSONS_AFFECTED:
          col = {
            title: "Mod. Persons Affected",
            field: "modifiedPersonsAffected",
            sorting: true,
          };
          break;
        case CITATION_COLUMN.MODIFIED_REGULATION:
          col = {
            title: "Mod. Regulation",
            field: "modifiedRegulation",
            sorting: true,
          };
          break;
        case CITATION_COLUMN.MODIFIED_S_AND_S:
          col = { title: "Mod. S&S", field: "modifiedSandS", sorting: true };
          break;
        case CITATION_COLUMN.WAS_CONTESTED:
          col = {
            title: "Was Contested",
            field: "wasContested",
            sorting: true,
          };
          break;
        case CITATION_COLUMN.MSHA_CASE_NUMBER:
          col = {
            title: "MSHA Case Number",
            field: "mshaCaseNumber",
            sorting: true,
          };
          break;
        case CITATION_COLUMN.MSHA_CURRENT_PENALTY:
          col = {
            title: "MSHA Current Penalty",
            field: "mshaCurrentPenalty",
            render: (rowData: any) => {
              return numberToCurrency(rowData.mshaCurrentPenalty);
            },
            sorting: true,
          };
          break;
        case CITATION_COLUMN.MSHA_FINAL_ORDER_DATE:
          col = {
            title: "MSHA Final Order Date",
            field: "mshaFinalOrderDate",
            sorting: true,
            render: (rowData: any) =>
              dateFormatted(rowData.mshaFinalOrderDate, "mmddyyyy"),
          };
          break;
        case CITATION_COLUMN.MSHA_PROPOSED_PENALTY:
          col = {
            title: "MSHA Proposed Penalty",
            field: "mshaProposedPenalty",
            sorting: true,
            render: (rowData: any) => {
              return numberToCurrency(rowData.mshaProposedPenalty);
            },
          };
          break;
        case CITATION_COLUMN.NOTES:
          col = {
            title: "Notes",
            field: "notes",
            sorting: true,
            filtering: false,
          };
          break;
        case CITATION_COLUMN.PENALTY_CALCULATION:
          col = {
            title: "Penalty",
            field: "penalty",
            sorting: true,
            render: (rowData: any) =>
              RenderCitationPenalty(
                rowData.specialAssessment,
                rowData.penalty,
                rowData.typeOfAction,
                false,
                false
              ),
            // customFilterAndSearch: (term: any, rowData: any) => {
            //   return false;
            // },
            filterComponent: () => (
              <GenericEqualityComparisonFilter
                filterKey={"penalty"}
                superHandleFilterChange={props.superHandleFiltersGenericChange}
                filters={props.filtersGeneric}
              />
            ),
          };
          break;
        case CITATION_COLUMN.PENALTY_CALCULATION_GFE:
          col = {
            title: "Penalty GFE",
            field: "penaltygfe",
            sorting: true,
            render: (rowData: any) =>
              RenderCitationPenalty(
                rowData.specialAssessment,
                rowData.penaltygfe,
                rowData.typeOfAction,
                true,
                false
              ),
            filterComponent: () => (
              <GenericEqualityComparisonFilter
                filterKey={"penaltygfe"}
                superHandleFilterChange={props.superHandleFiltersGenericChange}
                filters={props.filtersGeneric}
              />
            ),
          };
          break;
        case CITATION_COLUMN.PERSONS_AFFECTED:
          col = {
            title: "Persons Affected",
            field: "personsAffected",
            sorting: true,
          };
          break;
        case CITATION_COLUMN.TERMINATION_DATE:
          col = {
            title: "Termination Date",
            field: "terminationDate",
            sorting: true,
            render: (rowData: any) =>
              dateFormatted(rowData.terminationDate, "mmddyyyy"),
          };
          break;
        case CITATION_COLUMN.TERMINATION_DUE_DATE:
          col = {
            title: "Termination Due Date",
            field: "terminationDueDate",
            sorting: true,
            render: (rowData: any) =>
              dateFormatted(rowData.terminationDueDate, "mmddyyyy"),
          };
          break;
        case CITATION_COLUMN.DATA_SOURCE:
          col = { title: "Source", field: "dataSource", sorting: true };
      }
      if (col != null) {
        preparedColumns.push(col);
      }
    });
    return preparedColumns;
  };

  console.log("props.filtersGeneric", props.filtersGeneric);

  const prepareCSVData = () => {
    const csvData = [];
    const csvPrepared = [] as any[];
    props.data.forEach((datum: any) => {
      const item = {} as any;
      Object.keys(datum).forEach((key: string) => {
        if (selectedColumns.includes(keyToCitationColumn(key) as any)) {
          //TODO, need to enrich data same was as in the column draws, compare to PC output
          item[key] = datum[key];
        }
      });
      csvPrepared.push(item);
    });
  };

  const citations = useSelector((state: IRootState) => state.main.citations);

  const [isCitationNotesModalOpen, setIsCitationNotesModalOpen] =
    useState(false);
  const [citationNoteData, setCitationNoteData] = useState({} as any);

  const onCitationNoteSelected = (pk: any) => {
    const selectedCitation = citations[pk];
    setCitationNoteData(selectedCitation);
  };

  const onSubmitCitationNotesModal = (
    data: any,
    onSuccess: Function,
    onFail?: Function
  ) => {
    if (data.pk) {
    } else {
    }
  };

  const SSPercent = () => {
    let ssCount = 0;
    props.data.forEach((x: any) => {
      if (x.significant === "Yes") {
        ssCount++;
      }
    });

    console.log("ssCount", ssCount);
    console.log("props.data.length", props.data.length);

    return (ssCount / props.data.length) * 100;
  };

  return (
    <>
      <Loading loading={processsing} />
      <CitationNoteDetailsModal
        itemData={citationNoteData}
        open={isCitationNotesModalOpen}
        onClose={() => setIsCitationNotesModalOpen(false)}
        title="Citation Note Details"
        onSubmit={onSubmitCitationNotesModal}
        isEditMode={citationNoteData.pk ? true : false}
        currentCompanyCustomFields={currentCompanyCustomFields}
        minePk={minepk}
      />

      <StandardModal
        open={csvModalOpen}
        onOpen={() => setIncludeAllColumnsExport(true)}
        onClose={() => setCsvModalOpen(false)}
        actions={[
          {
            title: "Company",
            callback: () => {
              downloadAllCitations(
                true,
                "CompanyCitations_" +
                  simpleYYYYMMDD(props.startDate) +
                  "_" +
                  simpleYYYYMMDD(props.endDate) +
                  ".xlsx"
              );
            },
          },
          {
            title: "Current Mine",
            callback: () => {
              downloadAllCitations(
                false,
                mine.mineName +
                  "_" +
                  simpleYYYYMMDD(props.startDate) +
                  "_" +
                  simpleYYYYMMDD(props.endDate) +
                  ".xlsx"
              );
            },
          },
        ]}
        children={
          <div>
            <br />
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Include all Columns (toggle off to include only displayed columns)"
                  control={
                    <Switch
                      defaultChecked
                      onChange={(event) => {
                        setIncludeAllColumnsExport(event.target.checked);
                      }}
                    />
                  }
                />
              </Grid>
            </Grid>
            <br />
            <br />
          </div>
        }
        customActions={[]}
        paragraphs={["Export citations for the selected date range."]}
        title={"Export Citations to CSV"}
      />
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2, marginTop: 10 }}>
          <div
            style={{ maxWidth: 180, display: "inline-block", marginLeft: 16 }}
          >
            <KeyboardDatePicker
              label="From"
              size="small"
              // inputVariant="outlined"
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="none"
              value={props.startDate}
              onChange={(date) => {
                props.handleChangeStart(date);
              }}
              onBlur={() => {
                props.toggleDoUpdate(true);
              }}
              onAccept={() => {
                props.toggleDoUpdate(true);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </div>

          <div
            style={{ maxWidth: 180, display: "inline-block", marginLeft: 16 }}
          >
            <KeyboardDatePicker
              label="To"
              size="small"
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="none"
              value={props.endDate}
              onChange={(date) => {
                props.handleChangeEnd(date);
              }}
              onAccept={() => {
                props.toggleDoUpdate(true);
              }}
              onBlur={() => {
                props.toggleDoUpdate(true);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </div>

          <div
            style={{
              maxWidth: 420,
              display: "inline-block",
              marginLeft: 5,
              verticalAlign: "bottom",
            }}
          >
            <ButtonGroup
              //   variant="text"
              aria-label="outlined  button group"
              style={{
                borderWidth: "20px",
              }}
            >
              <Button
                style={{ borderRight: "10em" }}
                onClick={() => {
                  const date = new Date();
                  date.setDate(date.getDate() - 7);
                  localStorage.setItem("MineCitationFromDate", String(date));
                  sessionStorage.setItem(
                    "MineCitationToDate",
                    String(new Date())
                  );
                  window.location.reload();
                }}
                variant={"outlined"}
              >
                Week
              </Button>
              <Button
                style={{ borderRight: "10em" }}
                onClick={() => {
                  const date = new Date();
                  date.setMonth(date.getMonth() - 1);
                  localStorage.setItem("MineCitationFromDate", String(date));
                  sessionStorage.setItem(
                    "MineCitationToDate",
                    String(new Date())
                  );
                  window.location.reload();
                }}
                variant={"outlined"}
              >
                Month
              </Button>
              <Button
                style={{ borderRight: "10em" }}
                onClick={() => {
                  const date = new Date();
                  date.setMonth(date.getMonth() - 3);
                  localStorage.setItem("MineCitationFromDate", String(date));
                  sessionStorage.setItem(
                    "MineCitationToDate",
                    String(new Date())
                  );
                  window.location.reload();
                }}
                variant={"outlined"}
              >
                Quarter
              </Button>
              <Button
                style={{ borderRight: "10em" }}
                onClick={() => {
                  const date = new Date();
                  date.setFullYear(date.getFullYear() - 1);
                  localStorage.setItem("MineCitationFromDate", String(date));
                  sessionStorage.setItem(
                    "MineCitationToDate",
                    String(new Date())
                  );
                  window.location.reload();
                }}
                variant={"outlined"}
              >
                1 Year
              </Button>
              <Button
                onClick={() => {
                  const date = new Date();
                  date.setFullYear(date.getFullYear() - 2);
                  localStorage.setItem("MineCitationFromDate", String(date));
                  sessionStorage.setItem(
                    "MineCitationToDate",
                    String(new Date())
                  );
                  window.location.reload();
                }}
                variant={"outlined"}
              >
                2 years
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <div style={{ flex: 1, alignItems: "flex-end", textAlign: "right" }}>
          <Button
            onClick={() => {
              props.setFilters([]);
              props.setFiltersGeneric(initialGenericFilters);
            }}
            variant={"outlined"}
          >
            Clear Filters
          </Button>
          <Tooltip placement="top" title="CSV Download Options">
            <IconButton
              aria-label="csv-download"
              onClick={() => {
                prepareCSVData();
                setCsvModalOpen(true);
              }}
            >
              <CloudDownloadOutlined />
            </IconButton>
          </Tooltip>
          <div style={{ display: "inline-block" }}>
            <span
              className="transparent75"
              style={{ fontSize: 12, color: "rga(0,0,0,.54)" }}
            >
              Columns
            </span>
            <br />
            <Select
              label={"Columns"}
              labelId="selected-columns-label"
              id="selected-columns-select"
              multiple
              value={selectedColumns}
              onChange={handleColumnsChange}
              input={<Input />}
              renderValue={(selected: any) => {
                return <>{selected.length} Columns Displayed</>;
              }}
              MenuProps={MenuProps}
            >
              {CitationColumns.map((column, iindex: number) => (
                <MenuItem key={iindex} value={column} style={{}}>
                  {column}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>

      <br />
      <br />

      {ENABLE_CITATION_TABLE_SUMMARY && (
        <Paper className={"custom-pc-table-criteria"}>
          <div
            style={{
              textAlign: "center",
              padding: 16,
              backgroundColor: COLORS.GREY_LIGHT,
            }}
            className="custom-pc-table-bottom-border"
          >
            <b>Citations</b>
          </div>
          <table>
            <thead>
              <tr>
                <th>Penalty</th>
                <th>Penalty GFE</th>
                <th>Penalty (MSHA Proposed)</th>
                <th>SS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{numberToCurrency(sumBy(props.data, "penalty"))}</td>
                <td>{numberToCurrency(sumBy(props.data, "penaltygfe"))}</td>
                <td>
                  {numberToCurrency(sumBy(props.data, "mshaProposedPenalty"))}
                </td>
                <td>{SSPercent().toFixed(2)}%</td>
              </tr>
            </tbody>
          </table>
        </Paper>
      )}

      {/* {props.data && props.data.length ?  */}
      <MaterialTable
        icons={tableIcons}
        title="Citations"
        columns={selectColumns()}
        data={props.data}
        options={{
          sorting: true,
          headerStyle: {
            zIndex: 8,
          },
          paging: true,
          pageSize: Math.min(props.data.length, 15),
          draggable: false,
          filtering: true,
          filterCellStyle: { textOverflow: "hide" },
        }}
      />
    </>
  );
};

export default CitationTable;
