import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Checkbox } from "@material-ui/core";

interface ICheckBoxListProps {
  id: any;
  dataSource: any[];
  selectionLists: any;
  keyName: string;
  displayFieldName: string;
  displayFieldValue: string;
  onChange(...args: any[]): void;
}

export default function CheckBoxList(props: ICheckBoxListProps) {
  const isSelectedFromList = (item: any, property: any): boolean => {
    const lists: any = { ...props.selectionLists };
    const data = lists[property].filter(
      (el: any) => Number(el) === Number(item[props.displayFieldValue])
    );
    return data.length > 0 ? true : false;
  };

  return (
    <>
      <List dense={true} style={{ maxHeight: 300 }}>
        {props.dataSource.map((value: any) => {
          const labelId = `${props.keyName}-checkbox-list-label-${
            value[props.displayFieldValue]
          }`;

          return (
            <ListItem
              key={`li-${value[props.displayFieldValue]}`}
              role={undefined}
              dense
              button
              onClick={() => props.onChange(value, props.keyName)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                  checked={isSelectedFromList(value, props.keyName)}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={value[props.displayFieldName]}
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
