import { Input, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { FilterListRounded } from '@material-ui/icons';
import { IGenericFilters } from '../shared/generic-filter.interface';
interface ICitationMetaFilterProps {
	superHandleFilterChange: Function
  filterKey: string,
	filters: IGenericFilters
}

const MenuProps = {
	PaperProps: {
	  style: {
		maxHeight: '75vh', //ITEM_HEIGHT * 12.5 + ITEM_PADDING_TOP,
		width: 250,
	  },
	},
  };

const CitationMetaFilter: React.FC<ICitationMetaFilterProps> = (props) => {

  let iconColor = "disabled"
  if (props.filters[props.filterKey].selectedOptions.length){
    iconColor = "primary"
  }
	return (
		<div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <div style={{ display: 'inline-block' }}>
            <FilterListRounded color={iconColor as any} />
            <Select
              labelId="citation-filter-label"
              id="citation-filter-select"
              multiple={props.filters[props.filterKey].multiple}
              value={props.filters[props.filterKey].selectedOptions}
              onChange={(e: any) => props.superHandleFilterChange(props.filterKey, e.target.value)}
              input={<Input />}
              renderValue={(selected: any) => {
                return <span></span>
              }}
              MenuProps={MenuProps}
            >
                <MenuItem key={-1} value={'clear_filters'} style={{}}>
                  Clear Filters
                </MenuItem>
              {props.filters[props.filterKey].options.map((filter, iindex: number) => (
                <MenuItem key={iindex} value={filter.key} style={{}}>
                  {filter.title}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
		</div>
	);
}

export default CitationMetaFilter