import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Button,
  Container,
  Card,
  TextField,
  Typography,
  CardContent,
} from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";
import Loading from "../shared/Loading";
import { forgotPassword } from "../../store/actions/authActions";
import { useHistory } from "react-router"

interface IForgotPasswordProps {}

const ForgotPassword: React.FC<IForgotPasswordProps> = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [processing, setProcessing] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const history = useHistory();

  const handleCaptchaChange = (value?: string | null) => {
    setCaptcha(value as string);
  };

  const onSubmit = () => {
    console.log({ email, captcha });
    setProcessing(true);
    dispatch(
      forgotPassword(
        { email, captcha },
        () => {
          setProcessing(false);
          enqueueSnackbar(
            "An email was sent with password reset instructions",
            { variant: "success" }
          );
          history.push('/')
        },
        () => {
          setProcessing(false);
        }
      )
    );
  };

  return (
    <Container
      style={{
        display: "flex",
        minHeight: "100vh",
        backgroundColor: COLORS.WHITE_PAPER,
      }}
    >
      <Loading loading={processing} />
      <Card style={{ height: "fit-content", width: 500 }}>
        <CardContent style={{ display: "flex", flexDirection: "column" }}>
          <Typography color="textSecondary" gutterBottom variant="h5">
            Forgot Password
          </Typography>
          <TextField
            label="Email"
            fullWidth={true}
            size="small"
            onChange={(event) => setEmail(event?.target.value)}
            style={{ marginBottom: "2rem", marginTop: "2rem" }}
            value={email}
            variant="outlined"
          />
          <ReCAPTCHA
            onChange={(event) => handleCaptchaChange(event)}
            ref={recaptchaRef}
            sitekey="6Ldlu6QkAAAAAN48MxrIl_Vm_TqWjAHJqYTzyuhU"
          />
          <Button
            onClick={() => onSubmit()}
            style={{ marginTop: "2rem" }}
            variant="outlined"
          >
            Submit
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ForgotPassword;
