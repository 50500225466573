import { Input, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { FilterListRounded } from '@material-ui/icons';
import { FilterIcon } from './CitationComponents';
import { CitationTableFilters, citationTableFilterToString, CITATION_TABLE_FILTER } from '../../enums/citation-enums';
interface ICitationMetaFilterProps {
	superHandleFilterChange: Function
	selectedFilters: CITATION_TABLE_FILTER[]
}

const MenuProps = {
	PaperProps: {
	  style: {
		maxHeight: '75vh', //ITEM_HEIGHT * 12.5 + ITEM_PADDING_TOP,
		width: 250,
	  },
	},
  };

const CitationMetaFilter: React.FC<ICitationMetaFilterProps> = (props) => {

  let iconColor = "disabled"
  if (props.selectedFilters.length){
    iconColor = "primary"
  }
  //console.log("props.selectedFilters", props.selectedFilters)
	// const handleFilterChange = (event: any) => {
	// 	setSelectedFilters(event.target.value);
	// 	props.superHandleFilterChange(event.target.value)
	//   };
  //console.log("SELECTED?", props.selectedFilters)
	return (
		<div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          {/* <InputLabel id="citation-filter-label">Filters</InputLabel> */}
          <div style={{ display: 'inline-block' }}>
			      {/* {props.selectedFilters.length === 0 ? <FilterListRounded color="secondary" /> : null} */}
            <FilterListRounded color={iconColor as any} />
            <Select
              labelId="citation-filter-label"
              id="citation-filter-select"
              multiple
              value={props.selectedFilters}
              onChange={(e: any) => props.superHandleFilterChange(e.target.value)}
              input={<Input />}
              renderValue={(selected: any) => {
                return <span></span>
                // return selected.map((item: any, iindex: number) => {
                //   return (
                //     <span key={iindex}>
                //       {FilterIcon(item, false)}
                //       {/* {citationTableFilterToString(item)} */}
                //     </span>
                //   )
                // })
              }}
              MenuProps={MenuProps}
            >
                <MenuItem key={-1} value={''} style={{}}>
                  Select Filter
                </MenuItem>
              {CitationTableFilters.map((filter, iindex: number) => (
                <MenuItem key={iindex} value={filter} style={{}}>
                  {FilterIcon(filter, false)}
                  {citationTableFilterToString(filter)}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
		</div>
	);
}

export default CitationMetaFilter