import { Button, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import MaterialTable from "material-table";
import { tableIcons } from "../shared/MaterialTableShared";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import DialogBox from "../form/DialogBox";
import {
  createBusinessUnit,
  deleteBusinessUnit,
  getBusinessUnit,
  updateBusinessUnit,
} from "../../store/actions/businessUnitActions";
import BusinessUnitsModal from "./BusinessUnitsModal";
import { useParams } from "react-router";

interface IBusinessUnitsTable {
  data: any[];
}

const BusinessUnitsTable: React.FC<IBusinessUnitsTable> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState({} as any);
  const [selectedRow, setSelectedRow] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const onSubmitActionModal = (
    data: any,
    onSuccess: Function,
    onFail?: Function
  ) => {
    if (data.pk) {
      dispatch(
        updateBusinessUnit(
          data,
          () => {
            onSuccessAction(onSuccess);
          },
          onFail
        )
      );
    } else {
      dispatch(
        createBusinessUnit(
          { data: data, companyId: companypk },
          () => {
            onSuccessAction(onSuccess);
          },
          onFail
        )
      );
    }
  };

  const { companypk } = useParams() as any;
  const onSuccessAction = (fnModalAction: any) => {
    fnModalAction();
    setIsModalOpen(false);
  };

  const emptySet = {
    pk: 0,
    businessUnitName: "",
    companyFk: companypk,
    selectedMines: [],
  };

  const ongetBusinessUnitDetail = (itemId: any) => {
    dispatch(
      getBusinessUnit(itemId, (data: any) => {
        setRowData(data[0]);
        setIsModalOpen(true);
      })
    );
  };

  const onDeleteEmailSettingAction = (itemId: number) => {
    dispatch(deleteBusinessUnit(itemId, onDeleteItemSuccess, onDeleteItemFail));
  };

  const onDeleteItemSuccess = (wasAdded: boolean) => {
    enqueueSnackbar("Campany Email Setting was successfully deleted.", {
      variant: "success",
    });
  };

  const onDeleteItemFail = (message: string) => {
    enqueueSnackbar("Unable to delete company email setting.", {
      variant: "error",
    });
  };

  const onOkDialogAction = () => {
    onDeleteEmailSettingAction(selectedRow);
    setIsDeleteDialogOpen(false);
  };

  const onCancelOrCloseDialogAction = () => {
    setIsDeleteDialogOpen(false);
    setSelectedRow(0);
  };

  const columns = [
    { title: "Business Unit Name", field: "businessUnitName", sorting: true },
    {
      title: "Actions",
      render: (row: any) => {
        return (
          <div>
            <Tooltip title="Edit" placement="top">
              <EditIcon
                style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
                className="transparent75"
                onClick={() => {
                  if (row && row.pk) {
                    ongetBusinessUnitDetail(row.pk);
                  }
                }}
              />
            </Tooltip>

            <Tooltip title="Delete" placement="top">
              <DeleteIcon
                style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
                className="transparent75"
                onClick={() => {
                  if (row.pk) {
                    setIsDeleteDialogOpen(true);
                    setSelectedRow(row.pk);
                  }
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <DialogBox
        id="dialogbox1"
        open={isDeleteDialogOpen}
        onClose={onCancelOrCloseDialogAction}
        onCancel={onCancelOrCloseDialogAction}
        onOk={onOkDialogAction}
        title="Are you sure you want to delete this Business Unit?"
        innerText="After deleting this Business Unit you will not be able to roll it back."
      ></DialogBox>

      <BusinessUnitsModal
        itemData={rowData}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Business Unit Group"
        onSubmit={onSubmitActionModal}
        isEditMode={rowData.pk ? true : false}
        isCompany={true}
      />

      <Button
        style={{ marginBottom: 5 }}
        onClick={() => {
          setRowData(emptySet);
          setIsModalOpen(true);
        }}
        variant="outlined"
        size="small"
      >
        Add Business Unit
      </Button>
      <MaterialTable
        title="Business Unit Groups"
        icons={tableIcons}
        columns={columns}
        data={props.data}
        options={{
          sorting: true,
          headerStyle: {
            zIndex: 8,
          },
          paging: true,
          pageSize: 5,
          draggable: false,
          //actionsColumnIndex: -1
        }}
      />
    </>
  );
};

export default BusinessUnitsTable;
