import { takeEvery, takeLatest } from "redux-saga/effects";
import { ACTIONS } from "../../enums/actions";
import mineSaga from "./mineSaga";
import citationSaga from "./citationSaga";
import companySaga from "./companySaga";
import authSaga from "./authSaga";
import clientSaga from "./clientSaga";
import accountSaga from "./accountSaga";
import inspectionSaga from "./inspectionSaga";
import analyticsSaga from "./analyticsSaga";
import userSaga from "./userSaga";
import emailSettingsSaga from "./emailSettingsSaga";
import businessUnitSaga from "./businessUnitSaga";
import regionSaga from "./regionSaga";
import contractorSaga from "./contractorSaga";

export default function* rootSaga() {
  yield takeLatest(
    ACTIONS.GET_ALL_MINES_GROUP_BY_COMPANY,
    mineSaga.getAllMinesGroupByCompany
  );
  yield takeLatest(
    ACTIONS.GET_ALL_COLUMNS_MINES_SUMMARY,
    mineSaga.getAllColumnsMinesSummary
  );
  yield takeLatest(ACTIONS.GET_MINES_BY_COMPANY, mineSaga.getMinesByCompany);
  yield takeEvery(ACTIONS.GET_MINE, mineSaga.getMine);
  yield takeLatest(ACTIONS.UPDATE_MINE, mineSaga.updateMine);
  yield takeLatest(ACTIONS.CREATE_MINE, mineSaga.createMine);
  yield takeLatest(ACTIONS.DELETE_MINE, mineSaga.deleteMine);

  yield takeLatest(ACTIONS.GET_CITATIONS, citationSaga.getCitations);
  yield takeEvery(ACTIONS.GET_CITATION, citationSaga.getCitation);
  yield takeEvery(ACTIONS.DELETE_CITATION, citationSaga.deleteCitation);

  yield takeEvery(
    ACTIONS.GET_CITATION_WITH_METADATA,
    citationSaga.getCitationWithMetada
  );
  yield takeLatest(ACTIONS.UPDATE_CITATIONS, citationSaga.updateCitations);
  yield takeLatest(
    ACTIONS.UPDATE_CITATION_NOTE,
    citationSaga.updateCitationNote
  );
  yield takeLatest(ACTIONS.UPLOAD_CITATIONS, citationSaga.uploadCitations);
  yield takeLatest(
    ACTIONS.DOWNLOAD_CITATION_DOC,
    citationSaga.downloadCitationFile
  );
  yield takeLatest(ACTIONS.SAVE_CITATION_SCANS, citationSaga.saveCitationScans);
  yield takeLatest(
    ACTIONS.GET_PATERN_OF_VIOLATIONS,
    citationSaga.getPaternOfViolations
  );
  yield takeLatest(
    ACTIONS.GET_CITATION_PENALTY_CALCULATION_ANALYSIS,
    citationSaga.getCitationPenaltyCalculationAnalysis
  );
  yield takeLatest(
    ACTIONS.GET_CITATION_WARNINGS,
    citationSaga.getCitationWarnings
  );
  yield takeLatest(ACTIONS.GET_CONTROLLERS, analyticsSaga.getControllers);
  yield takeLatest(
    ACTIONS.GET_CONFERENCE_TRACKING,
    citationSaga.getConferenceTracking
  );
  yield takeLatest(
    ACTIONS.GET_CONFERENCE_TRACKING_BY_CITATION_PK,
    citationSaga.getConferenceTrackingByCitationPk
  );

  yield takeLatest(
    ACTIONS.UPSERT_CONFERENCE_TRACKING,
    citationSaga.upsertConferenceTracking
  );
  yield takeLatest(
    ACTIONS.DOWNLOAD_BULK_CITATIONS,
    citationSaga.downloadBulkCitations
  );
  yield takeLatest(
    ACTIONS.DELETE_CITATION_NOTES_FILE,
    citationSaga.deleteCitationNotesFile
  );
  yield takeLatest(
    ACTIONS.GET_CITATION_MGMT_CUSTOM_FIELDS,
    citationSaga.getCitationMgmtCustomFields
  );
  yield takeLatest(
    ACTIONS.UPDATE_CITATION_MGMT_CUSTOM_FIELD,
    citationSaga.updateCitationMgmtCustomField
  );

  yield takeLatest(ACTIONS.CREATE_COMPANY, companySaga.createCompany);
  yield takeLatest(ACTIONS.UPDATE_COMPANY, companySaga.updateCompany);
  yield takeLatest(ACTIONS.GET_COMPANY, companySaga.getCompany);
  yield takeLatest(ACTIONS.GET_COMPANIES, companySaga.getCompanies);
  yield takeLatest(ACTIONS.GET_COMPANY_SUMMARY, companySaga.getCompanySummary);
  yield takeLatest(
    ACTIONS.DOWNLOAD_COMPANY_SUMMARY_EXCEL,
    companySaga.downloadExcelSummaryReport
  );
  yield takeLatest(
    ACTIONS.DOWNLOAD_8K_SUMMARY_EXCEL,
    companySaga.download8KReport
  );
  yield takeLatest(
    ACTIONS.DOWNLOAD_10K_SUMMARY_EXCEL,
    companySaga.download10KReport
  );
  yield takeEvery(ACTIONS.DELETE_COMPANY, companySaga.deleteCompany);

  yield takeLatest(ACTIONS.LOGIN, authSaga.login);
  yield takeLatest(ACTIONS.LOGOUT, authSaga.logout);
  yield takeLatest(ACTIONS.FORGOT_PASSWORD, authSaga.forgotPassword);
  yield takeLatest(ACTIONS.RESET_PASSWORD, authSaga.resetPassword);

  yield takeLatest(ACTIONS.GET_ACCOUNTS_BY_FLAG, accountSaga.getAccountsByFlag);
  yield takeLatest(
    ACTIONS.GET_ADMIN_EMAIL_SETTINGS,
    accountSaga.getAdminEmailSettings
  );
  yield takeLatest(
    ACTIONS.UPDATE_ADMIN_EMAIL_SETTING,
    accountSaga.updateAdminEmailSetting
  );
  yield takeLatest(
    ACTIONS.GET_ACCOUNT_LOGINS_BY_COMPANY,
    accountSaga.getByCompany
  );
  yield takeLatest(
    ACTIONS.CREATE_ADMIN_EMAIL_SETTING,
    accountSaga.createAdminEmailSettings
  );
  yield takeLatest(
    ACTIONS.DELETE_ADMIN_EMAIL_SETTING,
    accountSaga.deleteAdminEmailSettings
  );
  yield takeLatest(
    ACTIONS.UPDATE_SELECTED_COLUMN_INDEXES,
    accountSaga.updatedSelectedColumnIndexes
  );
  yield takeLatest(
    ACTIONS.GET_ACTIVE_COMPANIES,
    accountSaga.getActiveCompanies
  );

  yield takeLatest(
    ACTIONS.GET_USERS_ACTIVITY,
    accountSaga.getUsersActivityByCompany
  );

  yield takeLatest(ACTIONS.SET_CLIENT_OPTIONS, clientSaga.setClientOptions);
  yield takeLatest(ACTIONS.GET_INSPECTION, inspectionSaga.getInspection);
  yield takeLatest(
    ACTIONS.GET_INSPECTIONS_BY_MINE,
    inspectionSaga.getInspectionsByMine
  );
  yield takeLatest(
    ACTIONS.GET_MINE_REGULATIOND_WARNING,
    mineSaga.getMineRegulationWarning
  );

  yield takeLatest(
    ACTIONS.GET_INSPECTION_BY_EVENT_NUMBER,
    inspectionSaga.getInspectionByEventNumber
  );
  yield takeLatest(ACTIONS.ADD_INSPECTION, inspectionSaga.addInspection);
  yield takeLatest(ACTIONS.UPDATE_INSPECTION, inspectionSaga.updateInspection);
  yield takeLatest(ACTIONS.DELETE_INSPECTION, inspectionSaga.deleteInspection);
  yield takeLatest(ACTIONS.GET_MINE_STATE, analyticsSaga.getMineState);
  yield takeLatest(
    ACTIONS.GET_INSPECTOR_AR_LIST,
    inspectionSaga.getInspectorARList
  );
  yield takeLatest(
    ACTIONS.GET_INSPECTOR_TRENDS,
    inspectionSaga.getInspectorTrends
  );

  yield takeEvery(ACTIONS.CREATE_USER, userSaga.createUser);
  yield takeLatest(ACTIONS.UPDATE_USER, userSaga.updateUser);
  yield takeLatest(ACTIONS.GET_USER, userSaga.getUser);
  yield takeLatest(ACTIONS.GET_ADMIN_USER, userSaga.getAdminUser);
  yield takeLatest(ACTIONS.DELETE_USER, userSaga.deleteUser);

  yield takeEvery(
    ACTIONS.CREATE_COMPANY_EMAIL_SETTING,
    emailSettingsSaga.createCompanyEmailSetting
  );
  yield takeLatest(
    ACTIONS.UPDATE_COMPANY_EMAIL_SETTING,
    emailSettingsSaga.updateCompanyEmailSetting
  );
  yield takeLatest(
    ACTIONS.GET_COMPANY_EMAIL_SETTING,
    emailSettingsSaga.getCompanyEmailSetting
  );
  yield takeLatest(
    ACTIONS.GET_ALL_COMPANY_EMAIL_SETTING,
    emailSettingsSaga.getAllCompanyEmailSettings
  );
  yield takeLatest(
    ACTIONS.DELETE_COMPANY_EMAIL_SETTING,
    emailSettingsSaga.deleteCompanyEmailSetting
  );

  yield takeEvery(
    ACTIONS.CREATE_BUSINESS_UNIT,
    businessUnitSaga.createBusinessUnit
  );
  yield takeLatest(
    ACTIONS.UPDATE_BUSINESS_UNIT,
    businessUnitSaga.updateBusinessUnit
  );
  yield takeLatest(ACTIONS.GET_BUSINESS_UNIT, businessUnitSaga.getBusinessUnit);
  yield takeLatest(
    ACTIONS.GET_ALL_BUSINESS_UNIT,
    businessUnitSaga.getAllBusinessUnit
  );
  yield takeLatest(
    ACTIONS.DELETE_BUSINESS_UNIT,
    businessUnitSaga.deleteBusinessUnit
  );

  yield takeEvery(ACTIONS.CREATE_REGION, regionSaga.createRegion);
  yield takeLatest(ACTIONS.UPDATE_REGION, regionSaga.updateRegion);
  yield takeLatest(ACTIONS.GET_REGION, regionSaga.getRegion);
  yield takeLatest(ACTIONS.GET_ALL_REGION, regionSaga.getAllRegion);
  yield takeLatest(ACTIONS.DELETE_REGION, regionSaga.deleteRegion);
  yield takeLatest(
    ACTIONS.GET_VPIDRPID_HISTORY,
    analyticsSaga.getVPIDRPIDHistory
  );

  yield takeLatest(ACTIONS.GET_CONTRACTORS, contractorSaga.getContractors);
}
