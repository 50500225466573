
import { COLORS } from "../../enums/layout-enums";
import { dateFormatted } from "../../utils/date-utils";
import { IVPIDHistory } from "./VPIDHistoryData";

export interface ICitationAnalyticDataSets {
  currentData: any[];
  futureData: any[];
  currentDataSerieName: string;
  futureDataSerieName: string;
}

export const DoubleChartForHistoricalPoints = (
  results: ICitationAnalyticDataSets
) => {
  const labels = results.currentData
    .map((x: IVPIDHistory) => {
      return dateFormatted(x.date, "mmddyyyy");
    })
    .sort(function (a: any, b: any) {
      var key1 = Date.parse(a);
      var key2 = Date.parse(b);

      if (key1 < key2) {
        return -1;
      } else if (key1 == key2) {
        return 0;
      } else {
        return 1;
      }
    });

  const dataSetsPoints = [
    {
      label: results.currentDataSerieName,
      data: results.currentData.map((x: IVPIDHistory) => {
        return x.value;
      }),
      backgroundColor: [COLORS.BLUE_MEDIUM_BACKGROUND],
      borderColor: COLORS.BLUE_MEDIUM_BACKGROUND,
      borderWidth: 1,
    },
  ];

  if (results.futureData) {
    if (results.futureData.length > 0) {
      dataSetsPoints.push({
        label: results.futureDataSerieName,
        data: results.futureData.map((x: IVPIDHistory) => {
          return x.value;
        }),
        backgroundColor: [COLORS.RED],
        borderColor: COLORS.RED,
        borderWidth: 1,
      });
    }
  }

  return {
    labels: labels,
    datasets: dataSetsPoints,
    updatedAt: new Date().toISOString(),
  };
};
