import {cloneDeep} from 'lodash';

export const arrayFromKeyedObject = (obj: any) => {

    let returnArray = [];
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            returnArray.push(cloneDeep(obj[key]));
        }
    }
    return returnArray;
}

export const filterArrayRandomly = (array: any[], chancePercent: number) => {
    const newArray = [] as any[];
    array.forEach((elem) => {
        if (Math.random() < chancePercent){
            newArray.push(elem)
        }
    })
    return newArray;
}