import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Loading from "../shared/Loading";
import { useSnackbar } from "notistack";
import { ICitationMetaData } from "../../interfaces/model/MSHAPCT/citation-meta-data.interface";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  CloseoutConferenceToString,
  CLOSEOUT_CONFERENCE,
  typeOfActionToString,
} from "../../enums/citation-enums";
import { useDispatch } from "react-redux";
import {
  getCitationWithMetada,
  getConferenceTrackingByCitationPk,
  upsertConferenceTracking,
} from "../../store/actions/citationActions";
import {
  ICitation,
  IConferenceTracking,
} from "../../interfaces/model/MSHAPCT/citation.interface";
import { useParams } from "react-router-dom";
import { useSelector } from "../../store";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { IRootState } from "../../interfaces/store";
import { makeStyles } from "@material-ui/core/styles";
import { dateFormatted } from "../../utils/date-utils";
import AddToCalendarHOC from "react-add-to-calendar-hoc";
import StandardModal from "../shared/StandardModal";
import { format } from "date-fns";
interface ICitationModificationTrackingProps {
  // conferenceTrackingPk?: number;
  // citationPk?: number;
  // citationMetaPk?: number;
  // open: boolean;
  // onClose: Function;
  // title: string;
  // onSubmit: Function;
  // isEditMode: boolean;
}
const useStyles = makeStyles({
  customTable: {
    color: "blue !important",
  },
  indicator: {
    backgroundColor: "transparent",
  },
});

const CitationModificationTracking: React.FC<ICitationModificationTrackingProps> =
  (props) => {
    const ATCDropdown = (args: any) => (
      <StandardModal
        open={true}
        maxWidth={"sm"}
        onClose={() => {
          setShowAddToCalendarModal(!showAddToCalendarModal);
        }}
        actions={[]}
        customActions={[]}
        paragraphs={[`Choose a calendar `]}
        title={"Add to Calendar"}
        children={
          <ul className="atc-dropdown">
            {args.children.map((link: any, i: any) => (
              <li key={i}>
                <span
                  onClick={() =>
                    setShowAddToCalendarModal(!showAddToCalendarModal)
                  }
                >
                  {link}
                </span>
              </li>
            ))}
          </ul>
        }
      />
    );

    const ATCWrapper = (args: any) => {
      console.log("ARGS", args);
      //setShowAddToCalendarModal(true)
      return (
        <a onClick={args.onClick} className="atc-item" href="/">
          {args.children}
        </a>
      );
    };

    const AddToCalendarDropdown = AddToCalendarHOC(ATCWrapper, ATCDropdown);

    const { citationpk } = useParams<{ citationpk: string }>();

    const [errors, setErrors] = useState({} as any);
    const { enqueueSnackbar } = useSnackbar();
    const [processing, setProcessing] = useState(true);

    const [conferenceTrackingPk, setConferenceTrackingPk] = useState<
      number | undefined
    >(undefined);
    const [citationPk, setCitationPk] = useState<number>(Number(citationpk));
    const [showAddToCalendarModal, setShowAddToCalendarModal] = useState(false);
    const [citationMetaPk, setCitationMetaPk] = useState<number | undefined>(
      undefined
    );
    const dispatch = useDispatch();
    const [conferenceTracking, setConferenceTracking] =
      useState<IConferenceTracking>({
        pk: undefined,
        closeoutConference: CLOSEOUT_CONFERENCE.ACCEPTED_AS_ISSUED,
        closeoutNotes: "",
        informalConferenceDate: new Date(),
        requested100_6: null,
        formalContest1007Requested: null,
        clrId: "",
        formalContestAssessmentControlNumber: "",
        formalContestDocket: "",
        alj: "",
        resultFinalSettlement: null,
        resultNewPenalty: 0,
        resultProposedPenalty: 0,
        resultMoneySaved: 0,
        formalContest1007Action: 3,
        informalAction: 3,
        informalConferenceResult: null,
        formalContestProposedPenalty: 0,
        informalContestNotes: "",
        formalContestNotes: "",
        resultsNotes: "",
      });

    const citationsMetaData = useSelector(
      (state: IRootState) => state.main.citation_meta_data
    );
    const citationList = useSelector(
      (state: IRootState) => state.main.citations
    );

    const [citationMetaData, setCitationMetaData] =
      useState<ICitationMetaData>();

    const [citation, setCitation] = useState<ICitation>({
      dateIssued: new Date(),
    });

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      const citationFound = citationList[citationPk];
      setCitation(citationFound);
      if (!conferenceTracking.pk) {
        setConferenceTracking({
          ...conferenceTracking,
          informalConferenceDate: addDays(
            citationFound.dateIssued ?? new Date(),
            10
          ),
        });
      }
    }, [citationList]);

    useEffect(() => {
      const sourceCitationsMetaData = Object.values(citationsMetaData).filter(
        (item) => item.citationFk === Number(citationPk)
      )[0];
      setCitationMetaData(sourceCitationsMetaData);
    }, [citationsMetaData]);

    useEffect(() => {
      dispatch(
        getConferenceTrackingByCitationPk({ pk: citationPk }, (data: any) => {
          if (data) {
            if (!data.formalContestProposedPenalty) {
              data.formalContestProposedPenalty = 0;
            }
            setConferenceTracking(data);
          } else {
            const issuedDate: Date = citation.dateIssued ?? new Date();
            setConferenceTracking({
              ...conferenceTracking,
              informalConferenceDate: addDays(issuedDate, 10),
            });
          }

          setProcessing(false);
        })
      );

      dispatch(getCitationWithMetada(citationPk));

      //props.conferenceTrackingPk, props.citationPk, dispatch
    }, [citationPk, dispatch]);

    const submitForm = (event: any) => {
      event.preventDefault();
      setProcessing(true);
      if (!formIsValid()) return;
      console.log("conferenceTracking", conferenceTracking);
      // conferenceTracking.resultMoneySaved = 20.00;
      // conferenceTracking.resultNewPenalty = 20.00;
      // conferenceTracking.resultProposedPenalty = 20.00;
      // conferenceTracking.resultFinalSettlement = false;
      // conferenceTracking.informalConferenceResult = 0;
      dispatch(
        upsertConferenceTracking(
          { conferenceTracking, CitationPk: citationPk },
          (data: any) => {
            console.log("success callback", data);
            setConferenceTracking(data.conferenceTracking);
            setProcessing(false);
            enqueueSnackbar("Changes were saved successfully..", {
              variant: "success",
            });
          }
        )
      );
    };

    useEffect(() => {
      if (conferenceTracking) {
        const resultProposedPenalty = citationMetaData?.penalty || 0;

        let resultMoneySaved = 0;
        if (conferenceTracking.resultFinalSettlement) {
          resultMoneySaved =
            resultProposedPenalty -
            Number(conferenceTracking.resultNewPenalty ?? 0);
        }

        setConferenceTracking({
          ...conferenceTracking,
          resultMoneySaved,
          resultProposedPenalty,
        });
      }
    }, [
      conferenceTracking.formalContestProposedPenalty,
      conferenceTracking.resultFinalSettlement,
      conferenceTracking.resultNewPenalty,
      citationMetaData,
    ]);

    function formIsValid() {
      const _errors: any = {};
      setErrors(_errors);
      return Object.keys(_errors).length === 0;
    }

    function onValueChange(event: any) {
      const conferenceTrackingUpdated = {
        ...conferenceTracking,
        [event.target.name]: event.target.value,
      };
      setConferenceTracking(conferenceTrackingUpdated);
    }

    const onDateChange = (name: any) => (date: Date | null) => {
      const conferenceTrackingUpdated = {
        ...conferenceTracking,
        [name]: date,
      };
      setConferenceTracking(conferenceTrackingUpdated);
    };

    const stringToBoolOrNull = (str: string) => {
      if (str === "yes") {
        return true;
      }
      if (str === "no") {
        return false;
      }
      return null;
    };

    const boolOrNullToString = (bl: any) => {
      if (bl === true) {
        return "yes";
      }
      if (bl === false) {
        return "no";
      }
      return "not_selected";
    };

    const addDays = (date: Date, days: number): Date => {
      if (typeof date === "string") {
        date = new Date(String(date));
      }

      date.setDate(date.getDate() + days);
      return date;
    };

    const classes = useStyles();
    const [tab1, setTab1] = React.useState(0);
    const [tab2, setTab2] = React.useState(0);
    const [tab3, setTab3] = React.useState(0);
    const [tab4, setTab4] = React.useState(0);

    const startTime =
      format(new Date(2018, 1, 11), "yyyyMMdd") +
      "T" +
      format(new Date(2018, 1, 11, 12), "HHmmss");
    const endTime =
      format(new Date(2018, 1, 11), "yyyyMMdd") +
      "T" +
      format(new Date(2018, 1, 11, 13), "HHmmss");
    const duration = 1;

    const getFormattedDate = (date: any, addHours: number) => {
      const addMS = addHours * 3600 * 1000;

      const adjustedDate = new Date(new Date(date).getTime() + addMS);
      return (
        format(adjustedDate, "yyyyMMdd") + "T" + format(adjustedDate, "HHmmss")
      );
    };

    console.log(
      "conferenceTracking?.informalConferenceDate",
      conferenceTracking?.informalConferenceDate
    );
    return (
      <>
        <Loading loading={processing} />

        <div
          style={{
            padding: 0,
            display: "flex",
            minWidth: 800,
          }}
        >
          <div style={{ flex: 1, alignSelf: "center", marginBottom: 16 }}>
            <Typography variant="h6">Citation Modifications</Typography>
            <div>Citation Number: {citation?.citationNumber}</div>
            <div>
              Type of Action:{" "}
              {citation?.typeOfAction
                ? typeOfActionToString(citation.typeOfAction)
                : null}
            </div>
            <div>Section of CFR: {citation?.sectionOfCfr}</div>
            <div>
              Date Issued:{" "}
              {citation ? dateFormatted(citation.dateIssued, "mmddyyyy") : null}
            </div>
          </div>
          {/* <div style={{ flex: 1, textAlign: "right" }}>
              <IconButton aria-label="close" onClick={() => props.onClose()}>
                <CloseIcon />
              </IconButton>
            </div> */}
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AppBar position="static">
              <Tabs
                value={tab1}
                aria-label="wrapped label tabs example"
                classes={{
                  indicator: classes.indicator,
                }}
              >
                <Tab label="Closeout" wrapped />
              </Tabs>
            </AppBar>
            <Box p={3}>
              <Grid container spacing={1} justify="center">
                <Grid item xs={12} lg={6}>
                  <Select
                    classes={{ root: classes.customTable }}
                    style={{ height: 40 }}
                    id="inlineFormCustomSelect"
                    variant="outlined"
                    value={conferenceTracking.closeoutConference}
                    onChange={(e) => {
                      setConferenceTracking({
                        ...conferenceTracking,
                        closeoutConference: e.target.value as string,
                      });
                    }}
                    displayEmpty
                  >
                    <MenuItem value={CLOSEOUT_CONFERENCE.VACATED}>
                      {CloseoutConferenceToString(CLOSEOUT_CONFERENCE.VACATED)}
                    </MenuItem>
                    <MenuItem value={CLOSEOUT_CONFERENCE.MODIFIED}>
                      {CloseoutConferenceToString(CLOSEOUT_CONFERENCE.MODIFIED)}
                    </MenuItem>
                    <MenuItem value={CLOSEOUT_CONFERENCE.UPHELD}>
                      {CloseoutConferenceToString(CLOSEOUT_CONFERENCE.UPHELD)}
                    </MenuItem>
                    <MenuItem value={CLOSEOUT_CONFERENCE.ACCEPTED_AS_ISSUED}>
                      {CloseoutConferenceToString(
                        CLOSEOUT_CONFERENCE.ACCEPTED_AS_ISSUED
                      )}
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <div className={errors.closeoutNotes ? "error " : ""}>
                    <InputLabel
                      shrink
                      htmlFor="closeoutNotes"
                      style={{ display: "inline-block" }}
                    >
                      Closeout Notes
                    </InputLabel>
                    <em>{errors.closeoutNotes}</em>
                    <TextField
                      fullWidth
                      multiline
                      id="closeoutNotes"
                      name="closeoutNotes"
                      size="small"
                      variant="outlined"
                      onChange={onValueChange}
                      type="text"
                      value={conferenceTracking?.closeoutNotes || ""}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} lg={6}></Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AppBar position="static" color="default">
              <Tabs
                value={tab2}
                aria-label="wrapped label tabs example"
                classes={{
                  indicator: classes.indicator,
                }}
              >
                <Tab label="Informal Conference" wrapped />
              </Tabs>
            </AppBar>
            <Box p={3}>
              <Grid container spacing={1} justify="center">
                <Grid item xs={12} lg={6}>
                  <div
                    className={errors.informalConferenceDate ? "error " : ""}
                  >
                    <InputLabel
                      shrink
                      htmlFor="informalConferenceDate"
                      style={{ display: "inline-block" }}
                    >
                      Informal Conference Request Due Date
                      {conferenceTracking?.informalConferenceDate && (
                        <AddToCalendarDropdown
                          className={"neat"}
                          linkProps={{
                            className: "other",
                          }}
                          event={{
                            description:
                              "Informal Conference Request Due Date.",
                            duration: 1,
                            endDatetime: getFormattedDate(
                              conferenceTracking?.informalConferenceDate,
                              1
                            ),
                            startDatetime: getFormattedDate(
                              conferenceTracking?.informalConferenceDate,
                              0
                            ),
                            title: "Informal Conference Request Due Date",
                          }}
                        />
                      )}
                    </InputLabel>
                    <em>{errors.informalConferenceDate}</em>
                    <KeyboardDatePicker
                      fullWidth
                      variant="inline"
                      inputVariant="outlined"
                      size="small"
                      disableToolbar
                      // defaultValue={
                      //   addDays(new Date(), 10).getMonth() +
                      //   1 +
                      //   "-" +
                      //   addDays(new Date(), 10).getDate() +
                      //   "-" +
                      //   addDays(new Date(), 10).getFullYear()
                      // }
                      format="MM/dd/yyyy"
                      id="informalConferenceDate"
                      name="informalConferenceDate"
                      value={conferenceTracking?.informalConferenceDate}
                      onChange={onDateChange("informalConferenceDate")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div className="flex-column-oriented full-width mr-16">
                      {/* <label htmlFor="Mine Selector">Mine Selector</label> */}
                      <InputLabel shrink id="">
                        <span>Date 100.6 Requested</span>
                        <br />
                        <br />
                      </InputLabel>
                      <Select
                        style={{ height: 40 }}
                        id="inlineFormCustomSelect"
                        variant="outlined"
                        value={boolOrNullToString(
                          conferenceTracking.requested100_6
                        )}
                        onChange={(e) => {
                          setConferenceTracking({
                            ...conferenceTracking,
                            requested100_6: stringToBoolOrNull(
                              e.target.value as string
                            ),
                          });
                        }}
                        displayEmpty
                      >
                        <MenuItem value={"yes"}>{"Yes"}</MenuItem>
                        <MenuItem value={"no"}>{"No"}</MenuItem>
                        <MenuItem value={"not_selected"}>
                          {<span>Select Result</span>}
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className={errors.clrId ? "error " : ""}>
                    <InputLabel
                      shrink
                      htmlFor="clrId"
                      style={{ display: "inline-block" }}
                    >
                      CLR Id
                    </InputLabel>
                    <em>{errors.clrId}</em>
                    <TextField
                      fullWidth
                      id="clrId"
                      name="clrId"
                      size="small"
                      variant="outlined"
                      onChange={onValueChange}
                      type="text"
                      value={conferenceTracking?.clrId || ""}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className={errors.informalContestNotes ? "error " : ""}>
                    <InputLabel
                      shrink
                      htmlFor="informalContestNotes"
                      style={{ display: "inline-block" }}
                    >
                      Informal Conference Notes
                    </InputLabel>
                    <em>{errors.informalContestNotes}</em>
                    <TextField
                      fullWidth
                      multiline
                      id="informalContestNotes"
                      name="informalContestNotes"
                      size="small"
                      variant="outlined"
                      onChange={onValueChange}
                      type="text"
                      value={conferenceTracking?.informalContestNotes || ""}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className={errors.none ? "error " : ""}></div>
                </Grid>

                <Grid item xs={12} lg={6}></Grid>
                <Grid item xs={12} lg={6}></Grid>
              </Grid>

              <Grid item xs={12} lg={6}>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div className="flex-column-oriented full-width mr-16">
                    {/* <label htmlFor="Mine Selector">Mine Selector</label> */}
                    <InputLabel shrink id="">
                      Action
                    </InputLabel>
                    <Select
                      style={{ height: 40 }}
                      id="inlineFormCustomSelect"
                      variant="outlined"
                      value={conferenceTracking.informalAction}
                      onChange={(e: any) => {
                        const value: number = e.target.value;
                        setConferenceTracking({
                          ...conferenceTracking,
                          informalAction: value,
                        });
                      }}
                      displayEmpty
                    >
                      <MenuItem value={0}>{"Vacated"}</MenuItem>
                      <MenuItem value={1}>{"Modified"}</MenuItem>
                      <MenuItem value={2}>{"Withdrawn"}</MenuItem>
                      <MenuItem value={3}>{"Upheld"}</MenuItem>
                      <MenuItem value={4}>{<span>&nbsp;</span>}</MenuItem>
                    </Select>
                  </div>
                </div>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {/* Formal Contest */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AppBar position="static" color="default">
              <Tabs
                value={tab3}
                aria-label="wrapped label tabs example"
                classes={{
                  indicator: classes.indicator,
                }}
              >
                <Tab label="Formal Contest" wrapped />
              </Tabs>
            </AppBar>
            <Box p={3}>
              <Grid container spacing={1} justify="center">
                <Grid item xs={12} lg={6}>
                  <div
                    className={
                      errors.formalContestAssessmentControlNumber
                        ? "error "
                        : ""
                    }
                  >
                    <InputLabel
                      shrink
                      htmlFor="formalContestAssessmentControlNumber"
                      style={{ display: "inline-block" }}
                    >
                      Assessment Control Number
                    </InputLabel>
                    <em>{errors.formalContestAssessmentControlNumber}</em>
                    <TextField
                      fullWidth
                      id="formalContestAssessmentControlNumber"
                      name="formalContestAssessmentControlNumber"
                      size="small"
                      variant="outlined"
                      onChange={onValueChange}
                      type="text"
                      value={
                        conferenceTracking?.formalContestAssessmentControlNumber ||
                        ""
                      }
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div
                    className={
                      errors.formalContestProposedPenalty ? "error " : ""
                    }
                  >
                    <InputLabel
                      shrink
                      htmlFor="formalContestProposedPenalty"
                      style={{ display: "inline-block" }}
                    >
                      Calculated Penalty as Issued
                    </InputLabel>
                    <em>{errors.formalContestProposedPenalty}</em>
                    <TextField
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      fullWidth
                      id="formalContestProposedPenalty"
                      name="formalContestProposedPenalty"
                      size="small"
                      variant="outlined"
                      // onChange={onValueChange}
                      type="number"
                      value={
                        citationMetaData?.penalty //conferenceTracking?.formalContestProposedPenalty || ""
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div className="flex-column-oriented full-width mr-16">
                      {/* <label htmlFor="Mine Selector">Mine Selector</label> */}
                      <InputLabel shrink id="">
                        Date Conference Requested
                      </InputLabel>
                      <Select
                        style={{ height: 40 }}
                        id="inlineFormCustomSelect"
                        variant="outlined"
                        value={boolOrNullToString(
                          conferenceTracking.formalContest1007Requested
                        )}
                        onChange={(e) => {
                          setConferenceTracking({
                            ...conferenceTracking,
                            formalContest1007Requested: stringToBoolOrNull(
                              e.target.value as string
                            ),
                          });
                        }}
                        displayEmpty
                      >
                        <MenuItem value={"yes"}>{"Yes"}</MenuItem>
                        <MenuItem value={"no"}>{"No"}</MenuItem>
                        <MenuItem value={"not_selected"}>
                          {<span>Select Result</span>}
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className={errors.formalContestDocket ? "error " : ""}>
                    <InputLabel
                      shrink
                      htmlFor="formalContestDocket"
                      style={{ display: "inline-block" }}
                    >
                      Docket Number
                    </InputLabel>
                    <em>{errors.formalContestDocket}</em>
                    <TextField
                      fullWidth
                      id="formalContestDocket"
                      name="formalContestDocket"
                      size="small"
                      variant="outlined"
                      onChange={onValueChange}
                      type="text"
                      value={conferenceTracking?.formalContestDocket || ""}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div className="flex-column-oriented full-width mr-16">
                      {/* <label htmlFor="Mine Selector">Mine Selector</label> */}
                      <InputLabel shrink id="">
                        100.7 Action
                      </InputLabel>
                      <Select
                        style={{ height: 40 }}
                        id="inlineFormCustomSelect"
                        variant="outlined"
                        value={conferenceTracking.formalContest1007Action}
                        onChange={(e: any) => {
                          const value: number = e.target.value;
                          setConferenceTracking({
                            ...conferenceTracking,
                            formalContest1007Action: value,
                          });
                        }}
                        displayEmpty
                      >
                        <MenuItem value={0}>{"Vacated"}</MenuItem>
                        <MenuItem value={1}>{"Modified"}</MenuItem>
                        <MenuItem value={2}>{"Withdrawn"}</MenuItem>
                        <MenuItem value={3}>{"Upheld"}</MenuItem>
                        <MenuItem value={4}>{<span>&nbsp;</span>}</MenuItem>
                      </Select>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className={errors.alj ? "error " : ""}>
                    <InputLabel
                      shrink
                      htmlFor="alj"
                      style={{ display: "inline-block" }}
                    >
                      ALJ
                    </InputLabel>
                    <em>{errors.alj}</em>
                    <TextField
                      fullWidth
                      id="alj"
                      name="alj"
                      size="small"
                      variant="outlined"
                      onChange={onValueChange}
                      type="text"
                      value={conferenceTracking?.alj || ""}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className={errors.formalContestNotes ? "error " : ""}>
                    <InputLabel
                      shrink
                      htmlFor="formalContestNotes"
                      style={{ display: "inline-block" }}
                    >
                      Formal Contest Notes
                    </InputLabel>
                    <em>{errors.formalContestNotes}</em>
                    <TextField
                      fullWidth
                      multiline
                      id="formalContestNotes"
                      name="formalContestNotes"
                      size="small"
                      variant="outlined"
                      onChange={onValueChange}
                      type="text"
                      value={conferenceTracking?.formalContestNotes || ""}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className={errors.none ? "error " : ""}></div>
                </Grid>

                <Grid item xs={12} lg={6}></Grid>
                <Grid item xs={12} lg={6}></Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {/* RESULT */}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AppBar position="static">
              <Tabs
                value={tab4}
                aria-label="wrapped label tabs example"
                classes={{
                  indicator: classes.indicator,
                }}
              >
                <Tab label="Result" wrapped />
              </Tabs>
            </AppBar>
            <Box p={3}>
              <Grid container spacing={1} justify="center">
                <Grid item xs={12} lg={6}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div className="flex-column-oriented full-width mr-16">
                      {/* <label htmlFor="Mine Selector">Mine Selector</label> */}
                      <InputLabel shrink id="">
                        Final Settlement
                      </InputLabel>
                      <Select
                        style={{ height: 40 }}
                        id="inlineFormCustomSelect"
                        variant="outlined"
                        value={boolOrNullToString(
                          conferenceTracking.resultFinalSettlement
                        )}
                        onChange={(e) => {
                          setConferenceTracking({
                            ...conferenceTracking,
                            resultFinalSettlement: stringToBoolOrNull(
                              e.target.value as string
                            ),
                          });
                        }}
                        displayEmpty
                      >
                        <MenuItem value={"yes"}>{"Yes"}</MenuItem>
                        <MenuItem value={"no"}>{"No"}</MenuItem>
                        <MenuItem value={"not_selected"}>
                          {<span>Select Result</span>}
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className={errors.resultNewPenalty ? "error " : ""}>
                    <InputLabel
                      shrink
                      htmlFor="resultNewPenalty"
                      style={{ display: "inline-block" }}
                    >
                      New Penalty
                    </InputLabel>
                    <em>{errors.resultNewPenalty}</em>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      fullWidth
                      id="resultNewPenalty"
                      name="resultNewPenalty"
                      size="small"
                      variant="outlined"
                      onChange={onValueChange}
                      type="number"
                      value={conferenceTracking?.resultNewPenalty || ""}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className={errors.resultProposedPenalty ? "error " : ""}>
                    <InputLabel
                      shrink
                      htmlFor="resultProposedPenalty"
                      style={{ display: "inline-block" }}
                    >
                      Calculated Penalty as Issued
                    </InputLabel>
                    <em>{errors.resultProposedPenalty}</em>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      fullWidth
                      id="resultProposedPenalty"
                      name="resultProposedPenalty"
                      size="small"
                      variant="outlined"
                      onChange={onValueChange}
                      type="number"
                      value={conferenceTracking?.resultProposedPenalty || ""}
                      disabled
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className={errors.resultMoneySaved ? "error " : ""}>
                    <InputLabel
                      shrink
                      htmlFor="moneySaved"
                      style={{ display: "inline-block" }}
                    >
                      Money Saved
                    </InputLabel>
                    <em>{errors.resultMoneySaved}</em>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      fullWidth
                      id="resultMoneySaved"
                      name="resultMoneySaved"
                      size="small"
                      variant="outlined"
                      onChange={onValueChange}
                      type="number"
                      value={conferenceTracking?.resultMoneySaved || ""}
                      disabled
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className={errors.resultsNotes ? "error " : ""}>
                    <InputLabel
                      shrink
                      htmlFor="resultsNotes"
                      style={{ display: "inline-block" }}
                    >
                      Result Notes
                    </InputLabel>
                    <em>{errors.resultsNotes}</em>
                    <TextField
                      fullWidth
                      multiline
                      id="resultsNotes"
                      name="resultsNotes"
                      size="small"
                      variant="outlined"
                      onChange={onValueChange}
                      type="text"
                      value={conferenceTracking?.resultsNotes || ""}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className={errors.none ? "error " : ""}></div>
                </Grid>

                <Grid item xs={12} lg={6}></Grid>
                <Grid item xs={12} lg={6}></Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Button
          variant={"contained"}
          autoFocus
          onClick={submitForm}
          color="primary"
        >
          Save
        </Button>
      </>
    );
  };

export default CitationModificationTracking;
