import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { MAIN_TAB } from "../../interfaces/store/main";
import { setClientOptions } from "../../store/actions/clientActions";
import { states } from "../../constants/state.constants";
import MenuItem from "@material-ui/core/MenuItem";
import { Button, Grid, InputLabel, Select, TextField } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { getMineState } from "../../store/actions/analyticsActions";
import { IRootState } from "../../interfaces/store";
import LineGraph from "../analytics/LineGraph";
import { dateFormatted } from "../../utils/date-utils";
import Loading from "../shared/Loading";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IMine } from "../../interfaces/model/MSHAPCT/mine.interface";
import { getAllMinesGroupByCompany } from "../../store/actions/mineActions";
import {
  IMineStateResponse,
} from "../../interfaces/stored_procs/mine-state-response.interface";
import {
  ElevatedCitationsPer100,
  fnCalculateNumberElevatedCitationsPerSelection,
  fnCalculateNumberSSCitationsPerSelection,
  fnCalculatePorcentElevatedCitationsPerSelection,
  fnGetAveragePenaltyData,
  fnGetChartDataAccident,
  fnGetChartDataAccident2,
  GetAccidentTypeDistributionData,
  PercentSSCitations,
  SSCitationsPer100,
  TopRegulationsAllState,
  TopRegulationsMine,
} from "./MineStateGraphHelpers";
import BarGraph from "../analytics/BarGraph";
import { useSnackbar } from "notistack";
import { RegulationURLFromSectionOfCfr } from "../../utils/citation-utils";

const GRAPH_HEIGHT = 160;

interface IMineStateProps {}

interface IObjectKeys {
  [key: string]: string | number;
}

const AllStateRegsColumns = [
  // { title: "Regulation", field: "regulation", sorting: true },
  {
    title: "Regulation",
    field: "regulation",
    sorting: true,
    render: (rowData: any) => {
      const regUrl = RegulationURLFromSectionOfCfr(rowData.regulation);
      return (
        <a className="linklike" target="_blank" href={regUrl} rel="noreferrer">
          {rowData.regulation}
        </a>
      );
    },
  },
  { title: "Total", field: "total", sorting: true },
  { title: "S&S", field: "significant", sorting: true },
  { title: "Elevated", field: "elevated", sorting: true },
];

const MineState: React.FC<IMineStateProps> = (props) => {
  const dispatch = useDispatch();

  const twoYearsAgo = new Date();
  twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);

  const [selectedState, setSelectedState] = useState("CompanyMines");
  const [selectedStateLabel, setSelectedStateLabel] = useState("Company Mines");
  const [startDate, setStartDate] = useState(twoYearsAgo);
  const [endDate, setEndDate] = useState(new Date());
  const [mineId, setMineId] = useState(0);

  const [initialRunComplete, setInitialRunComplete] = useState(false);

  const [valuesSSCitationsPer100, setValuesSSCitationsPer100] = useState(
    null as any
  );
  const [valuesSSCitationsPer100YValue, setValuesSSCitationsPer100YValue] =
    useState(250);
	const [valuesSSCitationsPer100YValue2, setValuesSSCitationsPer100YValue2] =
    useState(250);

  const [porcentValuesSSCitations, setPorcentValuesSSCitations] = useState(
    null as any
  );
  const [porcentValuesSSCitationsYValue, setPorcentValuesSSCitationsYValue] =
    useState(null as any);
  const [valuesElevatedCitationsPer100, setValuesElevatedCitationsPer100] =
    useState(null as any);
  const [
    valuesElevatedCitationsPer100YValue,
    setValuesElevatedCitationsPer100YValue,
  ] = useState(10);
  const [
    valuesElevatedCitationsPer100YValue2,
    setValuesElevatedCitationsPer100YValue2,
  ] = useState(10);
  const [
    valuesPorcentElevatedCitationsPerSelection,
    setValuesPorcentElevatedCitationsPerSelection,
  ] = useState(null as any);
  const [
    valuesNumberElevatedCitationsPerSelection,
    setValuesNumberElevatedCitationsPerSelection,
  ] = useState(null as any);
  const [
    valuesPorcentElevatedCitationsPerSelectionYValue,
    setValuesPorcentElevatedCitationsPerSelectionYValue,
  ] = useState(25);
  const [
    valuesNumberElevatedCitationsPerSelectionYValue,
    setValuesNumberElevatedCitationsPerSelectionYValue,
  ] = useState(25);
  const [valuesPercentSSCitations, setValuesPercentSSCitations] = useState(
    null as any
  );
  const [valuesPercentSSCitationsYValue, setValuesPercentSSCitationsYValue] =
    useState(50);
	const [valuesPercentSSCitationsYValue2, setValuesPercentSSCitationsYValue2] =
    useState(50);
  const [valuesTopRegulationsMine, setValuesTopRegulationsMine] = useState(
    null as any
  );
  const [valuesTopRegulationsAllState, setValuesTopRegulationsAllState] =
    useState(null as any);

  const [fatalIncidentRateChart, setFatalIncidentRateChart] = useState(
    null as any
  );
  const [fatalIncidentRateChartYValue, setFatalIncidentRateChartYValue] =
    useState(null as any);

  const [nfdlIncidentRateChart, setNfdlIncidentRateChart] = useState(
    null as any
  );
  const [nfdlIncidentRateChartYValue, setNfdlIncidentRateChartYValue] =
    useState(null as any);

  const [averagePenaltyChart, setAveragePenaltyChartChart] = useState(
    null as any
  );
  const [averagePenaltyChartYValue, setAveragePenaltyChartYValue] = useState(
    null as any
  );

  const [accidentTypeDistributionChart, setAccidentTypeDistributionChart] =
    useState(null as any);
  const [
    accidentTypeDistributionChartYValue,
    setAccidentTypeDistributionChartYValue,
  ] = useState(null as any);

  const { enqueueSnackbar } = useSnackbar();
  const [mineName, setMineName] = useState("");

  const [mineStateUpdatedAt, setMineStateUpdatedAt] = useState(
    new Date().getTime()
  );
  // const [valuesTotalSignificantMine, setValuesTotalSignificantMine] = useState<ILineChartDataInterface | null>(null)
  // const [valuesTotalSignificantState, setValuesTotalSignificantState] = useState<ILineChartDataInterface | null>(null)
  // const [valuesMineSignificantRatio, setValuesMineSignificantRatio] = useState<ILineChartDataInterface | null>(null)
  // const [valuesTotalInspectionHoursMine, setValuesTotalInspectionHoursMine] = useState<ILineChartDataInterface | null>(null)
  // const [valuesMineAvgPenalty, setValuesMineAvgPenalty ] = useState<ILineChartDataInterface | null>(null)
  const [loading, setLoading] = useState(false);
  let { path, url } = useRouteMatch();

  // const violation_trends = useSelector(
  //     (state: IRootState) => state.main.state_statistics.violation_trends
  // );

  const mines = useSelector((state: IRootState) => state.main.mines);

  useEffect(() => {
    if (Object.keys(mines).length === 0) {
      setLoading(true);
      dispatch(getAllMinesGroupByCompany(() => setLoading(false)));
    }
    dispatch(setClientOptions({ selected_tab: MAIN_TAB.MINE_STATE }));
    window.scrollTo(0, 0);
  }, []);

  const handleChangeState = (val: any) => {
    setSelectedState(val);
    if (val == "All") setSelectedStateLabel("All States");
    else if (val == "CompanyMines") setSelectedStateLabel("Company Mines");
    else setSelectedStateLabel(states[val]);
  };

  const handleChangeMineId = (val: any) => {
    if (val) {
      const split = val.split(" - ");
      setMineId(split[1]);
    }
  };

  const handleChangeStart = (e: Date) => {
    setStartDate(e);
  };

  const handleChangeEnd = (e: Date) => {
    setEndDate(e);
  };

  const handleRunAnalytics = () => {
    console.log("START DATE", startDate);
    updateMineState();
  };

  const updateMineState = () => {
    if (mineId === 0) {
      enqueueSnackbar("Select a mine before running Mine State", {
        variant: "error",
      });
      return;
    }
    setLoading(true);
    dispatch(
      getMineState(
        {
          StartDate: dateFormatted(startDate, "yyyymmdd"),
          EndDate: dateFormatted(endDate, "yyyymmdd"),
          MineId: mineId, //0200150
          State: selectedState,
        },
        (results: IMineStateResponse) => {
          console.log("RESULTS!", results);
          setLoading(false);
          setInitialRunComplete(true);
          // process data
          const ssCitationPer100Data = SSCitationsPer100(results);
          setValuesSSCitationsPer100(ssCitationPer100Data.datasets);
          setValuesSSCitationsPer100YValue(ssCitationPer100Data.maxYValue1);
		  setValuesSSCitationsPer100YValue2(ssCitationPer100Data.maxYValue2)

          const numberCitationData =
            fnCalculateNumberSSCitationsPerSelection(results);
          setPorcentValuesSSCitations(numberCitationData.datasets);
          setPorcentValuesSSCitationsYValue(numberCitationData.maxYValue);

          const elevatedData = ElevatedCitationsPer100(results);
          setValuesElevatedCitationsPer100(elevatedData.datasets);
          setValuesElevatedCitationsPer100YValue(elevatedData.maxYValue1);
          setValuesElevatedCitationsPer100YValue2(elevatedData.maxYValue2);
          const porcentElevatedCitationData =
            fnCalculatePorcentElevatedCitationsPerSelection(results);
          setValuesPorcentElevatedCitationsPerSelection(
            porcentElevatedCitationData.datasets
          );
          setValuesPorcentElevatedCitationsPerSelectionYValue(
            porcentElevatedCitationData.maxYValue
          );

          const numberElevatedCitationData =
            fnCalculateNumberElevatedCitationsPerSelection(results);

          setValuesNumberElevatedCitationsPerSelection(
            numberElevatedCitationData.datasets
          );

          setValuesNumberElevatedCitationsPerSelectionYValue(
            numberElevatedCitationData.maxYValue
          );

          const fatalIncidentRateChartData = fnGetChartDataAccident(
            results,
            "mineFatalityRate",
            "Fatal Incident Rate"
          );

          setFatalIncidentRateChart(fatalIncidentRateChartData.datasets);
          setFatalIncidentRateChartYValue(fatalIncidentRateChartData.maxYValue);

          const nfdlIncidentRateChartData = fnGetChartDataAccident2(
            results,
            "mineNFDLRate",
            "stateNFDLRate",
            `${results.mineName} NFDL Rate`,
            `${selectedStateLabel} NFDL Rate`
          );

          setNfdlIncidentRateChart(nfdlIncidentRateChartData.datasets);
          setNfdlIncidentRateChartYValue(nfdlIncidentRateChartData.maxYValue);

          const averagePenaltyChartData = fnGetAveragePenaltyData(
            results,
            selectedStateLabel
          );

          setAveragePenaltyChartChart(averagePenaltyChartData.datasets);
          setAveragePenaltyChartYValue(averagePenaltyChartData.maxYValue);

          const accidentTypeData = GetAccidentTypeDistributionData(
            results,
            selectedStateLabel
          );

          setAccidentTypeDistributionChart(accidentTypeData);

          const percentSSCitationData = PercentSSCitations(results);
          setValuesPercentSSCitations(percentSSCitationData.datasets);
          setValuesPercentSSCitationsYValue(percentSSCitationData.maxYValue1);
          setValuesPercentSSCitationsYValue2(percentSSCitationData.maxYValue2);

          setValuesTopRegulationsMine(TopRegulationsMine(results));
          setValuesTopRegulationsAllState(TopRegulationsAllState(results));

          setMineName(results.mineName);
          setMineStateUpdatedAt(new Date().getTime());
        }
      )
    );
  };

  const graphGridWidth = 6; //allow 6 and 12

  return (
    <div>
      <Loading loading={loading} />
      <h3>Mine State</h3>

      <div style={{ display: "flex" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div className="flex-column-oriented full-width mr-16">
            {/* <label htmlFor="Mine Selector">Mine Selector</label> */}
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              State
            </InputLabel>
            <Select
              style={{ height: 40 }}
              id="inlineFormCustomSelect"
              variant="outlined"
              value={selectedState}
              onChange={(e) => {
                handleChangeState(e.target.value);
              }}
              displayEmpty
            >
              <MenuItem value="CompanyMines">Company Mines</MenuItem>
              <MenuItem value="All">All States</MenuItem>
              {Object.keys(states).map((key) => {
                return (
                  <MenuItem key="key" value={key}>
                    {states[key]}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </div>
        <div style={{ flex: 1, marginTop: 6 }}>
          <Autocomplete
            size="small"
            freeSolo
            options={Object.values(mines).map(
              (mine: IMine) => mine.mineName + " - " + mine.mineId
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Mine"
                margin="normal"
                variant="outlined"
              />
            )}
            onChange={(event, newValue) => {
              handleChangeMineId(newValue);
            }}
          />
          {/* <div className="flex-column-oriented full-width mr-16">
                        <label htmlFor="Mine Id">Mine Id</label>
                        <TextField
                            variant="outlined"
                            size="small"
                            value={mineId}
                            onChange={(event) => handleChangeMineId(event?.target.value)}
                        />
                    </div> */}
        </div>
        <div style={{ flex: 1 }}></div>
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, marginTop: 10 }}>
          <div
            style={{ maxWidth: 180, display: "inline-block", marginLeft: 16 }}
          >
            <KeyboardDatePicker
              label="From"
              size="small"
              // inputVariant="outlined"
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="none"
              value={startDate}
              onChange={(date: any) => {
                handleChangeStart(date as Date);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </div>

          <div
            style={{ maxWidth: 180, display: "inline-block", marginLeft: 16 }}
          >
            <KeyboardDatePicker
              label="To"
              size="small"
              // inputVariant="outlined"
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="none"
              value={endDate}
              onChange={(date: any) => {
                handleChangeEnd(date as Date);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </div>
        </div>
        <div style={{ flex: 2, marginTop: 16 }}>
          <Button
            onClick={() => handleRunAnalytics()}
            variant="outlined"
            size="small"
          >
            Run Analytics
          </Button>
        </div>
      </div>

      {initialRunComplete && (
        <>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  {valuesSSCitationsPer100 && (
                    <LineGraph
                      title="S&S Citations Per 100 Inspection Hours"
                      uuid="ssCitationsPer100Graph"
                      key={"chart1" + mineStateUpdatedAt}
                      chartData={valuesSSCitationsPer100}
					  scales={{
						"y": {
							type: 'linear',
							max: valuesSSCitationsPer100YValue,
							min: 0,
							ticks: {
								stepSize: 0.5,
								color: "#418cf0"
							},
							position: 'left' 
						},
						"y2": {
							type: 'linear',
							max: valuesSSCitationsPer100YValue2,
							min: 0,
							ticks: {
								stepSize: 0.5,
								color: "#DAA520"
							},
							position: 'right',
							color: "rgba(255,0,0,0.8)"
					  	}
					  }}
                      maxYValue={valuesSSCitationsPer100YValue}
                      minYValue={0}
                      height={GRAPH_HEIGHT}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {valuesPercentSSCitations && (
                    <LineGraph
                      title={`% SS Citations - ${selectedStateLabel}`}
                      uuid="percentSSCitations"
                      key={"chart2" + mineStateUpdatedAt}
                      chartData={valuesPercentSSCitations}
					  scales={{
						"y": {
							type: 'linear',
							max: valuesPercentSSCitationsYValue,
							min: 0,
							ticks: {
								stepSize: 0.5,
								color: "#418cf0"
							},
							position: 'left' 
						},
						"y2": {
							type: 'linear',
							max: valuesPercentSSCitationsYValue2,
							min: 0,
							ticks: {
								stepSize: 0.5,
								color: "#DAA520"
							},
							position: 'right',
							color: "rgba(255,0,0,0.8)"
					  	}
					  }}
                      maxYValue={valuesPercentSSCitationsYValue}
                      minYValue={0}
                      height={GRAPH_HEIGHT}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {porcentValuesSSCitations && (
                    <LineGraph
                      title={`# SS Citations - ${selectedStateLabel}`}
                      uuid="numberSSCitationsSelection"
                      key={"chartNumCitation1" + mineStateUpdatedAt}
                      chartData={porcentValuesSSCitations}
                      maxYValue={porcentValuesSSCitationsYValue}
                      minYValue={0}
                      height={GRAPH_HEIGHT}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  {valuesElevatedCitationsPer100 && (
                    <LineGraph
                      title="# Elevated Citations Per 100 Inspection Hours"
                      uuid="elevatedCitationsPer100Graph"
                      key={"chart3" + mineStateUpdatedAt}
                      chartData={valuesElevatedCitationsPer100}
					  scales={{
						"y": {
							type: 'linear',
							max: valuesElevatedCitationsPer100YValue,
							min: 0,
							ticks: {
								stepSize: 0.5,
								color: "#418cf0"
							},
							position: 'left' 
						},
						"y2": {
							type: 'linear',
							max: valuesElevatedCitationsPer100YValue2,
							min: 0,
							ticks: {
								stepSize: 0.5,
								color: "#DAA520"
							},
							position: 'right',
							color: "rgba(255,0,0,0.8)"
					  	}
					  }}
                      maxYValue={valuesElevatedCitationsPer100YValue}
                      minYValue={0}
                      height={GRAPH_HEIGHT}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {valuesPorcentElevatedCitationsPerSelection && (
                    <LineGraph
                      title={`% Elevated Citations - ${selectedStateLabel}`}
                      uuid="porcentelevatedperselection"
                      key={"chart4" + mineStateUpdatedAt}
                      chartData={valuesPorcentElevatedCitationsPerSelection}
                      maxYValue={
                        valuesPorcentElevatedCitationsPerSelectionYValue
                      }
                      minYValue={0}
                      height={GRAPH_HEIGHT}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {valuesNumberElevatedCitationsPerSelection && (
                    <LineGraph
                      title={`# Elevated Citations - ${selectedStateLabel}`}
                      uuid="numberelevatedCitationsperselection"
                      key={"chart5" + mineStateUpdatedAt}
                      chartData={valuesNumberElevatedCitationsPerSelection}
                      maxYValue={
                        valuesNumberElevatedCitationsPerSelectionYValue
                      }
                      minYValue={0}
                      height={GRAPH_HEIGHT}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  {valuesTopRegulationsMine && mineName && (
                    <BarGraph
                      title={`Top Regulations - ${mineName}`}
                      uuid="topRegulationsMine"
                      key={"topRegulationsMine" + mineStateUpdatedAt}
                      chartData={valuesTopRegulationsMine}
                      indexAxis="y"
                      height={GRAPH_HEIGHT}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {valuesTopRegulationsAllState && mineName && (
                    <BarGraph
                      title={`Top Regulations - ${selectedStateLabel}`}
                      uuid="topRegulationsAllState"
                      key={"topRegulationsMine" + mineStateUpdatedAt}
                      chartData={valuesTopRegulationsAllState}
                      indexAxis="y"
                      height={GRAPH_HEIGHT}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  {fatalIncidentRateChart && (
                    <LineGraph
                      title={`Fatal Incident Rate`}
                      uuid="fatalIncidentRate"
                      key={"chartFatalIncidentRate" + mineStateUpdatedAt}
                      chartData={fatalIncidentRateChart}
                      maxYValue={fatalIncidentRateChartYValue}
                      minYValue={0}
                      height={GRAPH_HEIGHT}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {nfdlIncidentRateChart && (
                    <LineGraph
                      title={`NFDL Incident Rate`}
                      uuid="NFDLIncidentRate"
                      key={"chartNFDLIncidentRate" + mineStateUpdatedAt}
                      chartData={nfdlIncidentRateChart}
                      maxYValue={nfdlIncidentRateChartYValue}
                      minYValue={0}
                      height={GRAPH_HEIGHT}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  {averagePenaltyChart && (
                    <LineGraph
                      title={`Average Penalty`}
                      uuid="averagePenaltyChart"
                      key={"chartAveragePenaltyChart" + mineStateUpdatedAt}
                      chartData={averagePenaltyChart}
                      maxYValue={averagePenaltyChartYValue}
                      minYValue={0}
                      height={GRAPH_HEIGHT}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  {accidentTypeDistributionChart && (
                    <BarGraph
                      title={`Accident Type Distribution`}
                      uuid="accidentTypeDistributionChart"
                      key={"accidentTypeDistributionChart" + mineStateUpdatedAt}
                      chartData={accidentTypeDistributionChart}
                      indexAxis="y"
                      height={GRAPH_HEIGHT}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <br />
        </>
      )}
    </div>
  );
};

export default MineState;
