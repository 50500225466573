/* eslint-disable no-param-reassign */
import { IReduceAction } from "../../interfaces/store";
import { IFlatFileState } from "../../interfaces/store/flatfile";
import { autoReduce } from "./shared";

export const flatFileObject = {
  inspections: {},
};

const initialState: IFlatFileState = { ...flatFileObject };

const flatfileReducer = (
  state: any = initialState,
  action: IReduceAction
): IFlatFileState => {
  if (action && action.stateKey === "flatfile") {
    if (action.normalized || action.removeKeys || action.overrides || action.setKeys) {
      return autoReduce(action, state);
    }

    switch (action.type) {
      default:
        return state;
    }
  }
  return state;
};

export default flatfileReducer;
