import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
} from "@material-ui/core";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom";
import {
  GRAVITY_CHANCE,
  GRAVITY_RESULT_EXPECTED,
  NEGLIGENCE,
  typeOfActionToString,
  TYPE_OF_ACTION,
} from "../../enums/citation-enums";
import { COLORS, MARGIN_STANDARD } from "../../enums/layout-enums";
import { ICitationMetaData } from "../../interfaces/model/MSHAPCT/citation-meta-data.interface";
import { ICitation } from "../../interfaces/model/MSHAPCT/citation.interface";
import { IRootState } from "../../interfaces/store";
import { RenderCitationPenalty } from "../../services/citation.service";
import { getVPIDRPIDHistory } from "../../store/actions/analyticsActions";
import {
  getCitationPenaltyCalculationAnalysis,
  getCitationWarnings,
} from "../../store/actions/citationActions";
import { RegulationURLFromSectionOfCfr } from "../../utils/citation-utils";
import { dateFormatted } from "../../utils/date-utils";
import Loading from "../shared/Loading";
import {
  DoubleChartForHistoricalPoints,
  ICitationAnalyticDataSets,
} from "./CitationAnalyticsHelpers";
import LineGraph from "./LineGraph";
// @ts-ignore
import { numberToCurrency } from '../../utils/general-utils'

interface ICitationAnalytics {}



const CitationAnalytics: React.FC<ICitationAnalytics> = (props) => {
  const dispatch = useDispatch();
  const { minepk, citationpk } = useParams() as any;
  const [citation, setCitation] = useState<ICitation | null>(null);
  const [citationMeta, setCitationMeta] = useState<ICitationMetaData | null>(
    null
  );
  const [fodaNegligence, setFodaNegligence] = useState(NEGLIGENCE.NONE);
  const [fodaInjury, setFodaInjury] = useState(GRAVITY_CHANCE.HIGHLY_LIKELY);
  const [fodaInjuryResult, setFodaInjuryResult] = useState(
    GRAVITY_RESULT_EXPECTED.NO_LOST_WORKDAYS
  );
  const [fodaPersonsAffected, setFodaPersonsAffected] = useState<number | null>(
    null
  );
  const [fodaTypeOfAction, setFodaTypeOfAction] = useState(-1);
  const [fodaRegulation, setFodaRegulation] = useState("");
  const [impactAnalysisResult, setImpactAnalysisResult] = useState<any | null>(
    null
  );
  const [futureVPIDSlider, setFutureVPIDSlider] = useState(0);
  const [futureRPIDSlider, setFutureRPIDSlider] = useState(0);
  const [becomeFinalVPID, setBecomeFinalVPID] = useState(30);
  const [becomeFinalRPID, setBecomeFinalRPID] = useState(30);
  const [loading, setLoading] = useState(false);
  const [historyGraphsLoading, setHistoryGraphsLoading] = useState(false);
  const [vPIDHistoryChart, setVPIDHistoryChart] = useState(null as any);
  const [rPIDHistoryChart, setRPIDHistoryChart] = useState(null as any);
  const [initialUpdate, setInitialUpdate] = useState(false);
  const [vPIDCitationsNotFinal, setVPIDCitationsNotFinal] = useState(0);
  const [
    numOfCitationsNotAssessedForCode,
    setNumOfCitationsNotAssessedForCode,
  ] = useState(0);
  const [vpidFuturePenalty, setVpidFuturePenalty] = useState("");
  const [rpidFuturePenalty, setRpidFuturePenalty] = useState("");
  const [citationWarnings, setCitationWarnings] = useState<any[]>([]);
  const [showCitationUpdateGrid, setShowCitationUpdateGrid] = useState(false);
  const history = useHistory();

  const citations = useSelector((state: IRootState) => state.main.citations);
  const citation_meta_data = useSelector(
    (state: IRootState) => state.main.citation_meta_data
  );
  const [
    totalPenaltyOfDisplayedCitations,
    setTotalPenaltyOfDisplayedCitations,
  ] = useState({} as any);

  const [
    lastTotalPenaltyOfDisplayedCitations,
    setLastTotalPenaltyOfDisplayedCitations,
  ] = useState({} as any);

  const [
    hypotheticalPenaltyOfDisplayedCitations,
    setHypotheticalTotalPenaltyOfDisplayedCitations,
  ] = useState({} as any);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const matchedCitation = Object.values(citations).find(
      (x) => x.pk === Number(citationpk)
    );
    const matchedAssociatedMeta = Object.values(citation_meta_data).find(
      (x) => x.citationFk === Number(citationpk)
    );
    if (matchedCitation) {

      setCitation(matchedCitation);
      setFodaRegulation(matchedCitation.sectionOfCfr || "");
      setFodaPersonsAffected(matchedCitation.personsAffected || 0);
      setFodaNegligence(matchedCitation.negligence || 1);
      setFodaTypeOfAction(matchedCitation.typeOfAction || -1);
      setFodaInjury(matchedCitation.gravityChance || 0)
	  setFodaInjuryResult(matchedCitation.gravityResultExpected || 0)

    }
    if (matchedAssociatedMeta) {
      //console.log("matchedAssociatedMeta", matchedAssociatedMeta);
      setCitationMeta(matchedAssociatedMeta);
      const totalsPenalty: any = {};
      totalsPenalty.penalty = get(matchedAssociatedMeta, "penalty", 0);
      totalsPenalty.penaltyGfe = get(matchedAssociatedMeta, "penalty", 0) * 0.9;
      setTotalPenaltyOfDisplayedCitations(totalsPenalty);
    }
  }, [citations, citation_meta_data]);

  useEffect(() => {
    if (!initialUpdate) {
      if (citation && fodaPersonsAffected) {
        updatePenalty();
        updateVPIDHistory();
        setInitialUpdate(true);
        getAllCitationWarnings(citation.pk);
      }
    }
  }, [citation, fodaPersonsAffected]);

  const getAllCitationWarnings = (pk: any) => {
    dispatch(getCitationWarnings(pk, citationWarningsSuccess));
  };

  const citationWarningsSuccess = (data: any) => {
    setCitationWarnings(data);
  };

  const updatePenalty = () => {
    const payload = {
      pk: citation?.pk,
      gravityChance: fodaInjury,
      gravityResultExpected: fodaInjuryResult,
      // initialAction: fodaTypeOfAction,
      mineFk: minepk,
      negligence: fodaNegligence,
      personsAffected: fodaPersonsAffected,
      sectionOfCfr: fodaRegulation,
      typeOfAction: fodaTypeOfAction,
      dateIssued: citation?.dateIssued,
      // typeOfInspection?: TYPE_OF_INSPECTION
      // typeOfIssuance?: TYPE_OF_ISSUANCE
    };

    dispatch(
      getCitationPenaltyCalculationAnalysis(payload, (result: any) => {
        if (!showCitationUpdateGrid) {
          setShowCitationUpdateGrid(true);
        } else {


			const totalsPenaltyHypothetical: any = {};
			totalsPenaltyHypothetical.penalty = get(result.citationPenaltyCalculations, "totalPenaltyAmount", 0);
			totalsPenaltyHypothetical.penaltyGfe =
			  get(result.citationPenaltyCalculations, "totalPenaltyAmount", 0) * 0.9;
		setHypotheticalTotalPenaltyOfDisplayedCitations(totalsPenaltyHypothetical);


          const totalsPenalty: any = {};
          totalsPenalty.penalty = get(result.citationMetaData, "penalty", 0);
          totalsPenalty.penaltyGfe =
            get(result.citationMetaData, "penalty", 0) * 0.9;
          setLastTotalPenaltyOfDisplayedCitations(totalsPenalty);
        }

        let total = 0;
        total += result.citationPenaltyCalculations.negligencePoints;
        total +=
          result.citationPenaltyCalculations.gravityPoints.gravityChancePoints;
        total +=
          result.citationPenaltyCalculations.gravityPoints
            .gravityExpectedResultsPoints;
        total +=
          result.citationPenaltyCalculations.gravityPoints
            .gravityPersonsAffectedPoints;
        total += get(
          result.citationPenaltyCalculations,
          "citationViolationHistory.historyPoints",
          0
        );
        total += get(
          result.citationPenaltyCalculations,
          "citationViolationHistory.repeatViolationPoints",
          0
        );
        total += result.citationPenaltyCalculations.minePoints;
        total += result.citationPenaltyCalculations.controllerPoints;
        result.citationPenaltyCalculations.total = total;

        setImpactAnalysisResult(result.citationPenaltyCalculations);
      })
    );
  };

  const disputeCalcGFEDifference = () => {
    return Math.round(
      (lastTotalPenaltyOfDisplayedCitations.penaltyGfe -
        totalPenaltyOfDisplayedCitations.penaltyGfe) *
      100
    ) / 100
  }

  const disputeCalcGFEDirection = () => {
    if (disputeCalcGFEDifference() > 0) {
      return "higher"
    }
    else {
      return "lower"
    }
  }

  const updateVPIDHistory = () => {
    setHistoryGraphsLoading(true);
    dispatch(
      getVPIDRPIDHistory(
        {
          citationPk: citation?.pk,
          minePk: minepk,
          sectionCSRF: fodaRegulation,
          futureVPIDSlider,
          futureRPIDSlider,
          becomeFinalVPID,
          becomeFinalRPID,
        },
        (data: any) => {
          if (futureVPIDSlider > 0) {
            setVpidFuturePenalty(
              `Assuming that ${futureVPIDSlider} citations become final orders in ${becomeFinalVPID} days, a similar citation issued in ${
                becomeFinalVPID + 1
              } days would have a penalty of: $${
                data.futureVPIDCitationPenaltyCalculations.totalPenaltyAmount
              }`
            );
          } else {
            setVpidFuturePenalty("");
          }
          if (futureRPIDSlider > 0) {
            setRpidFuturePenalty(
              `Assuming that ${futureRPIDSlider} citations become final orders in ${becomeFinalRPID} days, a similar citation issued in ${
                becomeFinalRPID + 1
              } days would have a penalty of: $${
                data.futureRPIDCitationPenaltyCalculations.totalPenaltyAmount
              }`
            );
          } else {
            setRpidFuturePenalty("");
          }

          setHistoryGraphsLoading(false);

          const analitycDataVpId: ICitationAnalyticDataSets = {
            currentData: data.vpidPointResponses,
            currentDataSerieName: `Historical and Future VPID`,
            futureData: data.futureVpidPoints,
            futureDataSerieName: data.futureVpidSerieName,
          };
          var vpIdPreparedData =
            DoubleChartForHistoricalPoints(analitycDataVpId);
          setVPIDHistoryChart(vpIdPreparedData);

          const analitycDataRpId: ICitationAnalyticDataSets = {
            currentData: data.rpidPointResponses,
            currentDataSerieName: `VPID History`,
            futureData: data.futureRpidPoints,
            futureDataSerieName: data.futureRpidSerieName,
          };
          var rpIdPreparedData =
            DoubleChartForHistoricalPoints(analitycDataRpId);
          setRPIDHistoryChart(rpIdPreparedData);

          setVPIDCitationsNotFinal(data.citationsNotFinalOrders);
          setNumOfCitationsNotAssessedForCode(data.citationsUnassessedForCode);
        }
      )
    );
  };

  return (
    <div>
      <Loading loading={loading} />
      <h3>Citation Analytics</h3>

      {citation && citationMeta && (
        <>
          <Paper className={"custom-pc-table-criteria"}>
            <div
              style={{
                textAlign: "center",
                padding: 16,
                backgroundColor: COLORS.GREY_LIGHT,
              }}
              className="custom-pc-table-bottom-border"
            >
              <b>Citation Overview</b>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Date Issued</th>
                  <th>Citation/Order No.</th>
                  <th>Type of Action</th>
                  <th>Regulation</th>
                  <th>Penalty Calcuation</th>
                  <th>Penalty Calcuation GFE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{dateFormatted(citation.dateIssued, "mmddyyyy")}</td>
                  <td>
                    <span
                      className="darkbluelinklike"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        history.push(
                          `/Mines/${minepk}/Citations/${citation?.pk}/View`
                        );
                      }}
                    >
                      {citation?.citationNumber}
                    </span>
                  </td>
                  <td>{typeOfActionToString(citation.typeOfAction!)}</td>
                  <td>
                    <a
                      className={"darkbluelink"}
                      target="_blank"
                      href={RegulationURLFromSectionOfCfr(
                        citation?.sectionOfCfr
                      )}
                      rel="noreferrer"
                    >
                      {citation?.sectionOfCfr}
                    </a>
                  </td>
                  <td>
                    {RenderCitationPenalty(
                      citationMeta.specialAssessment!,
                      get(citationMeta, "penalty", 0),
                      citation.typeOfAction!,
                      false,
                      false
                    )}
                  </td>
                  <td>
                    {RenderCitationPenalty(
                      citationMeta.specialAssessment!,
                      get(citationMeta, "penalty", 0) * 0.9,
                      citation.typeOfAction!,
                      true,
                      false
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Paper>

          {citationWarnings.length > 0 && (
            <Paper
              style={{ marginTop: MARGIN_STANDARD.SECTION }}
              className={"custom-pc-table-criteria"}
            >
              <div
                style={{
                  textAlign: "center",
                  padding: 16,
                  backgroundColor: COLORS.GREY_LIGHT,
                }}
                className="custom-pc-table-bottom-border"
              >
                <b>Citation Warnings</b>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Warning</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {citationWarnings.map((warning, index) => (
                      <tr key={index}>
                        <td>{warning.header}</td>
                        <td>{warning.detail}</td>
                      </tr>
                  ))}
                </tbody>
              </table>
            </Paper>
          )}

          <Paper
            style={{ marginTop: MARGIN_STANDARD.SECTION }}
            className={"custom-pc-table-criteria"}
          >
            <div
              style={{
                textAlign: "center",
                padding: 16,
                backgroundColor: COLORS.GREY_LIGHT,
              }}
              className="custom-pc-table-bottom-border"
            >
              <b>Final Order Dispute Impact Analysis</b>
            </div>
            <Grid container wrap="nowrap">
              <Grid
                container
                spacing={2}
                style={{
                  padding: "2rem",
                }}
              >
                <Grid direction="column" item sm={3} xs={12}>
                  <InputLabel
                    style={{ marginBottom: 8 }}
                    htmlFor="fodaNegligence"
                  >
                    Negligence
                  </InputLabel>
                  <Select
                    id="fodaNegligence"
                    variant="outlined"
                    style={{ height: 40, width: "100%" }}
                    value={fodaNegligence}
                    onChange={(event) =>
                      setFodaNegligence(event.target.value as number)
                    }
                    displayEmpty
                  >
                    <MenuItem value={NEGLIGENCE.NONE}>None</MenuItem>
                    <MenuItem value={NEGLIGENCE.LOW}>Low</MenuItem>
                    <MenuItem value={NEGLIGENCE.MODERATE}>Moderate</MenuItem>
                    <MenuItem value={NEGLIGENCE.HIGH}>High</MenuItem>
                    <MenuItem value={NEGLIGENCE.RECKLESS_DISREGARD}>
                      Reckless Disregard
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid direction="column" item sm={3} xs={12}>
                  <InputLabel
                    style={{ marginBottom: 8 }}
                    htmlFor="fodaChanceOfInjury"
                  >
                    Chance of Injury Or Illness
                  </InputLabel>
                  <Select
                    id="fodaInjury"
                    variant="outlined"
                    style={{ height: 40, width: "100%" }}
                    value={fodaInjury}
                    onChange={(event) =>
                      setFodaInjury(event.target.value as number)
                    }
                    displayEmpty
                  >
                    <MenuItem value={GRAVITY_CHANCE.NO_LIKELIHOOD}>
                      No Likelihood
                    </MenuItem>
                    <MenuItem value={GRAVITY_CHANCE.UNLIKELY}>
                      Unlikely
                    </MenuItem>
                    <MenuItem value={GRAVITY_CHANCE.REASONABLY_LIKELY}>
                      Reasonably Likely
                    </MenuItem>
                    <MenuItem value={GRAVITY_CHANCE.HIGHLY_LIKELY}>
                      Highly Likely
                    </MenuItem>
                    <MenuItem value={GRAVITY_CHANCE.OCCURED}>Occurred</MenuItem>
                  </Select>
                </Grid>
                <Grid direction="column" item sm={3} xs={12}>
                  <InputLabel
                    style={{ marginBottom: 8 }}
                    htmlFor="fodaInjuryResult"
                  >
                    Result of Injury or Illness
                  </InputLabel>
                  <Select
                    id="fodaInjuryResult"
                    variant="outlined"
                    style={{ height: 40, width: "100%" }}
                    value={fodaInjuryResult}
                    onChange={(event) =>
                      setFodaInjuryResult(event.target.value as number)
                    }
                    displayEmpty
                  >
                    <MenuItem value={GRAVITY_RESULT_EXPECTED.NO_LOST_WORKDAYS}>
                      No Lost Workdays
                    </MenuItem>
                    <MenuItem
                      value={
                        GRAVITY_RESULT_EXPECTED.LOST_WORKDAYS_OR_RESTRICTED_DUTY
                      }
                    >
                      Lost Workdays or Restricted Duty
                    </MenuItem>
                    <MenuItem
                      value={GRAVITY_RESULT_EXPECTED.PERMANENTLY_DISABLING}
                    >
                      Permanently Disabling
                    </MenuItem>
                    <MenuItem value={GRAVITY_RESULT_EXPECTED.FATAL}>
                      Fatal
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid direction="column" item sm={3} xs={12}>
                  <InputLabel
                    style={{ marginBottom: 8 }}
                    htmlFor="fodaPersonsAffected"
                  >
                    Persons Affected
                  </InputLabel>
                  <TextField
                    id="fodaPersonsAffected"
                    size="small"
                    type="number"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={fodaPersonsAffected}
                    onChange={(event) =>
                      setFodaPersonsAffected(Number(event.target.value))
                    }
                  />
                </Grid>
                <Grid direction="column" item sm={3} xs={12}>
                  <InputLabel
                    style={{ marginBottom: 8 }}
                    htmlFor="fodaTypeOfAction"
                  >
                    Type of Action
                  </InputLabel>
                  <Select
                    id="fodaTypeOfAction"
                    variant="outlined"
                    style={{ height: 40, width: "100%" }}
                    value={fodaTypeOfAction}
                    onChange={(event) =>
                      setFodaTypeOfAction(event.target.value as number)
                    }
                    displayEmpty
                  >
                    <MenuItem value={-1} disabled>
                      Select
                    </MenuItem>
                    <MenuItem value={TYPE_OF_ACTION._103_J}>103(j)</MenuItem>
                    <MenuItem value={TYPE_OF_ACTION._103_K}>103(k)</MenuItem>
                    <MenuItem value={TYPE_OF_ACTION._104_A}>104(a)</MenuItem>
                    <MenuItem value={TYPE_OF_ACTION._104_B}>104(b)</MenuItem>
                    <MenuItem value={TYPE_OF_ACTION._104_D_1}>
                      104(d)(1)
                    </MenuItem>
                    <MenuItem value={TYPE_OF_ACTION._104_D_2}>
                      104(d)(2)
                    </MenuItem>
                    <MenuItem value={TYPE_OF_ACTION._104_E}>104(e)</MenuItem>
                    <MenuItem value={TYPE_OF_ACTION._104_F}>104(f)</MenuItem>
                    <MenuItem value={TYPE_OF_ACTION._104_G}>104(g)</MenuItem>
                    <MenuItem value={TYPE_OF_ACTION._107_A}>107(a)</MenuItem>
                    <MenuItem value={TYPE_OF_ACTION._110_A}>110(a)</MenuItem>
                    <MenuItem value={TYPE_OF_ACTION._314_B}>314(b)</MenuItem>
                  </Select>
                </Grid>
                <Grid direction="column" item sm={3} xs={12}>
                  <InputLabel
                    style={{ marginBottom: 8 }}
                    htmlFor="fodaRegulation"
                  >
                    Regulation
                  </InputLabel>
                  <TextField
                    id="fodaRegulation"
                    size="small"
                    variant="outlined"
                    value={fodaRegulation}
                    style={{ width: "100%" }}
                    onChange={(event) =>
                      setFodaRegulation(event.target.value as string)
                    }
                  />
                </Grid>
                <Grid direction="column" item sm={3} xs={12}>
                  <InputLabel
                    style={{ marginBottom: 8 }}
                    htmlFor="updateButton"
                  >
                    Update Penalty Calculations
                  </InputLabel>
                  <Button
                    style={{ height: 40, width: "100%" }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => updatePenalty()}
                  >
                    Update
                  </Button>
                </Grid>
                {lastTotalPenaltyOfDisplayedCitations?.penalty && (
                  <>
                  {Math.abs(disputeCalcGFEDifference()) > 12.5 && <Grid direction="column" item sm={12} xs={12}>
                    <div style={{color: COLORS.RED}}>
                    The penalty proposed by MSHA and the calculated penalty differ by more than $12.5. The penalty proposed by MSHA is {numberToCurrency(disputeCalcGFEDifference())} {disputeCalcGFEDirection()} lower than the predicted penalty with a good faith discount.
                    </div>
                  </Grid>}

                  <Grid direction="column" item sm={12} xs={12}>
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Original Penalty</th>
                          <th>New Penalty</th>
                          <th>Difference</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Standard</td>
                          <td>
                            {numberToCurrency(
                              Math.round(
                                totalPenaltyOfDisplayedCitations.penalty * 100
                              ) / 100
                            )}
                          </td>
                          <td>
                            {numberToCurrency(
                              Math.round(
                                hypotheticalPenaltyOfDisplayedCitations.penalty *
                                  100
                              ) / 100
                            )}
                          </td>
                          <td
                            style={{
                              color: `${
                                hypotheticalPenaltyOfDisplayedCitations.penalty -
                                  totalPenaltyOfDisplayedCitations.penalty <
                                0
                                  ? "green"
                                  : "red"
                              }`,
                            }}
                          >
                            {numberToCurrency(
                              Math.round(
                                (hypotheticalPenaltyOfDisplayedCitations.penalty -
                                  totalPenaltyOfDisplayedCitations.penalty) *
                                  100
                              ) / 100
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Good Faith</td>
                          <td>
                            {numberToCurrency(
                              Math.round(
                                totalPenaltyOfDisplayedCitations.penaltyGfe *
                                  100
                              ) / 100
                            )}
                          </td>
                          <td>
                            {numberToCurrency(
                              Math.round(
                                hypotheticalPenaltyOfDisplayedCitations.penaltyGfe *
                                  100
                              ) / 100
                            )}
                          </td>
                          <td
                            style={{
                              color: `${
                                hypotheticalPenaltyOfDisplayedCitations.penaltyGfe -
                                  totalPenaltyOfDisplayedCitations.penaltyGfe <
                                0
                                  ? "green"
                                  : "red"
                              }`,
                            }}
                          >
                            {numberToCurrency(
                              Math.round(
                                (hypotheticalPenaltyOfDisplayedCitations.penaltyGfe -
                                  totalPenaltyOfDisplayedCitations.penaltyGfe) *
                                  100
                              ) / 100
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>

          {impactAnalysisResult && (
            <Paper
              style={{ marginTop: MARGIN_STANDARD.SECTION }}
              className={"custom-pc-table-criteria"}
            >
              <div
                style={{
                  textAlign: "center",
                  padding: 16,
                  backgroundColor: COLORS.GREY_LIGHT,
                }}
              >
                <b>Citation Point Summary</b>
              </div>
              <Paper className={"custom-pc-table-criteria"}>
                <table>
                  <thead>
                    <tr>
                      <th colSpan={3}></th>
                      <th colSpan={9}>Gravity</th>
                      <th colSpan={12}></th>
                    </tr>
                    <tr>
                      <th colSpan={3}>Negligence</th>
                      <th colSpan={3}>Chance of Injury / Illness</th>
                      <th colSpan={3}>Result of Injury / Illness</th>
                      <th colSpan={3}>Persons Affected</th>
                      <th colSpan={2}>VPID</th>
                      <th colSpan={2}>RPID</th>
                      <th colSpan={2}>Mine</th>
                      <th colSpan={3}>Controller</th>
                      <th colSpan={3}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={3}>
                        {impactAnalysisResult.negligencePoints}
                      </td>
                      <td colSpan={3}>
                        {impactAnalysisResult.gravityPoints.gravityChancePoints}
                      </td>
                      <td colSpan={3}>
                        {
                          impactAnalysisResult.gravityPoints
                            .gravityExpectedResultsPoints
                        }
                      </td>
                      <td colSpan={3}>
                        {
                          impactAnalysisResult.gravityPoints
                            .gravityPersonsAffectedPoints
                        }
                      </td>
                      <td colSpan={2}>
                        {get(
                          impactAnalysisResult,
                          "citationViolationHistory.historyPoints",
                          0
                        )}
                      </td>
                      <td colSpan={2}>
                        {get(
                          impactAnalysisResult,
                          "citationViolationHistory.repeatViolationPoints",
                          0
                        )}
                      </td>
                      <td colSpan={2}>{impactAnalysisResult.minePoints}</td>
                      <td colSpan={3}>
                        {impactAnalysisResult.controllerPoints}
                      </td>
                      <td colSpan={3}>{impactAnalysisResult.total}</td>
                    </tr>
                  </tbody>
                </table>
              </Paper>
            </Paper>
          )}

          <Paper style={{ marginTop: MARGIN_STANDARD.SECTION }}>
            <Grid>
              <Grid>
                <Grid direction="column" item sm={12} xs={12}>
                  <Paper className={"custom-pc-table-criteria"}>
                    <table>
                      <thead>
                        <tr style={{ backgroundColor: COLORS.GREY_LIGHT }}>
                          <th colSpan={24}>Future VPID Analysis</th>
                        </tr>
                        {/* <tr>
                                                    <th colSpan={8}></th>
                                                    <th colSpan={8}>Gravity</th>
                                                    <th colSpan={8}></th>
                                                </tr>
                                                <tr>
                                                    <th colSpan={3}>Negligence</th>
                                                    <th colSpan={3}>Chance of Injury / Illness</th>
                                                    <th colSpan={3}>Result of Injury / Illness</th>
                                                    <th colSpan={3}>Persons Affected</th>
                                                    <th colSpan={2}>VPID</th>
                                                    <th colSpan={2}>RPID</th>
                                                    <th colSpan={2}>Mine</th>
                                                    <th colSpan={3}>Controller</th>
                                                    <th colSpan={3}>Total</th>
                                                </tr> */}
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={24}>
                            <div style={{ display: "flex" }}>
                              <div style={{ flex: 1, marginRight: 16 }}>
                                Number of Citations that have not become final
                                orders: {vPIDCitationsNotFinal}
                              </div>
                              <div style={{ flex: 1 }}>
                                <Slider
                                  value={futureVPIDSlider}
                                  onChange={(event, newValue) =>
                                    setFutureVPIDSlider(Number(newValue))
                                  }
                                  // defaultValue={50}
                                  //getAriaValueText={valuetext}
                                  aria-labelledby="discrete-slider"
                                  valueLabelDisplay="auto"
                                  step={1}
                                  //marks
                                  min={0}
                                  max={100}
                                />
                              </div>
                              <div style={{ flex: 1, marginLeft: 16 }}>
                                Note: All future calculations assume inspection
                                days equivalent to today's.
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: "flex" }}>
                              <div style={{ flex: 1, alignSelf: "center" }}>
                                Become Final Orders In
                              </div>
                              <div style={{ flex: 1 }}>
                                <RadioGroup
                                  row
                                  value={becomeFinalVPID}
                                  onChange={(event) => {
                                    const x = Number(event.target.value);
                                    setBecomeFinalVPID(x);
                                  }}
                                >
                                  <FormControlLabel
                                    classes={{ label: "tipography-font-size" }}
                                    value={30}
                                    control={<Radio />}
                                    label="30 Days"
                                  />
                                  <FormControlLabel
                                    classes={{ label: "tipography-font-size" }}
                                    value={60}
                                    control={<Radio />}
                                    label="60 Days"
                                  />
                                  <FormControlLabel
                                    classes={{ label: "tipography-font-size" }}
                                    value={90}
                                    control={<Radio />}
                                    label="90 Days"
                                  />
                                  <FormControlLabel
                                    classes={{ label: "tipography-font-size" }}
                                    value={120}
                                    control={<Radio />}
                                    label="120 Days"
                                  />
                                </RadioGroup>
                              </div>
                              <div style={{ flex: 1 }}>
                                <Button
                                  variant={"outlined"}
                                  autoFocus
								  disabled={historyGraphsLoading}
                                  onClick={() => {
                                    updateVPIDHistory();
                                  }}
                                  color="primary"
                                >
                                  Update
                                </Button>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div style={{ display: "flex" }}>
                              <div style={{ flex: 2, alignSelf: "center" }}>
                                {vPIDHistoryChart && !historyGraphsLoading ? (
                                  <LineGraph
                                    height={150}
                                    title="Future VPID Analysis"
                                    uuid="futureVPIDAnalysis"
                                    key={vPIDHistoryChart?.updatedAt}
                                    chartData={vPIDHistoryChart}
                                  />
                                ) : (
									<CircularProgress color="inherit" />
                                //   <span>Update to Load Data...</span>
                                )}
                              </div>
                              <div style={{ flex: 1, alignSelf: "center" }}>
                                If a similar citation were issued today, the
                                penalty would be: $
                                {impactAnalysisResult?.totalPenaltyAmount}
                                <br />
                                <br />
                                {vpidFuturePenalty.length > 0 &&
                                  vpidFuturePenalty}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Paper>
                  {/* {JSON.stringify(impactAnalysisResult)} */}
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Paper style={{ marginTop: MARGIN_STANDARD.SECTION }}>
            <Grid>
              <Grid>
                <Grid direction="column" item sm={12} xs={12}>
                  <Paper className={"custom-pc-table-criteria"}>
                    <table>
                      <thead>
                        <tr style={{ backgroundColor: COLORS.GREY_LIGHT }}>
                          <th colSpan={24}>Future RPID Analysis</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={24}>
                            <div style={{ display: "flex" }}>
                              <div style={{ flex: 1, marginRight: 16 }}>
                                Number of citations of this regulation that have
                                not become final orders:{" "}
                                {numOfCitationsNotAssessedForCode}
                              </div>
                              <div style={{ flex: 1 }}>
                                <Slider
                                  value={futureRPIDSlider}
                                  onChange={(event, newValue) =>
                                    setFutureRPIDSlider(Number(newValue))
                                  }
                                  aria-labelledby="discrete-slider"
                                  valueLabelDisplay="auto"
                                  step={1}
                                  //marks
                                  min={0}
                                  max={30}
                                />
                              </div>
                              <div style={{ flex: 1, marginLeft: 16 }}>
                                Note: All future calculations assume inspection
                                days equivalent to today's.
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ display: "flex" }}>
                              <div style={{ flex: 1, alignSelf: "center" }}>
                                Assess in
                              </div>
                              <div style={{ flex: 1 }}>
                                <RadioGroup
                                  row
                                  value={becomeFinalRPID}
                                  onChange={(event) => {
                                    const x = Number(event.target.value);
                                    setBecomeFinalRPID(x);
                                  }}
                                >
                                  <FormControlLabel
                                    classes={{ label: "tipography-font-size" }}
                                    value={30}
                                    control={<Radio />}
                                    label="30 Days"
                                  />
                                  <FormControlLabel
                                    classes={{ label: "tipography-font-size" }}
                                    value={60}
                                    control={<Radio />}
                                    label="60 Days"
                                  />
                                  <FormControlLabel
                                    classes={{ label: "tipography-font-size" }}
                                    value={90}
                                    control={<Radio />}
                                    label="90 Days"
                                  />
                                  <FormControlLabel
                                    classes={{ label: "tipography-font-size" }}
                                    value={120}
                                    control={<Radio />}
                                    label="120 Days"
                                  />
                                </RadioGroup>
                              </div>
                              <div style={{ flex: 1 }}>
                                <Button
                                  variant={"outlined"}
                                  autoFocus
								  disabled={historyGraphsLoading}
                                  onClick={() => {
                                    updateVPIDHistory();
                                  }}
                                  color="primary"
                                >
                                  Update
                                </Button>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div style={{ display: "flex" }}>
                              <div style={{ flex: 2, alignSelf: "center" }}>
                                {rPIDHistoryChart && !historyGraphsLoading ?  (
                                  <LineGraph
                                    height={150}
                                    title="Future RPID Analysis"
                                    uuid="futureRPIDAnalysis"
                                    key={rPIDHistoryChart?.updatedAt}
                                    chartData={rPIDHistoryChart}
                                  />
                                ) : (
									<CircularProgress color="inherit" />
                                )}
                              </div>
                              <div style={{ flex: 1, alignSelf: "center" }}>
                                If a similar citation were issued today, the
                                penalty would be: $
                                {impactAnalysisResult?.totalPenaltyAmount}
                                <br />
                                <br />
                                {rpidFuturePenalty.length > 0 &&
                                  rpidFuturePenalty}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Paper>
                  {/* {JSON.stringify(impactAnalysisResult)} */}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </div>
  );
};

export default CitationAnalytics;
