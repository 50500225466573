import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Loading from '../shared/Loading';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { uploadCitations } from '../../store/actions/citationActions';
import { useSnackbar } from 'notistack';
interface IUploadCitationModalProps {
    open: boolean
    onClose: Function
	mineName: string
}

const UploadCitationModal: React.FC<IUploadCitationModalProps> = (props) => {

    // const [companyName, setCompanyName] = useState('')
    // const [isPubliclyTraded, setIsPubliclyTraded] = useState(false)
    // const [isMineStateAllowed, setIsMineStateAllowed] = useState(false)
    // const [contractorCompany, setContractorCompany] = useState(false)
    // const [alwaysOverrideMSHAImport, setAlwaysOverrideMSHAImport] = useState(false)
    // const [enableEmailNotification, setEnableEmailNotification] = useState(false)
    // const dispatch = useDispatch();
    // const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [processsing, setProcesssing] = useState(false);
    const [fileCollection, setFileCollection] = useState<any>(null)
    const { minepk } = useParams() as any;
    const dispatch = useDispatch();
    const history = useHistory();
    const onFileChange = (e: any) => {
        setFileCollection({ imgCollection: e.target.files })
    }
    const resetForm = () => {
        setFileCollection(null);
    }

    const onSubmit = (e: any) => {

        e.preventDefault()
        setProcesssing(true);
        props.onClose()
        const collection = fileCollection.imgCollection
        var formData = new FormData();
        for (const key of Object.keys(collection)) {
            formData.append('files', collection[key])
        }
        dispatch(uploadCitations(formData, ()=> {
            enqueueSnackbar('Scan Complete', { variant: 'success' });
            history.push(`/Mines/${minepk}/CitationScanResults`)
        }, () => {
            enqueueSnackbar('Something went wrong.', { variant: 'error' });
        }))
        
    }


    return (
        <>
            <Loading loading={processsing} />
            
            <Dialog onClose={() => props.onClose()} aria-labelledby="customized-dialog-title" open={props.open}>
            <form onSubmit={onSubmit}>
                <MuiDialogTitle>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 3, marginTop: 10 }}>
                            <Typography variant="h6">Upload Citations for {props.mineName}</Typography>
                        </div>
                        <div style={{ flex: 1, textAlign: 'right' }}>
                            <IconButton aria-label="close" onClick={() => props.onClose()}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ol style={{fontSize: 16}}>
                                <li>Click choose files and select one to many citation documents (PDF).</li>
                                <li>Click upload to submit for processing.</li>
                            </ol>
                            <div className="form-group">
                        <input type="file" name="imgCollection" onChange={onFileChange} multiple />
                    </div>

                        </Grid>
                        <Grid item xs={12}>
                            {/* <UploadCitations 
                                onStartProcessing={() => {
                                    setProcesssing(true)
                                    props.onClose(true)
                                }}
                                onComplete={() => setProcesssing(false)}
                            /> */}
                        </Grid>
                    </Grid>
                    {/* onClick={() => submitForm()} */}
                </MuiDialogTitle>
                <DialogActions>
                    <Button variant={'outlined'} type={'submit'} autoFocus  color="primary">
                        Submit
                    </Button>
                </DialogActions>
                </form>
            </Dialog>
          
        </>
    )
}

export default UploadCitationModal