import { Input, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FilterListRounded } from '@material-ui/icons';
import { IGenericFilters } from '../shared/generic-filter.interface';
interface IGenericEqualityComparisonFilterProps {
	superHandleFilterChange: Function
  filterKey: string
	filters: IGenericFilters
}

const MenuProps = {
	PaperProps: {
	  style: {
		maxHeight: '75vh', //ITEM_HEIGHT * 12.5 + ITEM_PADDING_TOP,
		width: 250,
	  },
	},
  };

const options = [
{
  title: 'Clear Filters',
  key: 'clear_filters'
},
{
  title: '<',
  key: 'less_than'
},
{
  title: '=',
  key: 'equal'
},
{
  title: '>',
  key: 'more_than'
}
]

const GenericEqualityComparisonFilter: React.FC<IGenericEqualityComparisonFilterProps> = (props) => {

  let iconColor = "disabled"
  if (props.filters[props.filterKey].selectedOptions.length){
    iconColor = "primary"
  }

  let inputTimer: any = null;
  
  useEffect(() => {
    return () => clearTimeout(inputTimer);
  }, []);

  useEffect(() => {
    if (props.filters[props.filterKey].inputValue){
      setLocalInput(props.filters[props.filterKey].inputValue!)
    }
  }, [props.filters[props.filterKey].inputValue]);
  
  let [localInput, setLocalInput] = useState('')

  const getComparisonString = (data: any) => {
    
    if (data && data[0]){
      switch(data[0]){
      case 'less_than':
        return '<'
      case 'more_than':
        return '>'
      case 'equal':
        return '='
      default:
        return ''
      }
    }
    // switch(str){

    // }
  }

  if (!props.filters[props.filterKey].selectedOptions.length || props.filters[props.filterKey].selectedOptions[0] === 'clear_filters'){
    return <div onClick={() => props.superHandleFilterChange(props.filterKey, 'more_than')}>
      <FilterListRounded color={iconColor as any} />
    </div>
  }
  // return(
  //   <div>
  //   {JSON.stringify(props.filters[props.filterKey].selectedOptions)}
  //   </div>
  // )

	return (
		<div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <div style={{ display: 'inline-block' }}>
            {/* <FilterListRounded color={iconColor as any} /> */}
            <Select
              labelId="citation-filter-label"
              id="citation-filter-select"
              multiple={false}
              value={props.filters[props.filterKey].selectedOptions}
              onChange={(e: any) => {
                  let numVal = null;
                  if (e.target.value === 'clear_filters'){
                    numVal = 0;
                  }
                  props.superHandleFilterChange(props.filterKey, e.target.value, numVal)
           
                
              }}
              input={<Input />}
              renderValue={(selected: any) => {
                return <span>{getComparisonString(props.filters[props.filterKey].selectedOptions)}</span>
              }}
              MenuProps={MenuProps}
            >
                {/* <MenuItem key={-1} value={'clear_filters'} style={{}}>
                  Clear Filters
                </MenuItem> */}
              {options.map((filter, iindex: number) => (
                <MenuItem key={iindex} value={filter.key} style={{}}>
                  {filter.title}
                </MenuItem>
              ))}
            </Select>
            <TextField value={localInput} onChange={(e) => {
                setLocalInput(e.target.value)
                clearTimeout(inputTimer);
                inputTimer = setTimeout(() => {
                  props.superHandleFilterChange(props.filterKey, null, e.target.value)
                }, 2000);
              }} type={'number'} />
          </div>
        </div>
		</div>
	);
}

export default GenericEqualityComparisonFilter