import React from "react";
import { Container } from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";
interface ILegalNoticeProps {}

const LegalNotice: React.FC<ILegalNoticeProps> = () => {
  return (
    <Container
      style={{ minHeight: "100vh", backgroundColor: COLORS.WHITE_PAPER }}
    >
      <h1>Legal Notice</h1>
      <section>
        <b>TERMS OF USE</b>
        <br></br>
        <br></br>
        Predictive Compliance LLC (PCL) Web Site General Terms of Use<br></br>
        Predictive Compliance LLC (PCL) owns and operates this Site. The Site
        includes material, items, logos, trademarks, features, text, software,
        photos, videos, graphics, music, sound, data and information
        (“Material”) in its content that are the subject of trademark,
        copyright, patent or other proprietary protection. Further, certain
        Materials in this Site are the property of third parties, who have
        authorized PCL to distribute and use these Materials within this Site.
        All right, title and/or interest in and to such Materials, including all
        associated proprietary rights, are held by and shall remain with PCL.
        You acknowledge that you do not acquire any ownership rights in, nor do
        you acquire any license to any rights, either expressed or implied, to
        the Materials by accessing and using this Site. PCL specifically
        reserves all rights not expressly authorized under these Terms of Use,
        and you are only authorized to use this Site and the Materials as
        outlined herein. PCL has the right, at any time and without notice, to
        change or discontinue this Site or any aspect or feature of this Site,
        including without limitation, the content, hours of availability, and
        the equipment needed for access or use of this Site, and any service or
        product offered at this Site.<br></br>
        <br></br>
        Restrictions on Use (As Applicable)<br></br>
        You shall use the PCL Site for lawful purposes only. You shall not post
        or transmit on this Site any material which violates or infringes in any
        way upon the rights of others, which is unlawful, threatening, abusive,
        defamatory, invasive of privacy or publicity rights, vulgar, obscene,
        profane or otherwise objectionable, which encourages conduct that would
        constitute a criminal offense, gives rise to civil liability or
        otherwise violate any law. Without PCL’s express prior approval, you
        shall not post or transmit through this Site any material which contains
        advertising or any solicitation with respect to products or services.
        You shall not use this Site to advertise or perform any commercial
        solicitation, including without limitation, the solicitation of users to
        become subscribers of other on-line information services or products
        competitive with this Site. Any conduct by you that in PCL’s discretion
        restricts or inhibits any other user from using or enjoying this Site
        will not be permitted. Further, you may not eliminate, remove, alter or
        modify any advertising placed on this Site.<br></br>
        <br></br>
        Subject to the copyright and trademark use authorizations below, since
        this Site contains Materials that are the subject of copyright,
        trademark and other proprietary protection, you may not modify, publish,
        transmit, display, participate in the transfer or sale, create
        derivative works, or in any way exploit, any of the Materials, in whole
        or in part, or this Site, in whole or in part, external to you. You may
        download the Materials for your internal use only on a single computer
        or you may network the Materials only on your internal intranet, which
        is owned, leased or solely controlled by you. Except as otherwise
        expressly permitted under statutory proprietary law or under these Terms
        of Use, no copying, redistribution, retransmission, publication or
        commercial exploitation of downloaded Materials will be permitted
        without the express written permission of PCL and the third party owner.
        In the event of any permitted copying, redistribution or publication of
        the Materials, no changes in or deletion of author attribution,
        trademark legend or copyright notice shall be made, and the integrity of
        this Site shall be preserved.<br></br>
        <br></br>
        You shall not upload, post or otherwise make available on this Site any
        item protected by copyright, trademark or other proprietary right
        without the express permission of the owner of the copyright, trademark
        or other proprietary right and the burden of determining that any item
        is not protected by such proprietary rights rests with you. You shall be
        solely liable for any damage resulting from any infringement of
        copyrights, trademarks or other proprietary rights, or any other harm
        resulting from such availability. By providing items to any public area
        of this Site, you automatically grant, or warrant that the owner of such
        item has expressly granted to you, the royalty-free, worldwide,
        perpetual, irrevocable, non-exclusive right and license to use, in any
        manner, reproduce, modify, adapt, publish, display, translate and
        distribute such material (in whole or in part) and/or to incorporate it
        in other works in any form, media or technology now know or hereafter
        developed. You also permit any other user of this Site to access, view,
        store or reproduce the item for that user’s personal use on a single
        computer. You grant PCL the right to edit, copy display, publish and
        distribute any item made available on this Site by you.<br></br>
        <br></br>
        The foregoing provisions of this Section are for the benefit of PCL, its
        subsidiaries, affiliates and its third-party content providers and
        licensors and each shall have the right to assert and enforce such
        provisions, directly, against you or on its own behalf. You acknowledge
        that PCL, its parent, its subsidiaries, affiliates and third-party
        content providers and licensors assume no responsibility or liability
        for your actions or activities that are inconsistent with the restricted
        uses recited herein.<br></br>
        <br></br>
        Disclaimer of Warranty/Limitation of Liability<br></br>
        A. YOU EXPRESSLY AGREE THAT USE OF THIS SITE IS AT YOUR SOLE RISK. THIS
        SITE, AND THE CONTENTS THEREIN, ARE NOT GUARANTEED TO BE UNINTERRUPTED
        OR ERROR FREE; NOR IS ANY WARRANTY PROVIDED AS TO THE RESULTS THAT MAY
        BE OBTAINED FROM USE OF THIS SITE, AND THE CONTENTS THEREIN, OR AS TO
        THE ACCURACY OR RELIABILITY OF ANY INFORMATION, SERVICE OR MERCHANDISE
        PROVIDED THROUGH THIS SITE OR THAT THIS SITE IS FREE FROM ANY CLAIMS OF
        INFRINGEMENT. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY PCL,
        IT’S AGENTS OR EMPLOYEES SHALL CREATE ANY WARRANTY.<br></br>
        B. THIS SITE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND
        WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
        INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR NON-INFRINGEMENT
        OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
        PURPOSE, OTHER THAN THOSE WARRANTIES WHICH ARE IMPLIED BY AND INCAPABLE
        OF EXCLUSION, RESTRICTION OR MODIFICATION UNDER APPLICABLE LAW.
        ADDITIONALLY, THERE ARE NO WARRANTIES AS TO THE RESULTS OBTAINED FROM
        THE USE OF THIS SITE AND THE CONTENTS HEREIN. THE INFORMATION OR
        CONTENTS OF THIS SITE SHOULD BE USED BY YOU ONLY AS AN INFORMATIONAL
        SOURCE AND SHOULD NOT SERVE AS THE SOLE FACTOR IN OR BASIS FOR ANY
        DECISION, INFORMATION OR CONTENTS. THE INFORMATION OR CONTENTS OF THIS
        SITE ARE INTENDED TO SOLELY PROVIDE GENERAL GUIDANCE ON MATTERS OF
        INTEREST FOR YOUR USE AND YOU ACCEPT FULL RESPONSIBILITY FOR ITS USE.
        WHILE EVERY EFFORT IS MADE TO ENSURE THE CORRECTNESS AND COMPLETENESS OF
        THE INFORMATION OR CONTENTS CONTAINED HEREIN, YOU ACKNOWLEDGE THAT PCL
        DOES NOT PROMISE OR GUARANTEE THAT THE INFORMATION OR CONTENT CONTAINED
        HEREIN IS CORRECT, COMPLETE, OR ACCURATE.<br></br>
        C. IN NO EVENT WILL PCL OR ANY PERSON OR ENTITY INVOLVED IN CREATING,
        PRODUCING OR DISTRIBUTING THIS SITE, OR THE CONTENT INCLUDED THEREIN, BE
        LIABLE IN CONTRACT, IN TORT (INCLUDING FOR ITS OWN NEGLIGENCE) OR UNDER
        ANY OTHER LEGAL THEORY (INCLUDING STRICT LIABILITY) FOR ANY DAMAGES,
        INCLUDING WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
        PUNITIVE, CONSEQUENTIAL OR SIMILAR DAMAGES, INCLUDING WITHOUT
        LIMITATION, LOST PROFITS OR REVENUES, LOSS OF USE OR SIMILAR ECONOMIC
        LOSS, ARISING OUT OF THE USE OF OR INABILITY TO USE THIS SITE. YOU
        HEREBY ACKNOWLEDGE THAT THE PROVISIONS OF THIS SECTION SHALL APPLY TO
        ALL ASPECTS REGARDING THE USE OF THE CONTENTS OF THIS SITE. APPLICABLE
        LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL
        OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT
        APPLY TO YOU.<br></br>
        <br></br>
        Term/Obligations Upon Termination<br></br>
        Your right to use any of this Site may be subject to immediate
        termination by PCL if your use of this Site is inconsistent with the
        Terms of Use recited herein or if you misappropriate, misuse or infringe
        any of the Materials and/or the associated proprietary interests or
        rights of PCL or any third party. You are free to discontinue your use
        of this Site at any time. All Material is and shall remain the sole and
        exclusive property of PCL. Your right to use such Material is only
        authorized for the purposes stated herein, and you may store the
        Material during your continued and uninterrupted use of this Site;
        however, you may not store for future use such Material beyond your
        termination of access and use of this Site, nor may you develop a
        library of such Material during your use of this Site or after your
        termination of use of this Site.<br></br>
        <br></br>
        Site Applicable Related Terms<br></br>
        These Terms of Use incorporate by reference the following terms and/or
        statements:<br></br>
        Privacy Practice Statement<br></br>
        Governing Laws Terms<br></br>
        Trademark Use Terms<br></br>
        Copyright Use Terms<br></br>
        Permission to Link Terms<br></br>
        Third-Party Link Terms<br></br>
        Contact Information<br></br>
        <br></br>
        TRADEMARK USE TERMS<br></br>
        Predictive Compliance LLC ( PCL ) Trademark Use Authorization<br></br>
        Predictive Compliance LLC ( PCL ) trademarks, servicemarks, logos and/or
        tradenames (collectively, marks) identify PCL products and services, and
        let the public know the source of those products and services. You may
        make fair use of our trademarks in advertising and promotional
        materials, and in referring to our products and services (for example,
        in a magazine article) without our permission, provided you follow
        standard trademark usage practices and provide proper attribution. In
        the event you wish to use the marks for linking, please refer to PCL’s
        Permission to Link Terms.<br></br>
        <br></br>
        You may not use PCL marks, whether design or word marks, in the
        following ways:<br></br>
        <ul>
          <li>in a non-PCL product or service name or publication title; or</li>
          <li>
            in, as, or as part of your own trademarks, servicemarks, logos or
            tradenames; or
          </li>
          <li>to identify non- PCL products or services; or</li>
          <li>in a manner likely to cause confusion; or</li>
          <li>
            in a manner that implies inaccurately that PCL sponsors, endorses,
            or are otherwise is connected with your own activities, products,
            and services; or
          </li>
          <li>
            in a manner that distorts, changes or modifies the marks so as to
            affect its registered U.S. and international trademark status or in
            a manner detrimental to the enforcement of U.S. and international
            trademark protections; or
          </li>
          <li>
            in a manner that disparages or adversely impacts PCL or any of its
            parent, subsidiary, affiliate, associate or related business
            entities, or any of its directors, officers, employees or agents
            thereof, or its Site, products or services.
          </li>
        </ul>
        <br></br>
        Your use of any marks constitutes your acceptance of the terms of this
        authorization. PCL disclaims any warranties or guarantees, whether
        express or implied, including warranties against infringement with
        regard to the marks and their use, and the marks are provided “AS IS.”
        Further, PCL reserves the right, for any reason and without notice, at
        any time, to terminate this authorization or modify the terms recited
        herein. Any other uses require written permission from PCL. Please make
        such requests by contacting PCL as specified in this Site. PCL will
        evaluate your request as soon as possible.<br></br>
        In the event any other trademarks appear on this Site that are not PCL
        marks, you have no authorization to use, in any manner, or link nor does
        PCL grant you any rights, explicitly or implied, to use any or to link
        to such trademarks.<br></br>
        Additionally, PCL has rights to certain registered domain names in which
        it has a proprietary interest. The domain names are subject to the same
        restrictions as recited above and in the linking terms. PCL claims
        rights in “www.predictivecompliance.com.com.” &
        “www.predictivecompliancellc.com”<br></br>
        Proper Attribution of PCL Marks<br></br>
        <br></br>
        When you use PCL marks, please include an appropriate notice attributing
        the marks to PCL.<br></br>
        <ul>
          <li>
            Predictive Compliance™ is a trademark of Predictive Compliance LLC
          </li>
          <li>PCL Logo™ is a trademark of Predictive Compliance LLC.</li>
        </ul>
        <br></br>
        <br></br>
        COPYRIGHT USE TERMS<br></br>
        Predictive Compliance LLC ( PCL ) – Copyright Use Authorization<br></br>
        Predictive Compliance LLC’s (PCL’s) Site contains copyrighted material
        that includes, but is not limited to, screen shots, documents, data,
        graphical displays, pictures, digital images, text and computer code.
        These materials are owned by PCL or its suppliers, and your download,
        access or use of these materials from this Site does not transfer any
        rights, title or interest, whether expressed or implied, in the
        materials or the copyrights held in the materials, except as authorized
        herein, to you. PCL disclaims any warranties or guarantees, whether
        express or implied, including warranties against infringement with
        regard to the materials and their use, and the materials are provided
        “AS IS.” Further PCL reserves the right for any reason and without
        notice, at any time, to terminate this authorization or modify the terms
        recited herein. Personal Use of Web Site Copyrighted Material<br></br>
        PCL hereby authorizes you to view, copy, download, and print PCL
        materials that are available on this Site, subject to the following
        conditions:<br></br>
        <ul>
          <li>
            The materials may be used solely for your personal or intranet
            requirements for informational purposes, and for no other purposes,
            including commercial exploitation or distribution, either
            independently or in combination with or integrated in other
            products.
          </li>
          <li>
            The materials may not be modified, altered, changed, translated or
            edited nor may you create derivative works thereof, except where
            otherwise stated.
          </li>
          <li>
            The following copyright notice and permission notice must appear in
            each document: "Copyright © 2009 Predictive Compliance LLC ( PCL ).
            All rights reserved. Protected by the copyright laws of the United
            States and International Treaties and Authorized for Use by PCL."
          </li>
          <li>
            Copyright or any other proprietary notices may not be removed,
            deleted or altered in any manner.
          </li>
        </ul>
        <br></br>
        You may not reproduce, copy, or redistribute the design or layout of
        this PCL Site, individual elements of any of this Site design, or PCL
        logos, servicemarks or trademarks without the express written permission
        of PCL, or where otherwise stated.<br></br>
        Commercial Use of Web Site Copyrighted Material<br></br>
        Reproduction, copying, or redistribution for commercial purposes
        (external to your intranet) of any Materials on this Site is strictly
        prohibited without the express written permission of PCL.<br></br>
        <br></br>
        GOVERNING LAWS TERMS<br></br>
        Predictive Compliance LLC ( PCL)– Applicable Laws<br></br>
        This Site (excluding linked sites) is controlled by Predictive
        Compliance LLC ( PCL) from its offices within the state of Colorado,
        United States of America. It can be accessed from all 50 states, as well
        as from other countries around the world. As each of these places has
        laws that may differ from those of Colorado, by accessing this Site both
        you and PCL agree that the statutes and laws of the state of Colorado,
        without regard to the conflicts of laws principles thereof, will apply
        to all matters relating to use of this Site.<br></br>
        You and PCL also agree and hereby submit to the exclusive personal
        jurisdiction and venue of the Superior Court of Denver, Colorado and the
        United States District Court for Colorado with respect to such matters.
        PCL makes no representation that materials on this Site are appropriate
        or available for use in other locations, and accessing them from
        territories where their contents are illegal is prohibited. Those who
        choose to access this Site from other locations do so on their own
        initiative and are responsible for compliance with local laws. You shall
        adhere to U.S. Export Administration Laws and Regulations, and to
        International Internet Laws, Rules and Regulations.<br></br>
        <br></br>
        PERMISSION TO LINK TERMS<br></br>
        Predictive Compliance LLC (PCL) – Terms for Linking<br></br>
        Predictive Compliance LLC (PCL) welcomes a link from your web page to
        PCL‘s Site. The following describes how you may link and the process for
        achieving such interaction between this Site and your link. Except as
        PCL may authorize elsewhere, non-PCL sites may link to this Site only by
        using PCL’s logo, tradename, trademark or URL -
        “www.predictivecompliance.com” (collectively, Link Elements). By using
        these Link Elements, you agree to be bound by the following Terms.
        <br></br>
        <ol>
          <li>
            PCL grants you a non-exclusive, non-transferable right and license
            to display the Link Elements on your site only for the purposes of
            establishing a link to this Site. You may display one or more Link
            Elements on your site provided that when a user clicks on your
            choice of a Link Element, the user always links to this Site, except
            where otherwise authorized. Further, you are not permitted to change
            or modify the Link Elements in a manner that is detrimental to the
            enforcement of U.S. and international trademark and copyright
            interests and title. Further, you may not eliminate, remove, alter
            or modify any advertising placed on this Site or any affiliated PCL
            Sites.
          </li>
          <li>
            The link elements are not to be considered or to be construed,
            either explicit or implied, as any endorsement, agency or affiliate
            relationship, strategic alliance or any other legal or business
            relationship and are only provided for the convenience of the user
            at your site.
          </li>
          <li>
            You may not use the link elements for or in the encouragement of
            unlawful or illegal purposes, or use or display the link elements in
            a disparaging, derogatory, detrimental or adverse manner, whether or
            not related to PCL or PCL’s Sites.
          </li>
          <li>
            As a result of your use of these Linking Elements, you are not
            granted any other license or right in the trademark or copyrights
            held and owned by PCL in these Linking Elements and any related
            materials. Further, no license, either expressed or implied, is
            granted to any other intellectual property, including those
            properties associated with this Site, and any or all of the
            contents, therein.
          </li>
          <li>
            PCL, at any time without notice, reserves the right, for any reason
            and in its sole discretion, to terminate or modify immediately,
            PCL's Terms for Linking. Upon termination, you shall promptly remove
            the link elements from your site. PCL disclaims any warranties or
            guarantees, whether express or implied, including warranties against
            infringement, with regard to the link elements and their use, and
            the contents of this Site. The content of this Site is provided “AS
            IS”.
          </li>
          <li>
            PCL reserves the right to pursue any action against any use that
            does not conform to these terms or infringes any PCL intellectual
            property right or any or other interest, or violates any other
            applicable laws related to, arising from or associated with the
            link, Linking Elements, PCL or this Site.
          </li>
        </ol>
        <br></br>
        <br></br>
        THIRD PARTY LINK TERMS<br></br>
        Predictive Compliance LLC ( PCL ) – Links to Third-Party Sites<br></br>
        In the event there are any links provided in this Site to other
        third-party sites, which let you leave this Site, you acknowledge by
        your use of such links that PCL provides such links for your convenience
        only and makes no representations whatsoever about such third-party
        sites. These non-PCL linked sites are independent from PCL and PCL is
        not responsible for the contents of any linked site or any link
        contained in a linked site, or the operation, performance reliability of
        such site or the contents therein or any changes or updates to such
        sites. The inclusion of any such link does not imply endorsement, either
        expressly or implied, by PCL of the linked site. The linking to and use
        of such site is completely your responsibility and any liabilities or
        risks associated with such linking or use of the Site, and the contents
        therein, shall be assumed totally and completely by you. PCL assumes no
        liability or risk for any damages, either direct or consequential, or
        claims as a result of such linking and use of this Site and you will
        hold PCL completely harmless with respect to any claim made or action
        commenced as a result of such linking to or use or the site and any
        contents therein.
      </section>
    </Container>
  );
};

export default LegalNotice;
