import { all, call, put } from "redux-saga/effects";
import { ACTIONS } from "../../enums/actions";
import { HttpResponse } from "../../interfaces/api/HttpResponse";
import { IDispatchAction } from "../../interfaces/store";
import HttpClient from "../../lib/HttpClient";
import GetFriendlyError from "../../utils/general";
import { normalize, schema } from "normalizr";
const client = new HttpClient();

const businessunit_schema = new schema.Entity(
  "business_units",
  {},
  { idAttribute: "pk" }
);

async function getAllBusinessUnitAPI(
  itemId: Number
): Promise<HttpResponse<{ x: string }>> {
  return client.get(`api/companies/${itemId}/business-units`);
}

async function getBusinessUnitAPI(
  itemId: Number
): Promise<HttpResponse<{ x: string }>> {
  return client.get(`api/business-units/${itemId}`);
}

async function createBusinessUnitAPI(data: any): Promise<HttpResponse<any>> {
  return client.post(`api/business-units`, data);
}

async function updateBusinessUnitAPI(data: any): Promise<HttpResponse<any>> {
  return client.put("api/business-units", data);
}

async function deleteBusinessUnitAPI(
  itemId: Number
): Promise<HttpResponse<any>> {
  return client.delete(`api/business-units/${itemId}`);
}

const businessUnitSaga = {
  *deleteBusinessUnit(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        deleteBusinessUnitAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        const isAccountDeleted: boolean = response.data;
        if (isAccountDeleted) {
          let removeKeys = [];
          removeKeys.push({
            entities: "business_units",
            ids: Array.of(payload),
          });
          yield put({
            type: "DELETE_BUSINESS_UNIT_SUCCESS",
            removeKeys: removeKeys,
          });

          if (action.onSuccess) {
            action.onSuccess(response.data);
          }
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getBusinessUnit(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getBusinessUnitAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *createBusinessUnit(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        createBusinessUnitAPI,
        payload.data
      )) as HttpResponse<any>;

      if (response.status === 200 || response.status === 201) {
        yield all([
          put({
            type: "CREATE_BUSINESS_UNIT_SUCCESS",
            normalized: normalize(response.data, [businessunit_schema]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess(response.data.pk);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *updateBusinessUnit(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        updateBusinessUnitAPI,
        payload
      )) as HttpResponse<any>;

      if (response.status === 200 || response.status === 201) {
        yield all([
          put({
            type: "UPDATE_BUSINESS_UNIT_SUCCESS",
            normalized: normalize(response.data, [businessunit_schema]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess(response.data.pk);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getAllBusinessUnit(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getAllBusinessUnitAPI,
        payload
      )) as HttpResponse<any>;

      if (response.status === 200 || response.status === 201) {
        yield all([
          put({
            type: "GET_ALL_BUSINESS_UNIT_SUCCESS",
            normalized: normalize(response.data, [businessunit_schema]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess(response.data.pk);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
};

export default businessUnitSaga;
