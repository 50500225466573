import React from "react";
import { Container } from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";
interface ISupportProps {}

const Support: React.FC<ISupportProps> = () => {
  return (
    <Container
      style={{ minHeight: "100vh", backgroundColor: COLORS.WHITE_PAPER }}
    >
      <h1>Support</h1>
      <section>
        <article>
        <p>Technical Support</p>
          <p>Call: 434-841-1858</p>
          <p>Email: <a href="mailto:fieldsupport@iwtwireless.com?subject=Contact Us Inquiry">
          fieldsupport@iwtwireless.com
          </a></p>
          <p>1100 Main St.</p>
          <p>Lynchburg, VA 24504</p>
          <a href="http://www.predictivecompliance.com">
            www.predictivecompliance.com
          </a>
        </article>
      </section>
    </Container>
  );
};

export default Support;
