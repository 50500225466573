import React from "react";
import { Container } from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";
interface IPredictiveComplianceProps {}

const PredictiveCompliance: React.FC<IPredictiveComplianceProps> = () => {
  return (
    <Container
      style={{ minHeight: "100vh", backgroundColor: COLORS.WHITE_PAPER }}
    >
      <h1>Predictive Compliance</h1>
      <section>
        <article>
          Created by renowned MSHA compliance attorney Mark Savit, based on over
          30 years of dealing with compliance issues in the mining industry,
          Predictive Compliance represents a completely new approach to MSHA
          compliance management. Our software is designed to be as user friendly
          as possible. All you need to do is upload citations as you get them
          and our proprietary software calculates the predicted penalty, shows
          you the impact that this citation (and future citations) would have on
          compliance costs, allows you to plan how best to contest the citation,
          and provides a platform for citation management. It also will allow
          you to predict the impact of your future citations on costs, resources
          and operations. Predictive Compliance will allow you to dramatically
          reduce your costs of compliance and allocate your resources for safer,
          more efficient operations.
        </article>
      </section>
    </Container>
  );
};

export default PredictiveCompliance;
