import { orderBy } from "lodash";
import {
  IAccidentTypeInfo,
  IMineStateResponse,
  IPeriodStatistic,
  IRegulationInfo,
} from "../../interfaces/stored_procs/mine-state-response.interface";

export const SSCitationsPer100 = (results: IMineStateResponse) => {
  const pstatLabels = results.periodStatistics.map((x) => x.yearMonth);
  const dataPoint1 = results.periodStatistics.map((ps: IPeriodStatistic) => {
    return ps.sigMineCitationsPer100InspHrs;
  });
  const dataPoint2 = results.periodStatistics.map((ps: IPeriodStatistic) => {
    return ps.sigStateCitationsPer100InspHrs;
  });
  const datasets = {
    labels: pstatLabels,
    datasets: [
      {
        label: `S&S ${results.mineName} Citations Per 100 Inspection Hours`,
        data: dataPoint1,
        backgroundColor: ["#418cf0"],
        borderColor: "#418cf0",
        borderWidth: 1,
		yAxisID: 'y',
      },
      {
        label: "S&S State Citations Per 100 Inspection Hours",
        data: dataPoint2,
        backgroundColor: ["#DAA520"],
        borderColor: "#DAA520",
        borderWidth: 1,
		yAxisID: 'y2',
      },
    ],
    updatedAt: new Date().toISOString(),
  };

  const maxYValue1 = Math.max(...dataPoint1) + 1;
  const maxYValue2 = Math.max(...dataPoint2) + 1;

  const maxYValue = maxYValue1 > maxYValue2 ? maxYValue1 : maxYValue2;

  return {
    maxYValue,
	maxYValue1,
	maxYValue2,
    datasets,
  };
};

export const ElevatedCitationsPer100 = (results: IMineStateResponse) => {
  const pstatLabels = results.periodStatistics.map((x) => x.yearMonth);
  const dataPoint1 = results.periodStatistics.map((ps: IPeriodStatistic) => {
    return ps.elevatedMineCitationsPer100InspHrs;
  });
  const dataPoint2 = results.periodStatistics.map((ps: IPeriodStatistic) => {
    return ps.elevatedStateCitationsPer100InspHrs;
  });

  const datasets = {
    labels: pstatLabels,
    datasets: [
      {
        label: `Elevated ${results.mineName} Citations Per 100 Inspection Hours`,
        data: dataPoint1,
        backgroundColor: ["#418cf0"],
        borderColor: "#418cf0",
        borderWidth: 1,
		yAxisID: 'y',
      },
      {
        label: "Elevated State Citations Per 100 Inspection Hours",
        data: dataPoint2,
        backgroundColor: ["#DAA520"],
        borderColor: "#DAA520",
        borderWidth: 1,
		yAxisID: 'y2',
      },
    ],
    updatedAt: new Date().toISOString(),
  };

  const maxYValue1 = Math.max(...dataPoint1) + 1;
  const maxYValue2 = Math.max(...dataPoint2) + 1;
  const maxYValue = maxYValue1 > maxYValue2 ? maxYValue1 : maxYValue2
  return {
    maxYValue,
	maxYValue1,
	maxYValue2,
    datasets,
  };
};

export const fnCalculatePorcentElevatedCitationsPerSelection = (
  results: IMineStateResponse
) => {
  const pstatLabels = results.periodStatistics.map((x) => x.yearMonth);
  const dataPoints: number[] = results.periodStatistics.map(
    (ps: IPeriodStatistic) => {
      return ps.mineElevatedIssuanceRate;
    }
  );
  const maxYValue = Math.max(...dataPoints) + 1;
  const datasets = {
    labels: pstatLabels,
    datasets: [
      {
        label: `% Elevated - ${results.mineName}`,
        data: dataPoints,
        backgroundColor: ["#418cf0"],
        borderColor: "#418cf0",
        borderWidth: 1,
      },
    ],
    updatedAt: new Date().toISOString(),
  };
  return {
    maxYValue,
    datasets,
  };
};

export const fnGetAveragePenaltyData = (
  results: IMineStateResponse,
  state: string
) => {
  const pstatLabels = results.periodStatistics.map((x) => x.yearMonth);
  const dataPoints: number[] = results.periodStatistics.map(
    (ps: IPeriodStatistic) => {
      return ps.mineAveragePenalty;
    }
  );

  const dataPoints2: number[] = results.periodStatistics.map(
    (ps: IPeriodStatistic) => {
      return ps.stateAveragePenalty;
    }
  );

  const datasets = {
    labels: pstatLabels,
    datasets: [
      {
        label: `${results.mineName} Average Penalty`,
        data: dataPoints,
        backgroundColor: ["#418cf0"],
        borderColor: "#418cf0",
        borderWidth: 1,
      },
      {
        label: `${state} Average Penalty`,
        data: dataPoints2,
        backgroundColor: ["#DAA520"],
        borderColor: "#DAA520",
        borderWidth: 1,
      },
    ],
    updatedAt: new Date().toISOString(),
  };

  const maxYValue = Math.max(...dataPoints) + 1;
  const maxYValue2 = Math.max(...dataPoints2) + 1;

  return {
    maxYValue: maxYValue > maxYValue2 ? maxYValue : maxYValue2,
    datasets,
  };
};

export const fnCalculateNumberElevatedCitationsPerSelection = (
  results: IMineStateResponse
) => {
  const pstatLabels = results.periodStatistics.map((x) => x.yearMonth);
  const dataPoints: number[] = results.periodStatistics.map(
    (ps: IPeriodStatistic) => {
      return ps.totalElevatedMineIssuances;
    }
  );
  const maxYValue = Math.max(...dataPoints) + 1;
  const datasets = {
    labels: pstatLabels,
    datasets: [
      {
        label: `% Elevated - ${results.mineName}`,
        data: dataPoints,
        backgroundColor: ["#418cf0"],
        borderColor: "#418cf0",
        borderWidth: 1,
      },
    ],
    updatedAt: new Date().toISOString(),
  };
  return {
    maxYValue,
    datasets,
  };
};

export const fnCalculateNumberSSCitationsPerSelection = (
  results: IMineStateResponse
) => {
  const pstatLabels = results.periodStatistics.map((x) => x.yearMonth);
  const dataPoints: number[] = results.periodStatistics.map(
    (ps: IPeriodStatistic) => {
      return ps.totalSignificantMineIssuances;
    }
  );
  const maxYValue = Math.max(...dataPoints) + 1;
  const datasets = {
    labels: pstatLabels,
    datasets: [
      {
        label: `# Elevated - ${results.mineName}`,
        data: dataPoints,
        backgroundColor: ["#418cf0"],
        borderColor: "#418cf0",
        borderWidth: 1,
      },
    ],
    updatedAt: new Date().toISOString(),
  };
  return {
    maxYValue,
    datasets,
  };
};

export const fnGetChartDataAccident = (
  results: IMineStateResponse,
  property: string,
  chartTitle: string
) => {
  const pstatLabels = results.accidentStatistics.map((x) => x.quarterYear);
  const dataPoints: number[] = results.accidentStatistics.map((ps: any) => {
    return ps[property];
  });

  const maxYValue = Math.max(...dataPoints) + 1;
  const datasets = {
    labels: pstatLabels,
    datasets: [
      {
        label: chartTitle,
        data: dataPoints,
        backgroundColor: ["#418cf0"],
        borderColor: "#418cf0",
        borderWidth: 1,
      },
    ],
    updatedAt: new Date().toISOString(),
  };
  return {
    maxYValue,
    datasets,
  };
};

export const fnGetChartDataAccident2 = (
  results: IMineStateResponse,
  property: string,
  property2: string,
  chartTitle: string,
  chartTitle2: string
) => {
  const pstatLabels = results.accidentStatistics.map((x) => x.quarterYear);
  const dataPoints1: number[] = results.accidentStatistics.map((ps: any) => {
    return ps[property];
  });

  const dataPoints2: number[] = results.accidentStatistics.map((ps: any) => {
    return ps[property2];
  });

  const maxYValue = Math.max(...dataPoints1) + 1;
  const maxYValue2 = Math.max(...dataPoints2) + 1;

  const datasets = {
    labels: pstatLabels,
    datasets: [
      {
        label: chartTitle,
        data: dataPoints1,
        backgroundColor: ["#418cf0"],
        borderColor: "#418cf0",
        borderWidth: 1,
      },
      {
        label: chartTitle2,
        data: dataPoints2,
        backgroundColor: ["#DAA520"],
        borderColor: "#DAA520",
        borderWidth: 1,
      },
    ],
    updatedAt: new Date().toISOString(),
  };
  return {
    maxYValue: maxYValue > maxYValue2 ? maxYValue : maxYValue2,
    datasets,
  };
};

export const PercentSSCitations = (results: IMineStateResponse) => {
  const pstatLabels = results.periodStatistics.map((x) => x.yearMonth);
  const dataPoints1 = results.periodStatistics.map((ps: IPeriodStatistic) => {
    return ps.mineSignificantIssuanceRate;
  });
  const dataPoints2 = results.periodStatistics.map((ps: IPeriodStatistic) => {
    return ps.stateSignificantIssuanceRate;
  });
  console.log("DATA POINTS", dataPoints1, dataPoints2)
  const datasets = {
    labels: pstatLabels,
    datasets: [
      {
        label: `% S&S Citations - ${results.mineName}`,
        data: dataPoints1,
        backgroundColor: ["#418cf0"],
        borderColor: "#418cf0",
        borderWidth: 1,
		yAxisID: 'y',
      },
      {
        label: `% S&S State Citations - ${results.mineName}`,
        data: dataPoints2,
        backgroundColor: ["#DAA520"],
        borderColor: "#DAA520",
        borderWidth: 1,
		yAxisID: 'y2',
      },
    ],
    updatedAt: new Date().toISOString(),
  };

  const maxYValue1 = Math.max(...dataPoints1) + 1;
  const maxYValue2 = Math.max(...dataPoints2) + 1;

  const maxYValue = maxYValue1 > maxYValue2 ? maxYValue1 : maxYValue2

  return {
    maxYValue,
	maxYValue1,
	maxYValue2,
    datasets
  };
};

export const TopRegulationsMine = (results: IMineStateResponse) => {
  let regulations = orderBy(results.topRegulations, "totalIssued", "desc");
  regulations = regulations.filter(GetMineData);
  // let sliceEnd = Math.min(10, regulations.length);
  // regulations = regulations.slice(0, sliceEnd);
  return {
    labels: regulations.map((reg: IRegulationInfo) => {
      return reg.regulation;
    }),
    datasets: [
      {
        label: `Elevated - ${results.mineName}`,
        data: regulations.map((reg: IRegulationInfo) => {
          return reg.numIssuedElevated;
        }),
        backgroundColor: ["#07469b"],
        borderColor: "#07469b",
        borderWidth: 1,
      },
      {
        label: `Non-Significant - ${results.mineName}`,
        data: regulations.map((reg: IRegulationInfo) => {
          return reg.numIssuedNonSignificant;
        }),
        backgroundColor: ["#418cf0"],
        borderColor: "#418cf0",
        borderWidth: 1,
      },
      {
        label: `Significant - ${results.mineName}`,
        data: regulations.map((reg: IRegulationInfo) => {
          return reg.numIssuedSignificant;
        }),
        backgroundColor: ["#DAA520"],
        borderColor: "#DAA520",
        borderWidth: 1,
      },
    ],
    updatedAt: new Date().toISOString(),
  };
};

export const GetMineData = (data:any)=>{
  if(data.mineID != null)
  {
    return data;
  }   
}

export const GetAccidentTypeDistributionData = (
  results: IMineStateResponse,
  state: string
) => {
  let accidents = orderBy(
    results.accidentTypeStatistics,
    "accidentType",
    "asc"
  );

  return {
    labels: accidents.map((acc: IAccidentTypeInfo) => {
      return acc.accidentType;
    }),
    datasets: [
      {
        label: `${results.mineName} %`,
        data: accidents.map((acc: IAccidentTypeInfo) => {
          return acc.mineAccidentFrequency;
        }),
        backgroundColor: ["#418cf0"],
        borderColor: "#418cf0",
        borderWidth: 1,
      },
      {
        label: `${state} %`,
        data: accidents.map((acc: IAccidentTypeInfo) => {
          return acc.stateAccidentFrequency;
        }),
        backgroundColor: ["#DAA520"],
        borderColor: "#DAA520",
        borderWidth: 1,
      },
    ],
    updatedAt: new Date().toISOString(),
  };
};

export const TopRegulationsAllState = (results: IMineStateResponse) => {
  let regulations = results.allStateRegulations.map((x) => {
    return {
      ...x,
      numIssuedSignificant: Math.abs(x.numIssuedSignificant),
      numIssuedNonSignificant: Math.abs(x.numIssuedNonSignificant),
      numIssuedElevated: Math.abs(x.numIssuedElevated),
    };
  });
  regulations = orderBy(regulations, "totalIssued", "desc");
  let sliceEnd = Math.min(10, regulations.length);
  regulations = regulations.slice(0, sliceEnd);
  console.log("regs", regulations);
  return {
    labels: regulations.map((reg: IRegulationInfo) => {
      return reg.regulation;
    }),
    datasets: [
      {
        label: `Elevated`,
        data: regulations.map((reg: IRegulationInfo) => {
          return reg.numIssuedElevated;
        }),
        backgroundColor: ["#07469b"],
        borderColor: "#07469b",
        borderWidth: 1,
      },
      {
        label: `Non-Significant}`,
        data: regulations.map((reg: IRegulationInfo) => {
          return reg.numIssuedNonSignificant;
        }),
        backgroundColor: ["#418cf0"],
        borderColor: "#418cf0",
        borderWidth: 1,
      },
      {
        label: `Significant`,
        data: regulations.map((reg: IRegulationInfo) => {
          return reg.numIssuedSignificant;
        }),
        backgroundColor: ["#DAA520"],
        borderColor: "#DAA520",
        borderWidth: 1,
      },
    ],
    updatedAt: new Date().toISOString(),
  };
};

// export const TopRegulationsMine = (results: IMineStateResponse) => {
//     let regulations = orderBy(results.topRegulations, 'totalIssued', 'desc');
//     let sliceEnd = Math.min(10, regulations.length);
//     regulations = regulations.slice(0, sliceEnd);
//     return {
//         labels: regulations.map((reg: IRegulationInfo) => {
//             return reg.regulation
//         }),
//         datasets: [
//             {
//                 label: `Top Regulations - ${results.mineName}`,
//                 data: regulations.map((reg: IRegulationInfo) => {
//                     return reg.totalIssued
//                 }),
//                 backgroundColor: [
//                     "#418cf0"
//                 ],
//                 borderColor: "#418cf0",
//                 borderWidth: 1
//             }
//         ],
//         updatedAt: new Date().toISOString()
//     }
// }
