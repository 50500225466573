import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { getInspectionsByMine } from '../../store/actions/inspectionActions';
import { IRootState } from '../../interfaces/store';
import MaterialTable from 'material-table';
import { tableIcons } from '../shared/MaterialTableShared';
import { IInspection } from '../../interfaces/model/MSHAPCT/inspection.interface';
import { arrayFromKeyedObject } from '../../utils/collection-utils';
import { dateFormatted } from '../../utils/date-utils';
import { dataSourceToString } from '../../enums/citation-enums';
import { inspectionStatusToString } from '../../enums/inspection-enums';
import { Button, Tooltip } from '@material-ui/core';
import AddInspectionModal from '../inspections/AddInspectionModal';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete'
import EditInspectionModal from '../inspections/EditInspectionModal';
import {
    deleteInspection
  } from "../../store/actions/inspectionActions";
import StandardModal from '../shared/StandardModal';
interface IMineInspections {

}

const MineInspections: React.FC<IMineInspections> = (props) => {

    const dispatch = useDispatch();
    const { minepk } = useParams<{ minepk: string }>();
    const [inpsectionsPrepared, setInpsectionsPrepared] = useState<IInspection[]>([])
    const [addInspectionModalOpen, setAddInspectionModalOpen] = useState(false)
    const [editInspectionModalOpen, setEditInspectionModalOpen] = useState(false)
    const [selectedInspection, setSelectedInspection] = useState<IInspection | undefined>(undefined);
    const [confirmDeleteInspectionModalOpen,setConfirmDeleteInspectionModalOpen] = useState(false)
    const inspections = useSelector(
        (state: IRootState) => state.main.inspections
    );

    const selected_mine = useSelector(
        (state: IRootState) => state.main.client_options.selected_mine
    );

    useEffect(() => {
        dispatch(getInspectionsByMine({ minepk: Number(minepk) }))
    }, [])



    useEffect(() => {
        setInpsectionsPrepared(arrayFromKeyedObject(inspections));
    }, [inspections])

    return (
        <>
            <AddInspectionModal key={addInspectionModalOpen.toString()} mine={selected_mine} open={addInspectionModalOpen} onClose={() => setAddInspectionModalOpen(false)} />
            <EditInspectionModal key={selectedInspection?.pk} selectedInspection={selectedInspection} mine={selected_mine} open={editInspectionModalOpen} onClose={() => setEditInspectionModalOpen(false)} />
            <StandardModal 
      open={confirmDeleteInspectionModalOpen} 
      maxWidth={"sm"}
      onClose={() => {setConfirmDeleteInspectionModalOpen(false);}}
      paragraphs={[`Are you sure you want to delete client entered inspection ${selectedInspection?.eventNumber}`]}
      title={'Confirm Delete Inspection'}
      actions={[
        {
            title: "Cancel",
            callback: () => {
                setConfirmDeleteInspectionModalOpen(false)
            },
          },
        {
          title: "Yes",
          callback: () => {
            setConfirmDeleteInspectionModalOpen(false)
            dispatch(deleteInspection({pk: selectedInspection?.pk}))
          },
        },
      ]}
      customActions={[]}
      />
            {inpsectionsPrepared && inpsectionsPrepared.length ?
                <>
                    <Button
                        onClick={() => { setAddInspectionModalOpen(true) }}
                        variant="outlined"
                        size="small"
                    >
                        Add Inspection
                    </Button>
                    <div style={{ marginTop: 16 }}>
                        <MaterialTable
                            icons={tableIcons}
                            title="Inspections"
                            columns={[
                                {
                                    title: 'Start Date', field: 'startDate', sorting: true, defaultSort: 'desc',
                                    render: (rowData: any) => dateFormatted(rowData.startDate, 'mmddyyyy')
                                },
                                {
                                    title: 'End Date', field: 'endDate', sorting: true,
                                    render: (rowData: any) => dateFormatted(rowData.endDate, 'mmddyyyy')
                                },
                                {
                                    title: 'VPID Hours', field: 'vpidHours', sorting: true
                                },
                                {
                                    title: 'Event Number', field: 'eventNumber', sorting: true
                                },
                                {
                                    title: 'Data Source', field: 'dataSource', sorting: true,
                                    render: (rowData: any) => dataSourceToString(rowData.dataSource)
                                },
                                {
                                    title: 'Inspection Status', field: 'inspectionStatus', sorting: true,
                                    render: (rowData: any) => inspectionStatusToString(rowData.inspectionStatus)
                                },
                                {
                                    title: 'Notes', field: 'notes', sorting: true
                                },
                                {
                                    title: 'Actions', field: 'actions', render: (rowData: any) => {
                                        return <div>
                                            {rowData.dataSource === 1 &&
                                                <>
                                                    <Tooltip title="Edit" placement="top">
                                                        <EditIcon
                                                            style={{ fontSize: 16 }}
                                                            className="transparent75"
                                                            onClick={() => {
                                                                if (rowData && rowData.pk) {
                                                                    setSelectedInspection(rowData)
                                                                    setEditInspectionModalOpen(true);
                                                                    //history.push(`Administration/Company/${rowData.pk}/Edit`);
                                                                }
                                                            }}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Delete" placement="top">
                                                        <DeleteIcon
                                                            style={{ marginLeft: 12, fontSize: 16 }}
                                                            className="transparent75"
                                                            onClick={() => {
                                                                if (rowData && rowData.pk) {
                                                                    setSelectedInspection(rowData)
                                                                    setConfirmDeleteInspectionModalOpen(true);
                                                                    //setSelectedCompany(rowData);
                                                                    //setDeleteCompanyModalOpen(true);
                                                                    //deleteCompany(rowData)
                                                                }
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </>}
                                        </div>

                                    }
                                }
                            ]}
                            data={inpsectionsPrepared}
                            options={{
                                sorting: true,
                                headerStyle: {
                                    zIndex: 8
                                },
                                paging: true,
                                pageSize: Math.min(inpsectionsPrepared.length, 15),
                                draggable: false
                            }}
                            onRowClick={(event, rowData, togglePanel) => {

                            }}
                        // actions={[
                        // {
                        //     icon: () => <EditIcon
                        //             fontSize="small"
                        //             className="transparent75"
                        //             />,
                        //     tooltip: 'Filter',
                        //     onClick: (event, rowData) =>  {console.log("action", event, rowData)},
                        //     // isFreeAction: true
                        // },
                        // ]}
                        />
                    </div>

                </> : null}
        </>
    )
}

export default MineInspections

