import { CheckboxProps, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React from 'react';

interface ICheckBoxWrap extends CheckboxProps {
    mode: "edit" | "view"
    id: any
    label: string
}

const CheckboxWrap: React.FC<ICheckBoxWrap> = (props) => {
    const { mode, id, label, checked } = props;

    if (mode === "edit" && checked !== undefined) {
        return (
            <FormControlLabel
                id={id}
                control={
                    <Checkbox
                        {...props}
                    />
                }
                label={label}
            />
        )
    }

    return (
        <>
            <Typography variant="body1" component="span">
                {
                    label
                }
            </Typography>
            &nbsp;
            <Typography variant="body1" component="span">
                {
                    checked ? ': Yes' : ': No'
                }
            </Typography>
        </>
    );
}

export default CheckboxWrap