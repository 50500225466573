import { ACTIONS } from "../../enums/actions";

export const getUser = (
  payload: any,
  onSuccess?: (data: any) => void,
  onFail?: (data: any) => void
) => ({
  type: ACTIONS.GET_USER,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getAdminUser = (
  payload: any,
  onSuccess?: (data: any) => void,
  onFail?: (data: any) => void
) => ({
  type: ACTIONS.GET_ADMIN_USER,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const createUser = (
  payload: any,
  onSuccess?: (pk: number) => void,
  onFail?: (data: any) => void
) => ({
  type: ACTIONS.CREATE_USER,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const updateUser = (
  payload: any,
  onSuccess?: (pk: number) => void,
  onFail: Function | null = null
) => ({
  type: ACTIONS.UPDATE_USER,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const deleteUser = (
  payload: any,
  onSuccess?: (data: any) => void,
  onFail?: (data: any) => void
) => ({
  type: ACTIONS.DELETE_USER,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});
