import { ACTIONS } from "../../enums/actions";

export const getBusinessUnit = (
  payload: any,
  onSuccess?: (data: any) => void,
  onFail?: (data: any) => void
) => ({
  type: ACTIONS.GET_BUSINESS_UNIT,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getAllBusinessUnit = (
  payload: any,
  onSuccess?: (data: any) => void,
  onFail?: (data: any) => void
) => ({
  type: ACTIONS.GET_ALL_BUSINESS_UNIT,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const createBusinessUnit = (
  payload: any,
  onSuccess?: Function,
  onFail: Function | null = null
) => ({
  type: ACTIONS.CREATE_BUSINESS_UNIT,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const updateBusinessUnit = (
  payload: any,
  onSuccess?: Function,
  onFail: Function | null = null
) => ({
  type: ACTIONS.UPDATE_BUSINESS_UNIT,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const deleteBusinessUnit = (
  payload: any,
  onSuccess?: Function,
  onFail?: Function
) => ({
  type: ACTIONS.DELETE_BUSINESS_UNIT,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});
