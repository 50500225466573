export enum CITATION_TABLE_FILTER {
  RPID = 0,
  OUTSTANDING = 1,
  TERMINATED = 2,
  SPECIAL_ASSESSMENT = 3,
  PAID = 4,
  VACATED = 5,
  IN_CONTEST = 6,
  PENALTY_DISCREPANCY_UP = 7,
  PENALTY_DISCREPANCY_DOWN = 8,
}

export const citationTableFilterToString = (
  citationTableFilter: CITATION_TABLE_FILTER
) => {
  switch (citationTableFilter) {
    case CITATION_TABLE_FILTER.RPID:
      return "RPID";
    case CITATION_TABLE_FILTER.OUTSTANDING:
      return "Outstanding";
    case CITATION_TABLE_FILTER.TERMINATED:
      return "Terminated";
    case CITATION_TABLE_FILTER.SPECIAL_ASSESSMENT:
      return "Special Assessment";
    case CITATION_TABLE_FILTER.PAID:
      return "Paid";
    case CITATION_TABLE_FILTER.VACATED:
      return "Vacated";
    case CITATION_TABLE_FILTER.IN_CONTEST:
      return "In Contest";
    case CITATION_TABLE_FILTER.PENALTY_DISCREPANCY_UP:
      return "Discrepancy Up";
    case CITATION_TABLE_FILTER.PENALTY_DISCREPANCY_DOWN:
      return "Discrepancy Down";
  }
};

export enum GRAVITY_CHANCE {
  NO_LIKELIHOOD = 1,
  UNLIKELY = 2,
  REASONABLY_LIKELY = 3,
  HIGHLY_LIKELY = 4,
  OCCURED = 5,
}

export const gravityChanceToString = (gravityChance: GRAVITY_CHANCE) => {
  switch (gravityChance) {
    case GRAVITY_CHANCE.NO_LIKELIHOOD:
      return "No Likelihood";
    case GRAVITY_CHANCE.UNLIKELY:
      return "Unlikely";
    case GRAVITY_CHANCE.REASONABLY_LIKELY:
      return "Reasonably Likely";
    case GRAVITY_CHANCE.HIGHLY_LIKELY:
      return "Highly Likely";
    case GRAVITY_CHANCE.OCCURED:
      return "Occured";
  }
};

export enum ACCOUNT_TYPE {
  ANONYMOUS = 0x0,
  SYSTEM_ADMIN = 9, //0x1, 9
  COMPANY_ADMIN = 14, //0x2, 14
  COMPANY_ACCOUNT = 12, //0x4, 12
  IS_ENABLED = 0x8,
  IS_READ_ONLY = 0x10,
  ALLOW_8K_EXPORT = 0x20,
}

export enum DATA_SOURCE {
  CLIENT_ENTERED = 1,
  MSHA_DOWNLOAD = 2,
  INSPECTION_DETAILS = 4,
  MSHA_FLATFILE = 8,
}

export const dataSourceToString = (dataSource: DATA_SOURCE) => {
  switch (dataSource) {
    case DATA_SOURCE.CLIENT_ENTERED:
      return "Client Entered";
    case DATA_SOURCE.MSHA_DOWNLOAD:
      return "MSHA Download";
    case DATA_SOURCE.INSPECTION_DETAILS:
      return "Inspection Details";
    case DATA_SOURCE.MSHA_FLATFILE:
      return "MSHA Flatfile";
  }
};

export enum INSPECTION_STATUS {
  IN_PROGRESS = 1,
  COMPLETE = 2,
}

export enum EMAIL_NOTIFICATION_SCHEDULE {
  DAILY = 1,
  WEEKLY = 2,
}

export enum VIOLATION_TYPE {
  HEALTH = 1,
  SAFETY = 2,
  OTHER = 3,
}

export enum GRAVITY_RESULT_EXPECTED {
  NO_LOST_WORKDAYS = 1,
  LOST_WORKDAYS_OR_RESTRICTED_DUTY = 2,
  PERMANENTLY_DISABLING = 3,
  FATAL = 4,
}

export const gravityResultExpectedToString = (
  gravityResultExpected: GRAVITY_RESULT_EXPECTED
) => {
  switch (gravityResultExpected) {
    case GRAVITY_RESULT_EXPECTED.NO_LOST_WORKDAYS:
      return "No Lost Workdays";
    case GRAVITY_RESULT_EXPECTED.LOST_WORKDAYS_OR_RESTRICTED_DUTY:
      return "Lost Workdays / Resticted Duty";
    case GRAVITY_RESULT_EXPECTED.PERMANENTLY_DISABLING:
      return "Permanently Disabling";
    case GRAVITY_RESULT_EXPECTED.FATAL:
      return "Fatal";
  }
};

export enum NEGLIGENCE {
  NONE = 1,
  LOW = 2,
  MODERATE = 3,
  HIGH = 4,
  RECKLESS_DISREGARD = 5,
}

export const typeOfNegligenceString = (typeOfNegligence: NEGLIGENCE) => {
  switch (typeOfNegligence) {
    case NEGLIGENCE.NONE:
      return "None";
    case NEGLIGENCE.LOW:
      return "Low";
    case NEGLIGENCE.MODERATE:
      return "Moderate";
    case NEGLIGENCE.HIGH:
      return "High";
    case NEGLIGENCE.RECKLESS_DISREGARD:
      return "Reckless Disregard";
  }
};

export enum TYPE_OF_ACTION {
  _104_A = 1,
  _104_D_1 = 2,
  _104_D_2 = 3,
  _104_G = 4,
  _107_A = 5,
  _104_B = 6,
  _104_F = 7,
  _103_K = 8,
  _104_E = 9,
  _110_A = 10,
  _103_J = 11,
  _314_B = 12,
}

export const typeOfActionToString = (typeOfAction: TYPE_OF_ACTION) => {
  switch (typeOfAction) {
    case TYPE_OF_ACTION._104_A:
      return "104(a)";
    case TYPE_OF_ACTION._104_B:
      return "104(b)";
    case TYPE_OF_ACTION._104_D_1:
      return "104(d)(1)";
    case TYPE_OF_ACTION._104_D_2:
      return "104(d)(2)";
    case TYPE_OF_ACTION._104_G:
      return "104(g)";
    case TYPE_OF_ACTION._107_A:
      return "107(a)";
    case TYPE_OF_ACTION._104_B:
      return "107(b)";
    case TYPE_OF_ACTION._104_F:
      return "104(f)";
    case TYPE_OF_ACTION._103_K:
      return "103(k)";
    case TYPE_OF_ACTION._104_E:
      return "104(e)";
    case TYPE_OF_ACTION._110_A:
      return "110(a)";
    case TYPE_OF_ACTION._103_J:
      return "103(j)";
    case TYPE_OF_ACTION._314_B:
      return "314(b)";
  }
};

export enum TYPE_OF_ISSUANCE {
  CITATION = 1,
  ORDER = 2,
  SAFEGUARD = 3,
  WRITTEN_NOTICE = 4,
}

export const typeOfIssuanceToString = (typeOfIssuance: TYPE_OF_ISSUANCE) => {
  switch (typeOfIssuance) {
    case TYPE_OF_ISSUANCE.CITATION:
      return "Citation";
    case TYPE_OF_ISSUANCE.ORDER:
      return "Order";
    case TYPE_OF_ISSUANCE.SAFEGUARD:
      return "Safeguard";
    case TYPE_OF_ISSUANCE.WRITTEN_NOTICE:
      return "Written Notice";
  }
};

export enum TYPE_OF_INSPECTION {
  E01 = 1, // Regular safety and health inspection
  E04 = 2, // Verbal Hazard Complaint Inspection
  E16 = 3, // Spot Inspection
  E15 = 4, // Compliance Follow-up Inspection
  E08 = 5, // Non-injury accident investigation
  E02 = 6, // 103(j) Spot Inspection
  E03 = 7, // 103(g) Written Notification Hazard Complaint Inspection
  E06 = 8, // Fatal Accident Investigation
  E07 = 9, // Non-Fatal Accident Investigation
  E17 = 10, // Special Emphasis Programs
  E18 = 11, // Shaft, Slope or Major Construction Spot Inspection
  E19 = 12, // Electrical Technical Investigation
  E20 = 13, // Roof Control Technical Investigation
  E21 = 14, // Ventilation Technical Investigationccident Investigation
  E22 = 15, // Health Technical Investigation
  E23 = 16, // Impoundment Spot Inspection
  E24 = 17, // Other Technical Compliance Investigations
  E25 = 18, // Part 50 Audit
  E27 = 19, // Attempted Inspection (Denial of Entry)
  E28 = 20, // Mine Idle Activity
  E33 = 21, // Non-Chargeable Accident Investigation
}

export const typeOfInspectionToString = (
  typeOfInspection: TYPE_OF_INSPECTION
) => {
  switch (typeOfInspection) {
    case TYPE_OF_INSPECTION.E01:
      return "E01";
    case TYPE_OF_INSPECTION.E04:
      return "E04";
    case TYPE_OF_INSPECTION.E16:
      return "E16";
    case TYPE_OF_INSPECTION.E15:
      return "E15";
    case TYPE_OF_INSPECTION.E08:
      return "E08";
    case TYPE_OF_INSPECTION.E02:
      return "E02";
    case TYPE_OF_INSPECTION.E03:
      return "E03";
    case TYPE_OF_INSPECTION.E06:
      return "E06";
    case TYPE_OF_INSPECTION.E07:
      return "E07";
    case TYPE_OF_INSPECTION.E17:
      return "E17";
    case TYPE_OF_INSPECTION.E18:
      return "E18";
    case TYPE_OF_INSPECTION.E19:
      return "E19";
    case TYPE_OF_INSPECTION.E20:
      return "E20";
    case TYPE_OF_INSPECTION.E21:
      return "E21";
    case TYPE_OF_INSPECTION.E22:
      return "E22";
    case TYPE_OF_INSPECTION.E23:
      return "E23";
    case TYPE_OF_INSPECTION.E24:
      return "E24";
    case TYPE_OF_INSPECTION.E25:
      return "E25";
    case TYPE_OF_INSPECTION.E27:
      return "E27";
    case TYPE_OF_INSPECTION.E28:
      return "E28";
    case TYPE_OF_INSPECTION.E33:
      return "E33";
  }
};

export const primaryOrMillToString = ( pOrM: PRIMARY_OR_MILL) =>{
	switch (pOrM) {
		case 1:
			return  "Primary";
		case 2:
			return "Mill";
		default:
			return "";
	}
}

export enum PRIMARY_OR_MILL {
  PRIMARY = 1,
  MILL = 2,
}

export enum MSHA_CITATION_STATUS {
  PROPOSED = 1,
  IN_CONTEST = 2,
  DELINQUENT = 3,
  CLOSED = 4,
  NON_ASSESSABLE = 5,
  PENDING_PAYMENT = 6,
  PROPOSE_UNCOLLECTABLE = 7,
  SETTLEMENT = 8,
  VACATED = 9,
  BANKRUPTCY = 10,
}

export enum MSHA_HISTORY_UPDATE_AUDIT_LOG_ENTRY_TYPE {
  PROCESS_STARTED = 1,
  PROCESS_ENDED = 2,
  MINE_STARTED = 3,
  MINE_ENDED = 4,
  CONTRACTOR_STARTED = 5,
  CONTRACTOR_ENDED = 6,
  DOWNLOAD_STAGE = 7,
  EXTRACT_STAGE = 8,
  SSIS_EXECUTE_STAGE = 9,
  MINE_IMPORT_STAGE = 10,
  CITATION_IMPORT_STAGE = 11,
  INSPECTION_IMPORT_STAGE = 12,
  PRODUCTION_DATA_IMPORT_STAGE = 13,
}

export enum MSHA_HISTORY_UPDATE_AUDIT_LOG_ENTRY_LEVEL {
  ERROR = 1,
  WARNING = 2,
  INFO = 3,
}

export enum CITATION_MGMT_CUSTOM_FIELD_TYPE {
  MONEY = 1,
  TEXT = 2,
  WHOLE_NUMBER = 3,
  DECIMAL_NUMBER = 4,
  DATE_TIME = 5,
  LONG_TEXT = 6,
}

export enum PREDICTED_PENALTY_DISCREPANCY {
  WITHIN_THRESHOLD = 1,
  ABOVE_THRESHOLD = 2,
  BELOW_THRESHOLD = 3,
}

export enum PENALTY_RANGE {
  PenaltyZeroToTwentyFive = 1,
  PenaltyTwentyFiveToFifty = 2,
  PenaltyFiftyToSeventyFive = 3,
  PenaltySeventyFiveToHundred = 4,
  PenaltyGreaterThanHundred = 5,
}

export enum CITATION_COLUMN {
  DATE_ISSUED = "Date Issued",
  CITATION_ORDER_NO = "Citation Order No.",
  SERVED_TO = "Served To",
  CONDITION_OR_PRACTICE = "Condition or Practice",
  TYPE_OF_ACTION = "Type of Action",
  REGULATION = "Regulation",
  PENALTY_CALCULATION = "Penalty Calculation",
  PENALTY_CALCULATION_GFE = "Penalty Calculation GFE",
  MINE_ID = "Mine ID",
  MINE_NAME = "Mine Name",
  NEGLIGENCE = "Negligence",
  LIKELIHOOD = "Likelihood",
  INJURY_OR_ILLNESS_EXPECTED = "Injury or Illness Expected",
  PERSONS_AFFECTED = "Persons Affected",
  S_AND_S = "S&S",
  TYPE_OF_ISSUANCE = "Type of Issuance",
  AREA_OR_EQUIPMENT = "Area or Equipment",
  TERMINATION_DUE_DATE = "Termination Due Date",
  ACTION_TO_TERMINATE = "Action To Terminate",
  TERMINATION_DATE = "Termination Date",
  INSPECTION_TYPE = "Inspection Type",
  EVENT_NUMBER = "Event Number",
  AR_NUMBER = "AR Number",
  NOTES = "Notes",
  MSHA_PROPOSED_PENALTY = "MSHA Proposed Penalty",
  MSHA_CURRENT_PENALTY = "MSHA Current Penalty",
  MSHA_FINAL_ORDER_DATE = "MSHA Final Order Date",
  MSHA_CASE_NUMBER = "MSHA Case Number",
  WAS_CONTESTED = "Was Contested",
  LAST_CONTEST_DATE = "Last Contest Date",
  MODIFIED_NEGLIGENCE = "Modified Negligence",
  MODIFIED_LIKELIHOOD = "Modified Likelihood",
  MODIFIED_INJURY_ILLNESS = "Modified Injury/Illness",
  MODIFIED_PERSONS_AFFECTED = "Modified Persons Affected",
  MODIFIED_S_AND_S = "Modified_S&S",
  MODIFIED_REGULATION = "Modified Regulation",
  DATA_SOURCE = "Data Source",
}

export const keyToCitationColumn = (key: string) => {
  switch (key) {
    case "actionToTerminate":
      return CITATION_COLUMN.ACTION_TO_TERMINATE;
    case "area":
      return CITATION_COLUMN.AREA_OR_EQUIPMENT;
    case "arnumber":
      return CITATION_COLUMN.AR_NUMBER;
    case "citationNumber":
      return CITATION_COLUMN.CITATION_ORDER_NO;
    case "condition":
      return CITATION_COLUMN.CONDITION_OR_PRACTICE;
    case "dateIssued":
      return CITATION_COLUMN.DATE_ISSUED;
    case "eventNumber":
      return CITATION_COLUMN.EVENT_NUMBER;
    case "gravityChance":
      return CITATION_COLUMN.LIKELIHOOD;
    case "gravityResultExpected":
      return CITATION_COLUMN.INJURY_OR_ILLNESS_EXPECTED;
    case "lastContestDate":
      return CITATION_COLUMN.LAST_CONTEST_DATE;
    case "modifiedInjuryOrIllness":
      return CITATION_COLUMN.MODIFIED_INJURY_ILLNESS;
    case "modifiedLikelihood":
      return CITATION_COLUMN.MODIFIED_LIKELIHOOD;
    case "modifiedNegligence":
      return CITATION_COLUMN.MODIFIED_NEGLIGENCE;
    case "modifiedPersonsAffected":
      return CITATION_COLUMN.MODIFIED_PERSONS_AFFECTED;
    case "modifiedRegulation":
      return CITATION_COLUMN.MODIFIED_REGULATION;
    case "modifiedSandS":
      return CITATION_COLUMN.MODIFIED_S_AND_S;
    case "mshaCaseNumber":
      return CITATION_COLUMN.MSHA_CASE_NUMBER;
    case "mshaCurrentPenalty":
      return CITATION_COLUMN.MSHA_CURRENT_PENALTY;
    case "mshaFinalOrderDate":
      return CITATION_COLUMN.MSHA_FINAL_ORDER_DATE;
    case "mshaProposedPenalty":
      return CITATION_COLUMN.MSHA_PROPOSED_PENALTY;
    case "negligence":
      return CITATION_COLUMN.NEGLIGENCE;
    case "notes":
      return CITATION_COLUMN.NOTES;
    case "penalty":
      return CITATION_COLUMN.PENALTY_CALCULATION;
    case "personsAffected":
      return CITATION_COLUMN.PERSONS_AFFECTED;
    case "sectionOfCfr":
      return CITATION_COLUMN.REGULATION;
    case "servedTo":
      return CITATION_COLUMN.SERVED_TO;
    case "significant":
      return CITATION_COLUMN.S_AND_S;
    case "terminationDate":
      return CITATION_COLUMN.TERMINATION_DATE;
    case "terminationDueDate":
      return CITATION_COLUMN.TERMINATION_DUE_DATE;
    case "typeOfAction":
      return CITATION_COLUMN.TYPE_OF_ACTION;
    case "typeOfInspection":
      return CITATION_COLUMN.INSPECTION_TYPE;
    case "typeOfIssuance":
      return CITATION_COLUMN.TYPE_OF_ISSUANCE;
    case "wasContested":
      return CITATION_COLUMN.WAS_CONTESTED;
  }
};

export const CitationColumns: CITATION_COLUMN[] = [
  CITATION_COLUMN.ACTION_TO_TERMINATE,
  CITATION_COLUMN.AR_NUMBER,
  CITATION_COLUMN.AREA_OR_EQUIPMENT,
  CITATION_COLUMN.CITATION_ORDER_NO,
  CITATION_COLUMN.CONDITION_OR_PRACTICE,
  CITATION_COLUMN.DATE_ISSUED,
  CITATION_COLUMN.EVENT_NUMBER,
  CITATION_COLUMN.INJURY_OR_ILLNESS_EXPECTED,
  CITATION_COLUMN.INSPECTION_TYPE,
  CITATION_COLUMN.LAST_CONTEST_DATE,
  CITATION_COLUMN.LIKELIHOOD,
  CITATION_COLUMN.MINE_NAME,
  CITATION_COLUMN.MODIFIED_INJURY_ILLNESS,
  CITATION_COLUMN.MODIFIED_LIKELIHOOD,
  CITATION_COLUMN.MODIFIED_NEGLIGENCE,
  CITATION_COLUMN.MODIFIED_PERSONS_AFFECTED,
  CITATION_COLUMN.MODIFIED_REGULATION,
  CITATION_COLUMN.MODIFIED_S_AND_S,
  CITATION_COLUMN.MSHA_CASE_NUMBER,
  CITATION_COLUMN.MSHA_CURRENT_PENALTY,
  CITATION_COLUMN.MSHA_FINAL_ORDER_DATE,
  CITATION_COLUMN.MSHA_PROPOSED_PENALTY,
  CITATION_COLUMN.NEGLIGENCE,
  CITATION_COLUMN.NOTES,
  CITATION_COLUMN.PENALTY_CALCULATION,
  CITATION_COLUMN.PENALTY_CALCULATION_GFE,
  CITATION_COLUMN.PERSONS_AFFECTED,
  CITATION_COLUMN.REGULATION,
  CITATION_COLUMN.S_AND_S,
  CITATION_COLUMN.SERVED_TO,
  CITATION_COLUMN.TERMINATION_DATE,
  CITATION_COLUMN.TERMINATION_DUE_DATE,
  CITATION_COLUMN.TYPE_OF_ACTION,
  CITATION_COLUMN.TYPE_OF_ISSUANCE,
  CITATION_COLUMN.WAS_CONTESTED,
];

export const CitationTableFilters = [
  CITATION_TABLE_FILTER.PAID,
  CITATION_TABLE_FILTER.OUTSTANDING,
  CITATION_TABLE_FILTER.IN_CONTEST,
  CITATION_TABLE_FILTER.PENALTY_DISCREPANCY_DOWN,
  CITATION_TABLE_FILTER.PENALTY_DISCREPANCY_UP,
  CITATION_TABLE_FILTER.RPID,
  CITATION_TABLE_FILTER.SPECIAL_ASSESSMENT,
  CITATION_TABLE_FILTER.TERMINATED,
  CITATION_TABLE_FILTER.VACATED,
];

export enum CLOSEOUT_CONFERENCE {
  VACATED = "vacated",
  MODIFIED = "modified",
  UPHELD = "upheld",
  ACCEPTED_AS_ISSUED = "accepted_as_issued",
}

export const CloseoutConferenceToString = (
  closeoutConference: CLOSEOUT_CONFERENCE
) => {
  switch (closeoutConference) {
    case CLOSEOUT_CONFERENCE.VACATED:
      return "Vacated";
    case CLOSEOUT_CONFERENCE.MODIFIED:
      return "Modified";
    case CLOSEOUT_CONFERENCE.UPHELD:
      return "Upheld";
    case CLOSEOUT_CONFERENCE.ACCEPTED_AS_ISSUED:
      return "Accepted as Issued";
  }
};

export enum TYPE_OF_MINE {
  SURFACE = 1,
  UNDERGROUND = 2,
  FACILITY = 3,
}

export enum TYPE_OF_MINED_MATERIAL {
  COAL = 1,
  METAL_NON_METAL = 2,
}

export const sectionListFromMineTypes = (
  typeOfMine: TYPE_OF_MINE,
  typeOfMinedMaterial: TYPE_OF_MINED_MATERIAL
) => {
  switch (typeOfMine) {
    case TYPE_OF_MINE.SURFACE:
    case TYPE_OF_MINE.FACILITY:
      switch (typeOfMinedMaterial) {
        case TYPE_OF_MINED_MATERIAL.COAL:
          return sectionsSurfaceCoal;
        case TYPE_OF_MINED_MATERIAL.METAL_NON_METAL:
          return sectionsSurfaceMetalAndNonMetal;
      }
      break;
    case TYPE_OF_MINE.UNDERGROUND:
      switch (typeOfMinedMaterial) {
        case TYPE_OF_MINED_MATERIAL.COAL:
          return sectionsUndergroundCoal;
        case TYPE_OF_MINED_MATERIAL.METAL_NON_METAL:
          return sectionsUndergroundMetalAndNonMetal;
      }
      break;
  }
};

export const sectionsSurfaceMetalAndNonMetal = [
  "56.8529", //surface metal and non-metal
  "56.4201(a)(1)",
  "56.3360",
  "56.3200",
  "56.14100(b)",
  "56.12004",
  "56.11051(a)",
  "56.11001",
];

export const sectionsUndergroundMetalAndNonMetal = [
  "57.8529", //underground metal and non-metal
  "57.4201(a)(1)",
  "57.3360",
  "57.3200",
  "57.14100(b)",
  "57.12004",
  "57.11051(a)",
  "57.11001",
];

export const sectionsUndergroundCoal = [
  "75.503", //underground coal
  "75.403",
  "75.400",
  "75.370(a)(1)",
  "75.220(a)(1)",
  "75.202(a)",
  "75.1731(b)",
];

export const sectionsSurfaceCoal = [
  "77.503", //surface coal
  "77.403",
  "77.400",
  "77.370(a)(1)",
  "77.220(a)(1)",
  "77.202(a)",
  "77.1731(b)",
];
