export const states = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
} as any

// ORIGINAL OPTIONS FOR REFERENCE
// Alabama: "AL",
// Alaska: "AK",
// Arizona: "AZ",
// Arkansas: "AR",
// California: "CA",
// Colorado: "CO",
// Connecticut: "CT",
// Delaware: "DE",
// Florida: "FL",
// Georgia:"GA",
// Hawaii:"HI",
// Idaho:"ID",
// Illinois:"IL",
// Indiana: "IN",
// Iowa: "IA",
// Kansas: "KS",
// Kentucky: "KY",
// Louisiana: "LA",
// Maine:	"ME",
// Maryland: "MD",
// Massachusetts: "MA",
// Michigan: "MI",
// Minnesota:"MN",
// Mississippi: "MS",
// Missouri:	"MO",
// Montana:	"MT",
// Nebraska:	"NE",
// Nevada: "NV",
// New Hampshire: "NH",
// New Jersey: "NJ",
// New Mexico":"NM",
// New York: "NY",
// North Carolina: "NC",
// North Dakota: "ND",
// Ohio: "OH",
// Oklahoma: "OK",
// Oregon:	"OR",
// Pennsylvania:	"PA",
// Rhode Island:"RI",
// South Carolina: "SC",
// South Dakota: "SD",
// Tennessee: "TN",
// Texas:	"TX",
// Utah: "UT",
// Vermont: "VT",
// Virginia: "VA",
// Washington:"WA",
// West Virginia: "WV",
// Wisconsin: "WI",
// Wyoming: "WY