
import React, { useEffect } from 'react';

interface ITableauGenericViewProps {
    controllerParam: string
    height: number
}

const TrendAnalysisView: React.FC<ITableauGenericViewProps> = (props) => {

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://10ay.online.tableau.com/javascripts/api/viz_v1.js";
        script.async = true;

        document.body.appendChild(script);

    }, [])

    return (
            <div style={{ overflow: 'auto', textAlign: 'center', backgroundColor: 'white' }}>
                <div className='tableauPlaceholder' style={{ width: '100%', height: props.height, marginRight: 30 }}>
                <object className='tableauViz' width={'100%'} height={props.height} style={{ display: 'none' }}>
                        <param name='host_url' value='https%3A%2F%2F10ay.online.tableau.com%2F' />
                        <param name='embed_code_version' value='3' />
                        <param name='site_root' value='&#47;t&#47;predictivecompliance' />
                        <param name='name' value='TrendAnalysisBook&#47;TrendDash' />
                        <param name='tabs' value='no' />
                        <param name='toolbar' value='no' />
                        <param name='showAppBanner' value='false' />
                        <param name='filter' value={props.controllerParam}/>
                    </object>
                </div>
            </div>
    );
}

export default TrendAnalysisView








