import { PREDICTED_PENALTY_DISCREPANCY, TYPE_OF_ACTION } from "../enums/citation-enums"
import { usdFormatter } from "../utils/general";

const isOrderTypes = [
    TYPE_OF_ACTION._314_B,
    TYPE_OF_ACTION._107_A,
    TYPE_OF_ACTION._103_K,
    TYPE_OF_ACTION._104_B
]


export const RenderCitationPenalty = (specialAssessment: boolean, citationMetaDataPenalty: number, typeOfAction: TYPE_OF_ACTION, GFE: boolean, adjustAmount: boolean = true) => {
    let isOrder = false;

    if (isOrderTypes.includes(typeOfAction)) {
        isOrder = true;
    }

    let penaltyVal = citationMetaDataPenalty
    if (GFE && adjustAmount){
        penaltyVal = citationMetaDataPenalty * .9
    }
    let returnVal = usdFormatter.format(penaltyVal);

    if (specialAssessment) {
        return "(Special Assessment) " + returnVal;
    }
    else if (isOrder) {
        return "Order**";
    }

    return returnVal;

}

export const GetPenaltyDiscrepancy = (penalty: number, mshaProposedPenalty: number): PREDICTED_PENALTY_DISCREPANCY => {
    const penaltyWarningThreshold = 0.1 * mshaProposedPenalty;
    const goodFaithPenalty = Math.floor(penalty * .9);
    const penaltyDifference = goodFaithPenalty - mshaProposedPenalty;

    if (Math.abs(penaltyDifference) >= penaltyWarningThreshold) // TODO: make threshold configurable
    {
        if (penaltyDifference > 0){
            return PREDICTED_PENALTY_DISCREPANCY.ABOVE_THRESHOLD
        }
        else if (penaltyDifference < 0){
            return PREDICTED_PENALTY_DISCREPANCY.BELOW_THRESHOLD
        }  
    }
    return PREDICTED_PENALTY_DISCREPANCY.WITHIN_THRESHOLD
}