import { Button, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import MaterialTable from "material-table";
import { tableIcons } from "../shared/MaterialTableShared";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import DialogBox from "../form/DialogBox";
import {
  createRegion,
  deleteRegion,
  getRegion,
  updateRegion,
} from "../../store/actions/regionActions";
import RegionsModal from "./RegionsModal";
import { useParams } from "react-router";

interface IRegionsTable {
  data: any[];
}

const RegionsTable: React.FC<IRegionsTable> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState({} as any);
  const [selectedRow, setSelectedRow] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const onSubmitActionModal = (
    data: any,
    onSuccess: Function,
    onFail?: Function
  ) => {
    if (data.pk) {
      dispatch(
        updateRegion(
          data,
          () => {
            onSuccessAction(onSuccess);
          },
          onFail
        )
      );
    } else {
      dispatch(
        createRegion(
          { data: data, companyId: companypk },
          () => {
            onSuccessAction(onSuccess);
          },
          onFail
        )
      );
    }
  };

  const { companypk } = useParams() as any;
  const onSuccessAction = (fnModalAction: any) => {
    fnModalAction();
    setIsModalOpen(false);
  };

  const emptySet = {
    pk: 0,
    regionName: "",
    companyFk: companypk,
    selectedMines: [],
    selectedBusinessUnits: [],
  };

  const ongetRegionDetail = (itemId: any) => {
    dispatch(
      getRegion(itemId, (data: any) => {
        setRowData(data[0]);
        setIsModalOpen(true);
      })
    );
  };

  const onDeleteAction = (itemId: number) => {
    dispatch(deleteRegion(itemId, onDeleteItemSuccess, onDeleteItemFail));
  };

  const onDeleteItemSuccess = (wasAdded: boolean) => {
    enqueueSnackbar("Item was successfully deleted.", {
      variant: "success",
    });
  };

  const onDeleteItemFail = (message: string) => {
    enqueueSnackbar("Unable to delete this element.", {
      variant: "error",
    });
  };

  const onOkDialogAction = () => {
    onDeleteAction(selectedRow);
    setIsDeleteDialogOpen(false);
  };

  const onCancelOrCloseDialogAction = () => {
    setIsDeleteDialogOpen(false);
    setSelectedRow(0);
  };

  const columns = [
    { title: "Region Name", field: "regionName", sorting: true },
    {
      title: "Actions",
      render: (row: any) => {
        return (
          <div>
            <Tooltip title="Edit" placement="top">
              <EditIcon
                style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
                className="transparent75"
                onClick={() => {
                  if (row && row.pk) {
                    ongetRegionDetail(row.pk);
                  }
                }}
              />
            </Tooltip>

            <Tooltip title="Delete" placement="top">
              <DeleteIcon
                style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
                className="transparent75"
                onClick={() => {
                  if (row.pk) {
                    setIsDeleteDialogOpen(true);
                    setSelectedRow(row.pk);
                  }
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <DialogBox
        id="dialogbox1"
        open={isDeleteDialogOpen}
        onClose={onCancelOrCloseDialogAction}
        onCancel={onCancelOrCloseDialogAction}
        onOk={onOkDialogAction}
        title="Are you sure you want to delete this Region?"
        innerText="After deleting this Region you will not be able to roll it back."
      ></DialogBox>

      <RegionsModal
        itemData={rowData}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Region"
        onSubmit={onSubmitActionModal}
        isEditMode={rowData.pk ? true : false}
        isCompany={true}
      />

      <Button
        style={{ marginBottom: 5 }}
        onClick={() => {
          setRowData(emptySet);
          setIsModalOpen(true);
        }}
        variant="outlined"
        size="small"
      >
        Add Region
      </Button>
      <MaterialTable
        title="Regions"
        icons={tableIcons}
        columns={columns}
        data={props.data}
        options={{
          sorting: true,
          headerStyle: {
            zIndex: 8,
          },
          paging: true,
          pageSize: 5,
          draggable: false,
          //actionsColumnIndex: -1
        }}
      />
    </>
  );
};

export default RegionsTable;
