import React from 'react';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { Typography } from '@material-ui/core';
import { strftime } from '../../utils/strftime';

interface IKeyboardDatePickerWrap extends KeyboardDatePickerProps {
    mode: "edit" | "view"
}

const KeyboardDatePickerWrap: React.FC<IKeyboardDatePickerWrap> = (props) => {
    const { mode, value } = props;

    return mode === 'edit' ? (
        <KeyboardDatePicker
            {...props} />
    ) : (
        <Typography>
            { value ? strftime('%m/%d/%Y', new Date(value as string)): ''}
        </Typography>
    )
}

export default KeyboardDatePickerWrap;