import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";
interface IFAQProps {}

const FAQ: React.FC<IFAQProps> = () => {
  return (
    <Container
      style={{ minHeight: "100vh", backgroundColor: COLORS.WHITE_PAPER }}
    >
      <h1>FAQ</h1>
      <Accordion>
        <AccordionSummary>
          <Typography>
            <b>1.</b> How do I calculate a citation penalty?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Navigate to the instant penalty calculations section
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography>
            <b>2.</b> How do I scan a citation?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Navigate to your citations page and click "Add Citation"
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography>
            <b>3.</b> How do I delete a citation?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Navigate to your citations page and click "Delete Citation"
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default FAQ;
