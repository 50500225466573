import { all, call, put } from "redux-saga/effects";
import { ACTIONS } from "../../enums/actions";
import { HttpResponse } from "../../interfaces/api/HttpResponse";
import { IDispatchAction } from "../../interfaces/store";
import HttpClient from "../../lib/HttpClient";
import GetFriendlyError from "../../utils/general";
import { normalize, schema } from "normalizr";
const client = new HttpClient();

const companyEmailSettings_schema = new schema.Entity(
  "company_email_settings",
  {},
  { idAttribute: "pk" }
);

const citation_columns_schema = new schema.Entity(
  "company_email_settings_citation_columns",
  {},
  { idAttribute: "pk" }
);

const inspection_columns_schema = new schema.Entity(
  "company_email_settings_inspection_columns",
  {},
  { idAttribute: "pk" }
);

async function getAllCompanyEmailSettingsAPI(
  companyId: Number
): Promise<HttpResponse<{ x: string }>> {
  return client.get(`api/companies/${companyId}/settings`);
}

async function getCompanyEmailSettingsAPI(
  settingId: Number
): Promise<HttpResponse<{ x: string }>> {
  return client.get(`api/settings/${settingId}`);
}

async function createCompanyEmailSettingAPI(
  data: any,
  companyId: Number
): Promise<HttpResponse<any>> {
  return client.post(`api/companies/${companyId}/settings`, data);
}

async function updateCompanyEmailSettingAPI(
  data: any
): Promise<HttpResponse<any>> {
  return client.put("api/settings", data);
}

async function deleteCompanyEmailSettingAPI(
  settingId: Number
): Promise<HttpResponse<any>> {
  return client.delete(`api/settings/${settingId}`);
}

const emailSettingsSaga = {
  *deleteCompanyEmailSetting(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        deleteCompanyEmailSettingAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        const isAccountDeleted: boolean = response.data;
        if (isAccountDeleted) {
          let removeKeys = [];
          removeKeys.push({
            entities: "company_email_settings",
            ids: Array.of(payload),
          });
          yield put({
            type: "DELETE_COMPANY_EMAIL_SETTING_SUCCESS",
            removeKeys: removeKeys,
          });

          if (action.onSuccess) {
            action.onSuccess(response.data);
          }
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getCompanyEmailSetting(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getCompanyEmailSettingsAPI,
        payload
      )) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *createCompanyEmailSetting(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        createCompanyEmailSettingAPI,
        payload.data,
        payload.companyId
      )) as HttpResponse<any>;

      if (response.status === 200 || response.status === 201) {
        yield all([
          put({
            type: "CREATE_COMPANY_EMAIL_SETTING_SUCCESS",
            normalized: normalize(response.data, [companyEmailSettings_schema]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess(response.data.pk);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *updateCompanyEmailSetting(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        updateCompanyEmailSettingAPI,
        payload
      )) as HttpResponse<any>;

      if (response.status === 200 || response.status === 201) {
        yield all([
          put({
            type: "UPDATE_COMPANY_EMAIL_SETTING_SUCCESS",
            normalized: normalize(response.data, [companyEmailSettings_schema]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess(response.data.pk);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *getAllCompanyEmailSettings(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(
        getAllCompanyEmailSettingsAPI,
        payload
      )) as HttpResponse<any>;

      if (response.status === 200 || response.status === 201) {
        yield all([
          put({
            type: "GET_ALL_COMPANY_EMAIL_SETTING_SUCCESS",
            normalized: normalize(response.data.emailSettings, [
              companyEmailSettings_schema,
            ]),
          }),
          put({
            type: "GET_ALL_COMPANY_EMAIL_SETTING_SUCCESS",
            normalized: normalize(response.data.citationColumns, [
              citation_columns_schema,
            ]),
          }),
          put({
            type: "GET_ALL_COMPANY_EMAIL_SETTING_SUCCESS",
            normalized: normalize(response.data.inspectionColumns, [
              inspection_columns_schema,
            ]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess(response.data.pk);
        }
      }
    } catch (e) {
      const error = e as Error;

      if (action.onFail) {
        action.onFail(error.message);
      }

      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
};

export default emailSettingsSaga;
